import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { countries } from '@aircarbon/ui';
import { helpers } from '@aircarbon/utils-common';

import { fetchCarbonMetaOptions } from 'data-provider/carbon/fetchCarbonMetaOptions';

function useCarbonMetaOptions({ options, query = {} }: { options?: Record<string, any>; query?: Record<string, any> }) {
  const queryString = helpers.objectToQueryString(query);
  const {
    data: carbonMetaOptions,
    isLoading,
    error,
  } = useQuery(`/api/carbon/meta-options${queryString ? '?' + queryString : ''}`, () => fetchCarbonMetaOptions(query), {
    ...options,
    refetchOnWindowFocus: false,
  });

  const carbonMetaOptionsResult = useMemo(() => {
    return {
      ...carbonMetaOptions?.data,
      countries: countries.reduce((curr, country) => ({ ...curr, [country.countryCode]: country.name }), {}) as Record<
        string,
        string
      >,
    };
  }, [carbonMetaOptions]);

  return {
    carbonMetaOptions: carbonMetaOptionsResult,
    isLoading,
    error,
  };
}

export default useCarbonMetaOptions;
