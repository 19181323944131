import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { queryCache, useMutation } from 'react-query';
import { Input } from 'refreshed-component/atoms/Input';
import Loading from 'refreshed-component/molecules/Loading';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import { AuctionBidsQueryKey } from 'refreshed-pages/auction/Marketplace';
import { CmbBidsQueryKey } from 'refreshed-pages/market-board/Marketplace';
import * as yup from 'yup';

import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  Icon,
  IconName,
  Layer,
  Text,
  TextAs,
  TextColor,
  ToastVariant,
  Tooltip,
  TypographyVariant,
  showToast,
} from '@aircarbon/ui';
import { type AssetCategory, FeeType, formatter, helpers, logger } from '@aircarbon/utils-common';

import type { CmbAsk } from 'pages/account/carbon/CMB/types';

import FormDevTool from 'components/FormDevTool';
import SelectOboAccount, { type AccountDetails } from 'components/SelectOboAccount';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import useAvailableBalance from 'hooks/useAvailableBalance';
import useCurrencies from 'hooks/useCurrencies';
import useDebounce from 'hooks/useDebounce';
import useFee from 'hooks/useFee';

import { convertTextNumberToValue } from 'utils/helpers';

type FormData = {
  quantity: number;
  price: number;
};

type Props = {
  ask: CmbAsk;
  tokenAssetCategoryId: AssetCategory;
  depositPercentage: number;
  minQty: number;
  maxQty: number;
  bidQtyMultiplier: number;
  minBid?: number;
  availableBalance: number;
  tokenUnit: string;
  tokenAssetNumDecimals: number;
  onSuccess?: () => void;
  onLoading?: (isLoading?: boolean) => void;
};

const bidFormSchema = ({
  minQty,
  maxQty,
  bidQtyMultiplier,
  minBid,
  availableBalance,
  availableQuantity,
  tokenUnit,
  tokenAssetNumDecimals,
  depositAmount,
}: {
  minQty: number;
  maxQty: number;
  bidQtyMultiplier: number;
  minBid?: number;
  availableBalance: number;
  availableQuantity: number;
  tokenUnit: string;
  tokenAssetNumDecimals: number;
  depositAmount: number;
}) =>
  yup.object().shape({
    quantity: yup
      .number()
      .required()
      .test(
        'minQuantity',
        `Minimum quantity is ${formatter.formatNumber(minQty ?? 0, tokenAssetNumDecimals)} ${tokenUnit}`,
        function minQuantity(quantity?: number) {
          if (minQty && quantity) {
            return quantity >= minQty;
          }
          return true;
        },
      )
      .test(
        'maxQuantity',
        `Maximum quantity is ${formatter.formatNumber(maxQty ?? 0, tokenAssetNumDecimals)} ${tokenUnit}`,
        function maxQuantity(quantity?: number) {
          if (maxQty && quantity) {
            return quantity <= maxQty;
          }
          return true;
        },
      )
      .test('hasEnoughAmount', 'Insufficient funds to cover deposit', function hasEnoughAmount() {
        return depositAmount <= Number(availableBalance);
      })
      .test('isInMultiplesOf', `Should be multiple of ${bidQtyMultiplier}`, function isMultipleOf(quantity: any) {
        if (bidQtyMultiplier && quantity) {
          return quantity % bidQtyMultiplier === 0;
        }
        return true;
      })
      .test(
        'availableQty',
        `There is only ${formatter.formatNumber(availableQuantity, tokenAssetNumDecimals)} ${tokenUnit} available`,
        function availableQty(quantity: any) {
          if (availableQuantity && quantity) {
            return quantity <= availableQuantity;
          }
          return true;
        },
      ),
    price: yup
      .number()
      .required()
      .test(
        'minBidValidation',
        `Minimum bid is ${formatter.formatNumber(minBid ?? 0, tokenAssetNumDecimals)}`,
        function minBidValidation(price?: number) {
          if (!price) return false;
          if (minBid && price) return price >= minBid;
          return true;
        },
      )
      .transform(function (value, originalValue) {
        if (this.isType(value)) return value;

        return convertTextNumberToValue(originalValue);
      }),
  });

const PlaceBid = ({
  ask,
  tokenAssetCategoryId,
  minQty,
  maxQty,
  bidQtyMultiplier,
  depositPercentage,
  minBid,
  availableBalance,
  tokenUnit,
  tokenAssetNumDecimals,
  onSuccess,
  onLoading,
}: Props) => {
  const {
    selector: { mainCcyScId, mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();

  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();
  const ccyAsset = currenciesObjById?.[ask?.quoteAssetId];
  const {
    selector: { getAuthToken, getAccountAddress, getUserId, getFullName },
    status: { canCmbBidObo },
  } = User.useContainer();

  // Used for Members
  const [selectedAccount, setSelectedAccount] = useState<AccountDetails>({
    account: getAccountAddress(),
    userId: getUserId(),
    fullName: getFullName(),
  });

  const auctionBidsQueryKey = useContext(AuctionBidsQueryKey);
  const cmbBidsQueryKey = useContext(CmbBidsQueryKey);

  const [tokenQty, setTokenQty] = useState(0);
  const [price, setPrice] = useState(ask.price);
  const debouncedTokenQty = useDebounce(tokenQty, 500);
  const debouncedPrice = useDebounce(price, 500);

  const debouncedTradeTotal = debouncedTokenQty * debouncedPrice;

  const { feeAmount, isLoading: isLoadingFeeAmount } = useFee({
    params: {
      feeType: ask?.isAuction ? FeeType.TRADE_AUCTION_FEE : FeeType.TRADE_CMB_FEE,
      assetCategoryId: tokenAssetCategoryId,
      tokenQty: debouncedTokenQty,
      totalAmount: debouncedTradeTotal,
    },
    options: { enabled: debouncedTokenQty > 0 && debouncedPrice > 0 },
  });

  const depositAmount =
    depositPercentage === 100 ? debouncedTradeTotal + feeAmount : (depositPercentage * debouncedTradeTotal) / 100;

  const { balance: selectedAccountBalance, isLoading: isLoadingAccountBalance } = useAvailableBalance({
    params: { ccyTypeId: mainCcyScId, getRoot: false, userId: selectedAccount?.userId },
    options: { enabled: !!mainCcyScId },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      price: ask.price,
    },
    resolver: yupResolver(
      bidFormSchema({
        minQty,
        maxQty,
        bidQtyMultiplier,
        minBid,
        availableBalance: selectedAccountBalance?.availableAmount ?? availableBalance,
        availableQuantity: ask.quantity - ask.openBidsTotalQty,
        tokenUnit,
        tokenAssetNumDecimals,
        depositAmount,
      }),
    ),
  });

  const [mutate, { isLoading, isSuccess }] = useMutation(async (bid: Record<string, any>) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const data = await fetch(`/api/user/carbon/cmb-ask/${ask?.id}/bid`, {
      method: 'POST',
      headers: {
        authorization: `Bearer ${authToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...bid,
        buyerUserId: selectedAccount.userId,
        quantity: bid.quantity,
        price: convertTextNumberToValue(bid.price),
      }),
    });
    queryCache.invalidateQueries([auctionBidsQueryKey, cmbBidsQueryKey]);
    return data;
  });

  useEffect(() => {
    onLoading?.(isLoading);
  }, [isLoading, onLoading]);

  useEffect(() => {
    if (isSuccess) onSuccess?.();
  }, [isSuccess, onSuccess]);

  const onSubmitHandler = async (formData: FormData) => {
    showToast({
      variant: ToastVariant.Info,
      message: 'Submitting bid..',
    });
    try {
      const addBid = await mutate({ ...formData, askId: ask.id });
      if (addBid?.ok) {
        showToast({
          variant: ToastVariant.Success,
          message: 'Bid submitted.',
        });
      } else {
        logger.warn(addBid);
        const error = await addBid?.json();
        showToast({
          variant: ToastVariant.Danger,
          message: error?.message ?? 'Something went wrong!',
        });
        logger.warn({ error });
      }
    } catch (err: any) {
      showToast({
        variant: ToastVariant.Danger,
        message: err?.message ?? 'Something went wrong!',
      });
    }
  };

  return (
    <>
      <FormDevTool control={control} />
      <form onSubmit={handleSubmit(onSubmitHandler)} autoComplete="off">
        <ModalContent>
          <div className="flex flex-col gap-large">
            <Layer>
              <Card className="w-full p-small gap-small">
                <div className="flex flex-row gap-small">
                  <div className="flex flex-col flex-1 gap-small">
                    <div className="flex flex-col">
                      <Text color={TextColor.secondary} variant={TypographyVariant.caption}>
                        Offer Number
                      </Text>
                      <Text>#{ask.id}</Text>
                    </div>
                    <div className="flex flex-col">
                      <Text color={TextColor.secondary} variant={TypographyVariant.caption}>
                        Available Qty
                      </Text>
                      <Text>
                        {formatter.formatNumber(ask.quantity, tokenAssetNumDecimals)} {tokenUnit}
                      </Text>
                    </div>
                  </div>
                  <div className="flex flex-col flex-1 gap-small">
                    <div className="flex flex-col">
                      <Text color={TextColor.secondary} variant={TypographyVariant.caption}>
                        Project Name
                      </Text>
                      <Text className="max-w-full"> {ask.__carbonProject__.name}</Text>
                    </div>
                    <div className="flex flex-col">
                      <Text color={TextColor.secondary} variant={TypographyVariant.caption}>
                        Price (per {tokenUnit})
                      </Text>
                      <Text>
                        {ccyAsset?.code ?? ''}
                        {formatter.formatNumber(ask.price, ccyAsset?.numDecimals)}
                      </Text>
                    </div>
                  </div>
                </div>
              </Card>
            </Layer>

            {canCmbBidObo() && (
              <div className="flex flex-row gap-large">
                <div className="flex flex-col flex-1 gap-2xs">
                  <Text variant={TypographyVariant.body2}>On Behalf Of</Text>
                  <SelectOboAccount
                    name="OBO"
                    inputValue={selectedAccount.account}
                    setFieldValue={(_selectedField, value) => {
                      setSelectedAccount({ account: value.value, userId: value.user_id, fullName: value.fullName });
                    }}
                  />
                  <div className="flex">
                    <Text variant={TypographyVariant.caption}>Available Balance:</Text>
                    <div className="relative pl-2">
                      {isLoadingAccountBalance ? (
                        <Loading size="small" />
                      ) : (
                        <Text as={TextAs.span}>
                          {mainCcyCode}
                          {formatter.formatNumber(selectedAccountBalance?.availableAmount ?? 0, mainCcyNumDecimals)}
                        </Text>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex flex-col sm:flex-row gap-large">
              <div className="flex flex-col flex-1 gap-2xs">
                <Text variant={TypographyVariant.body2}>
                  Bid Quantity{' '}
                  {
                    <Tooltip
                      value={`Min: ${formatter.formatNumber(minQty, tokenAssetNumDecimals)}. Max: ${formatter.formatNumber(
                        maxQty,
                        tokenAssetNumDecimals,
                      )}. Multiples of: ${formatter.formatNumber(bidQtyMultiplier, tokenAssetNumDecimals)}. Quantities in ${tokenUnit}`}
                    >
                      <Icon name={IconName.InfoCircle} />
                    </Tooltip>
                  }
                </Text>
                <Input
                  config={{
                    size: 'sm',
                    color: errors.quantity ? 'error' : 'gray',
                    postfix: tokenUnit,
                    validation: {
                      type: 'float',
                      numeralDecimalScale: 2,
                      numeralPositiveOnly: true,
                      numeralThousandsGroupStyle: 'thousand',
                    },
                  }}
                  onChange={(event) => {
                    const value = (event?.target as any)?.rawValue;
                    setValue('quantity', value as unknown as number);
                    setTokenQty(helpers.convertTextNumberToValue(event.target.value));
                  }}
                  value={getValues('quantity')}
                />
                {errors.quantity && (
                  <Text variant={TypographyVariant.body2} color={TextColor.error}>
                    {errors.quantity.message}
                  </Text>
                )}
              </div>
              <div className="flex flex-col flex-1 gap-2xs">
                <Text variant={TypographyVariant.body2}>Price (per {tokenUnit})</Text>
                <Input
                  config={{
                    size: 'sm',
                    color: errors.price ? 'error' : 'gray',
                    postfix: ccyAsset?.symbol ?? '',
                    validation: {
                      type: 'float',
                      numeralDecimalScale: 2,
                      numeralPositiveOnly: true,
                      numeralThousandsGroupStyle: 'thousand',
                    },
                  }}
                  onChange={(event) => {
                    const value = (event?.target as any)?.rawValue;
                    setValue('price', value as unknown as number);
                    setPrice(helpers.convertTextNumberToValue(event.target.value));
                  }}
                  value={getValues('price')}
                />
                <Text color={TextColor.secondary} variant={TypographyVariant.caption}>
                  Amount (max: {ccyAsset?.code ?? ''}
                  {formatter.formatNumber(availableBalance, ccyAsset?.numDecimals)})
                </Text>
                {errors.price && (
                  <Text variant={TypographyVariant.body2} color={TextColor.error}>
                    {errors.price.message}
                  </Text>
                )}
              </div>
            </div>
            <Layer>
              <Card>
                <div className="w-full p-small gap-small">
                  <div className="flex flex-col flex-1 sm:flex-row gap-small">
                    <div className="flex flex-col flex-1">
                      <Text color={TextColor.secondary} variant={TypographyVariant.caption}>
                        Total Amount
                      </Text>
                      <Text className="max-w-full">
                        {ccyAsset?.code ?? ''}
                        {formatter.formatNumber(
                          helpers.convertTextNumberToValue(debouncedTradeTotal),
                          ccyAsset?.numDecimals,
                        )}
                      </Text>
                    </div>
                    <div className="flex flex-col flex-1">
                      <Text color={TextColor.secondary} variant={TypographyVariant.caption}>
                        Fee
                      </Text>
                      <Text>
                        {ccyAsset?.code ?? ''}
                        {formatter.formatNumber(feeAmount, ccyAsset?.numDecimals)}
                      </Text>
                    </div>
                    <div className="flex flex-col flex-1">
                      <Text color={TextColor.secondary} variant={TypographyVariant.caption}>
                        Deposit ({depositPercentage}%)
                      </Text>
                      <Text>
                        {ccyAsset?.code ?? ''}
                        {formatter.formatNumber(depositAmount, ccyAsset?.numDecimals)}
                      </Text>
                    </div>
                  </div>
                </div>
              </Card>
            </Layer>

            <div className="flex flex-col w-full gap-small">
              <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                A deposit will be automatically collected if the supplier accepts the bid. Please ensure that your
                account is sufficiently funded to meet the deposit requirement or else your bid will not be accepted
                (deposit: {depositPercentage}% of total). Once you press confirm, it will be a binding bid. You will not
                be able to cancel once the supplier confirms the trade.
              </Text>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button
            size={ButtonSize.s}
            type={ButtonType.Submit}
            className="flex-1"
            isLoading={isLoadingFeeAmount || isLoading}
          >
            Place Bid
          </Button>
        </ModalFooter>
      </form>
    </>
  );
};

export default PlaceBid;
