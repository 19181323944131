import type { CardCMBProps } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import { formatDate } from 'utils/helpers';

import type { FetchListingsResponse } from '../components/Listings/utils/fetchListings';
import type { OrderDetails } from '../components/PlaceOrderModal';
import { toTuple } from './toTuple';

const { formatNumber } = formatter;

export const toPlaceOrderModalOrderDetails =
  (
    toMetaFields: (criteriaItem: { id: string; key: string; value: string }) => CardCMBProps['metaFields'][0][0] | null,
  ) =>
  (otcOrderApiItem: FetchListingsResponse['data'][0]): OrderDetails => {
    return {
      id: otcOrderApiItem.id,
      type: otcOrderApiItem.type,
      endsAt: otcOrderApiItem?.expiryUtc ? formatDate(otcOrderApiItem?.expiryUtc) : '',
      openQuantity: formatNumber(otcOrderApiItem.quantity, otcOrderApiItem?.baseAsset?.numDecimals ?? 0),
      rawQuantity: otcOrderApiItem.quantity,
      rawPrice: otcOrderApiItem.price,
      isPrefunded: otcOrderApiItem.isPrefunded === 1,
      price: otcOrderApiItem?.price
        ? new Intl.NumberFormat('en-US', { style: 'currency', currency: otcOrderApiItem.priceCurrency }).format(
            otcOrderApiItem?.price,
          )
        : '-',
      priceLabel: `Price (per ${otcOrderApiItem.quantityUnit})`,
      minimumQuantity: otcOrderApiItem.minQty,
      maximumQuantity: otcOrderApiItem.maxQty,
      quantityMultiplesOf: otcOrderApiItem.qtyMultipleOf,
      contractType: otcOrderApiItem.baseAsset?.name,
      auctionRulesLink: otcOrderApiItem.auctionRulesLink,
      metaFields: [
        ...(otcOrderApiItem?.project?.name
          ? [
              [
                {
                  label: 'Project',
                  value: [otcOrderApiItem.project.registryProjectId, otcOrderApiItem.project.name]
                    .filter((v) => !!v)
                    .join(' | '),
                },
              ],
            ]
          : ([] as any)),
        ...(otcOrderApiItem.projects?.length
          ? [
              [
                {
                  label: otcOrderApiItem.projects.length > 1 ? 'Projects' : 'Project',
                  value:
                    otcOrderApiItem.projects.length > 1
                      ? otcOrderApiItem.projects.map((project) => project.registryProjectId).join(', ')
                      : [otcOrderApiItem.projects[0].registryProjectId, otcOrderApiItem.projects[0].name]
                          .filter((v) => !!v)
                          .join(' | '),
                },
              ],
            ]
          : []),
        ...toTuple(otcOrderApiItem.orderCriteria?.map(toMetaFields).filter((field) => !!field) || []),
      ],
    };
  };
