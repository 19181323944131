import { type RefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import {
  type CounterOffer,
  type TradeRequest,
  fetchTradeRequests,
} from 'refreshed-pages/market-board-v2/utils/fetchTradeRequests';

import { ToastVariant, showToast } from '@aircarbon/ui';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import type { AcceptTradeRequestModalRef } from '../components/AcceptTradeRequestModal';
import type {
  CounterOfferModalRef,
  ShowCounterOfferModalProps,
} from '../components/ListingPage/components/IncomingOffers/components/CounterOfferModal';
import type { RejectRequestModalRef } from '../components/ListingPage/components/IncomingOffers/components/RejectRequestModal';
import { updateTradeRequest } from '../components/ListingPage/components/IncomingOffers/utils/updateTradeRequest';
import { globalEventsEmitter } from '../utils/globalEventsEmitter';
import { toCMBOrderType } from '../utils/toCMBOrderType';

export interface UseTradeRequestsProps {
  counterOfferModalRef: RefObject<CounterOfferModalRef>;
  rejectRequestModalRef: RefObject<RejectRequestModalRef>;
  acceptTradeRequestModalRef: RefObject<AcceptTradeRequestModalRef>;
  otcOrderId?: string;
  requestStatus?: string[];
  currentPage?: number;
  refetchOrder?: () => void;
}

export const useTradeRequests = (props: UseTradeRequestsProps) => {
  const {
    otcOrderId,
    counterOfferModalRef,
    rejectRequestModalRef,
    acceptTradeRequestModalRef,
    refetchOrder,
    currentPage = 1,
  } = props;
  const [tradeRequests, setTradeRequests] = useState<Array<TradeRequest>>([]);
  const [totalPages, setTotalPages] = useState(0);
  const [isAcceptingTradeRequest, setIsAcceptingTradeRequest] = useState(false);
  const [acceptingTradeRequest, setAcceptingTradeRequest] = useState<TradeRequest | undefined>();
  const { product } = useMarketplaceProduct();

  const {
    data: tradeRequestsResponse,
    isFetching: isFetchingTradeRequests,
    isError,
    refetch,
  } = useQuery(
    `trade-requests?${otcOrderId}&${props.requestStatus ? props.requestStatus : ''}&${currentPage}&${product}`,
    () =>
      fetchTradeRequests({
        assetCategoryId: product,
        otcOrderId,
        page: currentPage,
        ...(props.requestStatus ? { status: props.requestStatus } : {}),
      }),
  );

  useEffect(() => {
    const unsubscribeFromGlobalEvents = globalEventsEmitter.on('tradeRequestCreated', () => {
      refetch();
    });

    return unsubscribeFromGlobalEvents;
  }, [refetch]);

  useEffect(() => {
    if (isError) {
      showToast({
        variant: ToastVariant.Danger,
        message: 'Failed to retrieve trade requests!',
      });
      return;
    }

    if (tradeRequestsResponse?.data) {
      setTradeRequests(tradeRequestsResponse.data);
      setTotalPages(tradeRequestsResponse.totalPages);
    }
  }, [tradeRequestsResponse, isError]);

  const onCounterOfferPlaced = useCallback((counterOffer: CounterOffer) => {
    setTradeRequests((prevTradeRequests) => {
      const tradeRequestIndex = prevTradeRequests.findIndex((offer) => offer.id === counterOffer.tradeRequestId);

      if (tradeRequestIndex === -1) {
        return prevTradeRequests;
      }

      const newTradeRequests = [...prevTradeRequests];

      newTradeRequests[tradeRequestIndex] = {
        ...newTradeRequests[tradeRequestIndex],
        counterOffers: [{ ...counterOffer, direction: 'sent' }, ...newTradeRequests[tradeRequestIndex].counterOffers],
      };

      return newTradeRequests;
    });
  }, []);

  const acceptTradeRequest = useCallback(async () => {
    setIsAcceptingTradeRequest(true);
    const result = await updateTradeRequest({
      tradeRequestId: (acceptingTradeRequest as TradeRequest).id,
      status: 'ACCEPTED',
    });

    setIsAcceptingTradeRequest(false);

    if (result.status === 'error') {
      showToast({
        variant: ToastVariant.Danger,
        message: result.error,
      });
      return;
    }

    setTradeRequests((prevRequests) => {
      const foundRequestIndex = prevRequests.findIndex((request) => request.id === acceptingTradeRequest?.id);

      if (foundRequestIndex === -1) {
        return prevRequests;
      }

      const newRequests = [...prevRequests];

      newRequests[foundRequestIndex].status = 'ACCEPTED';
      newRequests[foundRequestIndex].approvedAtUtc = result.data.approvedAtUtc;
      newRequests[foundRequestIndex].confirmedByBuyerAtUtc = result.data.confirmedByBuyerAtUtc;
      newRequests[foundRequestIndex].price = result.data.price;
      newRequests[foundRequestIndex].quantity = result.data.quantity;

      return newRequests;
    });

    if (refetchOrder) {
      refetchOrder();
    }

    acceptTradeRequestModalRef.current?.hide();
  }, [acceptingTradeRequest]);

  const showRejectTradeRequestModal = useCallback((tradeRequestId: string) => {
    rejectRequestModalRef.current?.show({
      tradeRequestId,
    });
  }, []);

  const showPlaceCounterOfferModal = useCallback((modalProps: ShowCounterOfferModalProps) => {
    counterOfferModalRef.current?.show(modalProps);
  }, []);

  const showReviewOrderModal = useCallback(
    async (showReviewOrderModalProps: { tradeRequestId: string }) => {
      const { tradeRequestId } = showReviewOrderModalProps;
      const tradeRequest = tradeRequests.find((tr) => tr.id === tradeRequestId);

      if (!tradeRequest) {
        return;
      }

      setAcceptingTradeRequest(tradeRequest);

      const modalSummary = [];

      if (tradeRequest.tradeRequestDetail.__project__?.name) {
        modalSummary.push({
          label: 'Project',
          value: tradeRequest?.tradeRequestDetail.__project__?.name,
        });
      }

      if (tradeRequest.tradeRequestDetail.carbonProjectVintageYear) {
        modalSummary.push({
          label: 'Vintage',
          value: String(tradeRequest?.tradeRequestDetail.carbonProjectVintageYear),
        });
      }

      if (tradeRequest.isPrefunded) {
        modalSummary.push({
          label: 'Assets Reserved',
          value: 'Yes',
        });
      }

      const orderType = toCMBOrderType({
        isAuction: !!tradeRequest.otcOrder.isAuction,
        sideId: tradeRequest.otcOrder.sideId,
      });

      const lastReceivedOffer = tradeRequest.counterOffers.find(
        (offer) => offer.direction === 'received',
      ) as CounterOffer;

      acceptTradeRequestModalRef.current?.show({
        summary: modalSummary,
        quantity: lastReceivedOffer.quantity,
        openQty: tradeRequest.otcOrder.qty - tradeRequest.otcOrder.filledQty,
        price: lastReceivedOffer.price,
        isMaker: !!otcOrderId,
        orderId: tradeRequest.otcOrderId,
        orderType,
        numDecimals: tradeRequest.baseAsset?.numDecimals,
      });
    },
    [tradeRequests],
  );

  const onTradeRequestRejected = useCallback(
    (result: {
      id: string;
      approvedAtUtc: string;
      approvedBy: number;
      buyerConfirmationStatus: string;
      sellerConfirmationStatus: string;
      buyerUpdatedAtUtc: string;
      sellerUpdatedAtUtc: string;
      cancelledAtUtc: string;
      price: number;
      quantity: number;
      comment: string | null;
    }) => {
      setTradeRequests((prevRequests) => {
        const foundRequestIndex = prevRequests.findIndex((request) => request.id === result.id);

        if (foundRequestIndex === -1) {
          return prevRequests;
        }

        const newRequests = [...prevRequests];

        newRequests[foundRequestIndex].status = 'CANCELLED';
        newRequests[foundRequestIndex].cancelledAtUtc = result.cancelledAtUtc;
        newRequests[foundRequestIndex].price = result.price;
        newRequests[foundRequestIndex].quantity = result.quantity;
        newRequests[foundRequestIndex].comment = result.comment;

        return newRequests;
      });
    },
    [],
  );

  const onTradeRequestCancelled = useCallback(
    (tradeRequest: {
      id: string;
      approvedAtUtc: string;
      approvedBy: number;
      buyerConfirmationStatus: string;
      sellerConfirmationStatus: string;
      buyerUpdatedAtUtc: string;
      sellerUpdatedAtUtc: string;
      cancelledAtUtc: string;
      price: number;
      quantity: number;
    }) => {
      setTradeRequests((prevRequests) => {
        const foundRequestIndex = prevRequests.findIndex((request) => request.id === tradeRequest.id);

        if (foundRequestIndex === -1) {
          return prevRequests;
        }

        const newRequests = [...prevRequests];

        newRequests[foundRequestIndex].status = 'CANCELLED';
        newRequests[foundRequestIndex].cancelledAtUtc = tradeRequest.cancelledAtUtc;

        return newRequests;
      });
    },
    [],
  );

  const memoizedResponse = useMemo(
    () => ({
      tradeRequests,
      isFetchingTradeRequests,
      onCounterOfferPlaced,
      acceptTradeRequest,
      showPlaceCounterOfferModal,
      showRejectTradeRequestModal,
      onTradeRequestRejected,
      showReviewOrderModal,
      isAcceptingTradeRequest,
      onTradeRequestCancelled,
      totalPages,
    }),
    [
      tradeRequests,
      isFetchingTradeRequests,
      onCounterOfferPlaced,
      acceptTradeRequest,
      showPlaceCounterOfferModal,
      showRejectTradeRequestModal,
      showReviewOrderModal,
      onTradeRequestRejected,
      onTradeRequestCancelled,
      isAcceptingTradeRequest,
      totalPages,
    ],
  );

  return memoizedResponse;
};
