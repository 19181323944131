import { useCallback, useEffect, useRef, useState } from 'react';
import { useFilters } from 'refreshed-pages/market-board-v2/hooks/useFilters';
import { wait } from 'refreshed-pages/utils/wait';

import { logger } from '@aircarbon/utils-common';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';
import useTabActive from 'hooks/useTabActive';

import { type FetchListingsResponse, fetchListings } from '../utils/fetchListings';

export const useListings = () => {
  const [listings, setListings] = useState<FetchListingsResponse['data']>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const { product } = useMarketplaceProduct();
  const { getApiQueryParams } = useFilters();
  const [pagination, setPagination] = useState({
    totalCount: 0,
    currentPage: 1,
    totalPages: 1,
  });
  const [loadingMoreStopIndex, setLoadingMoreStopIndex] = useState<number | null>(null);
  const [refetchListings, setRefetchListings] = useState(false);
  const rawDataByIdRef = useRef<Map<string, FetchListingsResponse['data'][0]>>(new Map());
  const cancelableFetchListings = useRef<ReturnType<typeof wait<FetchListingsResponse>>>();
  const isTabActive = useTabActive();

  const loadListings = useCallback(
    async (page = 1) => {
      setIsLoading(true);

      cancelableFetchListings.current = wait(() =>
        fetchListings({
          assetCategoryId: product,
          queryParams: getApiQueryParams(page),
        }),
      );

      rawDataByIdRef.current.clear();
      const fetchListingsResponse = await cancelableFetchListings.current.job;
      fetchListingsResponse.data.forEach((item) => {
        rawDataByIdRef.current.set(item.id, item);
      });

      setListings(fetchListingsResponse.data);
      setLoadingMoreStopIndex(null);
      setPagination({
        currentPage: fetchListingsResponse.currentPage,
        totalCount: fetchListingsResponse.totalCount,
        totalPages: fetchListingsResponse.totalPages,
      });
      setIsLoading(false);
    },
    [getApiQueryParams, product],
  );

  useEffect(() => {
    if (isTabActive) {
      loadListings();
    }

    if (refetchListings) {
      logger.info('Refreshing listings');
      loadListings();
      setRefetchListings(false);
    }

    return () => {
      if (cancelableFetchListings.current) {
        cancelableFetchListings.current.cancel();
      }
    };
  }, [loadListings, isTabActive, refetchListings]);

  /**
   * Load more listings
   * @param startIndex — from where to start loading (the index of the last loaded item)
   * @param stopIndex — the index of new last item to be loaded
   */
  const loadMore = useCallback(
    (startIndex: number, stopIndex: number) => {
      const loadListings = async () => {
        setLoadingMoreStopIndex(stopIndex);
        setIsLoadingMore(true);

        const loadedListings = await fetchListings({
          assetCategoryId: product,
          queryParams: getApiQueryParams(pagination.currentPage + 1),
        });

        loadedListings.data.forEach((item) => {
          rawDataByIdRef.current.set(item.id, item);
        });

        setListings((currentListings) => [...currentListings, ...loadedListings.data]);
        setPagination({
          currentPage: loadedListings.currentPage,
          totalCount: loadedListings.totalCount,
          totalPages: loadedListings.totalPages,
        });
        setIsLoadingMore(false);
      };

      if (stopIndex <= (loadingMoreStopIndex || 0)) {
        return;
      }

      if (!isLoadingMore && !isLoading && pagination.currentPage <= pagination.totalPages) {
        loadListings();
      }
    },
    [
      getApiQueryParams,
      loadingMoreStopIndex,
      product,
      isLoadingMore,
      pagination.totalPages,
      pagination.currentPage,
      isLoading,
    ],
  );

  /**
   * Load a specific page of listings
   * @param page - page number to load
   */
  const loadPage = useCallback(
    (page: number) => {
      if (page === pagination.currentPage) {
        return;
      }
      loadListings(page);
    },
    [loadListings, pagination.currentPage],
  );

  const getRawDataItemById = useCallback((itemId: string) => {
    return rawDataByIdRef.current.get(itemId);
  }, []);

  return {
    listings,
    loadMore,
    loadPage,
    isLoading,
    refetch: () => setRefetchListings(true),
    getRawDataItemById,
    totalCount: pagination.totalCount,
    pagination,
  };
};
