import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { generatePath } from 'refreshed-component/templates/ContentHolder';

import {
  BorderColor,
  ButtonSize,
  ButtonVariant,
  Divider,
  IconName,
  Knob,
  Text,
  TypographyVariant,
  SidebarMenu as UISidebarMenu,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
} from '@aircarbon/ui';

import { ThemeMode } from 'pages/account/trading/components/ThemeMode';

import { Entity } from 'state/entity';

import Popover from '../atoms/Popover';

const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(6)};
  outline: rgba(0, 0, 0, 0) solid 0px !important;
  transition:
    background-color 200ms ease-in-out,
    border-color 200ms ease-in-out,
    outline-color 200ms ease-in-out;
  padding: ${({ theme }) => toSpacing(theme)(4)} ${({ theme }) => toSpacing(theme)(6)};
  border-radius: ${({ theme }) => theme.system.border.radius.m};
  overflow: hidden;
`;

const SideBarRoot = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.components.sidebar.background};
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: ${({ theme }) => toSpacing(theme)(6)};
  padding-top: ${({ theme }) => toSpacing(theme)(12)};
  gap: ${({ theme }) => toSpacing(theme)(12)};

  > .title {
    display: flex;
    padding-inline-start: ${({ theme }) => toSpacing(theme)(6)};
    padding-inline-end: ${({ theme }) => toSpacing(theme)(6)};
    flex-direction: row;
    justify-items: center;
    align-items: center;
    justify-content: space-between;
  }

  > .menu {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }

  &.expanded {
    > .title {
      flex-direction: row;
      gap: ${({ theme }) => toSpacing(theme)(8)};
      align-items: flex-start;
    }
    > .menu {
      gap: ${({ theme }) => toSpacing(theme)(6)};
      > div.item {
        transition: all 100ms;
      }
    }
  }

  &.collapsed {
    align-items: center;
    > .title {
      flex-direction: column;
      gap: ${({ theme }) => toSpacing(theme)(6)};
    }
    > .menu {
      gap: ${({ theme }) => toSpacing(theme)(6)};
    }
  }
`;

export type SideBarMenuItemProps = {
  title: string;
  path?: string;
  query?: { [key: string]: string };
  isSelected?: boolean;
};

export type SideBarMenuProps = { isParentExpanded?: boolean; onClickItem?: () => void } & (
  | {
      type: 'Separator';
    }
  | ({
      path?: string;
      query?: {
        [key: string]: string;
      };
      title: string;
      icon: IconName;
    } & (
      | {
          type: 'list';
          list?: SideBarMenuItemProps[];
        }
      | {
          type: 'link';
          path?: string;
          query?: { [key: string]: string };
          isSelected?: boolean;
        }
    ))
);

export type SideBarProps = {
  menu: SideBarMenuProps[];
  isHeaderVisible: boolean;
  isExpandable?: boolean;
  isExpanded?: boolean;
  onCollops?: () => void;
};

const ItemListRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  transition:
    max-height 100ms ease-in-out,
    margin-bottom 100ms ease-in-out;
  &.collapsed {
    max-height: 0;
    margin-bottom: calc(-1 * ${({ theme }) => toSpacing(theme)(6)});
    overflow: hidden;
  }
  &.expanded {
    max-height: 1000px;
    overflow: hidden;
    flex-shrink: 0;
  }
  > .holder {
    display: flex;
    flex-direction: column;
    height: auto;
    > .title {
      width: 100%;
      min-width: 200px;
      padding: ${({ theme }) => toSpacing(theme)(4)};
      border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
      margin-bottom: ${({ theme }) => toSpacing(theme)(4)};
    }
    > .item {
      display: flex;
      width: 100%;
      gap: ${({ theme }) => toSpacing(theme)(4)};

      &.selected {
        background-color: ${({ theme }) => toLayerBackground(theme)('layerAccent')};
      }

      &:hover {
        background-color: ${({ theme }) => toLayerBackground(theme)('layer')} !important;
      }
    }
    > .label {
      display: flex;
      width: 100%;
      gap: ${({ theme }) => toSpacing(theme)(4)};
      padding-inline-start: ${({ theme }) => toSpacing(theme)(4)};
      padding-inline-end: ${({ theme }) => toSpacing(theme)(4)};
    }
  }
`;

const SideBarMenuItemList = ({
  isExpanded,
  title,
  list,
  onClickItem,
}: {
  isExpanded?: boolean;
  inPopover?: boolean;
  title?: string;
  list: SideBarMenuItemProps[];
  onClickItem?: () => void;
}) => {
  const history = useHistory();
  return (
    <ItemListRoot className={`${isExpanded ? 'expanded' : 'collapsed'}`}>
      <div className="holder">
        {title && (
          <div
            onClick={() => {
              const firstItem = list[0];
              if (firstItem) {
                if (firstItem.path) {
                  history.push(generatePath(firstItem.path, firstItem.query));
                }
                onClickItem?.();
              }
            }}
            className="cursor-pointer title"
          >
            <Text variant={TypographyVariant.h6Title}>{title}</Text>
          </div>
        )}
        {list.map((item) => {
          const isSelected = item.isSelected;
          return (
            <MenuButton
              key={`${item.title}-${item.path}`}
              onClick={() => {
                if (item.path) {
                  history.push(generatePath(item.path, item.query));
                }
                onClickItem?.();
              }}
              className={`item ${isSelected ? 'selected' : 'deselected'}`}
            >
              <Text variant={TypographyVariant.body2}>{item.title}</Text>
            </MenuButton>
          );
        })}
      </div>
    </ItemListRoot>
  );
};

const SideBarMenu = (props: SideBarMenuProps) => {
  const isListItemSelected =
    props.type === 'link'
      ? props.isSelected
      : props.type === 'list' && props.list?.map((item) => item.isSelected).find((isSelected) => isSelected);

  const [isExpanded, setExpand] = useState(isListItemSelected);
  const history = useHistory();

  if (props.type === 'Separator') {
    return <StyledDivider />;
  }

  const onPressMenu = () => {
    if (props.type === 'list') {
      if (!props.isParentExpanded) {
        const page = props.list?.[0];
        if (page?.path) {
          history.push(generatePath(page.path, page.query));
        }
        props.onClickItem?.();
      } else {
        setExpand(!isExpanded);
      }
    } else {
      if (props.path) {
        history.push(generatePath(props.path, props.query));
      }
      props.onClickItem?.();
    }
  };

  const menu = (
    <UISidebarMenu
      title={props.title}
      isActive={isListItemSelected && props.type !== 'list'}
      isParent={props.type === 'list'}
      isExpanded={isExpanded}
      onPress={onPressMenu}
      icon={props.icon}
    />
  );

  return (
    <>
      {props.isParentExpanded ? (
        menu
      ) : props.type === 'list' && props.list ? (
        <Popover
          placement={'rightTop'}
          view="sidebarMenu"
          content={() =>
            props.list && (
              <SideBarMenuItemList isExpanded={true} title={props.title} inPopover={true} list={props.list} />
            )
          }
        >
          <div>
            <UISidebarMenu
              title={props.title}
              isActive={isListItemSelected}
              isParent={props.type === 'list'}
              isExpanded={isExpanded}
              isCompact
              onPress={onPressMenu}
              icon={props.icon}
            />
          </div>
        </Popover>
      ) : (
        <Popover
          placement={'rightTop'}
          view="sidebarMenu"
          content={() => (
            <ItemListRoot
              onClick={() => {
                if (props.path) {
                  history.push(generatePath(props.path, props.query));
                }
                props.onClickItem?.();
              }}
              className={`expanded cursor-pointer`}
            >
              <div className="holder">
                <div className="label">
                  <Text>{props.title}</Text>
                </div>
              </div>
            </ItemListRoot>
          )}
        >
          <div>
            <UISidebarMenu
              title={props.title}
              isActive={isListItemSelected}
              isParent={props.type === 'list'}
              isExpanded={isExpanded}
              isCompact
              onPress={onPressMenu}
              icon={props.icon}
            />
          </div>
        </Popover>
      )}
      {props.type === 'list' && props.list && props.isParentExpanded && isExpanded && (
        <ItemListRoot className={`${isExpanded ? 'expanded' : 'collapsed'}`}>
          {props.list.map((childMenu) => (
            <UISidebarMenu
              title={childMenu.title}
              isActive={childMenu.isSelected}
              key={`${childMenu.title}-${childMenu.path}`}
              onPress={() => {
                if (childMenu.path) {
                  history.push(generatePath(childMenu.path, childMenu.query));
                }
                props.onClickItem?.();
              }}
            />
          ))}
        </ItemListRoot>
      )}
    </>
  );
};

const MiniLogo = styled.div<{ logoUrl: string }>`
  min-height: 36px;
  min-width: 36px;
  background-image: url(${(props) => props.logoUrl});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  cursor: pointer;
`;

export const SideBar: React.FC<SideBarProps> = (props) => {
  const { isExpanded, isHeaderVisible } = props;
  const { entity } = Entity.useContainer();
  const { themeMode } = ThemeMode.useContainer();
  const history = useHistory();

  const isFinallyExpanded =
    props.isExpanded !== undefined && props.isExpandable === false ? props.isExpanded : isExpanded;

  return (
    <SideBarRoot className={`${isFinallyExpanded ? 'expanded' : 'collapsed'}`}>
      {isHeaderVisible && (
        <div className="title">
          {isFinallyExpanded ? (
            <StyledLogoWrapper onClick={() => history.push('/account/balances')}>
              <img src={themeMode === 'dark' ? entity.settings.logoDark : entity.settings.logoLight} />
            </StyledLogoWrapper>
          ) : (
            <MiniLogo
              key="min"
              onClick={() => history.push('/account/balances')}
              logoUrl={
                themeMode === 'dark' ? entity.settings.logoSidebarMiniDark : entity.settings.logoSidebarMiniLight
              }
            />
          )}
          {props.onCollops && (
            <Knob
              className="children"
              icon={IconName.ArrowStart}
              size={ButtonSize.s}
              variant={ButtonVariant.outlined}
              onPress={props.onCollops}
            />
          )}
        </div>
      )}
      <div className="menu">
        {props.menu.map((item, index) => {
          return (
            <SideBarMenu
              key={index}
              {...item}
              isParentExpanded={isFinallyExpanded}
              onClickItem={() => props.onCollops?.()}
            />
          );
        })}
      </div>
    </SideBarRoot>
  );
};

const StyledDivider = styled(Divider)`
  flex-shrink: 0;
  background: ${({ theme }) => theme.components.sidebar.divider.color};
`;

const StyledLogoWrapper = styled.div`
  display: flex;
  max-width: 120px;
  max-height: 120px;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
`;
