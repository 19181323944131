import { AssetCategory } from './assetCategory';
import * as Dto from './dto';

const carbonKeys = [
  Dto.ProjectMetaKeyCode.ProjectId,
  Dto.ProjectMetaKeyCode.RegistryId,
  Dto.ProjectMetaKeyCode.VintageYear,
  Dto.ProjectMetaKeyCode.CountryCode,
  Dto.ProjectMetaKeyCode.Classification,
  Dto.ProjectMetaKeyCode.UnSectoralScope,
  Dto.ProjectMetaKeyCode.SdgGoal,
];

const recKeys = [
  Dto.ProjectMetaKeyCode.ProjectId,
  Dto.ProjectMetaKeyCode.VintageYear,
  Dto.ProjectMetaKeyCode.CountryCode,
  Dto.ProjectMetaKeyCode.RegistryId,
  Dto.ProjectMetaKeyCode.Technology,
  Dto.ProjectMetaKeyCode.PrimaryFuel,
  Dto.ProjectMetaKeyCode.SubFuel,
  Dto.ProjectMetaKeyCode.SubRegion,
  Dto.ProjectMetaKeyCode.GenerationPeriodStart,
  Dto.ProjectMetaKeyCode.GenerationPeriodEnd,
  Dto.ProjectMetaKeyCode.Capacity,
  Dto.ProjectMetaKeyCode.CommissioningDate,
  Dto.ProjectMetaKeyCode.OtherCertification,
];

const fctKeys = [
  Dto.ProjectMetaKeyCode.ProjectId,
  Dto.ProjectMetaKeyCode.RegistryId,
  Dto.ProjectMetaKeyCode.VintageYear,
  Dto.ProjectMetaKeyCode.CountryCode,
  Dto.ProjectMetaKeyCode.Classification,
  Dto.ProjectMetaKeyCode.UnSectoralScope,
  Dto.ProjectMetaKeyCode.SdgGoal,
];

const carbonRequiredFields = {
  bidByProjectsRequiredFields: [Dto.ProjectMetaKeyCode.ProjectId, Dto.ProjectMetaKeyCode.VintageYear],
  bidByCriteriaRequiredFields: [Dto.ProjectMetaKeyCode.RegistryId, Dto.ProjectMetaKeyCode.VintageYear],
  offerByProjectRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
  offerByCriteriaRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
  auctionByProjectRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
  auctionByCriteriaRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
};

const recRequiredFields = {
  bidByProjectsRequiredFields: [Dto.ProjectMetaKeyCode.ProjectId, Dto.ProjectMetaKeyCode.VintageYear],
  bidByCriteriaRequiredFields: [
    Dto.ProjectMetaKeyCode.VintageYear,
    Dto.ProjectMetaKeyCode.CountryCode,
    Dto.ProjectMetaKeyCode.RegistryId,
    Dto.ProjectMetaKeyCode.PrimaryFuel,
  ],
  offerByProjectRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
  offerByCriteriaRequiredFields: [
    Dto.ProjectMetaKeyCode.VintageYear,
    Dto.ProjectMetaKeyCode.CountryCode,
    Dto.ProjectMetaKeyCode.RegistryId,
    Dto.ProjectMetaKeyCode.PrimaryFuel,
  ],
  auctionByProjectRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
  auctionByCriteriaRequiredFields: [
    Dto.ProjectMetaKeyCode.VintageYear,
    Dto.ProjectMetaKeyCode.CountryCode,
    Dto.ProjectMetaKeyCode.RegistryId,
    Dto.ProjectMetaKeyCode.PrimaryFuel,
  ],
};

const fctRequiredFields = {
  bidByProjectsRequiredFields: [Dto.ProjectMetaKeyCode.ProjectId, Dto.ProjectMetaKeyCode.VintageYear],
  bidByCriteriaRequiredFields: [Dto.ProjectMetaKeyCode.RegistryId, Dto.ProjectMetaKeyCode.VintageYear],
  offerByProjectRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
  offerByCriteriaRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
  auctionByProjectRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
  auctionByCriteriaRequiredFields: [Dto.ProjectMetaKeyCode.VintageYear],
};

export type MetaKeyMapping = Record<Dto.MbSupportedAssetCategory, Dto.ProjectMetaKeyCode[]>;

export const metaKeysMapping: MetaKeyMapping = {
  [AssetCategory.token]: carbonKeys,
  [AssetCategory.rec]: recKeys,
  [AssetCategory.fct]: fctKeys,
};

export const projectMetaKeysMapping: MetaKeyMapping = {
  [AssetCategory.token]: [
    Dto.ProjectMetaKeyCode.ProjectId,
    Dto.ProjectMetaKeyCode.RegistryId,
    Dto.ProjectMetaKeyCode.VintageYear,
    Dto.ProjectMetaKeyCode.CountryCode,
    Dto.ProjectMetaKeyCode.RegistryProjectStatus,
    Dto.ProjectMetaKeyCode.Classification,
    Dto.ProjectMetaKeyCode.UnSectoralScope,
    Dto.ProjectMetaKeyCode.SdgGoal,
  ],
  [AssetCategory.rec]: [
    Dto.ProjectMetaKeyCode.ProjectId,
    Dto.ProjectMetaKeyCode.VintageYear,
    Dto.ProjectMetaKeyCode.CountryCode,
    Dto.ProjectMetaKeyCode.RegistryId,
    Dto.ProjectMetaKeyCode.Technology,
    Dto.ProjectMetaKeyCode.TechnologyCode,
    Dto.ProjectMetaKeyCode.PrimaryFuel,
    Dto.ProjectMetaKeyCode.SubFuel,
    Dto.ProjectMetaKeyCode.SubRegion,
    Dto.ProjectMetaKeyCode.GenerationPeriodStart,
    Dto.ProjectMetaKeyCode.RegistryProjectStatus,
    Dto.ProjectMetaKeyCode.GenerationPeriodEnd,
    Dto.ProjectMetaKeyCode.Capacity,
    Dto.ProjectMetaKeyCode.CommissioningDate,
    Dto.ProjectMetaKeyCode.OtherCertification,
  ],
  [AssetCategory.fct]: [
    Dto.ProjectMetaKeyCode.ProjectId,
    Dto.ProjectMetaKeyCode.RegistryId,
    Dto.ProjectMetaKeyCode.VintageYear,
    Dto.ProjectMetaKeyCode.CountryCode,
    Dto.ProjectMetaKeyCode.Classification,
    Dto.ProjectMetaKeyCode.UnSectoralScope,
    Dto.ProjectMetaKeyCode.SdgGoal,
    Dto.ProjectMetaKeyCode.RegistryProjectStatus,
  ],
};

export const metaKeysRequiredFields = {
  [AssetCategory.token]: carbonRequiredFields,
  [AssetCategory.rec]: recRequiredFields,
  [AssetCategory.fct]: fctRequiredFields,
};
