import { Text, TypographyVariant, countries, useSpacing } from '@aircarbon/ui';
import { projects } from '@aircarbon/utils-common';

function Criteria({ filters }: { filters: Array<{ id: number; filterName: string; filterValue: string }> }): any {
  const filtersObj = filters.reduce((result: Record<string, Array<string>>, filter) => {
    const cloneResult = { ...result };
    if (!cloneResult[filter.filterName]) cloneResult[filter.filterName] = [];
    cloneResult[filter.filterName].push(filter.filterValue);
    return cloneResult;
  }, {});
  const { spacing } = useSpacing();

  return (
    <div className="flex flex-row gap-base">
      {Object.keys(filtersObj).map((key) => {
        const criteriaValues = filtersObj[key];
        return (
          <div className="flex flex-row">
            <Text variant={TypographyVariant.subtitle2} marginHorizontal={spacing(8)} marginVertical={spacing(4)}>
              {(projects.projectLabel[key] ?? key) === 'Token' ? 'Asset' : (projects.projectLabel[key] ?? key)}
              {criteriaValues.map((value) => {
                let criteriaValue = value;
                if (key.includes('LOCATION')) {
                  criteriaValue = countries.find((country) => country.countryCode === criteriaValue)?.name;
                } else if (key.includes('COUNTRY')) {
                  criteriaValue = countries.find((country) => country.countryCode === criteriaValue)?.name;
                } else if (key.includes('DATE')) {
                  criteriaValue = criteriaValue.slice(criteriaValue.length - 4);
                }
                return (
                  <div key={`${key}-${criteriaValue}`} className="criteriaValue">
                    {criteriaValue}
                  </div>
                );
              })}
            </Text>
          </div>
        );
      })}
    </div>
  );
}

export default Criteria;
