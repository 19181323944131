import type React from 'react';
import { type ReactNode, useContext, useState } from 'react';
import {
  type ContainerSize,
  ResponsiveContainerContext,
  ResponsiveContainerWrapper,
} from 'refreshed-component/atoms/ResponsiveContainer';
import { Spacing } from 'refreshed-component/design-system';

import {
  BorderColor,
  Card,
  CardVariant,
  Icon,
  IconName,
  Text,
  TextColor,
  Tooltip,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
  toTextColor,
  useTheme,
} from '@aircarbon/ui';

const DescriptionContentList = styled.div<{ size: ContainerSize }>`
  display: flex;
  flex-direction: row;
  flex: 1 1 0;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  border-radius: ${({ theme }) => theme.system.border.radius.s};
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  flex: 1 1 0px;
  border-radius: var(--border-radius-large);
  background: ${({ theme }) => toLayerBackground(theme)('layer')};
  width: 100%;
  > .children {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => toSpacing(theme)(8)};
    flex: 1 1 0;
  }
  > .controls {
    display: flex;
    flex-direction: row;
    gap: ${({ theme }) => toSpacing(theme)(8)};
    justify-content: center;
    align-items: center;
  }

  ${({ size }) => {
    if (size === 'small' || size === 'medium' || size === 'xs') {
      return `
        flex-direction: column;
      `;
    }
  }}

  ${({ size }) => {
    if (size === 'small' || size === 'xs') {
      return `
        > .children {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
      `;
    } else if (size === 'medium') {
      return `
        > .children {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
        }
      `;
    }
  }}
`;

export const DetailedCardDescriptionItemWithContentList = ResponsiveContainerWrapper(
  ({
    children,
    className,
    onClick,
    controls,
  }: {
    className?: string;
    onClick?: () => void;
    children?: ReactNode;
    controls?: ReactNode;
  }) => {
    const responsiveContainerContext = useContext(ResponsiveContainerContext);
    return (
      <DescriptionContentList
        size={responsiveContainerContext.size}
        onClick={onClick}
        className={`item p-large ${className || ''}`}
      >
        <div className="children">{children}</div>
        {controls && <div className="controls">{controls}</div>}
      </DescriptionContentList>
    );
  },
);

export const DetailedCardRoot = styled(Card).attrs<{ type?: 'light' | 'white'; size: ContainerSize }>({
  variant: CardVariant.Bordered,
})`
  flex: 1 0 0;

  width: 100%;
  > .children {
    display: flex;
    flex-direction: row;
    width: 100%;
    overflow: hidden;
    > .description {
      display: flex;
      flex: 1 1 0%;
      overflow: hidden;
      ${({ size, theme }) => {
        if (size === 'small' || size === 'medium' || size === 'xs') {
          return `
            flex-direction: column;
            > .item {
              justify-content: start;
              width: 100% !important;
              border-bottom: 1px solid ${toBorderColor(theme)(BorderColor.neutral)};
              &:last-child {
                border-bottom: 0px solid ${toBorderColor(theme)(BorderColor.neutral)};
              }
            }
          `;
        }
        return `
          flex-direction: row;
          > .item {
            border-right: 1px solid ${toBorderColor(theme)(BorderColor.neutral)};
            &:last-child {
              border-right: 0px solid ${toBorderColor(theme)(BorderColor.neutral)};
            }
          }
        `;
      }}
    }
    > .handle {
      padding: var(${Spacing.large});
      border-left: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &.opened > svg {
        rotate: 180deg;
      }
    }
  }
  > .body {
    display: flex;
    flex-direction: column;
    border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    width: auto;
    background-color: ${({ theme }) => toLayerBackground(theme)('field')};
    padding: ${({ theme }) => toSpacing(theme)(12)};
    gap: ${({ theme }) => toSpacing(theme)(12)};
    border-radius: 0 0 ${({ theme }) => `${theme.system.border.radius.l} ${theme.system.border.radius.l}`};
    > .body-grid {
      display: flex;
      flex-direction: row;
      gap: ${({ theme }) => toSpacing(theme)(8)};
      ${({ size }) => {
        if (size === 'small' || size === 'xs' || size === 'medium') {
          return `flex-direction: column;`;
        }
        return `flex-direction: row;`;
      }}
      > .image {
        flex: 1 1 0;
        border-radius: ${({ theme }) => theme.system.border.radius.l};
        background-position: top left;
        background-size: cover;
        background-color: ${({ theme }) => toLayerBackground(theme)('layer')};
        ${({ size }) => {
          if (size === 'small' || size === 'xs') {
            return `min-height: 250px;`;
          }
          return `min-height: 350px;`;
        }}
      }
      > .list {
        display: grid;
        gap: ${({ theme }) => toSpacing(theme)(8)};
        flex: 2 1 0;
        ${({ size }) => {
          if (size === 'small' || size === 'xs') {
            return `grid-template-columns: 1fr;`;
          } else if (size === 'medium' || size === 'large') {
            return `grid-template-columns: 1fr 1fr;`;
          }
          return `grid-template-columns: 1fr 1fr 1fr;`;
        }}
        > .item {
          flex: 1 1 0%;
          display: flex;
          flex-direction: column;
          gap: ${({ theme }) => toSpacing(theme)(2)};
          overflow: hidden;
        }
      }
    }
    > .details {
      padding-top: ${({ theme }) => toSpacing(theme)(12)};
      border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
      gap: ${({ theme }) => toSpacing(theme)(12)};
      display: flex;
      &:empty {
        display: none;
      }
      ${({ size }) => {
        if (size === 'small' || size === 'xs') {
          return `  flex-direction: column;`;
        }
        return ` flex-direction: row;`;
      }}
      > .item {
        display: flex;
        flex-direction: column;
        gap: ${({ theme }) => toSpacing(theme)(4)};
        overflow: hidden;
      }
    }
    > .actions {
      padding-top: ${({ theme }) => toSpacing(theme)(12)};
      display: flex;
      border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
      gap: ${({ theme }) => toSpacing(theme)(12)};
      ${({ size }) => {
        if (size === 'small' || size === 'xs') {
          return `
            flex-direction: column;
            align-items: center;
            justify-content: center;
          `;
        }
        return `
          flex-direction: row;
          align-items: end;
          justify-content: end;
        `;
      }}
    }
  }
`;

export type DetailedCardProps = {
  body?: React.ReactNode;
  className?: string;
  type?: 'light' | 'white';
};

export const DetailedCardDescriptionItem: React.FC<{ className?: string; onClick?: () => void }> = ({
  children,
  className,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={`item p-medium ${className || ''}`}>
      {children}
    </div>
  );
};

export const DetailedCardDescriptionItemWithoutPadding: React.FC<{ className?: string; onClick?: () => void }> = ({
  children,
  className,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={`item ${className || ''}`}>
      {children}
    </div>
  );
};

export const DetailedCardBodyGrid = ({
  className,
  imgUrl,
  list,
}: {
  className?: string;
  imgUrl?: string;
  list: (
    | {
        title: React.ReactNode;
        tip?: string;
        description: string | React.ReactNode;
      }
    | undefined
  )[];
}) => {
  return (
    <div className={`body-grid ${className || ''}`}>
      {imgUrl && (
        <div
          className="image"
          style={{
            backgroundImage: `url(${imgUrl})`,
          }}
        />
      )}
      <div className="list">
        {list.map(
          (item) =>
            item && (
              <div key={`${item.title}-${item.tip}`} className="item">
                <Text variant={TypographyVariant.subtitle2}>
                  {item.title}
                  {!!item.tip && (
                    <Tooltip value={item.tip}>
                      <Icon name={IconName.InfoCircleOutlined} />
                    </Tooltip>
                  )}
                </Text>
                <Text color={TextColor.secondary}>{item.description}</Text>
              </div>
            ),
        )}
      </div>
    </div>
  );
};

export const DetailedCardBodyActions = ({ className, children }: { className?: string; children: React.ReactNode }) => {
  return <div className={`actions ${className || ''}`}>{children}</div>;
};

export const DetailedCard = ResponsiveContainerWrapper((({ children, body, className, type }) => {
  const [isOpen, setOpen] = useState(false);
  const responsiveContainerContext = useContext(ResponsiveContainerContext);
  const theme = useTheme();
  return (
    <DetailedCardRoot
      variant={CardVariant.Bordered}
      size={responsiveContainerContext.size}
      type={type || 'light'}
      className={className}
    >
      <div className="children">
        <div className="description">{children}</div>
        {body && (
          <div onClick={() => setOpen(!isOpen)} className={`handle ${isOpen ? 'opened' : 'closed'}`}>
            <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22.9584 11.375L14.5001 19.8333L6.04175 11.375"
                stroke={toTextColor(theme)(TextColor.secondary)}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
      {body && isOpen && <div className="body">{body}</div>}
    </DetailedCardRoot>
  );
}) as React.FC<DetailedCardProps>);
