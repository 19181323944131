import {
  BorderColor,
  TextColor,
  createGlobalStyle,
  toBorderColor,
  toLayerBackground,
  toTextColor,
} from '@aircarbon/ui';

import { Radius, Spacing } from './enums';

const EntityTheme = createGlobalStyle`
  :root {

    ${Spacing._3xs}: 0.12rem; /* 2px */
    ${Spacing._2xs}: 0.25rem; /* 4px */
    ${Spacing._1_5}: 0.375rem; /* 6px */
    ${Spacing.xs}: 0.5rem; /* 8px */
    ${Spacing.small}: 0.75rem; /* 12px */
    ${Spacing.medium}: 1.25rem; /* 24px */
    ${Spacing.base}: 1rem; /* 16px */
    ${Spacing.large}: 1.5rem; /* 28px */
    ${Spacing.xl}: 2rem; /* 32px */
    ${Spacing._2xl}: 3rem; /* 48px */
    ${Spacing._3xl}: 4rem; /* 64px */
    ${Spacing._4xl}: 6rem; /* 96px */
    ${Spacing._5xl}: 8rem; /* 128px */
    ${Spacing._6xl}: 9rem; /* 144px */

    ${Radius.xl}: 1rem; /* 24px */
    ${Radius.large}: 1rem; /* 16px */
    ${Radius.medium}: 0.5rem; /* 8px */
    ${Radius.small}: 0.25rem; /* 4px */
  }

  ::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    background: transparent;
  }

  *:hover::-webkit-scrollbar-thumb {
    -webkit-border-radius: 1ex;
  }

  ::-webkit-scrollbar-corner {
    background: transparent;
  }

  .ant-popover-inner {
    background-color: ${({ theme }) => toLayerBackground(theme)('layer')};
    border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  }

  html, body {
    color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
  }
`;

const CssTheme: React.FC = ({ children }) => {
  return (
    <div className="contents">
      <EntityTheme />
      {children}
    </div>
  );
};

export { CssTheme, Radius, Spacing };
