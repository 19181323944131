import type React from 'react';
import { type ReactNode, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Empty } from 'refreshed-component/atoms/Empty';
import CorpTransferBatchForm from 'refreshed-component/forms/CorpTransferBatchForm';
import RetireDeliverBatchForm from 'refreshed-component/forms/RetireDeliverBatchForm';
import Loading from 'refreshed-component/molecules/Loading';
import { Modal } from 'refreshed-component/molecules/Modal';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import {
  DetailedCard,
  DetailedCardBodyActions,
  DetailedCardDescriptionItem,
} from 'refreshed-component/templates/DetailedCard';
import { ProjectMetadata } from 'refreshed-component/templates/project-rating/ProjectMetadata';

import {
  Badge,
  BadgeSize,
  Button,
  ButtonVariant,
  Card,
  Icon,
  IconName,
  Layer,
  Select,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { AssetCategory, type AssetCategoryCode, formatter } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';
import { usePairs } from 'pages/account/trading/hooks/usePairs';

import { Account, type AccountUser } from 'state/account';
import { Entity } from 'state/entity';
import { User } from 'state/user';

import useAccountBalances from 'hooks/useAccountBalances';
import useTokenTypes from 'hooks/useTokenTypes';

import { getRoleName, groupBatchesByBatchId } from 'utils/helpers';

const { formatNumber, hex2int } = formatter;

export enum ProjectsType {
  Project = 'project',
  Deliver = 'deliver',
  Retire = 'retire',
}

const DeliveryFormModel = ({
  action,
  params: { onSuccess, ...params },
}: {
  action: React.ReactElement<typeof Button>;
  params: {
    scTokenTypeId: number;
    tokenAssetCategory: AssetCategory;
    project: Record<string, any>;
    currencyBalance: number;
    totalAmount: number;
    openAmount: number;
    currentAmount: number;
    stIds: number[];
    batchId: number;
    onSuccess?: () => void;
  };
}) => {
  return (
    <Modal title={'Request Delivery'} action={action}>
      {({ onClose }) => {
        return (
          <RetireDeliverBatchForm
            type="PHYSICAL_DELIVERY"
            {...params}
            onSuccess={() => {
              onClose();
              onSuccess?.();
            }}
          />
        );
      }}
    </Modal>
  );
};

const RetirementFormModel = ({
  action,
  params: { onSuccess, ...params },
}: {
  action: React.ReactElement<typeof Button>;
  params: {
    scTokenTypeId: number;
    tokenAssetCategory: AssetCategory;
    project: Record<string, any>;
    currencyBalance: number;
    totalAmount: number;
    openAmount: number;
    currentAmount: number;
    stIds: number[];
    batchId: number;
    onSuccess?: () => void;
  };
}) => {
  return (
    <Modal title={'Request Retirement'} action={action}>
      {({ onClose }) => {
        return (
          <RetireDeliverBatchForm
            type="RETIREMENT"
            {...params}
            onSuccess={() => {
              onClose();
              onSuccess?.();
            }}
          />
        );
      }}
    </Modal>
  );
};

const ControlContainer = styled.div`
  > * {
    min-width: 200px;
  }
`;

const TokenImage = styled.div`
  width: 44px;
  height: 44px;
`;

export const Projects = ({ type }: { type: ProjectsType }) => {
  const { consolidatedTokens, accountUsersData, isLoading, accountAddresses, accountUsers, syncRefetchAccountData } =
    Account.useContainer();
  const {
    selector: { getUserId, getFullName, getUserRootId },
    status: { canTransferAssets, canDeliverToken, canRetireToken, canTradeSpot, canAccessRecs, canAccessRecTokenBurn },
  } = User.useContainer();
  const {
    selector: { mainCcyCode, mainCcyNumDecimals, mainCcySymbol },
  } = Entity.useContainer();
  const {
    selector: { totalOpenQuantities, getTotalBalance, getTotalCurrencyBalanceByAddress, totalTokenQuantity },
  } = useAccountBalances(accountUsers.filter((user) => !!user.account).map((user) => user.user_id));
  const isRecsEnabled = canAccessRecs();
  const isRecTokenBurnEnabled = canAccessRecTokenBurn();

  const assetCategories = useMemo(() => {
    if (isRecsEnabled) {
      return [AssetCategory[AssetCategory.token], AssetCategory[AssetCategory.rec]];
    }

    return [AssetCategory[AssetCategory.token]];
  }, [isRecsEnabled]);

  const { getPairByAssetId } = usePairs({
    assetCategories: assetCategories as Array<AssetCategoryCode>,
    includeMarketData: true,
  });
  const { marketSettings, isLoading: isLoadingMarketSettings } = useMarketSettings({});
  const userIdAddressMap: Record<number, string> = useMemo(
    () =>
      accountUsers.reduce((previousValue, currentValue) => {
        return {
          ...previousValue,
          [currentValue.user_id]: currentValue.account,
        };
      }, {}),
    [accountUsers],
  );
  const rootUserId = getUserRootId();
  const rootUserName = accountUsers.find((user) => user.user_id === rootUserId)?.fullName ?? '';
  const currentUserId = getUserId();
  const currentUserName = getFullName();

  const [balanceUserDropdownItem, setUserDropdownItemForBalance] = useState<{
    id: number | string;
    label: ReactNode;
  } | null>({
    id: ['retire', 'deliver'].includes(type) ? rootUserId : currentUserId,
    label: ['retire', 'deliver'].includes(type) ? rootUserName : currentUserName,
  });
  const [tokenDropdownItem, serDropdownItemForToken] = useState<{ id: number | string; label: ReactNode } | null>(null);

  useEffect(() => {
    setUserDropdownItemForBalance({
      id: ['retire', 'deliver'].includes(type) ? rootUserId : currentUserId,
      label: ['retire', 'deliver'].includes(type) ? rootUserName : currentUserName,
    });
  }, [type, setUserDropdownItemForBalance, rootUserId, currentUserId, rootUserName, currentUserName]);

  const {
    selector: { getAvailableAmountByAddress, totalTokenQuantityByTokenTypeId, totalOpenOrdersByTokenType },
    refetchBalances,
  } = useAccountBalances([balanceUserDropdownItem?.id ?? getUserId()]);
  const finalTokens = Object.values(consolidatedTokens).filter((item) => !item.isCurrency && item.qty);

  const filteredTokens = tokenDropdownItem?.id
    ? finalTokens.filter((item) => item.asset.id === tokenDropdownItem?.id)
    : finalTokens;

  const assetValue = useMemo(
    () =>
      filteredTokens.reduce((previousValue, currentValue) => {
        const pair = getPairByAssetId(currentValue.asset.id);
        const accountAddress = balanceUserDropdownItem?.id
          ? userIdAddressMap[balanceUserDropdownItem?.id as number]
          : undefined;
        const assetQuantity = currentValue.isCurrency
          ? accountAddress
            ? getTotalCurrencyBalanceByAddress(accountAddress)
            : getTotalBalance() || 0
          : totalTokenQuantity(currentValue.asset.scId, currentValue.asset.uom.scRatio, accountAddress);

        return previousValue + (pair?.marketData?.referencePrice || 0) * assetQuantity;
      }, 0),
    [filteredTokens, balanceUserDropdownItem, getPairByAssetId],
  );

  const openAssetValue = useMemo(
    () =>
      filteredTokens.reduce((previousValue, currentValue) => {
        const pair = getPairByAssetId(currentValue.asset.id);
        const accountAddress = balanceUserDropdownItem?.id
          ? userIdAddressMap[balanceUserDropdownItem?.id as number]
          : undefined;
        const qty = totalOpenQuantities({
          accountAddress,
          ...(pair?.symbol ? { pair: { symbol: pair.symbol, baseTokenTypeId: currentValue.asset.scId } } : undefined),
        });

        return previousValue + (pair?.marketData?.referencePrice || 0) * qty;
      }, 0),
    [filteredTokens, balanceUserDropdownItem, getPairByAssetId, totalOpenQuantities, userIdAddressMap],
  );

  const totalOpenAsset = useMemo(() => {
    const pair = tokenDropdownItem?.id ? getPairByAssetId(tokenDropdownItem?.id as number) : undefined;
    const baseTokenTypeId = pair?.baseAsset?.scId;

    return totalOpenQuantities({
      accountAddress: balanceUserDropdownItem?.id ? userIdAddressMap[balanceUserDropdownItem?.id as number] : undefined,
      ...(pair !== undefined && baseTokenTypeId !== undefined
        ? { pair: { symbol: pair.symbol, baseTokenTypeId: baseTokenTypeId } }
        : undefined),
    });
  }, [balanceUserDropdownItem, tokenDropdownItem, userIdAddressMap, getPairByAssetId, totalOpenQuantities]);

  const totalAssetQty = useMemo(
    () =>
      filteredTokens.reduce((previousValue, currentValue) => {
        const accountAddress = balanceUserDropdownItem?.id
          ? userIdAddressMap[balanceUserDropdownItem?.id as number]
          : undefined;
        const totalTokenQty = totalTokenQuantity(
          currentValue.asset.scId,
          currentValue.asset.uom.scRatio,
          accountAddress,
        );
        return previousValue + (currentValue.isCurrency ? 0 : totalTokenQty);
      }, 0),
    [filteredTokens, balanceUserDropdownItem],
  );

  const maxAssetNumDecimals = useMemo(() => {
    const decimals = filteredTokens?.map((token) => token.asset.numDecimals) ?? [];
    return decimals.length > 0 ? Math.max(...decimals) : 0;
  }, [filteredTokens]);

  const { tokenTypes } = useTokenTypes({
    assetCategories: assetCategories as Array<AssetCategoryCode>,
  });
  const { search } = useLocation();
  const searchQuery = new URLSearchParams(search);
  const tokenTypeId = Number(searchQuery.get('tokenTypeId'));

  const batchHolder = accountUsers.find((user) => user.user_id === Number(balanceUserDropdownItem?.id ?? getUserId()));
  const addr = batchHolder?.account ?? '';

  const currencyBalance = getAvailableAmountByAddress(addr);
  const index = accountAddresses().findIndex((address) => address === addr);
  const accountData = accountUsersData[index];
  const { tokens = [] } = accountData ?? {};
  const { entity } = Entity.useContainer();
  let batches = accountData?.batches ?? [];
  if (tokenTypeId) {
    batches = batches?.filter((batch: Record<string, any>) => hex2int(batch.tokTypeId) === tokenTypeId);
  }

  const projectBatchesAll: Record<
    string,
    {
      batchId: string | number | null | undefined;
      tokenTypeId: number;
      project: Record<string, any>;
      currentQty: string | number;
      createdAt: string | number | Date;
      stIds: Array<number>;
    }
  > = groupBatchesByBatchId(batches, tokens, entity.name, tokenTypes);
  const projectBatches = Object.values(projectBatchesAll).filter((item) => {
    const tokenType = tokenTypes.find((tokenType) => Number(tokenType.scId) === Number(item.tokenTypeId));
    return !tokenDropdownItem?.id || tokenDropdownItem?.id === tokenType?.id;
  });

  const refreshData = () => {
    refetchBalances();
    syncRefetchAccountData();
  };

  if (isLoadingMarketSettings) return <Loading />;
  return (
    <PageHolder>
      <PageSections>
        <PageControls
          controls={{
            primary: (
              <div className="flex gap-6 items-center">
                <ControlContainer>
                  <Select
                    label="Account"
                    isSearchable
                    value={balanceUserDropdownItem?.id.toString()}
                    isDisabled={['retire', 'deliver'].includes(type)}
                    className="account-type"
                    tooltip={
                      ['retire', 'deliver'].includes(type)
                        ? 'Only assets on the Main account can be retired or delivered. You may transfer assets from your subaccounts.'
                        : undefined
                    }
                    items={accountUsers.map(
                      (
                        user,
                      ): {
                        value: string;
                        title: string;
                      } => {
                        return {
                          value: user.user_id.toString(),
                          title: `${user.fullName} ${getUserId() === user.user_id ? '(Me)' : ''}`,
                        };
                      },
                    )}
                    onChange={({ value }) => {
                      const selectedItem = accountUsers.find((user) => user.user_id === Number(value)) as AccountUser;

                      setUserDropdownItemForBalance({
                        id: selectedItem?.user_id,
                        label: `${selectedItem.fullName} ${getUserId() === selectedItem.user_id ? '(Me)' : ''}`,
                      });
                    }}
                  />
                </ControlContainer>
                <ControlContainer>
                  <Select
                    isSearchable
                    className="asset-type"
                    items={[
                      {
                        value: '0',
                        title: 'All assets',
                      },
                      ...finalTokens.map((token) => {
                        return {
                          value: token.asset.id.toString(),
                          title: token.name,
                        };
                      }),
                    ]}
                    label="Asset"
                    value={tokenDropdownItem?.id.toString() || '0'}
                    onChange={({ value }) => {
                      const selectedItem = finalTokens.find((token) => token.asset.id === Number(value));

                      if (!selectedItem) {
                        serDropdownItemForToken({
                          id: 0,
                          label: 'All assets',
                        });
                        return;
                      }

                      serDropdownItemForToken({
                        id: selectedItem.asset.id,
                        label: selectedItem.name,
                      });
                    }}
                  />
                </ControlContainer>
              </div>
            ),
          }}
        />
      </PageSections>
      <PageSections>
        <SummaryHolder>
          <SummaryCard
            title="Assets Value"
            tooltip={`Assets Value in ${mainCcySymbol}`}
            value={`${mainCcyCode}${formatNumber(assetValue, mainCcyNumDecimals)}`}
            icon={<Icon name={IconName.Bids} />}
            secondaryValue={
              openAssetValue ? `-${mainCcyCode}${formatNumber(openAssetValue, mainCcyNumDecimals)}` : undefined
            }
            secondaryValueColor={TextColor.error}
            secondaryTooltip={'Reserved Asset Value'}
          />
          <SummaryCard
            title="Assets Qty"
            tooltip={'Total quantity for all contracts'}
            value={formatNumber(totalAssetQty, maxAssetNumDecimals) ?? ''}
            icon={<Icon name={IconName.Database} />}
            secondaryValue={totalOpenAsset > 0 ? `-${formatNumber(totalOpenAsset, maxAssetNumDecimals)}` : undefined}
            secondaryValueColor={TextColor.error}
            secondaryTooltip={'Reserved Asset Quantity'}
          />
          <SummaryCard
            title="Total Batches"
            tooltip={'Total Number of batches'}
            value={`${projectBatches.length ?? ''}`}
            icon={<Icon name={IconName.Collection} />}
          />
        </SummaryHolder>
      </PageSections>
      <Layer>
        <StyledCard>
          {projectBatches?.length > 0 ? (
            <Layer>
              <div className="flex flex-col w-full gap-large">
                {projectBatches.map((batch, index) => {
                  // hide basket token
                  const tokenType = tokenTypes.find(
                    (tokenType) => Number(tokenType.scId) === Number(batch.tokenTypeId),
                  );
                  if (tokenType?.isBasket) return null;

                  const { project } = batch;
                  const totalAmount = Number(
                    totalTokenQuantityByTokenTypeId(addr, batch.tokenTypeId, tokenType?.uom?.scRatio ?? 1000),
                  );
                  const openAmount = Number(
                    totalOpenOrdersByTokenType({
                      address: addr,
                      tokenType: tokenType?.symbol ?? '',
                      side: 'sell',
                    }),
                  );
                  const canAccessDeliver =
                    canDeliverToken() &&
                    balanceUserDropdownItem?.id === getUserRootId() &&
                    (tokenType?.assetCategoryId !== AssetCategory.rec || isRecTokenBurnEnabled);

                  const canAccessRetire =
                    canRetireToken() &&
                    balanceUserDropdownItem?.id === getUserRootId() &&
                    (tokenType?.assetCategoryId !== AssetCategory.rec || isRecTokenBurnEnabled);
                  return (
                    <DetailedCard
                      key={`${batch.batchId}-${index}`}
                      className="project-item"
                      body={
                        <>
                          <ProjectMetadata projectMetadata={project} />
                          {type === ProjectsType.Project && (
                            <DetailedCardBodyActions>
                              {canAccessDeliver && (
                                <DeliveryFormModel
                                  action={
                                    <Button
                                      variant={ButtonVariant.outlined}
                                      isDisabled={marketSettings?.carbonEntryEnabled === 0}
                                      endIcon={IconName.Deliver}
                                    >
                                      Deliver
                                    </Button>
                                  }
                                  params={{
                                    scTokenTypeId: batch.tokenTypeId,
                                    tokenAssetCategory: tokenType?.assetCategoryId as AssetCategory,
                                    batchId: batch.batchId as number,
                                    currencyBalance: Number(currencyBalance),
                                    totalAmount,
                                    openAmount,
                                    currentAmount: Number(batch?.currentQty ?? 0),
                                    stIds: batch.stIds,
                                    project,
                                    onSuccess: () => refreshData(),
                                  }}
                                />
                              )}
                              {canAccessRetire && (
                                <RetirementFormModel
                                  action={
                                    <Button
                                      variant={ButtonVariant.outlined}
                                      isDisabled={marketSettings?.carbonEntryEnabled === 0}
                                      endIcon={IconName.CarbonCredits}
                                    >
                                      Retire
                                    </Button>
                                  }
                                  params={{
                                    scTokenTypeId: batch.tokenTypeId,
                                    tokenAssetCategory: tokenType?.assetCategoryId as AssetCategory,
                                    batchId: batch.batchId as number,
                                    currencyBalance: Number(currencyBalance),
                                    totalAmount,
                                    openAmount,
                                    currentAmount: Number(batch?.currentQty ?? 0),
                                    stIds: batch.stIds,
                                    project,
                                    onSuccess: () => refreshData(),
                                  }}
                                />
                              )}
                              {batchHolder && canTransferAssets() && (
                                <Modal
                                  title={'Make Transfer'}
                                  action={<Button endIcon={IconName.ArrowEnd}>Transfer</Button>}
                                >
                                  {({ onClose }) => {
                                    const { tokenTypeId: scTokenTypeId, currentQty, stIds, batchId } = batch;
                                    const totalAmount = Number(
                                      totalTokenQuantityByTokenTypeId(
                                        batchHolder.account,
                                        scTokenTypeId,
                                        tokenType?.uom?.scRatio ?? 1000,
                                      ),
                                    );
                                    const openAmount = Number(
                                      totalOpenOrdersByTokenType({
                                        address: batchHolder.account,
                                        tokenType: tokenType?.symbol ?? '',
                                        side: 'sell',
                                      }),
                                    );
                                    return (
                                      <CorpTransferBatchForm
                                        fromUser={{
                                          name: batchHolder.fullName,
                                          account: batchHolder.account,
                                          role: getRoleName({
                                            accountType: batchHolder.account_type,
                                            isMember: batchHolder?.isMember,
                                          }).short,
                                        }}
                                        project={project}
                                        scTokenTypeId={scTokenTypeId}
                                        totalAmount={totalAmount}
                                        openAmount={openAmount}
                                        currentAmount={Number(currentQty)}
                                        stIds={stIds}
                                        batchId={Number(batchId)}
                                        onSuccess={onClose}
                                      />
                                    );
                                  }}
                                </Modal>
                              )}
                            </DetailedCardBodyActions>
                          )}
                        </>
                      }
                    >
                      <DetailedCardDescriptionItem className="flex flex-col flex-1 gap-4 items-stretch md:flex-row">
                        <div className="flex flex-col flex-shrink-0 justify-center">
                          <Badge value={`Batch #${batch.batchId}`} size={BadgeSize.l} />
                        </div>
                        <div className="flex flex-col flex-auto gap-1 justify-center">
                          <Text variant={TypographyVariant.h6Title}>{batch.project?.TXT_PROJECT_NAME}</Text>
                          <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                            {batch.project?.TXT_REGISTRY} | ID {batch.project?.TXT_PROJECT_ID} |{' '}
                            {batch.project?.countryName} | {batch.project?.vintage}
                          </Text>
                        </div>
                      </DetailedCardDescriptionItem>
                      <DetailedCardDescriptionItem className="flex flex-row gap-4 justify-center items-center w-1/4">
                        {!!tokenType?.symbol && (
                          <TokenImage>
                            <img src={`/logos/${tokenType?.symbol}.png`} alt={''} />
                          </TokenImage>
                        )}

                        <div className="flex flex-col">
                          <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                            {tokenType?.symbol?.toUpperCase()}
                          </Text>
                          <Text variant={TypographyVariant.h6Title}>
                            {formatter.formatNumber(Number(batch?.currentQty), tokenType?.numDecimals)}
                          </Text>
                        </div>
                      </DetailedCardDescriptionItem>
                      {type === ProjectsType.Deliver && canAccessDeliver && (
                        <DetailedCardDescriptionItem className="flex flex-row gap-4 justify-center items-center">
                          <DeliveryFormModel
                            action={
                              <Button isDisabled={marketSettings?.carbonEntryEnabled === 0} endIcon={IconName.Deliver}>
                                Deliver
                              </Button>
                            }
                            params={{
                              scTokenTypeId: batch.tokenTypeId,
                              tokenAssetCategory: tokenType?.assetCategoryId as AssetCategory,
                              batchId: batch.batchId as number,
                              currencyBalance: Number(currencyBalance),
                              totalAmount,
                              openAmount,
                              currentAmount: Number(batch?.currentQty ?? 0),
                              stIds: batch.stIds,
                              project,
                              onSuccess: () => refreshData(),
                            }}
                          />
                        </DetailedCardDescriptionItem>
                      )}
                      {type === ProjectsType.Retire && canAccessRetire && (
                        <DetailedCardDescriptionItem className="flex flex-row gap-4 justify-center items-center">
                          <RetirementFormModel
                            action={
                              <Button isDisabled={marketSettings?.carbonEntryEnabled === 0} endIcon={IconName.ArrowEnd}>
                                Retire
                              </Button>
                            }
                            params={{
                              scTokenTypeId: batch.tokenTypeId,
                              tokenAssetCategory: tokenType?.assetCategoryId as AssetCategory,
                              batchId: batch.batchId as number,
                              currencyBalance: Number(currencyBalance),
                              totalAmount,
                              openAmount,
                              currentAmount: Number(batch?.currentQty ?? 0),
                              stIds: batch.stIds,
                              project,
                              onSuccess: () => refreshData(),
                            }}
                          />
                        </DetailedCardDescriptionItem>
                      )}
                    </DetailedCard>
                  );
                })}
              </div>
            </Layer>
          ) : isLoading ? (
            <div className="p-large">
              <Loading isOverLay={false} />
            </div>
          ) : type === ProjectsType.Project ? (
            <Empty
              title="You have no projects"
              description="Start trading to own projects or request corporate admins to transfer projects to your account."
              buttonLabel={canTradeSpot() ? 'Start Trading' : undefined}
              buttonLink={`/account/trade?assetCategoryId=${AssetCategory.token}`}
            />
          ) : (
            <Empty
              title="No projects on main account"
              description="Transfer assets to the main account to start delivering or retiring projects. Projects can be retired/delivered if they belong to the main corporate account."
              buttonLabel={canTradeSpot() ? 'Start Trading' : undefined}
              buttonLink={`/account/trade?assetCategoryId=${AssetCategory.token}`}
            />
          )}
        </StyledCard>
      </Layer>
    </PageHolder>
  );
};

const StyledCard = styled(Card)`
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;
