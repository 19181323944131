import {
  Icon,
  IconName,
  Text,
  TextAs,
  TextColor,
  TextDecoration,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import type { TradeRequest } from './fetchTradeRequests';
import { findLastCounterOffers } from './findLastCounterOffers';

const { formatPrice } = formatter;

export const toLastOfferPriceRaw = (props: { tradeRequest: TradeRequest; currentUserId: number }) => {
  const { tradeRequest } = props;

  if (tradeRequest.status === 'ACCEPTED') {
    return tradeRequest.price;
  }

  if (['ACCEPTED', 'REJECTED'].includes(tradeRequest.status)) {
    return tradeRequest.price;
  }

  const { lastCounterOffer } = findLastCounterOffers({
    counterOffers: tradeRequest.counterOffers,
  });

  return lastCounterOffer?.price ?? 0;
};

export const toOfferPrice = (props: { tradeRequest: TradeRequest; currentUserId: number; currency: string }) => {
  const { tradeRequest, currency } = props;

  if (['ACCEPTED', 'REJECTED', 'EXECUTED'].includes(tradeRequest.status)) {
    return formatPrice(tradeRequest.price ?? 0, currency);
  }

  const { lastCounterOffer, lastCounterOfferFromOwner, isLastFromOwner } = findLastCounterOffers({
    counterOffers: tradeRequest.counterOffers,
  });

  const isNewPrice = lastCounterOffer?.price !== lastCounterOfferFromOwner?.price;

  if (!lastCounterOffer || !isNewPrice) {
    return formatPrice(lastCounterOfferFromOwner?.price ?? 0, currency);
  }

  if (isLastFromOwner) {
    return (
      <>
        <Text variant={TypographyVariant.body2}>{formatPrice(lastCounterOffer.price, currency)} </Text>
        <CounterOffer>
          <Icon name={IconName.ArrowUpEnd} color={TextColor.secondary} />
          <Text variant={TypographyVariant.body2} color={TextColor.secondary} as={TextAs.span}>
            {formatPrice(lastCounterOfferFromOwner?.price ?? 0, currency)}
          </Text>
        </CounterOffer>
      </>
    );
  }

  return (
    <>
      <Text variant={TypographyVariant.body2}>{formatPrice(lastCounterOffer.price, currency)} </Text>
      <Text
        decoration={TextDecoration.LineThrough}
        variant={TypographyVariant.body2}
        color={TextColor.secondary}
        as={TextAs.span}
      >
        {formatPrice(lastCounterOfferFromOwner?.price ?? 0, currency)}
      </Text>
    </>
  );
};

const CounterOffer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(1)};
`;
