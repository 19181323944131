import queryString from 'query-string';

import { userAuthenticatedApi } from 'data-provider/fetcher';

import { toSearchResults } from './toSearchResults';

interface SearchProjectsProps {
  searchValue: string;
  assetCategoryId: string;
  ownedByUserId?: number;
  showOwnedOnly?: boolean;
  criteria?: Array<{
    key: string;
    operator: string;
    values: Array<string>;
  }>;
}

export const searchProjects = async (props: SearchProjectsProps) => {
  const { searchValue, assetCategoryId, ownedByUserId, showOwnedOnly = false, criteria } = props;

  const userService = await userAuthenticatedApi();
  const request = await userService
    .url(`/user/project`)
    .query(
      queryString.stringify(
        {
          searchBy: searchValue,
          assetCategoryId,
          ...(ownedByUserId
            ? {
                ownedByUserId,
                showOwnedOnly,
              }
            : {}),
          ...(criteria?.length
            ? criteria.reduce(
                (curr, cr) => ({
                  ...curr,
                  [`criteria[${cr.key}][${cr.operator}]`]: cr.values,
                }),
                {},
              )
            : {}),
        },
        { arrayFormat: 'bracket' },
      ),
    )
    .get();

  const response = (await request.json()) as any;

  return toSearchResults(response.data);
};
