import { flatten } from 'lodash';
import { TimeInForce } from 'refreshed-pages/market-board-v2/utils/TimeInForce';

import { CMBOrderType } from '@aircarbon/ui';
import { trades } from '@aircarbon/utils-common';

import type { AuctionFormValue } from '../components/AuctionForm';
import type { BidFormValue } from '../components/BidForm';
import type { OfferFormValue } from '../components/OfferForm';

const { OrderSide } = trades;
type FormValue<OrderType extends CMBOrderType> = OrderType extends CMBOrderType.Bid
  ? BidFormValue
  : OrderType extends CMBOrderType.Auction
    ? AuctionFormValue
    : OfferFormValue;

export const toCreateOtcOrderBody = <OT extends CMBOrderType>(props: {
  assetCategoryId: string;
  orderType: OT;
  formValue: FormValue<OT>;
  creatorUserId: number;
}) => {
  const { orderType, formValue, assetCategoryId, creatorUserId } = props;

  if (orderType === CMBOrderType.Bid) {
    const price = Number(formValue.price);
    return {
      assetCategoryId: Number(assetCategoryId),
      sideId: OrderSide.Buy,
      isAuction: false,
      qty: Number(formValue.quantity),
      ...(price > 0 ? { price } : {}),
      timeInForceTypeId: Number(TimeInForce.GoodTillCancel),
      isPrefunded: formValue.isInstantTrade,
      userId: creatorUserId,

      ...((formValue as BidFormValue).criteria.length || (formValue as BidFormValue).projects.length
        ? {
            criteria: [
              ...flatten(
                (formValue as BidFormValue).criteria.map((item) => {
                  const singleValues = item.value.split(',');

                  return singleValues.map((singleValue) => ({
                    key: item.key,
                    valueFrom: singleValue,
                    operator: 'IN',
                  }));
                }),
              ),
              ...(formValue as BidFormValue).projects.map((project) => ({
                key: 'PROJECT_ID',
                valueFrom: project.value,
                operator: 'IN',
              })),
            ],
          }
        : {}),
      ...(formValue.minimumQuantity ? { minQty: Number(formValue.minimumQuantity) } : {}),
      ...(formValue.maximumQuantity ? { maxQty: Number(formValue.maximumQuantity) } : {}),
      ...(formValue.quantityMultiplesOf ? { qtyMultipleOf: Number(formValue.quantityMultiplesOf) } : {}),
    };
  }
  if (orderType === CMBOrderType.Auction) {
    const [startDateISO, endDateISO] = (formValue as AuctionFormValue).auctionPeriod.split(',');

    return {
      assetCategoryId: Number(assetCategoryId),
      sideId: OrderSide.Sell,
      isAuction: true,
      qty: Number(formValue.quantity),
      price: Number(formValue.price),
      userId: Number(creatorUserId),
      startDate: new Date(startDateISO).toISOString(),
      endDate: new Date(endDateISO).toISOString(),
      isPrefunded: formValue.isInstantTrade,
      projectId: Number((formValue as AuctionFormValue).project?.value),
      ...((formValue as OfferFormValue).baseAssetId
        ? {
            baseAssetId: Number(formValue.baseAssetId),
          }
        : {}),
      timeInForceTypeId: Number(TimeInForce.GoodTillDate),
      criteria: [
        {
          key: 'VINTAGE_YEAR',
          valueFrom: (formValue as AuctionFormValue).vintage,
          operator: 'IN',
        },
      ],
      showBestBid: (formValue as AuctionFormValue).showBestBid,
      showBidVolume: (formValue as AuctionFormValue).showBidVolume,
      ...(formValue.minimumQuantity ? { minQty: Number(formValue.minimumQuantity) } : {}),
      ...(formValue.maximumQuantity ? { maxQty: Number(formValue.maximumQuantity) } : {}),
      ...(formValue.quantityMultiplesOf ? { qtyMultipleOf: Number(formValue.quantityMultiplesOf) } : {}),
    };
  }

  return {
    assetCategoryId: Number(assetCategoryId),
    sideId: OrderSide.Sell,
    isAuction: false,
    qty: Number(formValue.quantity),
    price: Number(formValue.price),
    userId: Number(creatorUserId),
    projectId: (formValue as OfferFormValue).project ? Number((formValue as OfferFormValue).project?.value) : undefined,
    ...((formValue as OfferFormValue).baseAssetId
      ? {
          baseAssetId: Number(formValue.baseAssetId),
        }
      : {}),

    ...((formValue as OfferFormValue).criteria.length
      ? {
          criteria: [
            ...flatten(
              (formValue as BidFormValue).criteria.map((item) => {
                const singleValues = item.value.split(',');

                return singleValues.map((singleValue) => ({
                  key: item.key,
                  valueFrom: singleValue,
                  operator: 'IN',
                }));
              }),
            ),
          ],
        }
      : {
          criteria: [
            {
              key: 'VINTAGE_YEAR',
              valueFrom: (formValue as OfferFormValue).vintage,
              operator: 'IN',
            },
          ],
        }),
    timeInForceTypeId: Number(TimeInForce.GoodTillCancel),
    isPrefunded: formValue.isInstantTrade,
    ...(formValue.minimumQuantity ? { minQty: Number(formValue.minimumQuantity) } : {}),
    ...(formValue.maximumQuantity ? { maxQty: Number(formValue.maximumQuantity) } : {}),
    ...(formValue.quantityMultiplesOf ? { qtyMultipleOf: Number(formValue.quantityMultiplesOf) } : {}),
  };
};
