import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { createContainer } from 'unstated-next';
import { type EffectReducer, useEffectReducer } from 'use-effect-reducer';

import { logger } from '@aircarbon/utils-common';

import useContractTxTypes from 'hooks/useContractTxTypes';
import useCurrencies from 'hooks/useCurrencies';
import useQueryParams from 'hooks/useQueryParams';
import useTokenTypes from 'hooks/useTokenTypes';

import { type SmartContract, fetchContract } from 'data-provider/contract/fetchContract';

import { UI } from './ui';
import { User } from './user';

declare global {
  interface Window {
    reportJSON: Record<string, any>;
    amILoadedYet: () => boolean;
  }
}

type Action =
  | {
      type: 'INIT';
      payload: {
        initState: Partial<State>;
        contractJson: SmartContract;
      };
    }
  | { type: 'CHANGE_PAGE'; payload: { page: number; limit: number | string } }
  | { type: 'FILTER_BY_EVENTS'; payload: { events: Array<string> } }
  | { type: 'FILTER_BY_TOKENS'; payload: { tokenTypes: Array<string> } }
  | {
      type: 'FILTER_BY_DATE';
      payload: {
        startDate?: Date | null | undefined;
        endDate?: Date | null | undefined;
      };
    }
  | {
      type: 'LOAD_ACCOUNTS';
      payload: {
        accounts: Array<{
          account: string;
          name: string;
        }>;
      };
    };

type FilterBy = {
  range?: {
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
  };
  eventNames?: Array<string>;
  tokenTypes?: Array<string>;
  address?: string;
  page: number;
  limit: number | string;
};

type State = {
  tokenUnit: string;
  ccys: Record<string, any>;
  activeCurrencies: string;
  tokenTypes: Record<string, any>;
  activeTokens: string;
  accountsNames: Record<string, string>;
  filterBy: FilterBy;
  contractJson?: SmartContract;
};

const initState: State = {
  tokenUnit: '',
  ccys: {},
  activeCurrencies: '',
  tokenTypes: {},
  activeTokens: '',
  accountsNames: {},
  filterBy: {
    page: 1,
    limit: 30,
  },
};

const reducer: EffectReducer<State, Action> = (state: State, action: Action) => {
  switch (action.type) {
    case 'INIT': {
      return {
        ...initState,
        ...action.payload.initState,
        contractJson: action.payload.contractJson,
      };
    }
    case 'LOAD_ACCOUNTS': {
      if (action.payload.accounts?.length) {
        const accountNames = action.payload.accounts.reduce((accounts, nextAccount) => {
          return { ...accounts, [nextAccount.account]: nextAccount.name };
        }, {});

        return {
          ...state,
          accountsNames: {
            ...state.accountsNames,
            ...accountNames,
          },
        };
      }

      return state;
    }
    case 'CHANGE_PAGE': {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          ...action.payload,
        },
      };
    }

    case 'FILTER_BY_DATE': {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          range: action.payload,
          // reset to 1st page
          page: 1,
        },
      };
    }

    case 'FILTER_BY_EVENTS': {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          eventNames: action.payload.events,
          // reset to 1st page
          page: 1,
        },
      };
    }

    case 'FILTER_BY_TOKENS': {
      return {
        ...state,
        filterBy: {
          ...state.filterBy,
          tokenTypes: action.payload.tokenTypes,
          // reset to 1st page
          page: 1,
        },
      };
    }

    default:
      return state;
  }
};

export function useContract(initFilter: FilterBy | undefined) {
  const tokenTypes = useTokenTypes();
  const { currencies, currenciesByScId } = useCurrencies();
  const {
    user,
    selector: { getAccountAddress },
  } = User.useContainer();
  const {
    getAccountNames,
    blockchainView,
    uiStatus: { isReady },
  } = UI.useContainer();
  const query = useQueryParams();

  // const deployedContractRef = useRef<any>();
  // const web3HttpRef = useRef<JsonRpcProvider>();
  // const web3WsRef = useRef<WebSocketProvider>();

  const [state, dispatch] = useEffectReducer(reducer, initState, {});
  const txTypes = useContractTxTypes(!blockchainView);

  const changeUrl = ({
    startDate,
    endDate,
    eventNames,
    tokenTypes,
    page,
    limit,
  }: {
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
    eventNames?: string | null | undefined;
    tokenTypes?: string | null | undefined;
    page: number;
    limit: number | string;
  }) => {
    if (eventNames) query.set('eventNames', eventNames);
    else if (eventNames === '') query.delete('eventNames');
    if (tokenTypes) query.set('tokenTypes', tokenTypes);
    else if (tokenTypes === '') query.delete('tokenTypes');
    if (page) query.set('page', page.toString());
    if (limit) query.set('limit', limit.toString());
    if (startDate) query.set('startDate', startDate?.toISOString() ?? '');
    else query.delete('startDate');
    if (endDate) query.set('endDate', endDate?.toISOString() ?? '');
    else query.delete('endDate');
    const url = new URL(window.location.href);
    url.search = query.toString();
    window.history.pushState(query.toString(), document.title, url.toString());
  };

  // actions
  const changePage = (page: number, limit: number | string) => {
    dispatch({
      type: 'CHANGE_PAGE',
      payload: {
        page,
        limit,
      },
    });
    changeUrl({
      page,
      limit,
    });
  };

  const loadAccounts = (
    accounts: Array<{
      name: string;
      account: string;
    }>,
  ) =>
    dispatch({
      type: 'LOAD_ACCOUNTS',
      payload: {
        accounts,
      },
    });

  const filterBySelectedRange = ({
    startDate,
    endDate,
  }: {
    startDate?: Date | null | undefined;
    endDate?: Date | null | undefined;
  }) => {
    dispatch({
      type: 'FILTER_BY_DATE',
      payload: {
        startDate,
        endDate,
      },
    });
    changeUrl({
      startDate,
      endDate,
      page: 1,
      limit: state.filterBy.limit,
    });
  };

  const filterByEvents = (events: Array<string>) => {
    dispatch({
      type: 'FILTER_BY_EVENTS',
      payload: {
        events,
      },
    });
    changeUrl({
      eventNames: events.join(','),
      page: 1,
      limit: state.filterBy.limit,
    });
  };

  const filterByTokens = (tokenTypes: Array<string>) => {
    dispatch({
      type: 'FILTER_BY_TOKENS',
      payload: {
        tokenTypes,
      },
    });
    changeUrl({
      tokenTypes: tokenTypes.join(','),
      page: 1,
      limit: state.filterBy.limit,
    });
  };

  // TODO: implement WS with Ethers
  // clear ws socket connection when reload page or close tab
  // useBeforeUnload(async () => {
  //   if (web3WsRef.current) {
  //     // clear all subscription
  //     // web3WsRef.current
  //     //   .clearSubscriptions()
  //     //   .then((result) => logger.warn(`Removed all subscriptions`, result))
  //     //   .catch(logger.error.bind(logger));

  //     logger.warn('Closing web3 ws connection');
  //     // close ws connection
  //     web3WsRef.current._websocket?.terminate(); // check status code from https://github.com/Luka967/websocket-close-codes
  //     // await sleep(3000);
  //   }
  // });

  const ethAddress = getAccountAddress();
  useEffect(() => {
    if (ethAddress && user?.signInUserSession?.idToken?.payload) {
      if (!state.accountsNames[ethAddress]) {
        dispatch({
          type: 'LOAD_ACCOUNTS',
          payload: {
            accounts: [
              {
                account: ethAddress,
                name: user?.signInUserSession?.idToken?.payload?.name,
              },
            ],
          },
        });
      }
    }
  }, [dispatch, ethAddress, state.accountsNames, user]);

  const { data: contractJson } = useQuery(
    '/api/contract',
    () =>
      fetchContract().then(({ data, status }) => {
        if (status === 'success' && data) {
          dispatch({
            type: 'INIT',
            payload: {
              initState: {
                accountsNames: {
                  ...initState.accountsNames,
                  ...getAccountNames(),
                  [data?.addr?.toLowerCase()]: `Contract: ${data.contractEnum}`,
                  '0x0000000000000000000000000000000000000000': 'Global Exchange Fee',
                },
                filterBy: initFilter,
              },
              contractJson: data,
            },
          });
          return data;
        } else {
          logger.error(data, 'Error fetching contract');
          return Promise.reject(data);
        }
      }),
    { enabled: isReady() && !state.contractJson?.addr },
  );

  const {
    filterBy: { limit },
  } = state;

  return {
    ...state,
    ...tokenTypes,
    contractJson,
    currencies,
    currenciesByScId,
    actions: {
      loadAccounts,
      filterBySelectedRange,
      filterByEvents,
      filterByTokens,
      changePage,
    },
    limit,
    txTypes,
  };
}

export const Contract = createContainer(useContract);
