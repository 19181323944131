import { forwardRef, useImperativeHandle, useRef } from 'react';

import { styled, toSpacing } from '@aircarbon/ui';

import type { BidFormValue } from '../../hooks/usePlaceOrderForm';
import { CommonOrderFields, type CommonOrderFieldsRef } from '../CommonOrderFields';

export interface PlaceBidFormProps {
  value: BidFormValue;
  priceCurrency: string;
  quantityUnit: string;
  isInstantTradeEnabled: boolean;
  minimumQuantity?: number;
  maximumQuantity?: number;
  quantityMultiplesOf?: number;
  openQuantity: number;
  onChange(value: BidFormValue): void;
  tokenAssetNumDecimals?: number;
}

export interface PlaceBidFormRef {
  /**
   * Validates the form and returns true if forms has errors
   */
  validate(): boolean;
}

export const PlaceBidForm = forwardRef<PlaceBidFormRef, PlaceBidFormProps>((props, ref) => {
  const {
    value,
    priceCurrency,
    quantityUnit,
    isInstantTradeEnabled,
    minimumQuantity,
    maximumQuantity,
    quantityMultiplesOf,
    openQuantity,
    onChange,
    tokenAssetNumDecimals,
  } = props;

  const commonOrderFieldsRef = useRef<CommonOrderFieldsRef>(null);

  useImperativeHandle(ref, () => ({
    validate() {
      return commonOrderFieldsRef.current?.validate() || false;
    },
  }));

  return (
    <StyledPlaceBidForm>
      <CommonOrderFields
        ref={commonOrderFieldsRef}
        value={value}
        priceCurrency={priceCurrency}
        quantityUnit={quantityUnit}
        onChange={onChange}
        isInstantTradeEnabled={isInstantTradeEnabled}
        isInstantTradeHidden
        minimumQuantity={minimumQuantity}
        maximumQuantity={maximumQuantity}
        quantityMultiplesOf={quantityMultiplesOf}
        openQuantity={openQuantity}
        tokenAssetNumDecimals={tokenAssetNumDecimals}
      />
    </StyledPlaceBidForm>
  );
});

const StyledPlaceBidForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;
