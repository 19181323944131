import 'bootstrap-daterangepicker/daterangepicker.css';
import { format } from 'date-fns';
import moment from 'moment';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Loading from 'refreshed-component/molecules/Loading';

import {
  BorderColor,
  Checkbox,
  Icon,
  IconName,
  InputText,
  InputTextType,
  Select,
  Text,
  TextColor,
  Toggle,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
} from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import { AssetsAllocationTable } from '../AssetsAllocationTable';
import { ProjectInformation } from './components/ProjectInformation';

const { formatPrice, formatNumber } = formatter;

export interface RetireBatchFormValue {
  retirementReason: string;
  isPublicRetirement: boolean;
  retirementBeneficiary: string;
  offsettingPeriod: {
    from?: Date;
    to?: Date;
  };
  offsetScopes: Record<number, boolean>;
  otherNotes: string;
  quantity: string;
}

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-bottom: 1rem;
  width: 100%;

  label {
    margin: 0 !important;
  }
`;

const toQuantityError = (errors: Record<string, string>) => {
  if (errors?.['formValue.quantity']) {
    return errors?.['formValue.quantity'];
  }

  const allocationsErrorsKeys = Object.keys(errors).filter((key) => key.includes('assetsBalances['));
  if (!allocationsErrorsKeys.length) {
    return '';
  }

  return errors[allocationsErrorsKeys[0]];
};

export const RetireBatchForm: React.FC<{
  value: RetireBatchFormValue;
  batchInformation: {
    batchId: number;
    quantity: number;
  };
  assets: Array<{
    assetType: string;
    balance: string;
    packQuantity: string;
    balanceAfter: string;
  }>;
  transactionFee: {
    total: number;
    isLoading: boolean;
  };
  errors: Record<string, string>;
  onChange(value: RetireBatchFormValue): void;
}> = (props) => {
  const { value, assets, errors, transactionFee, batchInformation, onChange } = props;
  const quantityError = toQuantityError(errors);

  const onChangeFormValue =
    <K extends keyof typeof value>(key: K) =>
    (newValue: (typeof value)[K]) => {
      onChange({
        ...value,
        [key]: newValue,
      });
    };

  const onChangeScope = (key: number) => () => {
    onChange({
      ...value,
      offsetScopes: {
        ...value.offsetScopes,
        [key]: !value.offsetScopes[key],
      },
    });
  };

  return (
    <StyledRetireBatchForm>
      <ProjectInformation
        quantity={formatNumber(batchInformation.quantity)}
        batchId={String(batchInformation.batchId)}
      />
      <FieldWrapper>
        <Select
          label="Retirement Reason*"
          placeholder="Select"
          items={[
            {
              title: 'Voluntary',
              value: 'Voluntary',
            },
            {
              title: 'Compliance',
              value: 'Compliance',
            },
          ]}
          onChange={({ value }) => {
            onChangeFormValue('retirementReason')(value);
          }}
        />
      </FieldWrapper>
      <MakePublicRetirement>
        <Toggle
          label="Make Retirement Public ?"
          isOn={value.isPublicRetirement}
          onChange={(isOn) => onChangeFormValue('isPublicRetirement')(isOn)}
        />
      </MakePublicRetirement>
      <BeneficiaryAndOfsettingPeriod>
        <FieldWrapper>
          <InputText
            label="Beneficiary"
            value={value.retirementBeneficiary}
            onChange={(e) => onChangeFormValue('retirementBeneficiary')(e.target.value)}
          />
        </FieldWrapper>
        <FieldWrapper>
          <Text variant={TypographyVariant.body2}>Offsetting Period*</Text>
          <DateRangePicker
            maxDate={moment(moment('2200-01-01'))}
            minDate={moment('1970-01-01')}
            showDropdowns={true}
            alwaysShowCalendars
            startDate={value.offsettingPeriod.from}
            endDate={value.offsettingPeriod.to}
            onApply={(_evt, picker) => {
              onChangeFormValue('offsettingPeriod')({
                from: picker.startDate.toDate(),
                to: picker.endDate.toDate(),
              });
            }}
          >
            <StyledSelectDate>
              <Icon name={IconName.Calendar} size="1.25rem" color={TextColor.disabled} />
              {!!value.offsettingPeriod.from && !!value.offsettingPeriod.to ? (
                <Text className="overflow-hidden w-full text-left whitespace-pre overflow-ellipsis">
                  {format(new Date(value.offsettingPeriod.from), 'MMM d yyyy')} -{' '}
                  {format(new Date(value.offsettingPeriod.to), 'MMM d yyyy')}
                </Text>
              ) : (
                <Text>Select date period</Text>
              )}
            </StyledSelectDate>
          </DateRangePicker>
          {!!(errors?.['formValue.offsettingPeriod.from'] || errors?.['formValue.offsettingPeriod.to']) && (
            <Text color={TextColor.error} variant={TypographyVariant.caption}>
              {errors?.['formValue.offsettingPeriod.from'] || errors?.['formValue.offsettingPeriod.to']}
            </Text>
          )}
        </FieldWrapper>
      </BeneficiaryAndOfsettingPeriod>
      <Text variant={TypographyVariant.subtitle1}>Offset Scope</Text>
      <OffsetScopes>
        <Checkbox label="Scope 1" isChecked={value.offsetScopes[1]} onChange={onChangeScope(1)} />
        <Checkbox label="Scope 2" isChecked={value.offsetScopes[2]} onChange={onChangeScope(2)} />
        <Checkbox label="Scope 3" isChecked={value.offsetScopes[3]} onChange={onChangeScope(3)} />
      </OffsetScopes>

      <FieldWrapper>
        <InputText
          label="Quantity (tCO2e)*"
          suffix="tCO2"
          error={quantityError}
          value={value.quantity}
          type={InputTextType.Number}
          onChange={(e) => onChangeFormValue('quantity')(e.target.value)}
        />

        <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
          Transaction Fee:{' '}
          {transactionFee.isLoading ? (
            <Loading size="small" />
          ) : (
            <Text variant={TypographyVariant.body2}>{formatPrice(transactionFee.total, 'USD')}</Text>
          )}
        </Text>
      </FieldWrapper>
      <AssetsAllocationTable assets={assets} errors={errors} />
    </StyledRetireBatchForm>
  );
};

const StyledRetireBatchForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const MakePublicRetirement = styled.div`
  padding: 16px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  background-color: ${({ theme }) => toLayerBackground(theme)('field')};
  margin-bottom: 16px;
`;

const BeneficiaryAndOfsettingPeriod = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 50%;

  & > * {
    width: auto;
  }

  @media (max-width: 716px) {
    grid-template-columns: 1fr;
    gap: 0;
  }
`;

const OffsetScopes = styled.div`
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  margin-top: 16px;
  margin-bottom: 16px;

  & > * {
    flex-basis: 33.33%;
  }
`;

const StyledSelectDate = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.system.border.radius.m};
  box-shadow: inset 0 0 0 1px ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  padding-inline-start: ${({ theme }) => toSpacing(theme)(8)};
  padding-inline-end: ${({ theme }) => toSpacing(theme)(6)};
  gap: ${({ theme }) => toSpacing(theme)(6)};
  height: 3rem;
  transition: box-shadow 0.15s linear;
  background: ${({ theme }) => toLayerBackground(theme)('field')};
  &:hover {
    background: ${({ theme }) => toLayerBackground(theme)('fieldHover')};
  }
`;
