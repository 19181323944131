import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import { IconName, InputText, InputTextSize, Modal, Select, ToastVariant, showToast } from '@aircarbon/ui';
import { UserType, formatter, logger } from '@aircarbon/utils-common';

import FormDevTool from 'components/FormDevTool';
import { FieldWrapper } from 'components/styled/Styled';

import { Account } from 'state/account';
import { Entity } from 'state/entity';
import { User } from 'state/user';

import useAccountBalances from 'hooks/useAccountBalances';
import useCurrencies from 'hooks/useCurrencies';
import useTokenTypes from 'hooks/useTokenTypes';

import { convertTextNumberToValue } from 'utils/helpers';

const schema = yup.object().shape({
  assetType: yup.string().required(),
  from: yup.string().required(),
  to: yup.string().required(),
  type: yup.string().required(),
  amount: yup
    .number()
    .transform(function (value, originalValue) {
      if (this.isType(value)) return value;

      return convertTextNumberToValue(originalValue);
    })
    .min(1)
    .test('hasEnoughAmount', 'Insufficient balance', function validateAmount(val: any): boolean {
      const { availableAmount, type } = this.parent;
      if (type) {
        return Number(val) <= availableAmount;
      }

      return false;
    })
    .required(),
});

export type TransferFormData = {
  from: string;
  to: string;
  amount: number;
  availableAmount: number;
  assetType?: number;
  type?: 'Token' | 'Currency';
};

type Props = {
  userIds: Array<number>;
  isVisible: boolean;
  onTransferSuccess(): void;
  onClose(): void;
};

export const CorpTransferForm: React.FunctionComponent<Props> = (props) => {
  const { userIds, isVisible, onTransferSuccess, onClose } = props;
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const { accountUsers, onTxRequestSuccess } = Account.useContainer();
  const {
    selector: { availableCurrencies },
  } = Entity.useContainer();
  const { currenciesByScId } = useCurrencies();
  const { tokenTypes } = useTokenTypes();

  const [isTransfering, setIsTransfering] = useState(false);

  const {
    refetchBalances,
    selector: { getAvailableAmountByAddress, getAvailableTokenQuantityByAddress, getAvailableTokensByAddress },
  } = useAccountBalances(userIds);

  const { control, handleSubmit, watch, reset, setValue } = useForm<TransferFormData>({
    defaultValues: {
      availableAmount: 0,
    },
    resolver: yupResolver(schema),
  });

  const [transfer] = useMutation(
    async (formData: Record<string, any>) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      return fetch(`/api/user/transfer/${formData.type === 'Token' ? 'token' : 'fund'}`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        } as any,
        body: JSON.stringify(formData),
      });
    },
    {
      onSuccess: async (data) => {
        if (data.ok) {
          reset();
          onTxRequestSuccess('We are processing your transfer. It may take several seconds.');
          refetchBalances();
          onTransferSuccess();
        } else {
          const error = await data.json();
          showToast({
            variant: ToastVariant.Danger,
            message: error?.message ?? 'Something went wrong!',
          });
          logger.error(error);
        }
        setIsTransfering(false);
      },
      onError: (error: Error) => {
        setIsTransfering(false);

        showToast({
          variant: ToastVariant.Danger,
          message: error?.message ?? 'Something went wrong!',
        });
        logger.error(error);
      },
    },
  );

  const onSubmit = useCallback(
    ({ from, to, amount, assetType, type }: TransferFormData) => {
      setIsTransfering(true);
      transfer(
        type === 'Token'
          ? {
              type,
              tokSender: {
                addr: from,
                tokTypeId: Number(assetType),
                tokenQty: Number(amount),
              },
              tokReceiver: {
                addr: to,
              },
            }
          : {
              type,
              ccySender: {
                addr: from,
                ccyTypeId: Number(assetType),
                ccyValue: Number(amount),
              },
              ccyReceiver: {
                addr: to,
              },
            },
      );
    },
    [transfer],
  );

  const { type, assetType, from, availableAmount, to } = watch();
  const showAccountTypes: string[] = [
    UserType.CORPORATE_ADMIN,
    UserType.CORPORATE_TRADER,
    UserType.CORPORATE_CLIENT_READ_ONLY,
    UserType.CORPORATE_CLIENT_DMA,
  ];
  const currentCcyAssetCode =
    type === 'Currency' ? Object.values(currenciesByScId())?.find((ccy: any) => ccy.scId === assetType)?.code : '';
  const currentTokenUnit =
    type === 'Token' ? Object.values(tokenTypes)?.find((token: any) => token.scId === assetType)?.uom?.code : '';
  const currentTokenScRatio =
    type === 'Token'
      ? (Object.values(tokenTypes)?.find((token: any) => token.scId === assetType)?.uom?.scRatio ?? 1000)
      : 1000;
  const currentTokenAssetNumDecimals =
    type === 'Token' ? Object.values(tokenTypes)?.find((token: any) => token.scId === assetType)?.numDecimals : 0;

  // set available amount base on type and sender account
  useEffect(() => {
    if (type && from) {
      setValue(
        'availableAmount',
        type === 'Token'
          ? getAvailableTokenQuantityByAddress({
              address: from,
              tokenTypeId: Number(assetType),
              tokenType:
                getAvailableTokensByAddress(from).find((tokenType) => Number(tokenType.id) === Number(assetType))
                  ?.name ?? '',
              scRatio: currentTokenScRatio,
            })
          : getAvailableAmountByAddress(from),
      );
    }
  }, [type, assetType, from]);

  useEffect(() => {
    setValue('assetType', undefined);
  }, [type, from]);

  const amountPostfix =
    assetType && type ? (type === 'Currency' ? availableCurrencies[assetType] : (currentTokenUnit ?? '')) : undefined;

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      title={'Make Transfer'}
      positiveButtonText="Confirm"
      positiveButtonIcon={IconName.ArrowEnd}
      isPositiveButtonLoading={isTransfering}
      onPressPositiveButton={handleSubmit(onSubmit)}
      onPressNegativeButton={onClose}
    >
      <FormDevTool control={control} />
      <form className="contents" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-0 w-full md:flex-row md:gap-base">
          <div className="flex-1">
            <FieldWrapper>
              <Controller
                name="from"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    value={field.value}
                    label={'From'}
                    size={InputTextSize.s}
                    placeholder="Select account"
                    items={accountUsers
                      .filter(
                        ({ account, account_type }) =>
                          showAccountTypes.includes(account_type) && account && account !== to,
                      )
                      .map((userAccount: Record<string, any>) => {
                        const { first_name, last_name, account } = userAccount;

                        return {
                          value: '' + account,
                          title: `${first_name} ${last_name}`,
                        };
                      })}
                    error={fieldState.error?.message}
                    onChange={({ value }) => field.onChange(value)}
                  />
                )}
              />
            </FieldWrapper>
          </div>
          <div className="flex-1">
            <FieldWrapper>
              <Controller
                name="to"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    value={field.value}
                    label={'To'}
                    size={InputTextSize.s}
                    placeholder="Select account"
                    items={
                      accountUsers
                        .filter(
                          ({ account, account_type }) =>
                            showAccountTypes.includes(account_type) && account && account !== from,
                        )
                        .map((userAccount: Record<string, any>) => {
                          const { first_name, last_name, account } = userAccount;

                          return {
                            value: '' + account,
                            title: `${first_name} ${last_name}`,
                          };
                        }) || []
                    }
                    error={fieldState.error?.message}
                    onChange={({ value }) => field.onChange(value)}
                  />
                )}
              />
            </FieldWrapper>
          </div>
        </div>
        <div className="flex flex-col gap-0 w-full md:flex-row md:gap-base">
          <div className="flex-1">
            <FieldWrapper>
              <Controller
                name="type"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    value={field.value}
                    label={'Type'}
                    size={InputTextSize.s}
                    placeholder="Select type"
                    items={[
                      {
                        value: 'Currency',
                        title: 'Currency',
                      },
                      {
                        value: 'Token',
                        title: 'Asset',
                      },
                    ]}
                    error={fieldState.error?.message}
                    onChange={({ value }) => field.onChange(value)}
                  />
                )}
              />
            </FieldWrapper>
          </div>
          <div className="flex-1">
            <FieldWrapper>
              <Controller
                name="assetType"
                control={control}
                render={({ field, fieldState }) => (
                  <Select
                    value={field.value?.toString()}
                    label={'Asset Type'}
                    size={InputTextSize.s}
                    placeholder="Select type"
                    items={
                      type === 'Token'
                        ? getAvailableTokensByAddress(from).map((token) => {
                            return { value: '' + token.id, title: token.name };
                          })
                        : Object.keys(availableCurrencies).map((value: string) => ({
                            value: value,
                            title: availableCurrencies[value] as string,
                          }))
                    }
                    error={fieldState.error?.message}
                    onChange={({ value }) => field.onChange(value)}
                  />
                )}
              />
            </FieldWrapper>
          </div>
        </div>

        <FieldWrapper>
          <Controller
            name="amount"
            control={control}
            render={({ field, fieldState }) => (
              <InputText
                size={InputTextSize.s}
                suffix={amountPostfix || ''}
                label={`${type === 'Currency' ? 'Amount' : 'Quantity'} (Available ${
                  type === 'Currency'
                    ? `${currentCcyAssetCode ?? ''} ${formatter.formatNumber(availableAmount, 2)}`
                    : `${formatter.formatNumber(availableAmount, currentTokenAssetNumDecimals)} ${currentTokenUnit ?? ''}`
                }
              )`}
                error={fieldState.error?.message}
                onChange={field.onChange}
                value={field.value?.toString()}
              />
            )}
          />
        </FieldWrapper>
      </form>
    </Modal>
  );
};
