import { styled } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';
import type { Asset } from '@aircarbon/utils-common/src/dto';

import { Entity } from 'state/entity';

import { BidTable } from './Styled';
import type { OpenOrder, PendingTransactionToken, Tokens } from './types';

interface Props {
  balance: number;
  available: number;
  tokens: Array<Tokens>;
  tokenTypes: Asset[];
  openOrders: Array<OpenOrder>;
  pendingTransactionAmount: number;
  pendingTransactionTokens: Array<PendingTransactionToken>;
}
const Wrapper = styled.div`
  border-radius: 0 0 5px 5px;
  overflow: hidden;
`;

const BalanceSummary = ({ balance, available, tokens, tokenTypes, openOrders, pendingTransactionTokens }: Props) => {
  const {
    selector: { mainCcyCode, mainCcyNumDecimals, mainCcySymbol },
  } = Entity.useContainer();
  return (
    <Wrapper>
      <BidTable>
        <thead>
          <tr>
            <th className="assetName" style={{ width: '80px' }}>
              Asset
            </th>
            <th>Open</th>
            <th>Available</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{mainCcySymbol}</td>
            <td>
              {mainCcyCode}
              {formatter.formatNumber(balance - available, mainCcyNumDecimals)}
            </td>
            <td>
              {mainCcyCode}
              {available > 0 ? formatter.formatNumber(available, mainCcyNumDecimals) : 0}
            </td>
          </tr>
          {tokenTypes?.map((tokenType) => {
            const { scId, symbol } = tokenType;
            const tokensQty = tokens
              .filter((item) => formatter.hex2int(item.tokTypeId) === Number(scId))
              .reduce((qty, token) => {
                return formatter.hex2int(token?.currentQty) + qty;
              }, 0);
            const tokenSymbol = symbol.toUpperCase();
            const openOrdersQty = openOrders.reduce((total, order) => {
              if (order.tokenTypeId === scId && order.side.toLowerCase() === 'sell') {
                return total + Number(order.quantity) - Number(order.fill_quantity);
              }

              return total;
            }, 0);
            const pendingTxQty = pendingTransactionTokens.reduce((total, tx) => {
              if (tx.symbol === `${tokenSymbol}/${mainCcySymbol}`) {
                return total + Number(tx.token);
              }

              return total;
            }, 0);

            return (
              <tr key={`balance-token-${scId}`}>
                <td>{tokenSymbol}</td>
                <td>
                  {formatter.formatNumber(openOrdersQty + pendingTxQty, tokenType.numDecimals)} {tokenType.uom?.name}
                </td>
                <td>
                  {tokensQty
                    ? formatter.formatNumber(
                        tokensQty / tokenType.uom.scRatio - openOrdersQty - pendingTxQty,
                        tokenType.numDecimals,
                      )
                    : 0}
                  {}
                </td>
              </tr>
            );
          })}
        </tbody>
      </BidTable>
    </Wrapper>
  );
};

export default BalanceSummary;
