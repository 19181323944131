import type { FunctionComponent } from 'react';
import { useFilters } from 'refreshed-pages/market-board-v2/hooks/useFilters';
import { Sorting } from 'refreshed-pages/market-board-v2/utils/Sorting';

import {
  IconName,
  InputTextSize,
  Select,
  Tabs,
  TabsVariant,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';

export enum ActiveViewType {
  List = 'list',
  Grid = 'grid',
}

export const SubHeader: FunctionComponent<{
  resultsCount: number;
  activeViewType: ActiveViewType;
  isListViewEnabled: boolean;
  onChangeViewType(viewType: ActiveViewType): void;
}> = ({ resultsCount, activeViewType, isListViewEnabled, onChangeViewType }) => {
  const { staticFilters, setSorting } = useFilters();
  const onChangeSortingSelect = ({ value }: { value: Sorting }) => {
    setSorting(value);
  };

  return (
    <StyledSubHeader>
      <Text variant={TypographyVariant.caption} color={TextColor.secondary}>
        {!!resultsCount && `${resultsCount} ${resultsCount > 1 ? 'results' : 'result'}`}
      </Text>
      <LayoutAndSortWrapper>
        {isListViewEnabled && (
          <Tabs
            activeItemId={activeViewType}
            variant={TabsVariant.Contained}
            onPressTab={(tab) => {
              onChangeViewType(tab.id as ActiveViewType);
            }}
            items={[
              {
                id: 'list',
                icon: IconName.ViewList,
              },
              {
                id: 'grid',
                icon: IconName.ViewGrid,
              },
            ]}
          />
        )}

        <Select
          value={staticFilters.sort}
          size={InputTextSize.s}
          onChange={onChangeSortingSelect}
          items={[
            {
              value: Sorting.MostRecent,
              title: 'Most Recent',
            },
            {
              value: Sorting.PriceLow,
              title: 'Price: Low to High',
            },
            {
              value: Sorting.PriceHigh,
              title: 'Price: High to Low',
            },
          ]}
        />
      </LayoutAndSortWrapper>
    </StyledSubHeader>
  );
};

const StyledSubHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => toSpacing(theme)(4)} ${({ theme }) => toSpacing(theme)(8)};
`;

const LayoutAndSortWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(4)};
`;
