import { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  Badge,
  BorderColor,
  Button,
  ButtonSize,
  ButtonVariant,
  Divider,
  Layer,
  Tabs,
  TabsSize,
  Text,
  TextAlign,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
  useLayerBackground,
  useSpacing,
  useTextColor,
} from '@aircarbon/ui';

import SimpleBar from 'pages/account/trading/components/Simplebar';
import { useNotifications } from 'pages/account/trading/hooks';

import { formatDate } from 'utils/helpers';

type Props = {
  remove?: () => void;
  count?: number;
};

const filters = ['All', 'Alert', 'Email'];

const NotificationsRoot = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: min-content;
  width: 440px;
  max-width: 100%;
  max-height: 100%;
  justify-items: stretch;
  overflow: hidden;
  > .list {
    max-height: 350px;
    overflow: auto;
    position: relative;
    display: block;
  }
`;

export const NotificationsOverlay = ({ count, ...props }: Props) => {
  const [selectedFilter, setFilter] = useState('All');
  const { spacing } = useSpacing();
  const { layerBackground } = useLayerBackground();
  const { textColor } = useTextColor();

  const history = useHistory();

  const { notifications, markAsRead } = useNotifications(selectedFilter, '');
  const notificationRef = useRef<HTMLDivElement>(null);

  const filteredNotifications = notifications?.filter((item: { category: string }) => {
    if (selectedFilter === 'All') return true;
    return selectedFilter?.toLocaleLowerCase() === item?.category?.toLocaleLowerCase();
  });
  const showedNotifications = filteredNotifications?.slice(0, 10) as any[];

  return (
    <NotificationsRoot ref={notificationRef}>
      <div className="flex flex-col">
        <Text margin={spacing(8)} variant={TypographyVariant.h6Title}>
          Notifications
        </Text>

        <Tabs
          size={TabsSize.l}
          activeItemId={selectedFilter}
          marginHorizontal={spacing(3)}
          items={filters.map((filter, index) => ({
            id: filter,
            label: filter,
            suffix: !!count && index === 0 && <Badge value={count.toString()} />,
          }))}
          onPressTab={(tab) => {
            setFilter(tab.id);
          }}
        />
        <Divider />
      </div>
      <div className="overflow-hidden flex-1 flex-shrink list">
        <SimpleBar>
          <div className="flex flex-col w-full">
            {showedNotifications?.length <= 0 ? (
              <Text margin={spacing(8)} align={TextAlign.center}>
                No Notifications Available
              </Text>
            ) : (
              showedNotifications?.map((item: any, index: number, list) => {
                const isLastItem = index === list.length - 1;
                const notification = (
                  <NotificationItem
                    key={`item-${item.id}`}
                    $isRead={!!item.isRead}
                    $isLast={isLastItem}
                    onClick={() => {
                      markAsRead(item.id);
                      history.push(`/account/notifications?id=${item.id}`);
                      if (props.remove) props.remove();
                    }}
                  >
                    <div
                      className="flex flex-row justify-center rounded-full p-xs"
                      style={{
                        background: layerBackground('layer'),
                      }}
                    >
                      <svg width="16" height="16" viewBox="0 0 16 12" fill="none">
                        <path
                          d="M0.00292969 1.884L7.99993 5.882L15.9969 1.884C15.9673 1.37444 15.744 0.895488 15.3728 0.545227C15.0015 0.194965 14.5103 -9.35847e-05 13.9999 3.36834e-08H1.99993C1.48951 -9.35847e-05 0.998377 0.194965 0.627107 0.545227C0.255838 0.895488 0.0325338 1.37444 0.00292969 1.884Z"
                          fill={layerBackground('layerAccent')}
                        />
                        <path
                          d="M16 4.11719L8 8.11719L0 4.11719V9.99919C0 10.5296 0.210714 11.0383 0.585786 11.4134C0.960859 11.7885 1.46957 11.9992 2 11.9992H14C14.5304 11.9992 15.0391 11.7885 15.4142 11.4134C15.7893 11.0383 16 10.5296 16 9.99919V4.11719Z"
                          fill={layerBackground('layerAccent')}
                        />
                      </svg>
                      {item.isRead === 0 && (
                        <svg className="absolute top-2 left-2 m-xs" width="8" height="8" viewBox="0 0 8 8" fill="none">
                          <circle
                            cx="4"
                            cy="4"
                            r="3.5"
                            fill={textColor(TextColor.error)}
                            stroke={textColor(TextColor.error)}
                          />
                        </svg>
                      )}
                    </div>
                    <div className="flex flex-col gap-xs">
                      <Text>
                        {item?.subject ?? item.action}{' '}
                        {item?.userInboxFiles?.length > 0 && (
                          <div className="px-2 text-center icon">
                            <svg width="12" height="14" fill="none">
                              <path
                                d="M10.9628 5.93166L5.9861 11.2971C5.02971 12.3282 3.41993 12.3887 2.38882 11.4323C1.35772 10.4759 1.29721 8.86617 2.2536 7.83507L7.66306 2.00304C8.26026 1.35918 9.2675 1.32132 9.91136 1.91853C10.5552 2.51573 10.5931 3.52297 9.99587 4.16682L5.45193 9.06573C5.21391 9.32234 4.80922 9.33755 4.55261 9.09953C4.296 8.86152 4.28079 8.45682 4.5188 8.20021L8.62999 3.76787L7.93015 3.11874L3.81896 7.55108C3.22175 8.19493 3.25962 9.20217 3.90347 9.79937C4.54733 10.3966 5.55456 10.3587 6.15177 9.71486L10.6957 4.81596C11.6521 3.78486 11.5916 2.17508 10.5605 1.21868C9.52939 0.26229 7.91961 0.322802 6.96322 1.3539L1.55376 7.18593C0.238178 8.60428 0.321339 10.8166 1.73969 12.1322C3.15804 13.4478 5.37036 13.3646 6.68594 11.9463L11.6626 6.58079L10.9628 5.93166Z"
                                fill="gray"
                              />
                            </svg>
                          </div>
                        )}
                      </Text>
                      <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                        {formatDate(item?.createdAt)}
                      </Text>
                    </div>
                  </NotificationItem>
                );

                return item.isRead ? notification : <Layer>{notification}</Layer>;
              })
            )}
          </div>
        </SimpleBar>
      </div>
      <Divider />
      <div className="flex flex-row p-large">
        <Button
          className="flex-1"
          variant={ButtonVariant.outlined}
          size={ButtonSize.s}
          onPress={() => {
            history.push(`/account/notifications`);
            if (props.remove) props.remove();
          }}
        >
          View All
        </Button>
      </div>
    </NotificationsRoot>
  );
};

const NotificationItem = styled.div<{
  $isRead: boolean;
  $isLast: boolean;
}>`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  padding: ${({ theme }) => toSpacing(theme)(8)};
  gap: ${({ theme }) => toSpacing(theme)(8)};
  background: ${({ theme }) => toLayerBackground(theme)('field')};
  ${({ theme, $isLast }) =>
    !$isLast ? `border-bottom: 1px solid ${toBorderColor(theme)(BorderColor.neutral)}` : undefined};
`;
