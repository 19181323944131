export const openHelp = () => {
  const clickEvent = new MouseEvent('click', {
    view: window,
    bubbles: true,
    cancelable: false,
  });
  const tFrame = document.getElementById('jsd-widget') as HTMLIFrameElement;
  const doc = (tFrame as any).contentWindow.document as Document;
  tFrame.classList.add('enable');
  doc.addEventListener('click', (event) => {
    const target = event.target as HTMLHtmlElement;
    if (target.className === 'close-icon fit') {
      tFrame.classList.remove('enable');
    }
  });
  const button = doc.getElementById('help-button');
  button?.dispatchEvent(clickEvent);
};
