import { useHistory, useParams } from 'react-router-dom';
import Loading from 'refreshed-component/molecules/Loading';
import { PageHeader } from 'refreshed-component/molecules/PageHeader';
import { Stepper } from 'refreshed-component/molecules/Stepper';

import {
  BorderColor,
  Button,
  ButtonSize,
  ButtonType,
  Card,
  CardVariant,
  Layer,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
} from '@aircarbon/ui';

import { ProjectForm, type ProjectFormMetadata, type ProjectFormValue } from '../ProjectForm';
import { SuccessMessage } from '../SuccessMessage';
import { useProjectCreator } from './hooks/useProjectCreator';
import { useProjectFormMetadata } from './hooks/useProjectFormMetadata';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const StyledListNewProject = styled.div`
  padding: 0 ${({ theme }) => toSpacing(theme)(16)};
  position: relative;
  overflow-y: auto;
  flex-grow: 1;
`;

const StepperInsideHeader = styled(Stepper)`
  width: calc(100% + ${({ theme }) => toSpacing(theme)(16)} * 2);
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

const ProjectFormContainer = styled.div`
  max-width: 820px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

const FormButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  width: 100%;
  padding: 24px;
  border-top: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  background-color: ${({ theme }) => toLayerBackground(theme)('layer')};
  align-self: flex-end;
`;

const ButtonsSection = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const ProjectFormPage = () => {
  const { projectId = '' } = useParams<{
    projectId: string;
  }>();
  const {
    project,
    formTitle,
    formSteps,
    formValue,
    formErrors,
    activeFormStep,
    isSaveVisible,
    saveButtonLabel,
    isNextButtonVisible,
    isNextButtonLoading,
    isSubmitButtonVisible,
    isSubmitButtonLoading,
    isFormSubmitted,
    isLoading,
    changeFormValue,
    goToNextStep,
    goToStep,
    submitForm,
  } = useProjectCreator(projectId);
  const history = useHistory();

  const { metadata, isLoading: isMetadataLoading } = useProjectFormMetadata(projectId);

  const onChangeProjectForm = (value: ProjectFormValue) => {
    changeFormValue(value);
  };

  const onPressStep = (stepIndex: number) => {
    goToStep(stepIndex);
  };

  const onPressViewProject = () => {
    history.replace(`/account/apx/my-projects/${projectId}`);
  };

  const onPressBack = () => {
    history.goBack();
  };

  if (isLoading || isMetadataLoading) {
    return <Loading />;
  }

  return (
    <Container>
      <StyledListNewProject>
        <PageHeader
          title={formTitle}
          onBack={onPressBack}
          marginVertical="24px"
          subtitle={
            <>
              {saveButtonLabel
                ? saveButtonLabel
                : project?.updatedAt
                  ? `Last saved ${project.updatedAt}`
                  : project?.createdAt
                    ? `Last saved ${project?.createdAt}`
                    : ''}{' '}
            </>
          }
          suffix={
            <ButtonsSection>
              {isSubmitButtonVisible && !isFormSubmitted && (
                <Button
                  size={ButtonSize.l}
                  type={ButtonType.Submit}
                  isLoading={isSubmitButtonLoading}
                  onPress={() => {
                    submitForm();
                  }}
                >
                  {isSubmitButtonLoading ? 'Submitting...' : 'Submit For Review'}
                </Button>
              )}
            </ButtonsSection>
          }
        />
        <Layer>
          <Card
            variant={CardVariant.Bordered}
            header={
              !isFormSubmitted && isSaveVisible ? (
                <div className="flex gap-4 items-center border-b">
                  <StepperInsideHeader steps={formSteps} onPressStep={onPressStep} />
                </div>
              ) : undefined
            }
          >
            {isFormSubmitted ? (
              <SuccessMessage onPressViewProject={onPressViewProject} />
            ) : (
              <ProjectFormContainer>
                <ProjectForm
                  value={formValue}
                  errors={formErrors}
                  metadata={metadata as ProjectFormMetadata}
                  activeStepIndex={activeFormStep}
                  onChange={onChangeProjectForm}
                />
              </ProjectFormContainer>
            )}
          </Card>
        </Layer>
      </StyledListNewProject>
      {!isFormSubmitted && !isSaveVisible && (
        <Layer>
          <FormButtons>
            {isNextButtonVisible && !isSubmitButtonVisible && (
              <Button
                size={ButtonSize.l}
                onPress={() => {
                  goToNextStep();
                }}
                isLoading={isNextButtonLoading}
              >
                Next
              </Button>
            )}
          </FormButtons>
        </Layer>
      )}
    </Container>
  );
};
