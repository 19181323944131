import { yupResolver } from '@hookform/resolvers/yup';
import Cleave from 'cleave.js/react';
import { useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import * as yup from 'yup';

import {
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  LabelWithDescription,
  ToastVariant,
  showToast,
} from '@aircarbon/ui';
import { formatter, logger, matching } from '@aircarbon/utils-common';

import FormDevTool from 'components/FormDevTool';
import Loading from 'components/styled/Loading';
import { InputError, InputWrapperWithThemeColors } from 'components/styled/Styled';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import { convertTextNumberToValue } from 'utils/helpers';

import { BidInfoWrapper, BidTable } from '../Styled';

interface Props {
  rfq: {
    id: number;
    type: string;
    fillType: string;
    tokenTypeId: number;
    ccyTypeId: number;
    quantity: number;
    filled: number;
    price: number;
    createdAt: Date;
    userId: number;
    __rfqRequestDetails__: Array<any>;
    __rfqResponses__: Array<any>;
  };
  addressData: Record<
    number,
    {
      tokTypeId: number;
      tokTypeName: string;
      stIds: Array<number>;
      qty: number;
      batchId: number;
      project: Record<string, any>;
    }
  >;
  onSubmit: () => void;
}

const rfqSchema = (quantity: number, fillType: string) =>
  yup.object().shape({
    batch: yup
      .array()
      .of(
        yup.object().shape({
          qty: yup
            .number()
            .required()
            .transform(function (value, originalValue) {
              if (this.isType(value)) return value;

              return convertTextNumberToValue(originalValue);
            })
            .min(0)
            .max(quantity)
            .test('validateAvailableQty', 'Exceeds available qty', function (qty) {
              return this.parent.availableQuantity >= (qty ?? 0);
            }),
        }),
      )
      .required()
      .min(1, 'Please select the project')
      .test('validateQty', 'Please adjust the quantity', function validateQty(batchQty) {
        const totalQty = batchQty?.reduce((total, item) => Number(total) + Number(item?.qty ?? 0), 0) ?? 0;
        logger.warn({
          quantity,
          totalQty,
        });

        if (fillType === 'ALL_OR_NONE') return Number(totalQty) === quantity;

        return Number(totalQty) > 0;
      }),
  });

type AcceptRFQForm = {
  batch: Array<{
    batchId: number;
    qty: number;
    availableQuantity: number;
  }>;
};

const AcceptRFQ = ({ rfq, addressData, onSubmit }: Props) => {
  const { getSetting } = UI.useContainer();
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const {
    selector: { mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();
  const rfqFeePerThousandTons = getSetting('global_fee_carbonFinderPerThousandTons');
  const rfqFeeMax = getSetting('global_fee_carbonFinderCap');
  const rfqFeeMin = getSetting('global_fee_carbonFinderMin');

  const { id: rfqId, quantity, price, filled, fillType } = rfq;
  const filters = rfq.__rfqRequestDetails__;

  const {
    watch,
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<AcceptRFQForm>({
    resolver: yupResolver(rfqSchema(quantity, fillType)),
    defaultValues: {
      batch: [],
    },
  });

  const [addRFQResponse] = useMutation(
    async (data: { rfqId: number; tokenTypeId: number; stIds: Array<number>; quantity: number }) => {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      return fetch(`/api/account/rfq/${data.rfqId}`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
      });
    },
  );

  const [transferRFQ] = useMutation(async (data: { rfqId: number; rfqResponseIds: Array<number> }) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    return fetch(`/api/account/rfq/${data.rfqId}/confirm`, {
      method: 'POST',
      body: JSON.stringify({
        rfqResponseIds: data.rfqResponseIds.filter(Boolean),
      }),
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
  });

  const batchQty = watch('batch');

  // only show matching the carbon filter with min qty >= 1
  const matchedProjects = Object.keys(addressData).filter(
    (batchId) =>
      Number(addressData[Number(batchId)]?.qty ?? 0) >= 1 &&
      matching.rfqMatching(filters, [addressData[Number(batchId)]].filter(Boolean)),
  );

  const onTransferRfq = async (rfqResponseIds: Array<number>) => {
    try {
      const result = await transferRFQ({
        rfqId,
        rfqResponseIds,
      });
      if (result?.ok) {
        showToast({
          variant: ToastVariant.Info,
          message: `Filling Bid ${rfqId} ..`,
        });
      } else {
        const error = await result?.json();
        showToast({
          variant: ToastVariant.Danger,
          message: error?.message ?? 'Something went wrong with transfer!',
        });
      }
    } catch (error) {
      showToast({
        variant: ToastVariant.Danger,
        message: (error as Error).message,
      });
    }
  };

  const onConfirmByBatch = async ({ batchId, qty }: { batchId: number; qty: number }) => {
    try {
      const tokenTypeId: number = addressData[batchId]?.tokTypeId ?? 0;
      const stIds: Array<number> = addressData[batchId]?.stIds ?? [];
      const result = await addRFQResponse({
        rfqId,
        tokenTypeId,
        stIds,
        quantity: qty,
      });
      if (result?.ok) {
        const { id } = (await result?.json()) ?? {};
        return id;
      } else {
        const error = await result?.json();
        showToast({
          variant: ToastVariant.Danger,
          message: error?.message ?? 'Something went wrong!',
        });
      }
    } catch (error) {
      showToast({
        variant: ToastVariant.Danger,
        message: (error as Error).message,
      });
    }
  };

  const onSubmitHandler = async (formData: AcceptRFQForm) => {
    try {
      const selectedBatches = formData.batch.filter((batch) => batch.qty > 0);
      logger.warn({
        selectedBatches,
      });
      const ids = await Promise.all(selectedBatches.map(onConfirmByBatch));
      await onTransferRfq(ids);
      onSubmit();
    } catch (error) {
      showToast({
        variant: ToastVariant.Danger,
        message: (error as Error).message,
      });
    }
  };

  const matchedProjectsRef = useRef(matchedProjects);
  // set default quantity after fetching data
  useEffect(() => {
    logger.warn(matchedProjectsRef, 'matchedProjects');
    if (matchedProjectsRef.current.length > 0) {
      let bidQty = rfq.quantity - rfq.filled;
      matchedProjectsRef.current.forEach((batchId) => {
        const availableQuantity = Number(addressData[Number(batchId)]?.qty ?? 0);
        let enteredQty = convertTextNumberToValue(batchQty[Number(batchId)]?.qty ?? 0);
        enteredQty = availableQuantity > bidQty ? bidQty : Math.min(availableQuantity, bidQty - enteredQty);
        setValue(`batch[${batchId}].batchId` as any, batchId);
        setValue(`batch[${batchId}].availableQuantity` as any, availableQuantity);
        setValue(`batch[${batchId}].qty` as any, enteredQty, { shouldValidate: true });
        bidQty -= enteredQty;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchedProjectsRef.current, rfq?.filled]); // only trigger if the project match changed or after partial fill

  if (matchedProjects.length === 0) return <h3>No matches project!</h3>;

  const selectedQty = batchQty.reduce((total, item) => {
    return total + convertTextNumberToValue(item.qty);
  }, 0);

  let isDisabled = Object.keys(errors).length > 0;
  if (fillType === 'ALL_OR_NONE') {
    isDisabled = isDisabled || selectedQty !== quantity;
  } else {
    isDisabled = isDisabled || quantity - selectedQty - filled < 0;
  }

  const totalFill =
    getValues().batch?.reduce((result, item) => {
      return result + convertTextNumberToValue(item?.qty ?? 0);
    }, 0) ?? 0;

  const calculateRfqFeePerThousandTons = (qty: number): number => {
    const totalFee = (Number(rfqFeePerThousandTons) * Number(qty)) / 1000;
    return totalFee > Number(rfqFeeMax)
      ? Number(rfqFeeMax)
      : totalFee < Number(rfqFeeMin)
        ? Number(rfqFeeMin)
        : totalFee;
  };
  const totalFee = calculateRfqFeePerThousandTons(totalFill);

  return (
    <div>
      <FormDevTool control={control} />
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <div>
          <BidTable>
            <thead>
              <tr>
                <th className="projectName">Project Name</th>
                <th className="assetName">Asset</th>
                <th>Available Qty</th>
                <th className="largeNumber">Fill Qty</th>
                <th>Fee</th>
                <th className="largeNumber">Receive Amount</th>
              </tr>
            </thead>
            <tbody>
              {matchedProjects.map((batchId) => {
                const availableQuantity = Number(addressData[Number(batchId)]?.qty ?? 0);
                const fee = calculateRfqFeePerThousandTons(
                  convertTextNumberToValue(getValues(`batch[${batchId}].qty` as any) || 0),
                );
                return (
                  <tr key={batchId}>
                    <td>{addressData[Number(batchId)]?.project?.TXT_PROJECT_NAME ?? ''}</td>
                    <td>{addressData[Number(batchId)]?.tokTypeName ?? ''}</td>
                    <td>
                      {/* // TODO: use numDecimals, if we used RFQ again */}
                      {formatter.formatNumber(addressData[Number(batchId)]?.qty ?? 0, 0)}
                      <input type="hidden" value={availableQuantity} name={`batch[${batchId}].availableQuantity`} />
                    </td>
                    <td>
                      <InputWrapperWithThemeColors>
                        <Controller
                          name={`batch[${batchId}].qty` as any}
                          control={control}
                          render={({ field }) => (
                            <Cleave
                              {...field}
                              placeholder="Fill quantity"
                              max={availableQuantity}
                              min={0}
                              options={{
                                numeral: true,
                                numeralThousandsGroupStyle: 'thousand',
                                numeralPositiveOnly: true,
                                numeralDecimalScale: 0,
                              }}
                            />
                          )}
                        />
                      </InputWrapperWithThemeColors>
                      {errors?.batch?.[Number(batchId)]?.qty?.message ? (
                        <InputError>{errors?.batch?.[Number(batchId)]?.qty?.message}</InputError>
                      ) : (
                        ''
                      )}
                    </td>
                    <td>
                      {mainCcyCode}
                      {formatter.formatNumber(fee, mainCcyNumDecimals)}
                    </td>
                    <td>
                      {mainCcyCode}{' '}
                      {formatter.formatNumber(
                        convertTextNumberToValue(getValues(`batch[${batchId}].qty` as any) || 0) * price - fee,
                        mainCcyNumDecimals,
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td>Total Fill</td>
                <td />
                <td />
                {/* // TODO: use numDecimals, if we used RFQ again */}
                <td>{formatter.formatNumber(totalFill, 0)}</td>
                <td>
                  {mainCcyCode}
                  {formatter.formatNumber(totalFee, mainCcyNumDecimals)}
                </td>
                <td>
                  {mainCcyCode}
                  {formatter.formatNumber(totalFill * Number(price) - totalFee, mainCcyNumDecimals)}
                </td>
              </tr>
            </tfoot>
          </BidTable>
        </div>
        <BidInfoWrapper className="justify-end">
          {fillType === 'ALL_OR_NONE' && <LabelWithDescription label="Fill Type" description="All or Nothing" />}
          <LabelWithDescription
            label="Remaining"
            description={formatter.formatNumber(quantity - filled - totalFill, mainCcyNumDecimals)}
          />

          {isSubmitting && Object.keys(errors).length === 0 && (
            <div className="block fixed top-0 left-0 z-50 w-full h-full bg-gray-200 opacity-50">
              <Loading />
            </div>
          )}

          <div className="m-3">
            <Button
              type={ButtonType.Submit}
              isDisabled={isDisabled}
              size={ButtonSize.s}
              variant={ButtonVariant.outlined}
              endIcon={
                isDisabled ? (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                ) : (
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                )
              }
            >
              Trade
            </Button>
          </div>
        </BidInfoWrapper>
      </form>
    </div>
  );
};

export default AcceptRFQ;
