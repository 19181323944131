import { formatRelative } from 'date-fns';
import type { CounterOffer } from 'refreshed-pages/market-board-v2/utils/fetchTradeRequests';

import type { CMBOrderType } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import type { OfferProps } from '../components/Offer';

const { formatPrice, formatNumber } = formatter;

export const toCounterOfferModalOffers = (props: {
  negotiations: Array<CounterOffer>;
  currentUserId: number;
  currentUserName: string;
  priceLabel: string;
  currency: string;
  isCurrentUserSeller: boolean;
  orderType: CMBOrderType;
  numDecimals: number;
}): Array<OfferProps> => {
  const { negotiations, currentUserName, priceLabel, currency, isCurrentUserSeller, numDecimals } = props;

  return [...negotiations].reverse().map((negotiation) => {
    const isMine = negotiation.direction === 'sent';

    return {
      from: isMine ? `${currentUserName} (Me)` : isCurrentUserSeller ? 'Buyer' : 'Seller',
      isMine,
      time: formatRelative(new Date(negotiation.createdAtUtc), new Date()),
      price: formatPrice(negotiation.price, currency),
      quantity: formatNumber(negotiation.quantity, numDecimals),
      priceLabel: priceLabel,
      priceRaw: negotiation.price,
      quantityRaw: negotiation.quantity,
    };
  });
};
