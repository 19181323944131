import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import Modal, { ModalContent } from 'refreshed-component/molecules/Modal';
import * as yup from 'yup';

import {
  Alert,
  AlertColor,
  AlertVariant,
  Button,
  ButtonSize,
  ButtonType,
  ButtonVariant,
  Card,
  CardVariant,
  IconName,
  InputText,
  Knob,
  Layer,
  Select,
  Text,
  TextColor,
  ToastVariant,
  TypographyVariant,
  showToast,
  styled,
  useSpacing,
} from '@aircarbon/ui';
import { AssetCategory } from '@aircarbon/utils-common';

import type { CarbonProject } from 'pages/account/carbon/types';

import { User } from 'state/user';

import useCarbonMetaOptions from 'hooks/useCarbonMetaOptions';
import useCarbonProjects from 'hooks/useCarbonProjects';

const schema = yup.object().shape({
  projectName: yup.string().required('Required'),
  registryId: yup.string().required('Required'),
  registryProjectId: yup.string().required('Required'),
});

export type ProjectFormData = {
  carbonProjectId: number;
  registryProjectUrl: string;
  registryId: number;
  registryProjectId: string;
  projectName: string;
};

type Props = {
  assetCategoryId: AssetCategory;
  onChange?: (option?: CarbonProject) => void;
};

export const AddCarbonProject = ({ onChange, assetCategoryId }: Props) => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();

  const {
    carbonMetaOptions: { registries },
  } = useCarbonMetaOptions({
    query: {
      assetCategory: AssetCategory[assetCategoryId],
    },
  });
  const [isSaving, setIsSaving] = useState(false);
  const [selectedProject, setSelectedProject] = useState<CarbonProject | null>(null);
  const [searchProjectId, setSearchProjectId] = useState<string | null>(null);

  const formProperties = useForm<ProjectFormData>({
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
    setValue,
    getValues,
    reset,
    watch,
    trigger,
  } = formProperties;

  const { registryId, registryProjectId } = watch();
  const { spacing } = useSpacing();

  const { projects, isLoading } = useCarbonProjects({
    isFilterLike: false,
    filterBy: { registryProjectId, registryId, status: 'APPROVED' },
    options: { enabled: !!registryId && !!registryProjectId },
  });

  const registryOptions =
    registries?.map((registry) => ({
      label: registry.registryName,
      value: Number(registry.id),
    })) || [];

  const onSubmit = useCallback(
    async ({ registryId, registryProjectId, projectName }: ProjectFormData) => {
      setIsSaving(true);
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      const response = await fetch(`/api/user/carbon/project`, {
        method: 'POST',
        body: JSON.stringify({
          registryId: Number(registryId),
          registryProjectId,
          projectName,
          assetCategory: AssetCategory[assetCategoryId],
        }),
        headers: {
          accept: 'application/json',
          'content-type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
      });
      if (response.ok) {
        const result = await response.json();
        showToast({
          variant: ToastVariant.Success,
          message: 'Project added successfully',
        });
        setSelectedProject(result?.carbonProject);
        if (onChange) onChange(result?.carbonProject);
        reset();
      } else {
        const result = await response.json();
        showToast({
          variant: ToastVariant.Danger,
          message: result.message || response.statusText,
        });
      }
      setIsSaving(false);
    },
    [onChange, reset],
  );

  const canSearchProject = registryId && registryProjectId;

  const removeSelectedProject = () => {
    onChange?.();
    setSelectedProject(null);
    reset();
  };

  const trySubmit = async () => {
    const isValid = await trigger();
    if (isValid === true) {
      onSubmit(getValues());
    }
  };

  return (
    <>
      {selectedProject ? (
        <Layer>
          <StyledProjectCard variant={CardVariant.Bordered} className="w-full rounded-large">
            <div className="flex flex-col flex-1 p-base">
              <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                {selectedProject?.__registry__?.registryName} | Project ID: {selectedProject?.registryProjectId}
              </Text>
              <Text variant={TypographyVariant.subtitle1}>{selectedProject?.name}</Text>
            </div>
            <Knob
              onPress={removeSelectedProject}
              variant={ButtonVariant.ghost}
              icon={IconName.Trash}
              marginEnd={spacing(8)}
            />
          </StyledProjectCard>
        </Layer>
      ) : (
        <Modal
          title="Add a Project"
          description="Let us try to locate your project on our directory."
          action={
            <Button
              variant={ButtonVariant.outlined}
              size={ButtonSize.s}
              type={ButtonType.Button}
              startIcon={IconName.PlusCircle}
            >
              Add Project
            </Button>
          }
        >
          {() => {
            return (
              <form
                className="contents"
                onSubmit={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  trySubmit();
                }}
              >
                <ModalContent>
                  <div className="flex flex-col gap-base">
                    <div className="flex flex-col sm:flex-row gap-base">
                      <div className="flex overflow-hidden flex-col flex-1 gap-xs">
                        <Select
                          value={registryId ? String(registryId) : ''}
                          placeholder="Select account"
                          label="Registry*"
                          onChange={({ value }) =>
                            setValue('registryId', Number(value) || undefined, {
                              shouldValidate: true,
                            })
                          }
                          error={errors.registryId?.message}
                          items={registryOptions.map(({ value, label }) => ({ value: String(value), title: label }))}
                        />
                      </div>
                      <div className="flex flex-col flex-1 gap-xs">
                        <InputText
                          label="Registry Project ID*"
                          value={getValues('registryProjectId')}
                          onChange={(event) => {
                            setValue('registryProjectId', (event?.target.value as any) || undefined, {
                              shouldValidate: true,
                            });
                            if (searchProjectId) setSearchProjectId(null);
                          }}
                          error={errors.registryProjectId?.message}
                        />
                      </div>
                    </div>
                    <Button
                      type={ButtonType.Button}
                      onPress={() => setSearchProjectId(registryProjectId)}
                      isDisabled={!canSearchProject}
                      className="flex-1"
                    >
                      Search
                    </Button>
                    <Layer>
                      {registryId && registryProjectId && !isLoading && projects && (
                        <>
                          {projects && projects?.length > 0 && (
                            <>
                              <Text variant={TypographyVariant.subtitle1}>Please select the project:</Text>
                              {projects?.map((project) => (
                                <div
                                  key={`option-${project.id}`}
                                  onClick={() => {
                                    if (onChange) onChange(project);
                                    setSelectedProject(project);
                                    setValue('carbonProjectId', project.id);
                                    setValue('projectName', project.name);
                                    setValue('registryId', project.registryId);
                                    setValue('registryProjectId', project.registryProjectId);
                                    setValue('registryProjectUrl', project.registryProjectUrl);
                                  }}
                                >
                                  <StyledProjectCard variant={CardVariant.Bordered} className="w-full cursor-pointer">
                                    <div className="flex flex-col flex-1 p-base">
                                      <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                                        {project?.__registry__?.registryName} | Project ID: {project?.registryProjectId}
                                      </Text>
                                      <Text variant={TypographyVariant.subtitle1}>{project?.name}</Text>
                                    </div>
                                  </StyledProjectCard>
                                </div>
                              ))}
                            </>
                          )}
                          {projects && projects?.length === 0 && (
                            <>
                              <Alert
                                title="We could not find your project. Please fill out the following information to add the
                                  project."
                                variant={AlertVariant.Secondary}
                                color={AlertColor.Warning}
                              />

                              <div className="flex flex-col flex-1 gap-xs">
                                <InputText
                                  label="Project Name*"
                                  value={getValues('projectName')}
                                  onChange={(event) => {
                                    setValue('projectName', event.target.value || undefined, {
                                      shouldValidate: true,
                                    });
                                  }}
                                  error={errors.projectName?.message}
                                />
                              </div>
                              <div className="flex flex-row items-center w-full">
                                <Button type={ButtonType.Submit} className="flex-1" isLoading={isSaving}>
                                  Add Project
                                </Button>
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </Layer>
                  </div>
                </ModalContent>
              </form>
            );
          }}
        </Modal>
      )}
    </>
  );
};

const StyledProjectCard = styled(Card)`
  flex-direction: row;
  align-items: center;
`;
