import { forwardRef, useImperativeHandle, useRef } from 'react';

import { CMBOrderType } from '@aircarbon/ui';
import { type AssetCategory, assetCategoryNumDecimals, assetCategoryUom } from '@aircarbon/utils-common';

import { useEntity } from 'state/entity';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import type { Project } from '../../../../utils/Project';
import { CommonFormFields, type CommonFormFieldsRef, type CommonFormFieldsValue } from '../CommonFormFields';
import { AddCriteria, type AddCriteriaRef } from './components/AddCriteria';
import type { Criteria } from './utils/Criteria';

export interface BidFormValue extends CommonFormFieldsValue {
  criteria: Array<Criteria>;
  projects: Array<Project>;
}

export interface BidFormRef {
  /**
   * Validates form;
   * Returns `true` if validation failed
   */
  validate(): boolean;
}

export const BidForm = forwardRef<
  BidFormRef,
  {
    value: BidFormValue;
    isEditing?: boolean;
    isInstantTradeHidden?: boolean;
    onChange(value: BidFormValue): void;
  }
>((props, ref) => {
  const { value, isEditing, isInstantTradeHidden, onChange } = props;

  const commonFormFieldsRef = useRef<CommonFormFieldsRef>(null);
  const addCriteriaRef = useRef<AddCriteriaRef>(null);
  const { product } = useMarketplaceProduct();
  const uom = assetCategoryUom[Number(product) as AssetCategory];
  const tokenAssetNumDecimals = assetCategoryNumDecimals[Number(product) as AssetCategory];
  const { selector } = useEntity();

  const currencyCode = selector.mainCcySymbol;

  useImperativeHandle(ref, () => ({
    validate() {
      const hasCommonFormFieldsErrors = commonFormFieldsRef.current?.validate() || false;
      const hasCriteriaFieldsErrors = addCriteriaRef.current?.validate() || false;

      return hasCommonFormFieldsErrors || hasCriteriaFieldsErrors;
    },
  }));

  const onChangeFormValue = (newValue: Partial<BidFormValue>) => {
    onChange({
      ...value,
      ...newValue,
    });
  };

  return (
    <>
      <AddCriteria ref={addCriteriaRef} value={value} onChange={onChangeFormValue} orderType={CMBOrderType.Bid} />
      <CommonFormFields
        isEditing={isEditing}
        orderType={CMBOrderType.Bid}
        ref={commonFormFieldsRef}
        minimumQuantityLabel="Minimum Offer Qty"
        minimumQuantityTooltip="Minimum Offer Qty is pre-set as per company policy"
        maximumQuantityLabel="Maximum Offer Qty"
        quantityMultiplesOfLabel="Offer Qty in Multiples of"
        quantityMultiplesOfTooltip="Offer Qty in Multiples of is pre-set as per company policy"
        quantityUnit={uom}
        tokenAssetNumDecimals={tokenAssetNumDecimals}
        currency={currencyCode}
        isInstantTradeEnabled
        isInstantTradeHidden={isInstantTradeHidden}
        isMaximumQuantityReadOnly
        isMinimumQuantityReadOnly
        isQuantityMultiplesOfReadOnly
        value={value}
        onChange={onChangeFormValue}
      />
    </>
  );
});
