import { useMemo } from 'react';
import { Table } from 'refreshed-component/templates/Table';
import { checkIfCurrentUserIsSeller } from 'refreshed-pages/market-board-v2/utils/checkIfCurrentUserIsSeller';
import type { OTCOrderResource } from 'refreshed-pages/market-board-v2/utils/fetchOTCOrder';
import type { TradeRequest } from 'refreshed-pages/market-board-v2/utils/fetchTradeRequests';
import { toCMBOrderType } from 'refreshed-pages/market-board-v2/utils/toCMBOrderType';

import {
  Badge,
  BadgeSize,
  BadgeVariant,
  Button,
  ButtonSize,
  ButtonVariant,
  CMBOrderType,
  Card,
  Icon,
  IconName,
  Link,
  LinkVariant,
  ListItem,
  Pagination,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
  useSpacing,
} from '@aircarbon/ui';
import { type AssetCategory, Const, assetCategoryUom } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { useEntity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import { toOfferStatusBadgeProps } from '../../../../utils/toOfferStatusBadgeProps';
import { EmptyList } from '../../../EmptyList';
import type { ShowCounterOfferModalProps } from './components/CounterOfferModal';
import { toCounterOfferModalOffers } from './utils/toCounterOfferModalOffers';
import { type Offer, toOffers } from './utils/toOffers';

export const IncomingOffers: React.FunctionComponent<{
  order: OTCOrderResource;
  tradeRequests: TradeRequest[];
  currentPage: number;
  totalPages: number;
  onChangePage(currentPage: number): void;
  showPlaceCounterOfferModal(modalProps: ShowCounterOfferModalProps): void;
  showRejectTradeRequestModal(tradeRequestId: string): void;
  showReviewOrderModal(props: { tradeRequestId: string }): void;
}> = (props) => {
  const {
    order,
    tradeRequests,
    currentPage,
    totalPages,
    showPlaceCounterOfferModal,
    showRejectTradeRequestModal,
    showReviewOrderModal,
    onChangePage,
  } = props;
  const { spacing } = useSpacing();
  const { product } = useMarketplaceProduct();
  const uom = assetCategoryUom[Number(product) as AssetCategory];
  const { selector } = useEntity();
  const { marketSettings } = useMarketSettings({});

  const currencyCode = selector.mainCcySymbol;

  const {
    selector: { getUserId, getFullName },
  } = User.useContainer();
  const { getSetting } = UI.useContainer();

  const userId = getUserId();
  const userName = getFullName();

  const offers = useMemo(
    () =>
      toOffers({
        tradeRequests: tradeRequests,
        currentUserId: userId,
        currency: currencyCode,
      }),
    [tradeRequests, currencyCode, userId],
  );

  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  const orderType = toCMBOrderType({
    isAuction: !!order.isAuction,
    sideId: order.sideId,
  });

  const title = `Incoming Requests`;
  const emptyOffersMessage = 'You have no incoming requests';

  const onPressCounterTradeRequest = (offer: Offer) => {
    const orderType = toCMBOrderType({
      isAuction: !!offer.otcOrder.isAuction,
      sideId: offer.otcOrder.sideId,
    });
    showPlaceCounterOfferModal({
      id: offer.id,
      orderOpenQty: offer.orderOpenQty,
      orderType,
      baseAssetSymbol:
        orderType === CMBOrderType.Bid && !offer.isOrderCreatedByBuyer ? offer.baseAsset?.symbol : undefined,
      reservedAssetQty: offer.reservedAssetQty ?? 0,
      sellerUserId: offer.sellerUserId,
      offers: toCounterOfferModalOffers({
        negotiations: offer.negotiations,
        currentUserId: userId,
        currentUserName: userName,
        priceLabel: `Price (per ${uom})`,
        currency: currencyCode,
        orderType,
        isCurrentUserSeller: checkIfCurrentUserIsSeller({
          sellerUserId: offer.sellerUserId,
          currentUserId: userId,
          tradeRequestPlacedByUserId: offer.createdBy,
          otcOrderPlacedByUserId: offer.otcOrder.placedBy,
          otcOrderOwnerUserId: offer.otcOrder.userId,
          orderType,
        }),
        numDecimals: offer.baseAsset?.numDecimals ?? 0,
      }),
    });
  };

  const onPressAcceptOffer = async (offer: Offer) => {
    showReviewOrderModal({
      tradeRequestId: offer.id,
    });
  };

  const receivedOffersCount = useMemo(() => {
    return offers.filter((offer) => offer.status === 'received').length;
  }, [offers]);

  return (
    <>
      <StyledCard>
        <Header>
          <Text variant={TypographyVariant.subtitle1}>{title}</Text>
          {!!receivedOffersCount && (
            <Badge value={String(receivedOffersCount)} variant={BadgeVariant.Counter} size={BadgeSize.s} />
          )}
        </Header>
        {!offers.length ? (
          <EmptyList
            title={emptyOffersMessage}
            description={
              <Text variant={TypographyVariant.subtitle2} color={TextColor.secondary} marginTop={spacing(4)}>
                but, you can always explore for{' '}
                <Link
                  href="/account/mb-and-auctions"
                  typographyVariant={TypographyVariant.subtitle2}
                  variant={LinkVariant.Underline}
                >
                  market matches
                </Link>
              </Text>
            }
          />
        ) : (
          <Table
            config={{
              sticky: {
                left: ['id'],
                right: ['actions'],
              },
              columns: {
                id: {
                  label: '#',
                },
                status: {
                  label: 'STATUS',
                },
                ...(orderType === CMBOrderType.Bid
                  ? {
                      name: {
                        label: 'NAME',
                      },
                    }
                  : {}),
                price: {
                  label: 'PRICE',
                },
                quantity: {
                  label: 'QTY',
                },
                ...(orderType === CMBOrderType.Bid && !isMarketBoardAndAuctionsV2SettlementModeEnabled
                  ? {
                      instant: {
                        label: 'ASSETS RESERVED',
                      },
                    }
                  : {}),
                ...(orderType !== CMBOrderType.Auction
                  ? {
                      actions: {
                        label: 'ACTIONS',
                      },
                    }
                  : {}),
              },
              rows: offers.map((offer, index) => {
                return {
                  _key: '' + index,
                  id: offer.id,
                  ...(orderType === CMBOrderType.Bid
                    ? {
                        name: offer.description ? (
                          <StyledListItem isReadOnly title={offer.name} description={offer.description} />
                        ) : (
                          offer.name
                        ),
                      }
                    : {}),
                  status: <Badge {...toOfferStatusBadgeProps(offer.status)} />,
                  expiresAt: (
                    <>
                      <Icon name={IconName.Clock} />
                      <Text marginStart={spacing(2)} variant={TypographyVariant.caption} color={TextColor.secondary}>
                        {offer.expiresAt}
                      </Text>
                    </>
                  ),
                  price: offer.price,
                  quantity: offer.quantity,
                  instant: offer.isPrefunded ? 'Yes' : 'No',
                  ...(orderType !== CMBOrderType.Auction
                    ? {
                        actions:
                          !offer.acceptedAt && !offer.rejectedAt && ['sent', 'received'].includes(offer.status) ? (
                            <ActionButtons>
                              <Button
                                variant={ButtonVariant.secondary}
                                size={ButtonSize.xs}
                                onPress={() => onPressAcceptOffer(offer)}
                                isDisabled={!marketSettings?.otcMatchingEnabled}
                              >
                                Accept
                              </Button>
                              {offer.orderOpenQty > 0 && (
                                <Button
                                  variant={ButtonVariant.outlined}
                                  size={ButtonSize.xs}
                                  onPress={() => onPressCounterTradeRequest(offer)}
                                  isDisabled={!marketSettings?.otcMatchingEnabled}
                                >
                                  Counter
                                </Button>
                              )}
                              <Button
                                variant={ButtonVariant.outlined}
                                size={ButtonSize.xs}
                                onPress={() => showRejectTradeRequestModal(offer.id)}
                                isDisabled={!marketSettings?.otcMatchingEnabled}
                              >
                                Reject
                              </Button>
                            </ActionButtons>
                          ) : offer.rejectedAt ? (
                            <div>
                              <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                                {toOfferStatusBadgeProps(offer.status).value} on {offer.rejectedAt}
                              </Text>
                              {offer.rejectionReason && (
                                <Text variant={TypographyVariant.subtitle2}>{offer.rejectionReason}</Text>
                              )}
                            </div>
                          ) : offer.status === 'pending' ? (
                            <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                              Project under review
                            </Text>
                          ) : (
                            <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                              Accepted on {offer.acceptedAt}
                            </Text>
                          ),
                      }
                    : {}),
                };
              }),
            }}
          />
        )}
        <StyledPagination currentPage={currentPage} pagesCount={totalPages} onChange={onChangePage} />
      </StyledCard>
    </>
  );
};

const StyledPagination = styled(Pagination)`
  align-self: flex-start;
`;

const StyledListItem = styled(ListItem)`
  padding: 0;
`;

const StyledCard = styled(Card)`
  padding: ${({ theme }) => `${toSpacing(theme)(8)} ${toSpacing(theme)(12)}`};
  gap: ${({ theme }) => toSpacing(theme)(8)};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(2)};
`;

const ActionButtons = styled.div`
  display: flex;
  gap: ${({ theme }) => toSpacing(theme)(4)};
  align-items: center;
`;
