import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Loading from 'refreshed-component/molecules/Loading';
import { PageNavigationHolder } from 'refreshed-component/molecules/PageNavigation';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { MarketPlaceProjectCard } from 'refreshed-component/templates/market-board/MarketPlaceProjectCard';

import { Badge, Card, Layer, Text, TextColor, TypographyVariant } from '@aircarbon/ui';
import { AssetCategory, formatter, logger } from '@aircarbon/utils-common';

import useCurrencies from 'hooks/useCurrencies';

import { fetchMyCMBAsk } from 'data-provider/market-board/fetchMyCMBAsk';

import { MyListingAskBids } from './MyListingAskBids';

export const MyListingAskInfo = () => {
  const { askId } = useParams<{ askId: string }>();
  const getAskURL = `/api/user/carbon/my-cmb-ask/${askId}`;

  const { currenciesById } = useCurrencies();
  const currenciesObjById = currenciesById();

  const {
    data: ask,
    isLoading,
    refetch,
  } = useQuery(getAskURL, async () => {
    try {
      const response = await fetchMyCMBAsk(askId);
      return response;
    } catch (error) {
      logger.error(error);
    }
  });

  if (!ask) <Loading />;

  const tokenAsset = ask?.__tokenAsset__;
  const tokenUnit = tokenAsset?.__uom__?.name ?? '';
  const ccyAsset = ask && currenciesObjById?.[ask?.quoteAssetId];

  return (
    <>
      <PageHolder>
        <PageSections>
          <PageNavigationHolder
            list={[
              {
                label: 'Marketplace',
                path: '/account/market-board/marketplace',
              },
              {
                label: 'My Listings',
                path: '/account/market-board/my-listings',
              },
              {
                label: `Project #${askId}`,
              },
            ]}
          />
          <Layer>
            {ask && (
              <MarketPlaceProjectCard
                info={{
                  carbonProject: { ...ask?.__carbonProject__, vintageYear: ask?.carbonProjectVintageYear },
                  id: `Project #${ask?.id}`,
                  carbonProjectVintageYear: ask?.carbonProjectVintageYear,
                }}
                type="white"
                controls={
                  <>
                    <div className="flex flex-row justify-between whitespace-pre gap-base">
                      <Badge value={ask.status} />
                    </div>
                    <div className="flex flex-row justify-between whitespace-pre gap-base">
                      <div className="flex flex-col gap-xs">
                        <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                          Price ({ccyAsset?.symbol})
                        </Text>
                        <Text variant={TypographyVariant.h5Title}>
                          {ccyAsset?.code}
                          {formatter.formatNumber(ask.price, ccyAsset?.numDecimals)}
                        </Text>
                      </div>
                      <div className="flex flex-col gap-xs">
                        <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                          Listed Qty ({ask?.__tokenAsset__?.__uom__?.code || tokenUnit})
                        </Text>
                        <Text variant={TypographyVariant.h5Title}>
                          {formatter.formatNumber(ask?.quantity - ask?.openBidsTotalQty, ccyAsset?.numDecimals)}{' '}
                        </Text>
                      </div>
                    </div>
                    <Layer>
                      <Card className="w-full p-small gap-3xs">
                        <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                          Initial: {formatter.formatNumber(ask?.quantity, ccyAsset?.numDecimals)} (
                          {ask?.__tokenAsset__?.__uom__?.code || tokenUnit})
                        </Text>
                        <Text color={TextColor.secondary} variant={TypographyVariant.body2}>
                          Open: {formatter.formatNumber(ask?.openBidsTotalQty, ccyAsset?.numDecimals)} (
                          {ask?.__tokenAsset__?.__uom__?.code || tokenUnit})
                        </Text>
                      </Card>
                    </Layer>
                  </>
                }
              />
            )}
          </Layer>
        </PageSections>
        <PageSections>
          <SummaryHolder>
            <SummaryCard title={'Total Bids'} value={ask?.__carbonEmbBids__.length.toString() ?? ''} />
            <SummaryCard
              title={'Accepted Bids'}
              value={
                ask?.__carbonEmbBids__
                  .filter((item) => ['CONFIRMED_BY_SELLER', 'DONE'].includes(item.status?.toUpperCase()))
                  .length.toString() ?? ''
              }
            />
            <SummaryCard
              title={'Open Quantity'}
              value={
                ask?.openBidsTotalQty
                  ? formatter.formatNumber(ask.openBidsTotalQty, ask?.__tokenAsset__?.numDecimals ?? 0)
                  : '-'
              }
            />
          </SummaryHolder>
        </PageSections>
        <PageSections>
          <Layer>
            {ask && (
              <MyListingAskBids
                bids={ask?.__carbonEmbBids__}
                tokenUnit={tokenUnit}
                tokenAssetNumDecimals={tokenAsset?.numDecimals ?? 0}
                tokenAssetCategoryId={tokenAsset?.assetCategoryId ?? AssetCategory.token}
                onChange={() => {
                  refetch();
                }}
              />
            )}
          </Layer>
          {isLoading && (
            <div className="p-large">
              <Loading isOverLay={false} />
            </div>
          )}
        </PageSections>
      </PageHolder>
    </>
  );
};
