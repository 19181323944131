import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Input } from 'refreshed-component/atoms/Input';
import { ModalContent, ModalFooter } from 'refreshed-component/molecules/Modal';
import * as Yup from 'yup';

import {
  Button,
  ButtonType,
  ButtonVariant,
  Text,
  TextColor,
  ToastVariant,
  TypographyVariant,
  showToast,
} from '@aircarbon/ui';
import { logger } from '@aircarbon/utils-common';

import FormDevTool from 'components/FormDevTool';

import { User } from 'state/user';

interface FormData {
  accountName: string;
  accountId: string;
}

interface Props {
  registryName: string;
  onSubmit: () => void;
  onCancel: () => void;
}

const RegistryAccountForm: React.FC<Props> = ({ registryName, onSubmit, onCancel }) => {
  const {
    selector: { getAuthToken },
  } = User.useContainer();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(
      Yup.object().shape({
        accountName: Yup.string().required('Account name is required. The field cannot be left blank.'),
        accountId: Yup.string().required('Account ID is required. The field cannot be left blank.'),
      }),
    ),
    defaultValues: {
      accountName: '',
      accountId: '',
    },
  });

  const [addRegistry] = useMutation(async (formData: FormData) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    return fetch('/api/user/user/registry-account', {
      method: 'POST',
      body: JSON.stringify({
        details: `accountId: ${formData.accountId} | accountName: ${formData.accountName}`,
        name: registryName,
      }),
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
  });

  const onSubmitHandler = async (formData: FormData) => {
    try {
      const data = await addRegistry(formData);

      if (data?.ok) {
        showToast({
          variant: ToastVariant.Success,
          message: 'Registry added',
        });

        onSubmit();
      } else {
        const error = await data?.json();
        showToast({
          variant: ToastVariant.Danger,
          message: error?.message ?? 'Something went wrong!',
        });
        logger.warn({ error });
      }
    } catch (error) {
      showToast({
        variant: ToastVariant.Danger,
        message: (error as Error).message,
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitHandler)}>
        <ModalContent>
          <FormDevTool control={control} />
          <div className="flex flex-col gap-base">
            <div className="flex flex-col gap-xs">
              <Text variant={TypographyVariant.body2}>Account name</Text>
              <Input
                type="text"
                config={{
                  color: 'gray',
                }}
                formRegister={register('accountName')}
              />
              {errors.accountName?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.accountName?.message}
                </Text>
              )}
            </div>
            <div className="flex flex-col gap-xs">
              <Text variant={TypographyVariant.body2}>Account ID</Text>
              <Input
                type="text"
                config={{
                  color: 'gray',
                }}
                formRegister={register('accountId')}
              />
              {errors.accountId?.message && (
                <Text variant={TypographyVariant.body2} color={TextColor.error}>
                  {errors.accountId?.message}
                </Text>
              )}
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <div className="flex flex-1 justify-center items-center gap-base">
            <Button
              variant={ButtonVariant.outlined}
              className="flex-1"
              type={ButtonType.Button}
              onPress={() => onCancel()}
            >
              Cancel
            </Button>
            <Button variant={ButtonVariant.secondary} className="flex-1" type={ButtonType.Submit}>
              Save
            </Button>
          </div>
        </ModalFooter>
      </form>
    </>
  );
};

export default RegistryAccountForm;
