import { type FunctionComponent, createContext } from 'react';

import { OpenRequests } from '../../OpenRequests';
import { OrderHistory } from '../../OrderHistory';
import { TradeHistory } from '../../TradeHistory';

export const bottomTabsContextInitialValue = {
  availableTabs: [
    {
      id: 'my-orders',
      label: 'My Listings',
      component: OrderHistory,
    },
    {
      id: 'my-requests',
      label: 'My Responses',
      component: OpenRequests,
    },
    {
      label: 'Trade History',
      id: 'trade-history',
      component: TradeHistory,
    },
  ],
  activeTabId: '',
  isOpened: false,
  updateState: () => {},
};

export const BottomTabsContext = createContext<{
  activeTabId: string;
  isOpened: boolean;
  availableTabs: Array<{
    id: string;
    label: string;
    component: FunctionComponent<object> | (() => JSX.Element);
  }>;
  updateState: (
    callback: (prevState: { isOpened: boolean; activeTabId: string }) => {
      isOpened: boolean;
      activeTabId: string;
    },
  ) => void;
}>(bottomTabsContextInitialValue);
