import { isBefore } from 'date-fns';
import type { FetchListingsResponse } from 'refreshed-pages/market-board-v2/components/Listings/utils/fetchListings';
import { useDateCountdown } from 'refreshed-pages/market-board-v2/hooks/useDateCountdown';

import { Button, ButtonSize, ButtonVariant, CMBOrderType, IconName, styled } from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { User } from 'state/user';

export const TableActions: React.FunctionComponent<{
  listing: FetchListingsResponse['data'][0];
  isMine: boolean;
  orderType: CMBOrderType;
  onPressAction(props: { listing: FetchListingsResponse['data'][0]; isMine: boolean }): void;
}> = (props) => {
  const { listing, orderType, isMine, onPressAction } = props;
  const { marketSettings } = useMarketSettings({});
  const {
    status: { canBidAuctionV2: checkCanBidAuction, canBidCmbAskV2, canManageCmbBidV2 },
  } = User.useContainer();
  const isFutureAuction = listing.startAtUtc ? isBefore(new Date(), new Date(listing.startAtUtc)) : false;
  const { isFinished: isAuctionFinished } = useDateCountdown({
    date: isFutureAuction
      ? new Date(listing.startAtUtc as string)
      : listing.expiryUtc
        ? new Date(listing.expiryUtc)
        : undefined,
  });

  const isAuctionBidDisabled = orderType === CMBOrderType.Auction && !checkCanBidAuction();
  const isCmbBidDisabled = orderType !== CMBOrderType.Auction && !canBidCmbAskV2();

  const isClosed = listing.statusId === Dto.OtcOrderStatus.Closed;
  const isCTADisabled =
    !isMine && (isFutureAuction || isAuctionFinished || isClosed || !marketSettings?.otcMatchingEnabled);
  const isCTAHidden = isMine ? !canManageCmbBidV2() : isAuctionBidDisabled || isCmbBidDisabled;

  if (isCTAHidden) {
    return null;
  }

  return (
    <StyledButton
      onPress={() => onPressAction({ listing, isMine })}
      size={ButtonSize.s}
      isDisabled={isCTADisabled}
      variant={isMine ? ButtonVariant.outlined : ButtonVariant.secondary}
      endIcon={isMine ? IconName.Pencil : undefined}
    >
      {isMine ? 'Manage' : orderType === CMBOrderType.Bid ? 'Offer' : 'Bid'}
    </StyledButton>
  );
};

const StyledButton = styled(Button)`
  flex-grow: 1;
`;
