import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, isWeekend } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  Button,
  ButtonSize,
  ButtonType,
  Card,
  CardColor,
  CardVariant,
  IconName,
  InputDate,
  InputText,
  InputTextSize,
  InputTextType,
  Layer,
  Radio,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { AssetCategory, FeeType, assetCategoryNumDecimals, logger } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import FeeAmount from 'components/FeeAmount';
import FormDevTool from 'components/FormDevTool';

import { UI } from 'state/ui';
import { User } from 'state/user';

import useDebounce from 'hooks/useDebounce';
import useFee from 'hooks/useFee';

import { FormWrapper, Wrapper } from './FormStyled';
import NewSettlementRequestPreview from './NewSettlementRequestPreview';
import RequestCriteria from './RequestCriteria';

// NOTE: move to common later
// The date should be on weekdays
// 3 days from today and no more than 30 days from today
// Also, ignore the public holiday like Christmas, New Year, etc.
const validateSettlementDate = (dateInput: Date | undefined) => {
  if (!dateInput) {
    return false;
  }

  const publicHolidays = [
    // New Year
    '1-1',
    // Christmas
    '24-12',
  ];

  const date = new Date(dateInput);
  // Check if the date is on weekends
  if (isWeekend(date)) {
    logger.info('Settlement date must be on weekdays');
    return false;
  }

  if (publicHolidays.includes(`${date.getDate()}-${date.getMonth()}`)) {
    logger.info('Settlement date must not be on public holidays');
    return false;
  }

  return true;
};

/**
 * Check criteria by carbon or REC.
 * This is simple logic by checking the length of the array. We might need to improve this later if needed
 *
 * @param {unknown[] | undefined} value
 * @param {boolean} isCarbon
 */
function validateCriteria(value: unknown[] | undefined, isCarbon: boolean) {
  if (Array.isArray(value)) {
    return isCarbon ? value.length === 2 : value.length === 4;
  }

  return false;
}

const schema = (isCarbon: boolean) =>
  yup.object().shape({
    sellerEmail: yup.string().trim().email('Invalid email').required('Required'),
    buyerEmail: yup.string().trim().email('Invalid email').required('Required'),
    quantity: yup.number().typeError('Required a number').positive('Must be an positive number').required('Required'),
    price: yup.number().typeError('Required a number').positive('Must be an positive number').required('Required'),
    settlementDate: yup
      .date()
      .required('Required')
      .typeError('Required a date')
      .test('is-valid-date', 'Invalid date', (value) => validateSettlementDate(value)),
    arrangerFee: yup.number().typeError('Invalid number').optional(),
    pairId: yup.number().optional(),
    criteria: yup
      .array()
      .of(
        yup.object().shape({
          metaKey: yup.string().required(),
          metaValue: yup.string().required(),
        }),
      )
      .test('is-valid-criteria', 'Invalid criteria', (value) => validateCriteria(value, isCarbon))
      .required('Required'),
  });

export type SettlementRequestFormValues = {
  type: 'seller' | 'buyer' | 'broker';
  buyerEmail: string;
  sellerEmail: string;
  quantity: number;
  price: number;
  arrangerFee?: number;
  pairId?: number;
  settlementDate: string;
  criteria: { metaKey: string; metaValue: string }[];
};

function SettlementRequestForm({
  tokenUnit = 'tCO2',
  currencyUnit = 'USD',
  productType,
  email,
  onSubmit,
}: {
  tokenUnit?: string;
  currencyUnit?: string;
  email: string;
  productType: 'Carbon' | 'REC';
  onSubmit: (formData: SettlementRequestFormValues) => Promise<void>;
}) {
  const {
    status: { canCreateSettlement, canCreateSettlementAsBroker },
  } = User.useContainer();
  const { marketSettings } = useMarketSettings({});
  const formMethods = useForm<SettlementRequestFormValues>({
    resolver: yupResolver(schema(productType === 'Carbon')),
    defaultValues: {
      buyerEmail: email,
      type: 'buyer',
    },
  });
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
    setValue,
    watch,
  } = formMethods;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const debouncedTokenQty = useDebounce(watch('quantity'), 500);
  const debouncedPrice = useDebounce(watch('price'), 500);
  const { feeAmount } = useFee({
    params: {
      feeType: FeeType.BLOCK_ORDER_FEE,
      totalAmount: debouncedTokenQty * debouncedPrice,
      assetCategoryId: productType === 'Carbon' ? AssetCategory.token : AssetCategory.rec,
      tokenQty: debouncedTokenQty,
    },
    options: { enabled: debouncedTokenQty > 0 && debouncedPrice > 0 },
  });
  const numDecimals = assetCategoryNumDecimals[productType];
  const { screenSize } = UI.useContainer();

  // Reset email when change type
  const type = watch('type');
  useEffect(() => {
    if (type === 'seller') {
      setValue('buyerEmail', '');
      setValue('sellerEmail', email, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else if (type === 'buyer') {
      setValue('sellerEmail', '');
      setValue('buyerEmail', email, {
        shouldDirty: true,
        shouldValidate: true,
      });
    } else {
      setValue('sellerEmail', '');
      setValue('buyerEmail', '');
    }
  }, [email, setValue, type]);

  const totalAmount = Number(watch('quantity') || 0) * Number(watch('price') || 0);

  const onSubmitHandler = async (formData: any) => {
    // Remove custom field for criteria (filterBy)
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const { filterBy, ...data } = formData;
    setIsSubmitting(true);
    try {
      await onSubmit(data);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Wrapper key={productType} screenSize={screenSize} className="container mx-auto max-w-screen-lg">
      <FormWrapper screenSize={screenSize}>
        <Layer>
          <FormProvider {...formMethods}>
            <form className="flex flex-col gap-4" onSubmit={handleSubmit(onSubmitHandler)}>
              {/* Info section */}
              <Card variant={CardVariant.Bordered} className="p-4">
                <Text color={TextColor.primary} variant={TypographyVariant.subtitle2}>
                  Creating instruction as:
                </Text>
                <div className="flex flex-row gap-2 mt-2">
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Radio
                        label="Buyer"
                        isChecked={field.value === 'buyer'}
                        onChange={() => field.onChange('buyer')}
                      />
                    )}
                  />

                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <Radio
                        label="Seller"
                        isChecked={field.value === 'seller'}
                        onChange={() => field.onChange('seller')}
                      />
                    )}
                  />

                  {!!canCreateSettlementAsBroker() && (
                    <Controller
                      name="type"
                      control={control}
                      render={({ field }) => (
                        <Radio
                          label="Broker"
                          isChecked={field.value === 'broker'}
                          onChange={() => field.onChange('broker')}
                        />
                      )}
                    />
                  )}
                </div>
                <div className="grid gap-2 mt-4 w-full sm:grid-cols-2 grid-cols-1-col">
                  <Controller
                    name="buyerEmail"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        label="Buyer Email Address"
                        placeholder="buyer@example.com"
                        type={InputTextType.Email}
                        size={InputTextSize.s}
                        value={field.value}
                        onChange={field.onChange}
                        isDisabled={watch('type') === 'buyer'}
                        error={errors.buyerEmail?.message}
                      />
                    )}
                  />
                  <Controller
                    name="sellerEmail"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        label="Seller Email Address"
                        placeholder="seller@example.com"
                        type={InputTextType.Email}
                        size={InputTextSize.s}
                        value={field.value}
                        onChange={field.onChange}
                        isDisabled={watch('type') === 'seller'}
                        error={errors.sellerEmail?.message}
                      />
                    )}
                  />
                </div>
                <div
                  className={`grid grid-cols-1 gap-4 mt-4 w-full ${watch('type') === 'broker' ? 'md:grid-cols-2' : 'md:grid-cols-3'}`}
                >
                  <Controller
                    name="quantity"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        label="Quantity"
                        placeholder="0"
                        type={InputTextType.Number}
                        size={InputTextSize.s}
                        value={field.value ? String(field.value) : undefined}
                        onChange={field.onChange}
                        error={errors.quantity?.message}
                        suffix={tokenUnit}
                      />
                    )}
                  />
                  <Controller
                    name="price"
                    control={control}
                    render={({ field }) => (
                      <InputText
                        label={`Price (per ${tokenUnit})`}
                        placeholder="0"
                        type={InputTextType.Number}
                        size={InputTextSize.s}
                        value={field.value ? String(field.value) : undefined}
                        onChange={field.onChange}
                        error={errors.price?.message}
                        suffix={currencyUnit}
                      />
                    )}
                  />

                  {watch('type') === 'broker' && (
                    <Controller
                      name="arrangerFee"
                      control={control}
                      render={({ field }) => (
                        <InputText
                          label="Arranger Fee"
                          placeholder="0"
                          type={InputTextType.Number}
                          size={InputTextSize.s}
                          value={field.value ? String(field.value) : undefined}
                          onChange={field.onChange}
                          error={errors.arrangerFee?.message}
                          suffix={currencyUnit}
                        />
                      )}
                    />
                  )}

                  <Controller
                    name="settlementDate"
                    control={control}
                    render={({ field }) => (
                      <InputDate
                        tooltip="Settlement date must be at least 3 days from today and no more than 30 days from today."
                        label="Settlement Date"
                        value={field.value}
                        size={InputTextSize.s}
                        onChange={(selectedDate) =>
                          setValue(field.name, selectedDate.value, {
                            shouldDirty: true,
                            shouldValidate: true,
                          })
                        }
                        error={errors.settlementDate?.message}
                        minDate={addDays(new Date(), 3).toDateString()}
                        maxDate={addDays(new Date(), 30).toDateString()}
                      />
                    )}
                  />
                </div>
              </Card>
              {/* Criteria section */}
              <Card variant={CardVariant.Bordered} className="p-4">
                <input {...register('criteria')} type="hidden" />

                <RequestCriteria
                  type={productType}
                  error={errors?.criteria?.message}
                  onChange={(criteria) =>
                    setValue('criteria', criteria, {
                      shouldDirty: true,
                      shouldTouch: true,
                    })
                  }
                />
                <StyledTotalsContainerCard variant={CardVariant.Bordered} className="gap-2 mt-4">
                  <StyledTotalsCard>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                      Trade Amount:
                    </Text>
                    <FeeAmount amount={totalAmount} />
                  </StyledTotalsCard>
                  <StyledTotalsCard color={CardColor.Field}>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                      Fee:
                    </Text>
                    <FeeAmount amount={feeAmount} />
                  </StyledTotalsCard>
                  <StyledTotalsCard>
                    <Text variant={TypographyVariant.body2} color={TextColor.secondary}>
                      Arranger Fee:
                    </Text>
                    <FeeAmount amount={Number(watch('arrangerFee') || 0)} />
                  </StyledTotalsCard>
                  <StyledTotalsCard color={CardColor.Field}>
                    <Text variant={TypographyVariant.body2} color={TextColor.primary}>
                      Buyer pays:
                    </Text>
                    <FeeAmount isPrimary amount={totalAmount + feeAmount + Number(watch('arrangerFee') || 0)} />
                  </StyledTotalsCard>
                  <StyledTotalsCard>
                    <Text variant={TypographyVariant.body2} color={TextColor.primary}>
                      Seller receives:
                    </Text>
                    <FeeAmount isPrimary amount={totalAmount - feeAmount - Number(watch('arrangerFee') || 0)} />
                  </StyledTotalsCard>
                </StyledTotalsContainerCard>
              </Card>
              {/* Action section */}
              {canCreateSettlement() && (
                <NewSettlementRequestPreview
                  tokenUnit={tokenUnit}
                  currencyUnit={currencyUnit}
                  totalQty={watch('quantity')}
                  totalAmount={totalAmount}
                  feeAmount={feeAmount}
                  tokenNumDecimals={numDecimals}
                >
                  <Button
                    endIcon={IconName.ArrowEnd}
                    isDisabled={marketSettings?.otcEntryEnabled === 0}
                    size={ButtonSize.l}
                    type={ButtonType.Button}
                    isLoading={isSubmitting}
                    onPress={() => {
                      // Call submit the button outside the form
                      handleSubmit(onSubmitHandler)();
                    }}
                  >
                    Submit
                  </Button>
                </NewSettlementRequestPreview>
              )}
            </form>
          </FormProvider>
        </Layer>
      </FormWrapper>
      <FormDevTool control={control} />
    </Wrapper>
  );
}

export default SettlementRequestForm;

const StyledTotalsCard = styled(Card)`
  flex-direction: row;
  border-radius: 0;
  justify-content: space-between;
  flex: 1;
  padding: ${({ theme }) => toSpacing(theme)(8)};
`;

const StyledTotalsContainerCard = styled(Card)`
  overflow: hidden;
`;
