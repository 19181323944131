import { useEffect } from 'react';
import { queryCache, useQuery } from 'react-query';

import {
  Button,
  ButtonVariant,
  Divider,
  LabelWithDescription,
  Text,
  TypographyVariant,
  useSpacing,
} from '@aircarbon/ui';
import { formatter, helpers } from '@aircarbon/utils-common';

import Loading from 'components/styled/Loading';

import { Entity } from 'state/entity';
import { User } from 'state/user';

import useAddressData from 'hooks/useAddressData';
import useCarbonFinderRealtimeMessage from 'hooks/useCarbonFinderRealtimeMessage';
import useCurrencies from 'hooks/useCurrencies';
import useTokenTypes from 'hooks/useTokenTypes';

import { fetchRFQ } from 'data-provider/rfq';

import RFQAccept from '../Accept';
import Criteria from '../Criteria';
import RFQResponses from '../Responses';
import { BidInfoWrapper } from '../Styled';
import { CriteriaWrapper, Header, Wrapper } from './styled';

interface Props {
  rfqId: number;
  tokenTypeId: number;
  addr: string;
  isRFQOwner: boolean;
  onSubmitAccept: () => void;
  cancelRFQRequest: (id: number) => void;
  rfqOrderCancelationEnabled?: number;
}

const { hex2int } = formatter;

const ViewRFQ = ({
  addr,
  rfqId,
  tokenTypeId,
  isRFQOwner = false,
  onSubmitAccept,
  cancelRFQRequest,
  rfqOrderCancelationEnabled,
}: Props) => {
  const {
    selector: { getUserId },
    status: { canTradeRFQ },
  } = User.useContainer();
  const { tokenTypesByScId } = useTokenTypes();
  const { currenciesByScId } = useCurrencies();
  const {
    selector: { mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();
  const realtimeMessage = useCarbonFinderRealtimeMessage();

  const { addressData: rawAddressData, isLoading } = useAddressData({ address: addr });

  const { tokens = [] } = rawAddressData?.account ?? {};
  const addressData = rawAddressData?.batches?.reduce((projects: Record<string, any>, batch: Record<string, any>) => {
    const batchId = hex2int(batch.batchId);
    const { tokTypeId } = helpers.decodeWeb3Object(batch) as Record<string, any>;

    // TODO: account could have multiple secTokId from one batch

    // filter projects base on token type id
    if (tokenTypeId && tokenTypeId !== hex2int(tokTypeId)) {
      return projects;
    }

    const tokenType = tokenTypesByScId()[tokenTypeId];

    const currentToken = tokens.find((token) => token.batchId === batchId);
    const project: Record<string, any> = batch.metaData;

    if (!currentToken) return projects;

    if (projects[batchId]) {
      return {
        ...projects,
        [batchId]: {
          ...projects[batchId],
          stIds: [...projects[batchId].stIds, (currentToken?.stId ?? 0).toString()],
        },
      };
    }

    return {
      ...projects,
      [batchId]: {
        batchId,
        tokTypeId: hex2int(tokTypeId),
        tokTypeName: currentToken?.tokTypeName,
        stIds: [(currentToken?.stId ?? 0).toString()],
        qty: currentToken?.currentQty / (tokenType?.uom?.scRatio ?? 1), // assumption: is only work for tons
        project,
      },
    };
  }, {});

  const { data: rfq, isLoading: isLoadingRFQ } = useQuery(['view-bid', addr, rfqId], async () => fetchRFQ(rfqId), {
    enabled: rawAddressData,
  });
  const { spacing } = useSpacing();

  // Update realtime for view bid UI
  useEffect(() => {
    const isThisRFQ = rfqId === realtimeMessage?.rfqMessages?.id;
    if (realtimeMessage?.rfqMessages?.type === 'RFQ_UPDATE' && isThisRFQ) {
      queryCache.invalidateQueries(['view-bid', addr, rfqId]);
    }
  }, [addr, realtimeMessage, rfqId]);

  if (!addr || !rfq) return null;

  if (isLoadingRFQ || isLoading) {
    return (
      <div className="h-44">
        <Loading />
      </div>
    );
  }

  const filledQty = rfq.filled;
  const remaining = rfq.quantity - filledQty;
  let status = rfq.status;
  if (filledQty === 0 && status === 'OPEN') status = 'New';
  if (filledQty > 0 && rfq.quantity > filledQty && status !== 'CANCELLED') status = 'Partially Filled';
  if (remaining === 0) status = 'Filled';

  const BidCriteria = (
    <CriteriaWrapper>
      <div>BID CRITERIA</div>
      <Criteria
        filters={[
          {
            id: 'tokenTypeId',
            filterName: 'Token',
            filterValue: (rfq?.tokenTypeId ?? 0) > 0 ? tokenTypesByScId()?.[rfq?.tokenTypeId]?.symbol : 'ANY',
          },
          ...rfq.__rfqRequestDetails__,
        ]}
      />
    </CriteriaWrapper>
  );

  return (
    <Wrapper>
      <Header>
        <BidInfoWrapper>
          <LabelWithDescription label={`BID #${rfq.id}`} description={new Date(rfq.createdAt).toDateString()} />
          <LabelWithDescription
            label="Quantity"
            description={formatter.formatNumber(rfq.quantity, mainCcyNumDecimals)}
          />
          <LabelWithDescription
            label={`Price (${currenciesByScId()?.[rfq.ccyTypeId]?.symbol})`}
            description={`${mainCcyCode} ${formatter.formatNumber(rfq.price, mainCcyNumDecimals)}`}
          />
          <LabelWithDescription
            label={`Total (${currenciesByScId()?.[rfq.ccyTypeId]?.symbol})`}
            description={`${mainCcyCode} ${formatter.formatNumber(rfq.quantity * rfq.price, currenciesByScId()?.[rfq.ccyTypeId]?.numDecimals)}`}
          />

          <Divider />
          {/* // TODO: use numDecimals, if we used RFQ again */}
          <LabelWithDescription label="Filled Qty" description={formatter.formatNumber(filledQty, 0)} />
          {/* // TODO: use numDecimals, if we used RFQ again */}
          <LabelWithDescription label="Open Qty" description={formatter.formatNumber(rfq.quantity - filledQty, 0)} />
          <LabelWithDescription label="Status" description={status} />

          {getUserId() === rfq.userId && !['DONE', 'CANCELLED'].includes(rfq.status) && canTradeRFQ() && (
            <div className="ml-auto">
              <Button
                variant={ButtonVariant.secondary}
                isDisabled={rfqOrderCancelationEnabled === 0}
                onPress={() => {
                  cancelRFQRequest(rfq.id);
                  onSubmitAccept();
                }}
              >
                Cancel
              </Button>
            </div>
          )}
        </BidInfoWrapper>
      </Header>
      {BidCriteria}
      {!isRFQOwner && Number(rfq.filled) < Number(rfq.quantity) && (
        <>
          <Text variant={TypographyVariant.h6Title} marginBottom={spacing(16)}>
            My eligible projects matching bid criteria
          </Text>
          <RFQAccept rfq={rfq} addressData={addressData} onSubmit={onSubmitAccept} />
        </>
      )}
      <RFQResponses rfq={rfq} userId={!isRFQOwner ? getUserId() : null} />
    </Wrapper>
  );
};

export default ViewRFQ;
