import type { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';

import { SDGGoal, countries, styled, toSpacing } from '@aircarbon/ui';

export const toMetaRows = ({
  orderCriteria,
  filteringOptions,
}: {
  filteringOptions: OtcCriteria;
  orderCriteria: Array<{
    id: string;
    key: string;
    value: string;
  }>;
}) => {
  const filteringOptionsMap = new Map();

  filteringOptions.forEach((filteringOption) => {
    const optionsMap = new Map();

    filteringOption.options?.forEach((option) => {
      optionsMap.set(String(option.value), option);
    });

    filteringOptionsMap.set(String(filteringOption.value), {
      label: filteringOption.label,
      value: filteringOption.value,
      optionsMap,
    });
  });

  return {
    ...Array.from(filteringOptionsMap.entries()).reduce((curr, [key, item]) => {
      if (item.optionsMap.size) {
        return {
          ...curr,
          [key]: '-',
        };
      }
      return curr;
    }, {}),
    ...orderCriteria.reduce((curr, item) => {
      if (item.key === 'COUNTRY_CODE') {
        return {
          ...curr,
          [item.key]:
            item.value
              .split(',')
              .map((countryCode) => countries?.find((country) => country.countryCode === countryCode)?.name)
              .join(', ') ?? '-',
        };
      }

      if (item.key === 'VINTAGE_YEAR') {
        return {
          ...curr,
          [item.key]: item.value.split(',').join(', '),
        };
      }

      if (item.key === 'SDG_GOAL') {
        const sdgGoalIds = item.value.split(',').map((goal) => goal.split(':')[0]) as Array<
          React.ComponentProps<typeof SDGGoal>['id']
        >;

        return {
          ...curr,
          [item.key]: (
            <SDGGoalWrapper>
              {sdgGoalIds.map((goalId) => (
                <SDGGoal id={goalId} key={goalId} />
              ))}
            </SDGGoalWrapper>
          ),
        };
      }

      const filteringOption = filteringOptionsMap.get(String(item.key));

      if (!filteringOption) {
        return curr;
      }

      if (!filteringOption.optionsMap.size) {
        return curr;
      }

      const optionsMap = filteringOption.optionsMap;

      const value =
        item.value
          .split(',')
          .map((singleValue) => optionsMap.get(singleValue)?.label)
          .filter((i) => !!i) ?? [];

      if (!value?.length) {
        return {
          ...curr,
          [item.key]: '-',
        };
      }

      return {
        ...curr,
        [item.key]: value.join(', '),
      };
    }, {}),
  };
};

const SDGGoalWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(6)};
`;
