import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

import { InputTextSize, Select, Text, ToastVariant, TypographyVariant, countries, showToast } from '@aircarbon/ui';
import { projects } from '@aircarbon/utils-common';

import { usePairs } from 'pages/account/trading/hooks/usePairs';

import { User } from 'state/user';

import useCarbonMetaOptions from 'hooks/useCarbonMetaOptions';
import useCarbonProjects from 'hooks/useCarbonProjects';
import useDebounce from 'hooks/useDebounce';
import useMyProjects from 'hooks/useMyProjects';

import NewCarbonProject, { type NewProjectFormValues } from './NewCarbonProject';
import SelectCarbonProjectInput from './SelectCarbonProjectInput';

const years = Array(50)
  .fill(1)
  .map((_, i) => 2000 + i);

function RequestCriteria({
  type,
  error,
  onChange,
}: {
  type: 'Carbon' | 'REC';
  error?: string;
  onChange: (values: { metaKey: string; metaValue: string }[]) => void;
}) {
  const { setValue, control, watch } = useFormContext();
  const [addNewProject, setAddNewProject] = useState(false);
  const [onlyMyProjects, setOnlyMyProjects] = useState(false);
  const [searchBy, setSearchBy] = useState('');
  const [pendingProject, setPendingProject] = useState<{
    id: number;
    registryId: string;
    registryProjectId: string;
    projectName: string;
  }>();

  const {
    carbonMetaOptions: { registries },
    isLoading: isLoadingRegistry,
  } = useCarbonMetaOptions({
    query: {
      assetCategory: type === 'Carbon' ? 'token' : 'rec',
    },
  });

  const debouncedSearchInput = useDebounce(searchBy, 500);
  const { projects: projectsList, isLoading: isFetchingProjects } = useCarbonProjects({
    isFilterLike: false,
    filterBy: { status: 'APPROVED' },
    searchBy: debouncedSearchInput,
    options: {
      enabled: !onlyMyProjects,
    },
  });
  const { projects: myProjects, isLoading: isLoadingMyProjects } = useMyProjects({
    statuses: ['APPROVED', 'NEW'],
    searchBy: debouncedSearchInput,
    options: {
      enabled: onlyMyProjects,
    },
  });

  const { pairsSelectOptions } = usePairs({
    filterByMarket: 'block',
    assetCategories: [type === 'Carbon' ? 'token' : 'rec'],
  });

  const filterBy = watch('filterBy');
  // Transform filterBy to criteria and do the validation
  useEffect(() => {
    if (!filterBy) {
      onChange([]);
      return;
    }

    const metaKeyPerAsset =
      type === 'Carbon'
        ? ['PROJECT_ID', 'VINTAGE_YEAR']
        : ['REGISTRY_ID', 'VINTAGE_YEAR', 'COUNTRY_CODE', 'REC_TECHNOLOGY'];

    const criteria = Object.keys(filterBy).reduce((acc: { metaKey: string; metaValue: string }[], key: string) => {
      const value = filterBy[key];
      if (metaKeyPerAsset.includes(key) && value) {
        acc.push({ metaKey: key, metaValue: value });
      }
      return acc;
    }, []);

    onChange(criteria);
  }, [filterBy, onChange, type]);

  const {
    selector: { getAuthToken },
  } = User.useContainer();

  const onAddNewProject = async (newProject: NewProjectFormValues) => {
    const authToken = await getAuthToken();
    showToast({
      variant: ToastVariant.Info,
      message: 'Adding new project',
    });
    const response = await fetch('/api/user/carbon/project', {
      method: 'POST',
      body: JSON.stringify({
        registryId: Number(newProject.registryId),
        registryProjectId: newProject.registryProjectId,
        projectName: newProject.projectName,
        assetCategory: type === 'Carbon' ? 'token' : 'rec',
      }),
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });

    if (response.ok) {
      showToast({
        variant: ToastVariant.Success,
        message: 'New project added successfully',
      });
      const result = await response.json();
      setPendingProject({
        id: result.carbonProject?.id,
        registryId: result.carbonProject?.registryId,
        registryProjectId: result.carbonProject?.registryProjectId,
        projectName: result.carbonProject?.name ?? 'N/A',
      });

      return {
        id: result.carbonProject?.id,
        registryProjectId: result.carbonProject?.registryProjectId,
      };
    }

    showToast({
      variant: ToastVariant.Danger,
      message: 'Failed to add new project',
    });
  };

  return (
    <div className="w-full">
      <NewCarbonProject
        type={type}
        isOpen={addNewProject}
        onClose={() => setAddNewProject(false)}
        onNewProject={(newProject) => {
          setAddNewProject(false);
          onAddNewProject(newProject).then((result) => {
            setValue('filterBy.PROJECT_ID', String(result?.registryProjectId ?? ''));
          });
        }}
      />
      {type === 'Carbon' && (
        <div className="flex flex-col">
          <Text variant={TypographyVariant.subtitle2}>Project Name</Text>
          <Controller
            control={control}
            name="filterBy.PROJECT_ID"
            render={({ field: { name, value } }) => (
              <SelectCarbonProjectInput
                isFetching={isFetchingProjects || (onlyMyProjects && isLoadingMyProjects)}
                onlyMyProjects={onlyMyProjects}
                onFilter={(onlyMyProjects) => setOnlyMyProjects(onlyMyProjects)}
                items={
                  onlyMyProjects
                    ? myProjects.map((item) => ({
                        value: item.registryProjectId,
                        title: item.projectName,
                        meta: {
                          isNew: item.status === 'NEW',
                          registryProjectId: item.registryProjectId,
                          registryName:
                            registries?.find((item) => Number(item.id) === Number(item.registryId))?.registryName ?? '',
                          countryCode: item.countryCode ?? '',
                        },
                      }))
                    : [
                        ...(pendingProject
                          ? [
                              {
                                value: String(pendingProject.registryProjectId),
                                title: pendingProject.projectName,
                                meta: {
                                  isNew: true, // NOTE: Will have different style for new project (not approved yet!)
                                  registryName:
                                    registries?.find((item) => Number(item.id) === Number(pendingProject.registryId))
                                      ?.registryName ?? '',
                                  countryCode: '',
                                  registryProjectId: pendingProject.registryProjectId,
                                },
                              },
                            ]
                          : []),
                        ...(projectsList?.map((item) => ({
                          value: item.registryProjectId,
                          title: item.name,
                          meta: {
                            registryName: item.__registry__?.registryName ?? '',
                            countryCode: item.countryCode,
                            registryProjectId: item.registryProjectId,
                          },
                        })) ?? []),
                      ]
                }
                onSelect={(selected) => setValue(name, selected.value)}
                value={value}
                onNewProject={() => {
                  setAddNewProject(true);
                }}
                error={!!error && !value ? `Please select the Project` : undefined}
                onSearch={(query) => setSearchBy(query)}
              />
            )}
          />
        </div>
      )}
      <div className="grid grid-cols-2 gap-4">
        {type === 'REC' && (
          <div className="flex flex-col mt-4 w-full">
            {isLoadingRegistry ? (
              <Text>Loading...</Text>
            ) : (
              <Controller
                control={control}
                name="filterBy.REGISTRY_ID"
                render={({ field: { name, value } }) => (
                  <Select
                    className="w-full"
                    label="Registry"
                    placeholder="Select Registry"
                    items={registries?.map((item) => ({ value: String(item.id), title: item.registryName })) ?? []}
                    value={value}
                    onChange={(selected) => setValue(name, selected.value)}
                    size={InputTextSize.s}
                    error={!!error && !value ? `Please select Registry` : undefined}
                  />
                )}
              />
            )}
          </div>
        )}
        <div className="flex flex-col mt-4 w-full">
          <Controller
            control={control}
            name="filterBy.VINTAGE_YEAR"
            render={({ field: { name, value } }) => (
              <Select
                className="w-full"
                label="Vintage"
                placeholder="Select Vintage Year"
                items={years.map((item) => ({ value: String(item), title: String(item) }))}
                value={value}
                onChange={(selected) => setValue(name, selected.value)}
                size={InputTextSize.s}
                error={!!error && !value ? 'Please select Vintage Year' : undefined}
              />
            )}
          />
        </div>

        {type === 'REC' && (
          <>
            <div className="flex flex-col mt-4 w-full">
              <Controller
                control={control}
                name="filterBy.COUNTRY_CODE"
                render={({ field: { name, value } }) => (
                  <Select
                    className="w-full"
                    label="Country"
                    placeholder="Select Country"
                    items={countries.map((item) => ({
                      value: item.countryCode,
                      title: item.name,
                    }))}
                    value={value}
                    onChange={(selected) => setValue(name, selected.value)}
                    size={InputTextSize.s}
                    error={!!error && !value ? `Please select Country` : ''}
                  />
                )}
              />
            </div>
            <div className="flex flex-col mt-4 w-full">
              <Controller
                control={control}
                name="filterBy.REC_TECHNOLOGY"
                render={({ field: { name, value } }) => (
                  <Select
                    className="w-full"
                    label="Technology"
                    placeholder="Select Technology"
                    items={Object.values(projects.RecTechnology).map((item) => ({
                      value: item,
                      title: item,
                    }))}
                    value={value}
                    onChange={(selected) => setValue(name, selected.value)}
                    size={InputTextSize.s}
                    error={!!error && !value ? `Please select Technology` : undefined}
                  />
                )}
              />
            </div>
          </>
        )}

        {type === 'Carbon' && (
          <div className="flex flex-col mt-4 w-full">
            <Controller
              control={control}
              name="pairId"
              render={({ field: { value, name } }) => (
                <Select
                  label="Contract (optional)"
                  placeholder="Select Contract"
                  items={pairsSelectOptions.map((item) => ({ title: item.label, value: String(item.value) }))}
                  value={value}
                  onChange={(selectedItem) => setValue(name, selectedItem.value)}
                  size={InputTextSize.s}
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default RequestCriteria;
