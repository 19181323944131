import type { Reducer } from 'react';

import type { DepositRequestsAction } from './DepositRequestsAction';
import type { DepositRequestsContextValueWithoutActions } from './DepositRequestsContext';
import { toModalApproveDepositRequestProps } from './toModalApproveDepositRequestProps';

export const depositRequestsReducer: Reducer<DepositRequestsContextValueWithoutActions, DepositRequestsAction> = (
  prevState,
  action,
) => {
  if (action.type === 'change-filter') {
    const filterIndex = prevState.filters.findIndex((oldFilter) => oldFilter.id === action.payload.id);

    if (filterIndex === -1) {
      return prevState;
    }

    const newFilters = [...prevState.filters];

    newFilters[filterIndex] = action.payload;

    return {
      ...prevState,
      filters: newFilters,
      pagination: {
        ...prevState.pagination,
        currentPage: 1,
      },
    };
  }

  if (action.type === 'change-search') {
    return {
      ...prevState,
      search: action.payload,
      pagination: {
        ...prevState.pagination,
        currentPage: 1,
      },
    };
  }

  if (action.type === 'change-pagination') {
    return {
      ...prevState,
      pagination: {
        ...prevState.pagination,
        ...action.payload,
      },
    };
  }

  if (action.type === 'change-rejection-reason') {
    return {
      ...prevState,
      modalRejectDepositRequestProps: {
        ...prevState.modalRejectDepositRequestProps,
        rejectionReason: action.payload,
      },
    };
  }

  if (action.type === 'show-approve-modal') {
    const depositRequest = prevState.depositRequests.find((request) => request.id === Number(action.payload));

    if (!depositRequest) {
      return prevState;
    }

    return {
      ...prevState,
      modalApproveDepositRequestProps: {
        ...prevState.modalApproveDepositRequestProps,
        ...toModalApproveDepositRequestProps(depositRequest),
        isVisible: true,
      },
    };
  }

  if (action.type === 'update-deposit-request') {
    const depositRequestIndex = prevState.depositRequests.findIndex(
      (request) => request.id === Number(action.payload.id),
    );

    if (depositRequestIndex === -1) {
      return prevState;
    }

    const newRequests = [...prevState.depositRequests];

    newRequests[depositRequestIndex] = { ...newRequests[depositRequestIndex], ...action.payload };

    return {
      ...prevState,
      depositRequests: newRequests,
    };
  }

  if (action.type === 'add-alert') {
    return {
      ...prevState,
      alerts: [action.payload, ...prevState.alerts],
    };
  }

  if (action.type === 'remove-alert') {
    const newAlerts = [...prevState.alerts];

    newAlerts.splice(action.payload, 1);

    return {
      ...prevState,
      alerts: newAlerts,
    };
  }

  if (['start-approving-request', 'finish-approving-request'].includes(action.type)) {
    return {
      ...prevState,
      modalApproveDepositRequestProps: {
        ...prevState.modalApproveDepositRequestProps,
        isConfirming: action.type === 'start-approving-request',
      },
    };
  }

  if (['start-rejecting-request', 'finish-rejecting-request'].includes(action.type)) {
    return {
      ...prevState,
      modalRejectDepositRequestProps: {
        ...prevState.modalRejectDepositRequestProps,
        isRejecting: action.type === 'start-rejecting-request',
      },
    };
  }

  if (action.type === 'hide-approve-modal') {
    return {
      ...prevState,
      modalApproveDepositRequestProps: {
        ...prevState.modalApproveDepositRequestProps,
        isVisible: false,
      },
    };
  }

  if (action.type === 'show-reject-modal') {
    return {
      ...prevState,
      modalRejectDepositRequestProps: {
        ...prevState.modalRejectDepositRequestProps,
        requestId: action.payload,
        isVisible: true,
      },
    };
  }

  if (action.type === 'hide-reject-modal') {
    return {
      ...prevState,
      modalRejectDepositRequestProps: {
        ...prevState.modalRejectDepositRequestProps,
        isVisible: false,
      },
    };
  }

  if (action.type === 'set-deposit-requests') {
    return {
      ...prevState,
      depositRequests: action.payload,
    };
  }

  if (action.type === 'fetching-requests') {
    return {
      ...prevState,
      isLoadingDepositRequests: action.payload,
    };
  }

  return prevState;
};
