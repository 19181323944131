import { format } from 'date-fns';
import type { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';
import { toCMBOrderType } from 'refreshed-pages/market-board-v2/utils/toCMBOrderType';
import { toOrderSideBadgeProps } from 'refreshed-pages/market-board-v2/utils/toOrderSideBadgeProps';

import { Badge, CMBOrderType, Icon, IconName, Text, TypographyVariant, styled, toLayerBackground } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import type { FetchListingsResponse } from '../../../utils/fetchListings';
import { TableActions } from '../components/TableActions';
import { toMetaRows } from './toMetaRows';

const { formatNumber, formatPrice } = formatter;

export const toTableRows = ({
  listings,
  userIds,
  currencyCode,
  categoryNumDecimals,
  filteringOptions,
  onPressAction,
}: {
  listings: FetchListingsResponse['data'];
  userIds: Array<number>;
  currencyCode: string;
  categoryNumDecimals: number;
  filteringOptions: OtcCriteria;
  onPressAction(props: { listing: FetchListingsResponse['data'][0]; isMine: boolean }): void;
}) => {
  return listings.map((item) => {
    const isMine = userIds.includes(item.userId) || userIds.includes(item.placedBy);
    const orderType = toCMBOrderType({
      isAuction: !!item.isAuction,
      sideId: item.sideId,
    });
    const numDecimals = item?.baseAsset ? item.baseAsset.numDecimals : categoryNumDecimals;

    return {
      _key: item.id,
      id: (
        <Text variant={TypographyVariant.subtitle2}>
          {item.id}
          {isMine && (
            <>
              {' '}
              <UserIconWrapper>
                <Icon name={IconName.User} />
              </UserIconWrapper>
            </>
          )}
        </Text>
      ),
      side: <Badge icon={IconName.Tag} {...toOrderSideBadgeProps(orderType)} />,
      price: item.price ? formatPrice(item.price, currencyCode) : '-',
      qty: formatNumber(item.quantity, numDecimals),
      project: item.project
        ? [item.project.registryProjectId, item.project.name].filter((v) => !!v).join(' | ')
        : item.projects?.length
          ? item.projects.map((project) => [project.registryProjectId, project.name].filter((v) => !!v).join(' | '))
          : '-',
      contract: item.baseAsset?.name ?? '-',
      ...toMetaRows({ orderCriteria: item.orderCriteria, filteringOptions }),
      ...(item.type === CMBOrderType.Auction
        ? {
            bidVolume: item.bidVolume ? `${formatNumber(item.bidVolume, numDecimals)} ${item.quantityUnit}` : '-',
            bestBid: item.bestOffer ? formatPrice(item.bestOffer ?? 0, item.quoteAsset?.symbol) : '-',
            startsAt: item.startAtUtc ? format(new Date(item.startAtUtc), 'd MMM yyyy, p') : 'N/A',
            endsAt: item.expiryUtc ? format(new Date(item.expiryUtc), 'd MMM yyyy, p') : 'N/A',
          }
        : {
            bidVolume: 'N/A',
            bestBid: 'N/A',
            startsAt: 'N/A',
            endsAt: 'N/A',
          }),
      actions: <TableActions onPressAction={onPressAction} listing={item} orderType={orderType} isMine={isMine} />,
    };
  });
};

const UserIconWrapper = styled.div`
  display: flex;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => toLayerBackground(theme)('layerAccent')};
`;
