import type { PropsWithChildren } from 'react';

import { Theme } from '@aircarbon/ui';

import { ThemeMode } from 'pages/account/trading/components/ThemeMode';

import { Entity } from 'state/entity';

export const UILibraryThemeProvider: React.FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
  const { entity } = Entity.useContainer();
  const { themeMode } = ThemeMode.useContainer();
  const themeName: any = (entity.themeCode || 'blueSky') + (themeMode === 'light' ? 'Light' : 'Dark');

  return <Theme theme={themeName}>{children}</Theme>;
};
