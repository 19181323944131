import type { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';

import { countries } from '@aircarbon/ui';

export const toMetaFields = (filteringOptions: OtcCriteria) => {
  const filteringOptionsMap = new Map();

  filteringOptions.forEach((filteringOption) => {
    const optionsMap = new Map();

    filteringOption.options?.forEach((option) => {
      optionsMap.set(option.value, option);
    });

    filteringOptionsMap.set(filteringOption.value, {
      label: filteringOption.label,
      value: filteringOption.value,
      optionsMap,
    });
  });

  return (criteriaItem: { id: string; key: string; value: string }) => {
    const filteringOption = filteringOptionsMap.get(criteriaItem.key);

    if (!filteringOption) {
      return null;
    }

    if (filteringOption.value === 'COUNTRY_CODE') {
      const countriesByCode = countries.reduce(
        (curr, country) => ({
          ...curr,
          [country.countryCode]: country.name,
        }),
        {},
      );
      const value = criteriaItem.value.split(',').map((countryCode) => countriesByCode[countryCode]);
      if (!value) {
        return null;
      }
      return {
        label: filteringOption.label,
        value,
      };
    }

    if (filteringOption.value === 'VINTAGE_YEAR') {
      return {
        label: filteringOption.label,
        value: criteriaItem.value.split(','),
      };
    }

    if (!filteringOption.optionsMap.size) {
      return null;
    }

    const optionsMap = filteringOption.optionsMap;

    const value = criteriaItem.value
      .split(',')
      .map((singleValue) => optionsMap.get(singleValue)?.label)
      .filter((i) => !!i);

    if (!value) {
      return null;
    }

    return {
      label: filteringOption.label,
      value,
    };
  };
};
