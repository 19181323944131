import { CardWithGapAndPadding } from 'refreshed-component/atoms/CardWithGapAndPadding';
import BiofuelClientForm from 'refreshed-component/forms/BiofuelClientForm';
import { ConfirmModal } from 'refreshed-component/molecules/ConfirmModal';
import Modal from 'refreshed-component/molecules/Modal';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  IconName,
  Layer,
  Text,
  TextAlign,
  TextColor,
  ToastVariant,
  TypographyVariant,
  showToast,
} from '@aircarbon/ui';
import type { ExternalBrokerClient } from '@aircarbon/utils-common/src/dto/trading';

import { useExternalBrokerClient } from 'pages/account/trading/hooks/useExternalBrokerClient';

import { User } from 'state/user';

type BiofuelClientFormData = {
  name: string;
  address: string;
  country: string;
  email: string;
  phone: string;
  contactPersonName: string;
};

export const ExternalClients = () => {
  const {
    selector: { getAuthToken },
    status: {
      canAddExternalBrokerClientForBlockTrade,
      canAddExternalBrokerClientForBiofuel,
      canUpdateExternalBrokerClientForBlockTrade,
      canUpdateExternalBrokerClientForBiofuel,
    },
  } = User.useContainer();

  const { data: clientList, refetch: refetchExternalClients } = useExternalBrokerClient();

  const deleteClientRequest = async (clientData: ExternalBrokerClient) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/broker/external-client/${clientData.id}`, {
      method: 'DELETE',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    });
    if (response.ok) {
      showToast({
        variant: ToastVariant.Success,
        message: `Client #${clientData.id} is Deleted successfully.`,
      });
    } else {
      showToast({
        variant: ToastVariant.Danger,
        message: `Failed to Delete client #${clientData.id}.`,
      });
    }
    refetchExternalClients();
  };

  const editClientRequest = async (clientId: number, formData: BiofuelClientFormData) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/broker/external-client/${clientId}`, {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        address: formData.address || undefined,
        country: formData.country || undefined,
        phone: formData.phone || undefined,
        contactPersonName: formData.contactPersonName || undefined,
      }),
    });
    if (response.ok) {
      showToast({
        variant: ToastVariant.Success,
        message: `Client #${clientId} is edited successfully.`,
      });
    } else {
      showToast({
        variant: ToastVariant.Danger,
        message: `Failed to edit client #${clientId}.`,
      });
    }
    refetchExternalClients();
  };

  const addClientRequest = async (formData: BiofuelClientFormData) => {
    // TODO: Implement data-mutation
    const authToken = await getAuthToken();
    const response = await fetch(`/api/user/broker/external-client`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        name: formData.name,
        email: formData.email,
        address: formData.address || undefined,
        country: formData.country || undefined,
        phone: formData.phone || undefined,
        contactPersonName: formData.contactPersonName || undefined,
      }),
    });
    if (response.ok) {
      showToast({
        variant: ToastVariant.Success,
        message: 'Client added successfully.',
      });
    } else {
      showToast({
        variant: ToastVariant.Danger,
        message: 'Failed to add client.',
      });
    }
    refetchExternalClients();
  };

  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title="External Clients"
          controls={{
            secondary: !!(canAddExternalBrokerClientForBlockTrade() || canAddExternalBrokerClientForBiofuel()) && (
              <Modal
                title={`Add External Client`}
                action={<Button endIcon={IconName.UserAdd}>Add External Client</Button>}
              >
                {({ onClose, onLoading }) => {
                  return (
                    <BiofuelClientForm
                      onClose={onClose}
                      onSubmit={(clientData) => {
                        onLoading(true);
                        addClientRequest(clientData)
                          .then(() => {
                            onLoading(false);
                            onClose();
                          })
                          .catch(() => {
                            onLoading(false);
                          });
                      }}
                    />
                  );
                }}
              </Modal>
            ),
          }}
        />
      </PageSections>
      <Layer>
        <CardWithGapAndPadding>
          <Table
            config={{
              sticky: {
                left: ['id'],
                right: ['action'],
              },
              columns: {
                id: {
                  label: '#',
                  minWidth: 50,
                },
                clientName: {
                  label: 'CLIENT NAME',
                },
                address: {
                  label: 'ADDRESS',
                },
                country: {
                  label: 'COUNTRY',
                },
                email: {
                  label: 'EMAIL',
                },
                phone: {
                  label: 'PHONE',
                },
                contactPersonName: {
                  label: 'CONTACT PERSON NAME',
                },
                action: {
                  label: 'ACTION',
                },
              },
              rows:
                clientList?.map((item) => {
                  return {
                    _key: item.id.toString(),
                    id: item.id,
                    clientName: item.name || '-',
                    address: item.address || '-',
                    country: item.country || '-',
                    email: item.email || '-',
                    phone: item.phone || '-',
                    contactPersonName: item.contactPersonName || '-',
                    action: (
                      <>
                        {(canUpdateExternalBrokerClientForBlockTrade() ||
                          canUpdateExternalBrokerClientForBiofuel()) && (
                          <div className="flex flex-row gap-base">
                            <Modal
                              title={`Edit client #${item.id}`}
                              action={
                                <Button variant={ButtonVariant.outlined} size={ButtonSize.s} endIcon={IconName.Pencil}>
                                  Edit
                                </Button>
                              }
                            >
                              {({ onClose, onLoading }) => {
                                return (
                                  <BiofuelClientForm
                                    edit={item}
                                    onClose={onClose}
                                    onSubmit={(clientData) => {
                                      onLoading(true);
                                      editClientRequest(item.id, clientData)
                                        .then(() => {
                                          onLoading(false);
                                          onClose();
                                        })
                                        .catch(() => {
                                          onLoading(false);
                                        });
                                    }}
                                  />
                                );
                              }}
                            </Modal>
                            <ConfirmModal
                              title={`Delete client #${item.id}`}
                              action={
                                <Button
                                  variant={ButtonVariant.secondary}
                                  size={ButtonSize.s}
                                  endIcon={IconName.ArrowEnd}
                                >
                                  Delete
                                </Button>
                              }
                              accept={{
                                label: 'Delete',
                                callback(props) {
                                  props.onLoading(true);
                                  deleteClientRequest(item)
                                    .then(() => {
                                      props.onLoading(false);
                                      props.onClose();
                                    })
                                    .catch(() => {
                                      props.onLoading(false);
                                    });
                                  return false;
                                },
                              }}
                              cancel={{
                                label: 'Keep',
                              }}
                            >
                              <Text
                                align={TextAlign.center}
                                color={TextColor.secondary}
                                variant={TypographyVariant.subtitle1}
                              >
                                Are you sure you want to{' '}
                                <Text align={TextAlign.center} variant={TypographyVariant.subtitle1}>
                                  {`Delete client #${item.id}`}
                                </Text>
                                ?
                              </Text>
                            </ConfirmModal>
                          </div>
                        )}
                      </>
                    ),
                  };
                }) || [],
            }}
          />
        </CardWithGapAndPadding>
      </Layer>
    </PageHolder>
  );
};
