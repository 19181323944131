import { AssetCategory } from './assetCategory';

const assetCategoryNumDecimals: Record<AssetCategory, number> = {
  [AssetCategory.token]: 0,
  [AssetCategory.biofuel]: 0,
  [AssetCategory.rec]: 6,
  [AssetCategory.fct]: 0,
  [AssetCategory.currency]: 2,
};

export default assetCategoryNumDecimals;
