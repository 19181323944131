import 'bootstrap-daterangepicker/daterangepicker.css';
import format from 'date-fns/format';
import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { CardWithGapAndPadding } from 'refreshed-component/atoms/CardWithGapAndPadding';
import { DownloadCSV } from 'refreshed-component/molecules/DownloadCSV';
import {
  type FilterDateRangeInput,
  FilterDropdown,
  type FilterRadioBox,
  FilterSelections,
} from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { Table } from 'refreshed-component/templates/Table';

import { Layer, Pagination } from '@aircarbon/ui';
import { helpers } from '@aircarbon/utils-common';

import { usePagination } from 'pages/account/trading/hooks/usePagination';

import { Account } from 'state/account';
import { User } from 'state/user';

const { WEB_INDEXER_API } = process.env;
const { serialize } = helpers;

type TransactionItem = {
  blockNumber: number;
  from: {
    account: string;
    columnType: string;
    fee: number;
    formattedValue: string;
    symbol: string;
    value: number;
    valueType: string;
  };
  gasPrice: number;
  to: {
    account: string;
    columnType: string;
    fee: number;
    formattedValue: string;
    symbol: string;
    value: number;
    valueType: string;
  };
  tradePrice: number;
  txDate: string;
  txHash: string;
  txLabel: string;
  txType: string;
};

interface Transaction {
  data: Array<TransactionItem>;
}

//TODO:
//case needed to differentiate between funds
//deposited from refund of rejected/cancelled withdrawal

function transformData({
  transactions,
  accountUsers,
  tokenUnit,
}: {
  transactions: Array<TransactionItem> | undefined;
  accountUsers: Array<Record<string, any>> | null;
  tokenUnit: string;
}): Array<{
  date: string;
  from: string;
  to: string;
  asset: string;
  amount: string;
  txHash: string;
} | null> {
  return (
    transactions
      ?.map((trade) => {
        const { from, to, txDate, txHash } = trade;
        const txTime = format(new Date(txDate), 'MMM d yyyy h:mm:ss a');
        const fromAccount = (accountUsers ?? []).find((user: Record<string, any>) => user.account === from.account);
        const toAccount = (accountUsers ?? []).find((user: Record<string, any>) => user.account === to.account);

        // Must be internal transfers. Between Corporate accounts.
        if (!fromAccount || !toAccount) return null;

        return {
          txHash,
          date: txTime,
          from: fromAccount ? `${fromAccount?.first_name} ${fromAccount?.last_name}` : from.account,
          to: toAccount ? `${toAccount?.first_name} ${toAccount?.last_name}` : to.account,
          asset: from.symbol,
          amount:
            from.columnType === 'token' && tokenUnit ? `${from.formattedValue} ${tokenUnit}` : from.formattedValue,
        };
      })
      .filter(Boolean) ?? []
  );
}

export const Transfers = () => {
  const pagination = usePagination();

  const { accountAddresses, accountUsers } = Account.useContainer();
  const accountOptions = accountUsers
    ?.filter((account) => account.account)
    ?.map((userAccount) => ({
      label: `${userAccount.first_name} ${userAccount.last_name}`,
      id: userAccount.account,
    }));
  const filters: {
    account: FilterRadioBox;
    date: FilterDateRangeInput;
  } = {
    account: {
      type: 'radio-box',
      label: 'Accounts',
      list: accountOptions || [],
    },
    date: {
      type: 'date-range-input',
      label: 'Date range',
    },
  };
  const [filterSelections, setFilterSelections] = useState<FilterSelections<typeof filters> | undefined>({});

  const {
    status: { isSuperAdmin },
  } = User.useContainer();

  const [summary, setSummary] = useState<Record<string, any>>();

  // Paginated URL
  let url = `${WEB_INDEXER_API}/transactions?page=${pagination.page}&limit=${pagination.pageSize}${
    isSuperAdmin() ? '&excludeTestAsset=no' : ''
  }`;
  if (filterSelections?.date?.range?.startDate && filterSelections?.date?.range?.endDate)
    url += `&startDate=${filterSelections?.date?.range?.startDate.toISOString()}&endDate=${filterSelections?.date?.range?.endDate.toISOString()}`;
  // url += `&${serialize({ txType: ['tokenTransfer', 'currencyTransfer'] })}`;
  // url += `&${serialize({ transferType: ['RELATED_TRANSFER'] })}`;
  url += `&${serialize({ txLabel: ['User Related Transfer'] })}`;
  url += `&${serialize({
    address: filterSelections?.account?.selection ? [filterSelections?.account?.selection] : accountAddresses(),
  })}`;

  // Non-Paginated URL for Export CSV
  let nonPaginatedUrl = `${WEB_INDEXER_API}/transactions?page=${pagination.page}&limit=${500}${
    isSuperAdmin() ? '&excludeTestAsset=no' : ''
  }`;
  if (filterSelections?.date?.range?.startDate && filterSelections?.date?.range?.endDate)
    nonPaginatedUrl += `&startDate=${filterSelections?.date?.range?.startDate.toISOString()}&endDate=${filterSelections?.date?.range?.endDate.toISOString()}`;

  nonPaginatedUrl += `&${serialize({ txLabel: ['User Related Transfer'] })}`;
  nonPaginatedUrl += `&${serialize({ address: accountAddresses() })}`;

  const fetchTransactions = async (dataUrl: string): Promise<Transaction> => {
    return fetch(dataUrl, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((resp: Response) => resp.json())
      .then((result) => {
        setSummary(result.summary);
        return result;
      });
  };

  const { data: transactions, isFetching } = useQuery(url, fetchTransactions);

  // TODO: tokenUnit should not be applied to all items. This is by asset type.
  const rows = transformData({
    transactions: transactions?.data,
    accountUsers,
    tokenUnit: '',
  });

  const getNonPaginatedData = useCallback(
    () =>
      fetch(nonPaginatedUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }).then((resp: Response) => resp.json()),
    [nonPaginatedUrl],
  );

  const getCSVRows = async () => {
    const allTransactions = await getNonPaginatedData();
    const nonPaginatedRows = transformData({
      transactions: allTransactions?.data,
      accountUsers,
      tokenUnit: '',
    });
    return nonPaginatedRows?.map((item) => ({
      Date: item?.date,
      From: item?.from,
      To: item?.to,
      Asset: item?.asset,
      Amount: item?.amount,
    }));
  };
  return (
    <PageHolder>
      <PageSections>
        <PageControls
          title="Transfer"
          controls={{
            secondary: <DownloadCSV data={getCSVRows} fileName={'users-transfer-report.csv'} />,
          }}
        />
      </PageSections>
      <Layer>
        <CardWithGapAndPadding>
          <div className="flex flex-row justify-between items-center gap-base">
            <div className="flex flex-row justify-start">
              <FilterDropdown
                selections={filterSelections}
                onChange={(value) => setFilterSelections(value)}
                list={filters}
              />
            </div>
          </div>
          <FilterSelections
            selections={filterSelections}
            onChange={(value) => setFilterSelections(value)}
            list={filters}
          />
          {isFetching ? (
            <Loading isOverLay={true} />
          ) : (
            <Table
              config={{
                columns: {
                  date: {
                    label: 'DATE',
                  },
                  from: {
                    label: 'FROM',
                  },
                  to: {
                    label: 'To',
                  },
                  asset: {
                    label: 'ASSET',
                  },
                  amount: {
                    label: 'Amount',
                  },
                },
                rows:
                  rows.map((item) => {
                    return {
                      _key: `${item?.from} ${item?.to}`,
                      date: item?.date || '',
                      from: item?.from || '',
                      to: item?.to || '',
                      asset: item?.asset || '',
                      amount: item?.amount || '',
                    };
                  }) || [],
              }}
            />
          )}
          {!!summary?.totalTransactions && (
            <Pagination
              currentPage={pagination.page}
              pagesCount={Math.ceil(summary?.totalTransactions / pagination.pageSize)}
              onChange={(currentPage) => {
                pagination.setPage(currentPage);
              }}
            />
          )}
        </CardWithGapAndPadding>
      </Layer>
    </PageHolder>
  );
};
