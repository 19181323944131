import { useEffect, useState } from 'react';
import { createContainer } from 'unstated-next';

import { LocalStorage } from '@aircarbon/utils-common';

import emitter from 'utils/emitter';

export type ThemeModeType = 'dark' | 'light';

function getPreferredColorScheme() {
  if (window.matchMedia) {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
      return 'dark';
    } else {
      return 'light';
    }
  }
  return 'light';
}

export function getTheme() {
  return LocalStorage.getItem<'dark' | 'light' | 'system'>('ui-theme', 'system');
}

export function setTheme(theme: string) {
  return LocalStorage.setItem('ui-theme', theme);
}

export function useThemeMode() {
  const [{ themeMode }, setTheme] = useState({
    themeMode: 'dark',
  });

  useEffect(() => {
    const onChange = () => {
      const loadedThemeMode = getTheme();
      const theme = loadedThemeMode === 'system' ? getPreferredColorScheme() : loadedThemeMode;
      setTheme({
        themeMode: theme,
      });
    };

    onChange();
    window.matchMedia?.('(prefers-color-scheme: dark)').addEventListener('change', () => {
      onChange();
    });
    const removeEvent = emitter.on('trading-screen-theme-changed', onChange);
    return () => {
      removeEvent();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    setTheme,
    themeMode: themeMode as ThemeModeType,
  };
}

export const ThemeMode = createContainer(useThemeMode);
