import queryString from 'query-string';

import type { AssetCategoryCode } from '@aircarbon/utils-common';
import type { Asset } from '@aircarbon/utils-common/src/dto';

import { type ApiResponse, userAuthenticatedApi } from '../fetcher';

export const fetchTokenTypes = async (assetCategories?: Array<AssetCategoryCode>) => {
  const qs = queryString.stringify(
    { 'assetCategories[]': assetCategories },
    {
      skipNull: true,
      skipEmptyString: true,
    },
  );

  const userService = await userAuthenticatedApi();

  const res = userService.get(`/token/types?${qs}`);
  return res.json<ApiResponse<Asset[]>>();
};
