import { forwardRef, useImperativeHandle, useState } from 'react';
import { FieldsGroup } from 'refreshed-pages/market-board-v2/components/FieldsGroup';
import * as yup from 'yup';

import {
  BadgeInstantTrade,
  Card,
  CardVariant,
  InputText,
  InputTextType,
  Select,
  Toggle,
  ToggleLayout,
} from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

export interface CommonOrderFieldsValue {
  quantity: string;
  price: string;
  timeInForce: string;
  isInstantTrade: boolean;
  endDate: string;
  balance?: number;
  balancePerAsset?: { [key: string]: { assetId: number; quantity: number } };
  isInstantTradeEnabled?: boolean;
  baseAssetId?: string;
}

export interface CommonOrderFieldsRef {
  /**
   * Validates the form and returns true if forms has errors
   */
  validate(): boolean;
}

const valueValidator = yup.object().shape({
  price: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .moreThan(0, 'Price should be more than 0')
    .required('Please provide Price'),
  quantity: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .when(['$minimumQuantity', '$maximumQuantity', '$quantityMultiplesOf', '$openQuantity'], ((
      minimumQuantity: number | undefined,
      maximumQuantity: number | undefined,
      quantityMultiplesOf: number | undefined,
      openQuantity: number,
      schema: any,
    ) => {
      if (minimumQuantity && openQuantity < minimumQuantity) {
        schema = schema.min(openQuantity, 'Quantity should not be less than ' + openQuantity);
      } else if (minimumQuantity !== undefined) {
        schema = schema.min(minimumQuantity, 'Quantity should not be less than ' + minimumQuantity);
      } else {
        schema = schema.moreThan(0, 'Quantity should be more than 0');
      }

      if (maximumQuantity) {
        schema = schema.max(maximumQuantity, 'Quantity should not be more than ' + maximumQuantity);
      }

      if (quantityMultiplesOf) {
        schema = schema.test(
          'quantityMultiplesOf',
          'Quantity should be in multiples of ' + quantityMultiplesOf,
          (q: number) => q % quantityMultiplesOf === 0,
        );
      }
      return schema;
    }) as any)
    .required('Please provide Quantity'),
});

export const CommonOrderFields = forwardRef<
  CommonOrderFieldsRef,
  {
    value: CommonOrderFieldsValue;
    quantityUnit: string;
    priceCurrency: string;
    minimumQuantity?: number;
    maximumQuantity?: number;
    quantityMultiplesOf?: number;
    balance?: number;
    balancePerAsset?: { [key: string]: { assetId: number; quantity: number } };
    isInstantTradeEnabled?: boolean;
    isInstantTradeHidden?: boolean;
    openQuantity: number;
    onChange(value: CommonOrderFieldsValue): void;
    tokenAssetNumDecimals?: number;
  }
>((props, ref) => {
  const {
    value,
    quantityUnit,
    priceCurrency,
    onChange,
    isInstantTradeEnabled,
    balancePerAsset,
    isInstantTradeHidden = false,
    minimumQuantity,
    maximumQuantity,
    quantityMultiplesOf,
    openQuantity,
    tokenAssetNumDecimals,
  } = props;
  const { quantity, price, isInstantTrade } = value;
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});

  const onChangeFormValue =
    <F extends keyof CommonOrderFieldsValue>(field: F) =>
    (fieldValue: CommonOrderFieldsValue[F]) => {
      setDirtyFields((prevFields) => ({
        ...prevFields,
        [field]: true,
      }));
      onChange({
        ...value,
        [field]: fieldValue,
      });
    };

  useImperativeHandle(
    ref,
    () => ({
      validate() {
        setDirtyFields({});
        try {
          valueValidator.validateSync(value, {
            abortEarly: false,
            context: {
              minimumQuantity,
              maximumQuantity,
              quantityMultiplesOf,
              openQuantity,
            },
          });
        } catch (e) {
          setErrors(
            (e as yup.ValidationError).inner.reduce(
              (acc, curr) => ({ ...acc, [curr.path as string]: curr.message }),
              {},
            ),
          );
          return true;
        }

        setErrors({});
        return false;
      },
    }),
    [value, minimumQuantity, maximumQuantity, quantityMultiplesOf],
  );

  return (
    <>
      {!isInstantTradeHidden && (
        <Card variant={CardVariant.Bordered}>
          <Toggle
            margin="16px"
            isOn={isInstantTrade}
            isDisabled={!isInstantTradeEnabled}
            label={<BadgeInstantTrade />}
            onChange={(isOn) => onChangeFormValue('isInstantTrade')(isOn)}
            description="Faster settlement with pre-funded accounts"
            layout={ToggleLayout.LabelFirst}
            toggleTooltip={!isInstantTradeEnabled ? "You don't have enough balance for assets reservation" : ''}
          />
        </Card>
      )}
      {value.isInstantTrade && (
        <FieldsGroup>
          <Select
            label="Contract"
            placeholder="Select contract to trade"
            value={value.baseAssetId}
            items={
              balancePerAsset
                ? Object.keys(balancePerAsset).map((key) => ({
                    value: balancePerAsset[key].assetId.toString(),
                    title: `${key} | ${formatter.formatNumber(balancePerAsset[key].quantity, tokenAssetNumDecimals)}`,
                  }))
                : []
            }
            onChange={({ value }) => onChangeFormValue('baseAssetId')(value)}
          />
        </FieldsGroup>
      )}
      <FieldsGroup columnMinWidth="14rem">
        <InputText
          type={InputTextType.Number}
          label={'Quantity'}
          suffix={quantityUnit}
          value={quantity}
          onChange={(e) => onChangeFormValue('quantity')(e.target.value)}
          error={!dirtyFields.quantity ? errors.quantity : undefined}
        />
        <InputText
          value={price}
          onChange={(e) => onChangeFormValue('price')(e.target.value)}
          error={!dirtyFields.price ? errors.price : undefined}
          type={InputTextType.Number}
          label={`Price (per ${quantityUnit})`}
          suffix={priceCurrency}
        />
      </FieldsGroup>
    </>
  );
});
