import Emittery from 'emittery';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { CMBOrderType } from '@aircarbon/ui';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import type { OtcCriteria } from '../utils/OtcCriteria';
import { OtcCriteriaEventName } from '../utils/OtcCriteriaEvent';
import { fetchOtcCriteria } from '../utils/fetchOtcCriteria';

interface OtcCriteriaResponse {
  offerByCriteriaRequiredFields: Array<string>;
  offerByProjectRequiredFields: Array<string>;
  bidByProjectsRequiredFields: Array<string>;
  bidByCriteriaRequiredFields: Array<string>;
  auctionByCriteriaRequiredFields: Array<string>;
  auctionByProjectRequiredFields: Array<string>;
  criteria: OtcCriteria;
}
let cachedResponse: OtcCriteriaResponse = {
  auctionByCriteriaRequiredFields: [],
  auctionByProjectRequiredFields: [],
  bidByCriteriaRequiredFields: [],
  bidByProjectsRequiredFields: [],
  offerByCriteriaRequiredFields: [],
  offerByProjectRequiredFields: [],
  criteria: [],
};
let assetCategoryId: string;

const eventEmitter = new Emittery();

export const useOtcCriteria = () => {
  const { product } = useMarketplaceProduct();
  const [currentResponse, setCurrentResponse] = useState(cachedResponse);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const unsubscribeOtcCriteriaChange = eventEmitter.on(
      OtcCriteriaEventName.Changed,
      (payload: OtcCriteriaResponse) => {
        setCurrentResponse(payload);
      },
    );
    const unsubscribeIsFetching = eventEmitter.on(OtcCriteriaEventName.Fetching, (payload: boolean) => {
      setIsFetching(payload);
    });

    return () => {
      unsubscribeOtcCriteriaChange();
      unsubscribeIsFetching();
    };
  }, []);

  useEffect(() => {
    if (product === assetCategoryId) {
      return;
    }

    assetCategoryId = product;

    const loadOtcCriteria = async () => {
      eventEmitter.emit(OtcCriteriaEventName.Fetching, true);
      const fetchedMeta = await fetchOtcCriteria(Number(assetCategoryId));

      cachedResponse = {
        ...fetchedMeta,
        criteria: fetchedMeta.keys
          .map((item) => ({
            value: item?.code,
            label: item?.name,
            options: item?.__projectMetaOptions__?.map((metaOption) => ({
              value: metaOption.code,
              label: metaOption.metaValue,
            })),
          }))
          .filter((item) => {
            if (item.value === 'COUNTRY_CODE' || item.value === 'VINTAGE_YEAR' || item.value === 'PROJECT_ID') {
              return true;
            }

            return !!item.options?.length;
          }),
      };

      eventEmitter.emit(OtcCriteriaEventName.Changed, cachedResponse);

      eventEmitter.emit(OtcCriteriaEventName.Fetching, false);
    };

    loadOtcCriteria();
  }, [product]);

  const getRequiredFields = useCallback(
    (orderType: CMBOrderType, isProjectsTab?: boolean) => {
      return {
        [CMBOrderType.Auction]: currentResponse.auctionByProjectRequiredFields,
        [CMBOrderType.Bid]: isProjectsTab
          ? currentResponse.bidByProjectsRequiredFields
          : currentResponse.bidByCriteriaRequiredFields,
        [CMBOrderType.Offer]: isProjectsTab
          ? currentResponse.offerByProjectRequiredFields
          : currentResponse.offerByCriteriaRequiredFields,
      }[orderType];
    },
    [currentResponse],
  );

  const checkIfFieldIsRequired = useCallback(
    (criteriaKey: string, orderType: CMBOrderType, isProjectsTab?: boolean) => {
      return getRequiredFields(orderType, isProjectsTab).includes(criteriaKey);
    },
    [getRequiredFields],
  );

  const memoized = useMemo(
    () => ({
      meta: currentResponse.criteria,
      isFetching,
      checkIfFieldIsRequired,
      getRequiredFields,
    }),
    [currentResponse.criteria, isFetching, checkIfFieldIsRequired, getRequiredFields],
  );

  return memoized;
};
