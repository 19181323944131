import { toTuple } from 'refreshed-pages/market-board-v2/utils/toTuple';

import { CMBOrderType, type CardCMBProps } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';
import { Dto } from '@aircarbon/utils-common';

import type { FetchListingsResponse } from './fetchListings';

const { formatNumber, formatPrice, formatCurrency } = formatter;

export const toCmbCardProps =
  (
    toMetaFields: (criteriaItem: { id: string; key: string; value: string }) => CardCMBProps['metaFields'][0][0] | null,
  ) =>
  (props: {
    item: FetchListingsResponse['data'][0];
    userIds: Array<number>;
  }): Omit<CardCMBProps, 'onPressCTA' | 'isCTADisabled'> & {
    startsAt?: string;
  } => {
    const { item, userIds } = props;

    const result = {
      isMine: userIds.includes(item.userId) || userIds.includes(item.placedBy),
      id: item.id,
      quantity: formatNumber(item.quantity, item.baseAsset?.numDecimals ?? 0),
      quantityUnit: item.quantityUnit,
      price: item.price ? formatCurrency(item.price, item.quoteAsset?.numDecimals ?? 2) : '-',
      priceCurrency: item.priceCurrency,
      type: item.type,
      isClosed: item.statusId === Dto.OtcOrderStatus.Closed,
      ...(item.statusId !== Dto.OtcOrderStatus.Closed ? { endsAt: item.expiryUtc, startsAt: item.startAtUtc } : {}),
      isInstantTrade: !!item.isPrefunded,
      contractType: item.baseAsset?.name,
      metaFields: [
        ...toTuple(
          [
            ...(item.project
              ? [
                  {
                    label: 'Project',
                    value: [item.project.registryProjectId, item.project.name].filter((v) => !!v).join(' | '),
                  },
                ]
              : []),
            ...(item.projects?.length
              ? [
                  {
                    label: 'Projects',
                    value: item.projects.map((project) =>
                      [project.registryProjectId, project.name].filter((v) => !!v).join(' | '),
                    ),
                  },
                ]
              : []),
            ...(item.orderCriteria?.map(toMetaFields).filter((field) => !!field) as Array<
              CardCMBProps['metaFields'][0][0]
            >),
          ],
          (currentItem) => ['Project', 'Projects'].includes(currentItem.label),
        ),
      ],
    };

    if (item.type === CMBOrderType.Auction) {
      (result as any).bidVolume = item.bidVolume
        ? `${formatNumber(item.bidVolume, item.baseAsset?.numDecimals ?? 0)} ${item.quantityUnit}`
        : '';
      (result as any).bestBid = item.bestOffer ? formatPrice(item.bestOffer, item.quoteAsset?.symbol) : '';
    }

    return result;
  };
