import { format } from 'date-fns';

import type { CounterOffer, TradeRequest } from '../../../../../utils/fetchTradeRequests';
import { toOfferDescription } from '../../../../../utils/toOfferDescription';
import { toOfferName } from '../../../../../utils/toOfferName';
import { toLastOfferPriceRaw, toOfferPrice } from '../../../../../utils/toOfferPrice';
import { toLastOfferQuantityRaw, toOfferQuantity } from '../../../../../utils/toOfferQuantity';
import { toOfferStatus } from '../../../../../utils/toOfferStatus';

export interface Offer {
  id: string;
  status: string;
  name: string;
  expiresAt: string;
  price: string | React.ReactElement;
  quantity: string | React.ReactElement;
  orderOpenQty: number;
  acceptedAt?: string;
  rejectedAt?: string;
  rejectionReason?: string;
  negotiations: Array<CounterOffer>;
  reservedAssetQty: number | null;
  isPrefunded: boolean;
  baseAsset?: {
    numDecimals: number;
    symbol: string;
  };
  sellerUserId: number | null;
  isOrderCreatedByBuyer: boolean;
  description: string;
  createdBy: number | null;
  otcOrder: {
    id: string;
    sideId: number;
    isAuction: number;
    filledQty: number;
    qty: number;
    userId: number;
    placedBy: number;
  };
}

export const toOffers = (props: {
  tradeRequests: Array<TradeRequest>;
  currentUserId: number;
  currency: string;
}): Array<Offer> => {
  const { tradeRequests, currentUserId, currency } = props;

  return tradeRequests.map((tradeRequest) => {
    return {
      id: tradeRequest.id,
      price: toOfferPrice({
        tradeRequest,
        currentUserId,
        currency,
      }),
      quantity: toOfferQuantity({
        tradeRequest,
      }),
      lastPriceRaw: toLastOfferPriceRaw({ tradeRequest, currentUserId }),
      lastQtyRaw: toLastOfferQuantityRaw({ tradeRequest, currentUserId }),
      orderOpenQty: tradeRequest.otcOrder.qty - tradeRequest.otcOrder.filledQty,
      name: toOfferName(tradeRequest),
      description: toOfferDescription(tradeRequest.tradeRequestDetail.__project__),
      expiresAt: tradeRequest.expiryUtc ? format(new Date(tradeRequest.expiryUtc), 'do MMM, yyyy') : '-',
      isPrefunded: tradeRequest.isPrefunded === 1,
      status: toOfferStatus({ tradeRequest, currentUserId }),
      negotiations: tradeRequest.counterOffers,
      rejectionReason: tradeRequest.comment ?? undefined,
      otcOrder: tradeRequest.otcOrder,
      baseAsset: tradeRequest.baseAsset ?? undefined,
      sellerUserId: tradeRequest.sellerUserId,
      reservedAssetQty: tradeRequest.reservedAssetQty,
      isOrderCreatedByBuyer: tradeRequest.buyerUserId === tradeRequest.otcOrder.userId,
      createdBy: tradeRequest.createdBy,
      ...(tradeRequest.approvedAtUtc
        ? {
            acceptedAt: format(new Date(tradeRequest.approvedAtUtc), 'do MMM, yyyy'),
          }
        : {}),
      ...(tradeRequest.cancelledAtUtc
        ? {
            rejectedAt: format(new Date(tradeRequest.cancelledAtUtc), 'do MMM, yyyy'),
          }
        : {}),
    };
  });
};
