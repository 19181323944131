import { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { CreateNewProjectModal } from 'refreshed-pages/market-board-v2/components/CreateNewProjectModal';
import { FieldsGroup } from 'refreshed-pages/market-board-v2/components/FieldsGroup';
import {
  SelectProjectAndVintage,
  type SelectProjectAndVintageRef,
} from 'refreshed-pages/market-board-v2/components/SelectProjectAndVintage';
import { useOtcCriteria } from 'refreshed-pages/market-board-v2/hooks/useOtcCriteria';
import type { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';
import { toTuple } from 'refreshed-pages/market-board-v2/utils/toTuple';
import { toVintageYears } from 'refreshed-pages/market-board-v2/utils/toVintageYears';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  type CMBOrderType,
  Card,
  CardVariant,
  IconName,
  Knob,
  Select,
  SelectCountry,
  type TabItem,
  Tabs,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import type { Dto } from '@aircarbon/utils-common';
import { AssetCategory } from '@aircarbon/utils-common';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import type { Project } from '../../../../../../utils/Project';
import { AddCriteriaModal } from '../../../BidForm/components/AddCriteriaModal';
import type { Criteria } from '../../../BidForm/utils/Criteria';

export interface AddCriteriaRef {
  /**
   * Validates the form and returns true if has errors
   */
  validate(): boolean;
}

export const AddCriteria = forwardRef<
  AddCriteriaRef,
  {
    value: {
      criteria: Array<Criteria>;
      project?: Project;
      vintage: string;
    };
    orderType: CMBOrderType;
    isReadOnly?: boolean;
    ownedByUserId: number;

    onChange(value: { criteria: Array<Criteria>; project?: Project; vintage: string }): void;
  }
>((props, ref) => {
  const { value, onChange, ownedByUserId, isReadOnly, orderType } = props;
  const { criteria, project } = value;
  const [isAddCriteriaModalVisible, setIsAddCriteriaModalVisible] = useState(false);
  const { meta, isFetching, checkIfFieldIsRequired, getRequiredFields } = useOtcCriteria();
  const [validationErrors, setValidationErrors] = useState<Record<string, boolean>>({});
  const [isCreateNewProjectModalVisible, setIsCreateNewProjectModalVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(project ? 'project' : 'criteria');
  const { product } = useMarketplaceProduct();
  const selectProjectAndVintageRef = useRef<SelectProjectAndVintageRef>(null);

  const criteriaByKey = useMemo(() => {
    const criteriaMap = new Map<string, { key: string; value: string }>();

    criteria.forEach((item) => criteriaMap.set(item.key, item));

    return criteriaMap;
  }, [criteria]);

  const metaTupples = useMemo(() => {
    if (isFetching) {
      return null;
    }
    const filteredMeta: Array<
      OtcCriteria[0] & {
        isRequired?: boolean;
      }
    > = [];

    meta.forEach((metaItem) => {
      const isRequired = checkIfFieldIsRequired(metaItem.value, orderType, activeTab === 'projects');
      const isInTupple =
        criteriaByKey.has(metaItem.value) || isRequired || (metaItem.value === 'PROJECT_ID' && project);

      if (isInTupple) {
        filteredMeta.push({ ...metaItem, isRequired });
      }
    });

    return toTuple(filteredMeta, (item) => item.value === 'PROJECT_ID');
  }, [isFetching, meta, criteriaByKey, orderType, checkIfFieldIsRequired, project, activeTab]);

  useImperativeHandle(ref, () => ({
    validate() {
      const newValidationErrors: Record<string, boolean> = {};

      if (activeTab === 'project') {
        return selectProjectAndVintageRef.current?.validate();
      }

      getRequiredFields(orderType, activeTab === 'project').forEach((field) => {
        if (field === 'PROJECT_ID') {
          if (!project) {
            newValidationErrors[field] = true;
          }
          return;
        }

        if (!criteriaByKey.get(field)?.value) {
          newValidationErrors[field] = true;
        }
      });

      setValidationErrors(newValidationErrors);

      return !!Object.keys(newValidationErrors).length;
    },
  }));

  const onCloseAddCriteriaModal = () => {
    setIsAddCriteriaModalVisible(false);
  };

  const onPressAddCriteria = () => {
    setIsAddCriteriaModalVisible(true);
  };

  const onAddCriteria = (addCriteriaProps: { criteria: Array<Criteria> }) => {
    onChange({
      criteria: addCriteriaProps.criteria,
      project,
      vintage: value.vintage,
    });
    setIsAddCriteriaModalVisible(false);
  };

  const onPressDeleteCriteria = (key: string) => {
    criteriaByKey.delete(key);

    onChange({
      ...value,
      criteria: Array.from(criteriaByKey.values()),
    });
  };

  const onChangeCriteria = (criteria: Criteria) => {
    if (!criteria.value) {
      criteriaByKey.delete(criteria.key);
    } else {
      criteriaByKey.set(criteria.key, criteria);
    }

    onChange({
      ...value,
      criteria: Array.from(criteriaByKey.values()),
    });
  };

  const onPressCreateNewProject = () => {
    setIsCreateNewProjectModalVisible(true);
  };

  const onCloseCreateNewProjectModal = () => {
    setIsCreateNewProjectModalVisible(false);
  };

  const onNewProjectCreated = (newProject: {
    id: string;
    name: string;
    description: string;
    registryProjectId: string;
    status: Dto.ProjectStatus;
  }) => {
    onChange({
      ...value,
      project: {
        title: newProject.name,
        description: newProject.description,
        value: newProject.id,
        registryProjectId: newProject.registryProjectId,
        status: newProject.status,
      },
    });

    setIsCreateNewProjectModalVisible(false);
  };

  const onPressTab = (tab: TabItem) => {
    if (tab.id === 'criteria') {
      onChange({
        criteria,
        project: undefined,
        vintage: value.vintage,
      });
    }

    if (tab.id === 'project') {
      criteriaByKey.clear();

      onChange({
        ...value,
        criteria: [],
      });
    }

    setActiveTab(tab.id);
  };

  return (
    <>
      <AddCriteriaModal
        isVisible={isAddCriteriaModalVisible}
        isMultiple={false}
        onAdd={onAddCriteria}
        onClose={onCloseAddCriteriaModal}
        criteria={criteria}
      />
      <CreateNewProjectModal
        isVisible={isCreateNewProjectModalVisible}
        onClose={onCloseCreateNewProjectModal}
        onProjectCreated={onNewProjectCreated}
      />

      <StyledSectionCard
        header={
          <Tabs
            activeItemId={activeTab}
            items={[
              {
                id: 'criteria',
                label: 'Select criteria',
              },
              {
                id: 'project',
                label: 'Select device',
              },
            ]}
            onPressTab={onPressTab}
          />
        }
      >
        {activeTab === 'criteria' && (
          <>
            {metaTupples?.map((tupple, index) => (
              <FieldsGroup columnMinWidth="14rem" key={index}>
                {tupple.map((item) =>
                  !item ? (
                    false
                  ) : item?.value === 'COUNTRY_CODE' ? (
                    <ClearableField key={item.value}>
                      <SelectCountry
                        label={item.label}
                        placeholder="Any"
                        isDisabled={isReadOnly}
                        error={validationErrors['COUNTRY_CODE'] ? `Please specify ${item.label}` : ''}
                        onChange={({ value }) =>
                          onChangeCriteria({
                            key: item.value,
                            value,
                          })
                        }
                        value={criteriaByKey.get(item.value)?.value}
                      />
                      {!isReadOnly && !item.isRequired && (
                        <Knob
                          variant={ButtonVariant.outlined}
                          icon={IconName.Trash}
                          onPress={() => onPressDeleteCriteria(item.value)}
                        />
                      )}
                    </ClearableField>
                  ) : item?.value === 'VINTAGE_YEAR' ? (
                    <ClearableField key={item.value}>
                      <Select
                        label={item?.label}
                        placeholder="Any"
                        isDisabled={isReadOnly}
                        error={validationErrors['VINTAGE_YEAR'] ? `Please specify ${item.label}` : ''}
                        onChange={({ value }) =>
                          onChangeCriteria({
                            key: item.value,
                            value,
                          })
                        }
                        value={criteriaByKey.get(item.value)?.value}
                        items={toVintageYears({
                          to: Number(product) === AssetCategory.fct ? 5 : 0,
                        })}
                      />
                      {!isReadOnly && !item.isRequired && (
                        <Knob
                          variant={ButtonVariant.outlined}
                          icon={IconName.Trash}
                          onPress={() => onPressDeleteCriteria(item.value)}
                        />
                      )}
                    </ClearableField>
                  ) : item?.value === 'PROJECT_ID' ? null : (
                    <ClearableField key={item.value}>
                      <Select
                        label={item?.label}
                        placeholder="Any"
                        error={validationErrors[item.value] ? `Please specify ${item.label}` : ''}
                        onChange={({ value }) =>
                          onChangeCriteria({
                            key: item.value,
                            value,
                          })
                        }
                        isDisabled={isReadOnly}
                        value={criteriaByKey.get(item.value)?.value}
                        items={item?.options?.map((option) => ({ title: option.label, value: option.value })) || []}
                      />
                      {!isReadOnly && !item.isRequired && (
                        <Knob
                          variant={ButtonVariant.outlined}
                          icon={IconName.Trash}
                          onPress={() => onPressDeleteCriteria(item.value)}
                        />
                      )}
                    </ClearableField>
                  ),
                )}
              </FieldsGroup>
            ))}

            {!isReadOnly && (
              <Button
                variant={ButtonVariant.outlined}
                size={ButtonSize.l}
                isDisabled={isReadOnly}
                onPress={onPressAddCriteria}
              >
                Add More Criteria
              </Button>
            )}
          </>
        )}
        {activeTab === 'project' && (
          <SelectProjectAndVintage
            ref={selectProjectAndVintageRef}
            ownedByUserId={ownedByUserId}
            project={value.project}
            vintage={value.vintage}
            onChange={onChange}
            onPressCreateNewProject={onPressCreateNewProject}
          />
        )}
      </StyledSectionCard>
    </>
  );
});

const ClearableField = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => toSpacing(theme)(2)};
  flex-shrink: 0;

  > *:first-child {
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
  }

  > *:nth-child(2) {
    flex-shrink: 0;
    margin-top: ${({ theme }) => toSpacing(theme)(14)};
  }
`;

export const StyledSectionCard = styled(Card).attrs({
  variant: CardVariant.Bordered,
})`
  padding: ${({ theme }) => toSpacing(theme)(12)};
  padding-top: ${({ theme }) => toSpacing(theme)(6)};
  gap: ${({ theme }) => toSpacing(theme)(8)};
  width: 100%;
  max-width: 47rem;
`;
