import { userAuthenticatedApi } from 'data-provider/fetcher';

interface FetchTradesProps {
  orderId?: string;
  page?: number;
  assetCategoryId?: number;
}

export interface FetchTradesResponse {
  data: Array<{
    id: string;
    otcOrderId: string;
    pairId: number;
    price: number;
    qty: number;
    buyerUserId: number;
    fee: number;
    sideId: number;
    pairName: string;
    tradeRequestId: number;
    tradeTypeId: number;
    transactionId: number;
    statusId: number;
    createdAtUtc: string;
    numDecimals: number;
  }>;
  page: number;
  pageCount: number;
  totalCount: number;
  totalPages: number;
}

export const fetchTrades = async (
  props: FetchTradesProps = {},
): Promise<
  | {
      status: 'ok';
      data: FetchTradesResponse;
    }
  | { status: 'error'; error: string }
> => {
  try {
    const userService = await userAuthenticatedApi();

    const request = userService.url(`/user/otc/trade`).query(props).get();

    const response = await request.res();

    if (response.status >= 300) {
      return {
        status: 'error',
        error: response.statusText ?? 'Something went wrong. Please try again',
      };
    }

    const responseJson = await request.json();

    return {
      status: 'ok',
      data: responseJson as FetchTradesResponse,
    };
  } catch (e) {
    const error = JSON.parse((e as Error).message);
    return {
      status: 'error',
      error: error.message ?? 'Something went wrong. Please try again',
    };
  }
};
