import { InputText, InputTextType, styled } from '@aircarbon/ui';

import { FieldWrapper } from 'components/styled/Styled';

import { AssetsAllocationTable } from '../AssetsAllocationTable';
import { Balance } from './components/Balance';

interface PackUnpackGERFormProps {
  balanceTitle: string;
  quantity: string;
  assets: Array<{
    assetType: string;
    balance: string;
    packQuantity: string;
    balanceAfter: string;
  }>;
  resultingBalance: string;
  errors?: Record<string, string>;
  onChangeQuantity(value: string): void;
}

export const PackUnpackGERForm: React.FC<PackUnpackGERFormProps> = (props) => {
  const { balanceTitle, quantity, assets, resultingBalance, errors, onChangeQuantity } = props;

  // Combined error based on quantity and resulting in invalid assets allocation or false resulting balance
  const quantityError = errors ? errors[Object.keys(errors)[0]] : '';

  return (
    <StyledPackUnpackGERForm>
      <FieldWrapper>
        <InputText
          type={InputTextType.Number}
          suffix="tCO2"
          label="Quantity (min. 1,000)"
          onChange={(e) => onChangeQuantity(e.target.value)}
          value={quantity}
          error={quantityError}
        />
      </FieldWrapper>
      <AssetsAllocationTable assets={assets} errors={errors} />
      <Balance title={balanceTitle} value={resultingBalance} />
    </StyledPackUnpackGERForm>
  );
};

const StyledPackUnpackGERForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
