import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useFilters } from 'refreshed-pages/market-board-v2/hooks/useFilters';
import { usePlaceOrderModal } from 'refreshed-pages/market-board-v2/hooks/usePlaceOrderModal';
import { toMetaFields } from 'refreshed-pages/market-board-v2/utils/toMetaFields';
import { toPlaceOrderModalOrderDetails } from 'refreshed-pages/market-board-v2/utils/toPlaceOrderModalOrderDetails';

import {
  Button,
  ButtonSize,
  ButtonVariant,
  Card,
  type CardCMBProps,
  CardVariant,
  IconName,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { hooks } from '@aircarbon/utils-common';

import useMarketSettings from 'pages/account/trading/hooks/useMarketSettings';

import { Account } from 'state/account';
import { User } from 'state/user';

import { FilterBadges } from './components/FilterBadges';
import { Grid } from './components/Grid';
import { List } from './components/List';
import { OrderTypeTabs } from './components/OrderTypeTabs';
import { ActiveViewType, SubHeader } from './components/SubHeader';
import { useListings } from './hooks/useListings';
import type { FetchListingsResponse } from './utils/fetchListings';
import { toCmbCardProps } from './utils/toCmbCardProps';

export interface ListingsProps {
  isFiltersVisible: boolean;
  onPressFilter(): void;
  onPressShowOpenRequests(): void;
}

const IS_LIST_VIEW_ENABLED = true;

export const Listings: React.FunctionComponent<ListingsProps> = (props) => {
  const { isFiltersVisible, onPressFilter, onPressShowOpenRequests } = props;
  const { listings, loadMore, loadPage, isLoading, totalCount, getRawDataItemById, pagination } = useListings();
  const { filteringOptions } = useFilters();

  const history = useHistory();
  const { modal: placeOrderModal, open: openPlaceOrderModal } = usePlaceOrderModal({ onPressShowOpenRequests });
  const {
    status: { canCreateAuctionV2, canManageCmbBidV2 },
  } = User.useContainer();
  const { marketSettings } = useMarketSettings({});
  const [viewType, setViewType] = useState(ActiveViewType.List);
  const prevViewType = hooks.usePrevious(viewType);
  const { accountUsers } = Account.useContainer();

  const userIds = useMemo(() => {
    return accountUsers.map((user) => user.user_id);
  }, [accountUsers]);
  const canPlaceAnOrder = canManageCmbBidV2() || canCreateAuctionV2();
  const isPlacingOrderDisabled = !marketSettings?.otcEntryEnabled;

  const shouldShowGridView = !IS_LIST_VIEW_ENABLED || viewType === ActiveViewType.Grid;
  const shouldShowListView = IS_LIST_VIEW_ENABLED && viewType === ActiveViewType.List;
  const gridListings = useMemo(
    () => listings.map((item) => toCmbCardProps(toMetaFields(filteringOptions))({ item, userIds })),
    [listings, userIds, filteringOptions],
  );

  useEffect(() => {
    if (viewType !== prevViewType) {
      loadPage(1);
    }
  }, [loadPage, viewType, prevViewType]);

  const onPressPlaceOrder = useCallback(() => {
    history.push('/account/mb-and-auctions/place-order');
  }, [history]);

  const onPressCardCTA = useCallback(
    (item: Omit<CardCMBProps, 'onPressCTA'>) => {
      if (item.isMine) {
        history.push(`/account/mb-and-auctions/${item.id}`);
        return;
      }

      const rawDataItem = getRawDataItemById(item.id);

      if (!rawDataItem) {
        return;
      }

      openPlaceOrderModal({
        orderDetails: toPlaceOrderModalOrderDetails(toMetaFields(filteringOptions))(rawDataItem),
        orderCriteria: rawDataItem.orderCriteria || [],
        projects: rawDataItem.projects,
      });
    },
    [filteringOptions],
  );

  const onPressAction = (actionProps: { listing: FetchListingsResponse['data'][0]; isMine: boolean }) => {
    const { listing, isMine } = actionProps;
    if (isMine) {
      history.push(`/account/mb-and-auctions/${listing.id}`);
      return;
    }

    openPlaceOrderModal({
      orderDetails: toPlaceOrderModalOrderDetails(toMetaFields(filteringOptions))(listing),
      orderCriteria: listing.orderCriteria || [],
      projects: listing.projects,
    });
  };

  return (
    <>
      {placeOrderModal}
      <StyledCard variant={CardVariant.Bordered}>
        <Header>
          <Button
            variant={ButtonVariant.outlined}
            startIcon={isFiltersVisible ? IconName.X : IconName.Filter}
            size={ButtonSize.s}
            onPress={onPressFilter}
          >
            Filter
          </Button>
          <OrderTypeTabs isViewAllEnabled />
          {canPlaceAnOrder && (
            <Button
              isDisabled={isPlacingOrderDisabled}
              startIcon={IconName.PlusCircle}
              size={ButtonSize.s}
              onPress={onPressPlaceOrder}
            >
              Place order
            </Button>
          )}
        </Header>
        <FilterBadges />
        <SubHeader
          isListViewEnabled={IS_LIST_VIEW_ENABLED}
          resultsCount={totalCount}
          activeViewType={viewType}
          onChangeViewType={setViewType}
        />
        {shouldShowGridView && (
          <Grid listings={gridListings} isLoading={isLoading} onPressCardCTA={onPressCardCTA} onLoadMore={loadMore} />
        )}
        {shouldShowListView && (
          <List
            listings={listings}
            isLoading={isLoading}
            currentPage={pagination.currentPage}
            pagesCount={pagination.totalPages}
            onPressAction={onPressAction}
            onChangePage={loadPage}
          />
        )}
      </StyledCard>
    </>
  );
};

const StyledCard = styled(Card)`
  flex-grow: 1;
  align-self: stretch;
  overflow: hidden;

  @media (max-width: 1258px) {
    width: 100%;
    max-height: 100vh;
    align-self: flex-start;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 ${({ theme }) => toSpacing(theme)(8)};
  padding-top: ${({ theme }) => toSpacing(theme)(8)};
`;
