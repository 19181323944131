import * as React from 'react';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { CssTheme } from 'refreshed-component/design-system';
import { RootHolder } from 'refreshed-pages/RootHolder';

import {
  BorderColor,
  TextColor,
  styled,
  toBorderColor,
  toLayerBackground,
  toTextColor,
  useLayerBackground,
} from '@aircarbon/ui';

import { MonkeyTestingModeContext } from 'pages/account/trading/components/MonkeyController';

import { Entity } from 'state/entity';
import { type ScreenSizes, UI } from 'state/ui';

import useQueryParams from 'hooks/useQueryParams';

const Wrapper = styled.div<{ headerSize: string; themeBackground: string }>`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: #000;
  background-color: ${({ themeBackground }) => themeBackground};

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &.link {
      color: ${({ theme }) => toTextColor(theme)(TextColor.info)};
    }
  }

  p {
    margin-bottom: 1rem;
  }

  .ac-input {
    border: 1px solid #ddd;
    background: white;
    padding: 12px;
    height: 38px;
    display: inline-flex;
    width: 100%;
    color: #222;
    border-radius: 5px;

    &:-internal-autofill-selected,
    &:-webkit-autofill:focus,
    &:-webkit-autofill {
      background-color: ${({ theme }) => toLayerBackground(theme)('field')} !important;
      transition:
        background-color 600000s 0s,
        color 600000s 0s;
    }

    &:focus {
      outline: none;
      border-color: ${({ theme }) => toBorderColor(theme)(BorderColor.active)};
    }

    &::placeholder {
      font-weight: 300;
      color: #828282;
    }
  }

  .acx-date-input-sell {
    border: 1px solid #333;
    background: #333;
    padding: 8px;
    height: 30px;
    display: inline-flex;
    width: 100%;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    white-space: pre;

    &:-internal-autofill-selected,
    &:-webkit-autofill:focus,
    &:-webkit-autofill {
      background-color: ${({ theme }) => toLayerBackground(theme)('field')} !important;
      transition:
        background-color 600000s 0s,
        color 600000s 0s;
    }

    &:focus {
      outline: none;
      border-color: red;
    }

    &::placeholder {
      font-weight: 300;
      color: #828282;
    }
  }

  select.ac-input {
    appearance: none;
    padding-right: 16px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: 8px;
      color: black;
      z-index: 9;
    }
  }

  .acx-date-input-buy {
    border: 1px solid #333;
    background: #333;
    padding: 8px;
    height: 30px;
    display: inline-flex;
    width: 100%;
    color: #fff;
    border-radius: 5px;
    font-size: 12px;
    white-space: pre;

    &:-internal-autofill-selected,
    &:-webkit-autofill:focus,
    &:-webkit-autofill {
      background-color: ${({ theme }) => toLayerBackground(theme)('field')} !important;
      transition:
        background-color 600000s 0s,
        color 600000s 0s;
    }

    &:focus {
      outline: none;
      border-color: green;
    }

    &::placeholder {
      font-weight: 300;
      color: #828282;
    }
  }

  select.ac-input {
    appearance: none;
    padding-right: 16px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      right: 8px;
      color: black;
      z-index: 9;
    }
  }

  .content-wrapper {
    background: ${({ theme }) => toLayerBackground(theme)('layer')};
    overflow: hidden;
    position: relative;
    flex-grow: 1;
    margin-top: ${(props) => props.headerSize};
    position: relative;
    z-index: 0;

    & > .container {
      position: relative;
      overflow-y: auto;
      overflow-x: hidden;
      min-height: 600px;
    }

    &.small {
      margin-top: 0px;
    }
  }

  .pageContent {
    display: flex;

    .sidebar {
      padding-left: 2rem;
      min-width: 300px;
      max-width: 300px;

      .section {
        margin-bottom: 4rem;
      }

      .title {
        font-size: 1.6rem;
        font-weight: 700;
        margin-bottom: 2rem;
      }
    }
  }

  .markdown,
  .richtext {
    h1,
    h2,
    h3,
    h4 {
      margin: 2rem 0;
      font-weight: 700;
    }
    h3,
    h4 {
      margin: 2rem 0;
    }
    p {
      margin-bottom: 2rem;
    }
    h2 {
      font-size: 1.4rem;
      color: ${({ theme }) => toTextColor(theme)(TextColor.info)};
    }
    h3 {
      font-size: 1.2rem;
      font-weight: 700;
    }
    h4 {
      font-size: 1.1rem;
      font-weight: 700;
    }
    ul {
      list-style: disc;
      margin-left: 2rem;
    }
    img {
      display: inline-block;
      margin-right: 2rem;
      margin-bottom: 2rem;
    }
  }
`;

type Props = {
  title?: string;
  showHeader?: boolean;
  className?: string;
  enableContentHolder?: boolean;
};

const Layout: React.FC<Props> = ({ children, title = '', className = '', enableContentHolder }) => {
  const { selector } = Entity.useContainer();
  const { setScreenSize, headerSize } = UI.useContainer();
  const [screenSize, setScreenSizeState] = React.useState<ScreenSizes>();
  const wrapperEl = useRef<HTMLDivElement>(null);
  const searchQuery = useQueryParams();

  const [isMonkeyModeActive, setMonkeyModeActive] = React.useState<boolean>(
    searchQuery.get('testing-mode') === 'monkey' ? true : false,
  );
  const [isMonkeyModeVisible, setMonkeyModeVisible] = React.useState<boolean>(
    searchQuery.get('testing-mode') === 'monkey' ? true : false,
  );
  const [monkeySpeed, setMonkeySpeed] = React.useState<number>(1);
  const [monkeyMessage, setMonkeyMessage] = React.useState<string | undefined>();

  const url = window.location.href;
  React.useEffect(() => {
    if (searchQuery.get('testing') === 'monkey-mode') {
      if (!isMonkeyModeActive) {
        setMonkeyModeVisible(true);
      }
    } else if (isMonkeyModeActive === true) {
      setMonkeyModeVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  const checkAndUpdateScreenSize = () => {
    const clientWidth = wrapperEl?.current?.clientWidth ?? 460;
    let detectedScreenSize: ScreenSizes;
    if (clientWidth <= 460) {
      detectedScreenSize = 'small';
    } else if (clientWidth <= 1024) {
      detectedScreenSize = 'medium';
    } else if (clientWidth <= 1280) {
      detectedScreenSize = 'large';
    } else {
      detectedScreenSize = 'xlarge';
    }
    if (screenSize !== detectedScreenSize) setScreenSizeState(detectedScreenSize);
  };

  React.useEffect(() => {
    window.addEventListener('resize', checkAndUpdateScreenSize);
    checkAndUpdateScreenSize();
    return () => {
      window.removeEventListener('resize', checkAndUpdateScreenSize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setScreenSize(screenSize || 'small');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenSize]);

  const { layerBackground } = useLayerBackground();

  return (
    <MonkeyTestingModeContext.Provider
      value={{
        isVisible: isMonkeyModeVisible,
        isActive: isMonkeyModeActive,
        speed: monkeySpeed,
        message: monkeyMessage,
        setSpeed: setMonkeySpeed,
        setActive: setMonkeyModeActive,
        setMessage: setMonkeyMessage,
      }}
    >
      <Wrapper
        style={{
          zIndex: 0,
        }}
        className={className}
        ref={wrapperEl}
        themeBackground={layerBackground('layer')}
        headerSize={headerSize()}
      >
        <Helmet>
          <title>{title}</title>
          <meta charSet="utf-8" />
          <meta name="viewport" content="initial-scale=1.0, width=device-width" />
          {selector.getLinkTags().map((link) => (
            <link key={link.href} rel={link.rel} href={link.href} type={link.type} sizes={link.sizes} />
          ))}
        </Helmet>
        <CssTheme>{enableContentHolder ? <RootHolder /> : <>{children}</>}</CssTheme>
      </Wrapper>
    </MonkeyTestingModeContext.Provider>
  );
};

export default Layout;
