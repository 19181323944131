import { useMemo } from 'react';
import { Table } from 'refreshed-component/templates/Table';
import { useFilters } from 'refreshed-pages/market-board-v2/hooks/useFilters';

import { Pagination, styled, toSpacing } from '@aircarbon/ui';
import { type AssetCategory, assetCategoryNumDecimals, assetCategoryUom } from '@aircarbon/utils-common';

import { Account } from 'state/account';
import { useEntity } from 'state/entity';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import type { FetchListingsResponse } from '../../utils/fetchListings';
import { toTableColumns } from './utils/toTableColumns';
import { toTableRows } from './utils/toTableRows';

export const List: React.FunctionComponent<{
  isLoading?: boolean;
  listings: FetchListingsResponse['data'];
  currentPage: number;
  pagesCount: number;
  onChangePage(newPage: number): void;
  onPressAction(props: { listing: FetchListingsResponse['data'][0]; isMine: boolean }): void;
}> = (props) => {
  const { listings, isLoading, currentPage, pagesCount, onPressAction, onChangePage } = props;
  const { filteringOptions } = useFilters();
  const { selector } = useEntity();
  const { accountUsers } = Account.useContainer();
  const { product } = useMarketplaceProduct();

  const userIds = useMemo(() => {
    return accountUsers.map((user) => user.user_id);
  }, [accountUsers]);
  const uom = assetCategoryUom[Number(product) as AssetCategory];
  const categoryNumDecimals = assetCategoryNumDecimals[Number(product) as AssetCategory];
  const currencyCode = selector.mainCcySymbol;

  return (
    <TableWrapper>
      <Table
        config={{
          loading: isLoading,
          sticky: {
            left: ['id'],
            right: ['actions'],
          },
          columns: {
            id: {
              label: 'ID',
            },
            side: {
              label: 'Side',
            },
            price: {
              label: `Price (${currencyCode})`,
            },
            qty: {
              label: `Qty (${uom})`,
            },
            project: {
              label: 'Project Name',
            },
            contract: {
              label: 'Eligible Contract',
            },
            ...toTableColumns({ filteringOptions }),
            bidVolume: {
              label: 'Bid Volume',
            },
            bestBid: {
              label: 'Best Bid',
            },
            startsAt: {
              label: 'Starts At',
            },
            endsAt: {
              label: 'Ends At',
            },
            actions: {
              label: 'Actions',
            },
          },
          rows: toTableRows({ listings, currencyCode, userIds, categoryNumDecimals, filteringOptions, onPressAction }),
        }}
      />
      <StyledPagination currentPage={currentPage} pagesCount={pagesCount} onChange={onChangePage} />
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  padding: 0 ${({ theme }) => toSpacing(theme)(8)};
  padding-bottom: ${({ theme }) => toSpacing(theme)(8)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => toSpacing(theme)(8)};
  align-items: flex-start;
  overflow-y: auto;
`;

const StyledPagination = styled(Pagination)`
  flex-shrink: 0;
`;
