import { createContext, useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { CardWithGapAndPadding } from 'refreshed-component/atoms/CardWithGapAndPadding';
import { FilterDropdown, FilterSelections, type Filters } from 'refreshed-component/molecules/Filter';
import Loading from 'refreshed-component/molecules/Loading';
import { PageControls } from 'refreshed-component/organisms/PageControls';
import { PageHolder, PageSections } from 'refreshed-component/organisms/PageHolder';
import { SearchProject } from 'refreshed-component/organisms/SearchProject';
import { MarketBoardSummery } from 'refreshed-component/templates/market-board/MarketBoardSummery';
import { MarketPlaceList } from 'refreshed-component/templates/market-board/MarketPlaceList';
import { useProjectsFromUrlQuery } from 'refreshed-pages/hooks/useProjectsFromUrlQuery';

import { Layer, Pagination } from '@aircarbon/ui';
import { AssetCategory, helpers } from '@aircarbon/utils-common';
import type { CmbAsk } from '@aircarbon/utils-common/src/dto';

import { usePagination } from 'pages/account/trading/hooks/usePagination';

import useCarbonMetaOptions from 'hooks/useCarbonMetaOptions';
import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';
import useTokenTypes from 'hooks/useTokenTypes';

import { fetchCMBAsk } from 'data-provider/market-board/fetchCMBAsk';

import { toFilterSelections } from './utils/toFilterSelections';
import { toQueryParams } from './utils/toQueryParams';

const parseSelectMetaOptions = (metaOptions: Record<string, any>) =>
  Object.keys(metaOptions).reduce((selectOptions: Record<string, any>, optionKey: string) => {
    const metaOption = metaOptions[optionKey];
    const years = Array(50)
      .fill(1)
      .map((el, i) => 2000 + i);
    switch (optionKey) {
      case 'projectRatings':
        return {
          ...selectOptions,
          projectRatings: {
            type: 'check-box',
            label: 'Project Rating',
            list: metaOption.map((item: string) => ({
              label: item,
              id: item,
            })),
          },
        };

      case 'tokenTypes':
        return {
          ...selectOptions,
          tokenTypeId: {
            type: 'radio-box',
            label: 'Asset Type',
            list: metaOption.map((item: { fullName: string; name: string; scId: number }) => ({
              label: `${item.fullName} (${item.name})`,
              id: item.scId,
            })),
          },
        };

      case 'registries':
        return {
          ...selectOptions,
          registryIds: {
            type: 'check-box',
            label: 'Carbon Registry',
            list: metaOption.map((item: { registryName: string; id: number }) => ({
              label: item.registryName,
              id: item.id,
            })),
          },
        };

      case 'sdgGoals':
        return {
          ...selectOptions,
          carbonSdgGoalIds: {
            type: 'check-box',
            label: 'SDG Goals',
            list: metaOption.map((item: { sdgGoalId: string; sdgGoalName: string; id: number }) => ({
              label: `${item.sdgGoalId}. ${item.sdgGoalName}`,
              id: item.sdgGoalId,
            })),
          },
        };

      case 'unSectoralScopes':
        return {
          ...selectOptions,
          unSectoralScopeIds: {
            type: 'check-box',
            label: 'UN Sectoral Scope',
            list: metaOption.map((item: { unSectoralScopeId: string; unSectoralScopeName: string; id: number }) => ({
              label: `${item.unSectoralScopeId}. ${item.unSectoralScopeName}`,
              id: item.id,
            })),
          },
        };

      case 'countries':
        return {
          ...selectOptions,
          countryCodes: {
            type: 'check-box',
            label: 'Country',
            list: Object.keys(metaOption).map((countryCode: string) => ({
              label: metaOption[countryCode],
              id: countryCode,
            })),
          },
        };

      case 'vintageYears':
        return {
          ...selectOptions,
          vintageYears: {
            type: 'check-box',
            label: 'Vintage Year',
            list: years?.map((year: number) => ({
              label: year,
              id: year,
            })),
          },
        };

      case 'sdgVerificationLevels':
        return {
          ...selectOptions,
          sdgVerificationLevelIds: {
            type: 'check-box',
            label: 'SDG Verification Level',
            list: metaOption.map((item: { id: number; name: number }) => ({ label: item.name, id: item.id })),
          },
        };

      default:
        return {
          ...selectOptions,
        };
    }
  }, {});

const cmbBidsQueryKey = '/api/user/carbon/my-cmb-ask';

export const CmbBidsQueryKey = createContext(cmbBidsQueryKey);

export const MarketPlace = () => {
  const { search: queryParams } = useLocation();
  const { tokenTypes } = useTokenTypes({ options: { enabled: true } });
  const { product } = useMarketplaceProduct();

  const { carbonMetaOptions } = useCarbonMetaOptions({
    query: {
      assetCategory: AssetCategory[Number(product)],
    },
  });
  const metaFilters: Record<string, any> = useMemo(
    () =>
      parseSelectMetaOptions({
        ...carbonMetaOptions,
        vintageYears: {},
        tokenTypes,
      }),
    [carbonMetaOptions, tokenTypes, product],
  );

  const filters: Filters = metaFilters as never as Filters;
  const [filterSelections, setFilterSelections] = useState<FilterSelections<any> | undefined>({});
  const { projects, setProjects } = useProjectsFromUrlQuery({
    assetCategoryId: product,
  });

  const filterSelectionsFilters = useMemo(() => {
    return {
      ...filters,
      projectIds: {
        type: 'check-box' as const,
        label: 'Projects',
        list: projects,
      },
    };
  }, [filters, projects]);

  const filterSelectionsSelections: FilterSelections<any> | undefined = useMemo(() => {
    return {
      ...filterSelections,
      projectIds: {
        type: 'check-box' as const,
        selection: projects.map((p) => p.id),
      },
    };
  }, [filterSelections, projects]);

  const filterQuery = helpers.objectToQueryString(
    Object.keys({ ...filterSelectionsSelections }).reduce((selectOptions, optionKey: string) => {
      const selection = filterSelectionsSelections?.[optionKey]?.selection;
      if (selection) {
        return { ...selectOptions, [optionKey]: filterSelectionsSelections?.[optionKey]?.selection };
      } else {
        return selectOptions;
      }
    }, {}),
  );

  const pagination = usePagination();
  const getProjectsURL = '/user/carbon/cmb-ask';
  const { data, isLoading } = useQuery(
    [getProjectsURL, filterQuery, product, pagination.page, pagination.pageSize],
    async () =>
      fetchCMBAsk({
        filterQuery,
        assetCategoryId: product,
        page: pagination.page,
        limit: pagination.pageSize,
        isAuction: 'no',
      }),
  );

  useEffect(() => {
    const filtersFromQueryParams = toFilterSelections({
      queryParams,
      filters,
    });

    setFilterSelections(filtersFromQueryParams);
  }, [queryParams, filters]);

  useEffect(() => {
    const updatedQueryParams = toQueryParams({
      filterSelections,
      projects,
      assetCategoryId: product,
    });

    window.history.replaceState(
      undefined,
      '',
      `/account/market-board/marketplace${updatedQueryParams ? `?${updatedQueryParams}` : ''}`,
    );
  }, [projects, filterSelections, product]);

  if (isLoading && data === undefined) {
    return <Loading />;
  }

  const onChangeFilterSelections = (newSelections?: FilterSelections<any> | undefined) => {
    let newProjects: Array<{
      label: string;
      id: number;
    }> = [];
    if (!!newSelections && Object.keys(newSelections).includes('projectIds')) {
      if (newSelections.projectIds?.selection?.length) {
        newProjects = projects.filter((p) => newSelections.projectIds?.selection?.includes(p.id));
      }

      setProjects(newProjects);

      delete newSelections.projectIds;
    }
    setFilterSelections(newSelections);
  };

  const onSelectProject = (project: { id: number; label: string }) => {
    let newProjects = projects;

    if (!newProjects.find((p) => p.id === project.id)) {
      newProjects = [...newProjects, project];
    }
    setProjects(newProjects);
  };

  return (
    <PageHolder>
      <PageSections>
        <MarketBoardSummery />
      </PageSections>
      <Layer>
        <CardWithGapAndPadding>
          <PageControls
            controls={{
              primary: (
                <>
                  <SearchProject onSelectProject={onSelectProject} assetCategoryId={product} />
                  <FilterDropdown
                    selections={filterSelections}
                    onChange={(value) => setFilterSelections(value)}
                    list={filters}
                  />
                </>
              ),
              secondary:
                data?.total > 1 ? (
                  <Pagination
                    currentPage={pagination.page}
                    pagesCount={Math.ceil(data?.total / pagination.pageSize)}
                    onChange={(currentPage) => {
                      pagination.setPage(currentPage);
                    }}
                  />
                ) : undefined,
            }}
          />
          <FilterSelections
            selections={filterSelectionsSelections}
            onChange={onChangeFilterSelections}
            list={filterSelectionsFilters}
          />
          <Layer>
            <MarketPlaceList items={data?.items as CmbAsk[]} isLoading={isLoading} />
          </Layer>
          {/* TODO: better control this scenario. Perhaps telling the Pagination component if location is top or bottom. */}
          {/* If total items outside of view show bottom pagination */}
          {(data?.total ?? 0) > 4 && (
            <PageControls
              controls={{
                secondary:
                  data?.total > 1 ? (
                    <Pagination
                      currentPage={pagination.page}
                      pagesCount={Math.ceil(data?.total / pagination.pageSize)}
                      onChange={(currentPage) => {
                        pagination.setPage(currentPage);
                      }}
                    />
                  ) : undefined,
              }}
            />
          )}
        </CardWithGapAndPadding>
      </Layer>
    </PageHolder>
  );
};
