import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { CreateNewProjectModal } from 'refreshed-pages/market-board-v2/components/CreateNewProjectModal';

import { CMBOrderType } from '@aircarbon/ui';
import { type AssetCategory, type Dto, assetCategoryNumDecimals, assetCategoryUom } from '@aircarbon/utils-common';

import { useEntity } from 'state/entity';

import { useMarketplaceProduct } from 'hooks/useMarketplaceProduct';

import type { Project } from '../../../../utils/Project';
import { SelectProjectAndVintage, type SelectProjectAndVintageRef } from '../../../SelectProjectAndVintage';
import { CommonFormFields, type CommonFormFieldsRef, type CommonFormFieldsValue } from '../CommonFormFields';
import { StyledSectionCard } from '../StyledSectionCard';
import { AuctionFormFields, type AuctionFormFieldsRef } from './components/AuctionFormFields';

export interface AuctionFormValue extends CommonFormFieldsValue {
  project?: Project;
  vintage: string;
  auctionPeriod: string;
  showBidVolume: boolean;
  showBestBid: boolean;
}

export interface AuctionFormRef {
  /**
   * Validates form;
   * Returns `true` if validation failed
   */
  validate(): boolean;
}

interface AuctionFormProps {
  value: AuctionFormValue;
  ownedByUserId: number;
  isEditing?: boolean;
  isInstantTradeHidden?: boolean;
  onChange(value: AuctionFormValue): void;
}

export const AuctionForm = forwardRef<AuctionFormRef, AuctionFormProps>((props, ref) => {
  const { onChange, value, ownedByUserId, isEditing, isInstantTradeHidden } = props;
  const [isCreateNewProjectModalVisible, setIsCreateNewProjectModalVisible] = useState(false);
  const { product } = useMarketplaceProduct();
  const uom = assetCategoryUom[Number(product) as AssetCategory];
  const tokenAssetNumDecimals = assetCategoryNumDecimals[Number(product) as AssetCategory];
  const { selector } = useEntity();

  const currencyCode = selector.mainCcySymbol;

  const onChangeFormValue = (newValue: Partial<AuctionFormValue>) => {
    onChange({
      ...value,
      ...newValue,
    });
  };
  const commonFormFieldsRef = useRef<CommonFormFieldsRef>(null);
  const selectProjectAndVintageRef = useRef<SelectProjectAndVintageRef>(null);
  const auctionFormFieldsRef = useRef<AuctionFormFieldsRef>(null);

  useImperativeHandle(ref, () => ({
    validate() {
      const hasCommonFormFieldsErrors = commonFormFieldsRef.current?.validate();
      const hasSelectProjectAndVintageErrors = selectProjectAndVintageRef.current?.validate();
      const hasAuctionFormFieldsErrors = auctionFormFieldsRef.current?.validate();

      return !!(hasCommonFormFieldsErrors || hasSelectProjectAndVintageErrors || hasAuctionFormFieldsErrors);
    },
  }));

  const onPressCreateNewProject = () => {
    setIsCreateNewProjectModalVisible(true);
  };

  const onCloseCreateNewProjectModal = () => {
    setIsCreateNewProjectModalVisible(false);
  };

  const onNewProjectCreated = (newProject: {
    id: string;
    name: string;
    description: string;
    registryProjectId: string;
    status: Dto.ProjectStatus;
  }) => {
    onChangeFormValue({
      project: {
        title: newProject.name,
        description: newProject.description,
        value: newProject.id,
        registryProjectId: newProject.registryProjectId,
        status: newProject.status,
      },
    });
    setIsCreateNewProjectModalVisible(false);
  };

  return (
    <>
      <CreateNewProjectModal
        isVisible={isCreateNewProjectModalVisible}
        onClose={onCloseCreateNewProjectModal}
        onProjectCreated={onNewProjectCreated}
      />

      <StyledSectionCard>
        <AuctionFormFields ref={auctionFormFieldsRef} value={value} onChange={onChangeFormValue} />
      </StyledSectionCard>
      <StyledSectionCard>
        <SelectProjectAndVintage
          ref={selectProjectAndVintageRef}
          ownedByUserId={ownedByUserId}
          vintage={value.vintage}
          project={value.project}
          onChange={onChangeFormValue}
          onPressCreateNewProject={onPressCreateNewProject}
        />
      </StyledSectionCard>
      <CommonFormFields
        isEditing={isEditing}
        ref={commonFormFieldsRef}
        minimumQuantityLabel="Minimum Bid Qty"
        maximumQuantityLabel="Maximum Bid Qty"
        quantityMultiplesOfLabel="Bid Qty in Multiples of"
        quantityUnit={uom}
        currency={currencyCode}
        value={value}
        orderType={CMBOrderType.Auction}
        onChange={onChangeFormValue}
        balance={value.project?.vintageBalance}
        balancePerAsset={value.project?.vintageBalancePerAsset}
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        isInstantTradeEnabled={value.project?.vintageBalance! > 0}
        isInstantTradeHidden={isInstantTradeHidden}
        tokenAssetNumDecimals={tokenAssetNumDecimals}
      />
    </>
  );
});
