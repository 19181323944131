import { format, getMonth, getYear } from 'date-fns';
import { range } from 'lodash';
import React, { useState } from 'react';
import ReactDatePicker, { type ReactDatePickerProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
  BorderColor,
  Icon,
  IconName,
  Popover,
  Text,
  TextColor,
  TypographyVariant,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
  toTextColor,
} from '@aircarbon/ui';

const CalendarWrapper = styled.div`
  > div {
    width: 100%;
    padding-right: 0 !important;
    display: flex;
    div.react-datepicker {
      background-color: ${({ theme }) => toLayerBackground(theme)('field')};
      color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
      border: none;
      border-radius: 0;
      position: relative;
      height: max-content;
      display: flex;
      flex-direction: row;
    }
    div.react-datepicker__header {
      background-color: ${({ theme }) => toLayerBackground(theme)('field')};
      border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__month-text--keyboard-selected,
    .react-datepicker__quarter-text--keyboard-selected,
    .react-datepicker__year-text--keyboard-selected {
      background-color: ${({ theme }) => toLayerBackground(theme)('fieldAccent')};
      color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    }
    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range,
    .react-datepicker__month-text--selected,
    .react-datepicker__month-text--in-selecting-range,
    .react-datepicker__month-text--in-range,
    .react-datepicker__quarter-text--selected,
    .react-datepicker__quarter-text--in-selecting-range,
    .react-datepicker__quarter-text--in-range,
    .react-datepicker__year-text--selected,
    .react-datepicker__year-text--in-selecting-range,
    .react-datepicker__year-text--in-range {
      background-color: ${({ theme }) => toLayerBackground(theme)('fieldAccent')};
      color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    }
    .react-datepicker__day:hover,
    .react-datepicker__month-text:hover,
    .react-datepicker__quarter-text:hover,
    .react-datepicker__year-text:hover {
      color: ${({ theme }) => toTextColor(theme)(TextColor.inverse)};
      background-color: ${({ theme }) => toLayerBackground(theme)('fieldInverted')};
    }
    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    }
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
      border-top-color: ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    }
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before,
    .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
      border-bottom-color: ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    }
    .react-datepicker__day--disabled,
    .react-datepicker__month-text--disabled,
    .react-datepicker__quarter-text--disabled,
    .react-datepicker__year-text--disabled {
      color: ${({ theme }) => toTextColor(theme)(TextColor.disabled)};
    }
    .react-datepicker__time-container {
      border-left: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    }
    .react-datepicker__time-container .react-datepicker__time {
      background-color: ${({ theme }) => toLayerBackground(theme)('field')};
    }
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--selected {
      color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
      background-color: ${({ theme }) => toLayerBackground(theme)('fieldAccent')};
    }
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item:hover {
      color: ${({ theme }) => toTextColor(theme)(TextColor.inverse)};
      background-color: ${({ theme }) => toLayerBackground(theme)('fieldInverted')};
    }
    .react-datepicker__time-container {
      min-height: 100%;
      display: flex;
      flex-direction: column;
    }
    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list {
      // height: 100%;
    }
    .react-datepicker__triangle {
      display: none;
    }
  }
`;

const Select = styled.select`
  outline: none;
  appearance: none;
  text-align: center;
  background-color: transparent;
`;

const DatePickerFn = <CustomModifierNames extends string = never, WithRange extends boolean | undefined = undefined>(
  props: ReactDatePickerProps<CustomModifierNames, WithRange> & {
    onClear?: () => void;
    onShowChange?: (value: boolean) => void;
  } & {
    ref?: React.RefObject<HTMLButtonElement> | React.ForwardedRef<HTMLButtonElement>;
  },
) => {
  const [isShowing, setShow] = useState(false);
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <Popover
      isVisible={isShowing}
      onHide={() => setShow(false)}
      onApplySize={() => {}}
      value={
        <CalendarWrapper>
          <ReactDatePicker
            {...(props as Omit<typeof props, 'ref'>)}
            renderCustomHeader={({
              date,
              changeYear,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
              prevYearButtonDisabled,
              nextYearButtonDisabled,
              decreaseYear,
              increaseYear,
            }) => (
              <div className="flex flex-row justify-center items-center w-full gap-xs px-xs">
                <div className="flex flex-row flex-1 justify-center items-center w-full gap-xs">
                  <div onClick={prevMonthButtonDisabled ? undefined : decreaseMonth}>
                    <Icon
                      className="cursor-pointer"
                      color={prevMonthButtonDisabled ? TextColor.disabled : TextColor.primary}
                      name={IconName.ChevronStart}
                    />
                  </div>
                  <Text variant={TypographyVariant.body2}>
                    <Select
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                    >
                      {months.map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </Text>
                  <div onClick={nextMonthButtonDisabled ? undefined : increaseMonth}>
                    <Icon
                      className="cursor-pointer"
                      color={nextMonthButtonDisabled ? TextColor.disabled : TextColor.primary}
                      name={IconName.ChevronEnd}
                    />
                  </div>
                  <div onClick={prevYearButtonDisabled ? undefined : decreaseYear}>
                    <Icon
                      className="cursor-pointer"
                      color={prevYearButtonDisabled ? TextColor.disabled : TextColor.primary}
                      name={IconName.ChevronStart}
                    />
                  </div>

                  <Text variant={TypographyVariant.body2}>
                    <Select value={getYear(date)} onChange={({ target: { value } }) => changeYear(Number(value))}>
                      {range(getYear(date || new Date()) - 10, getYear(date || new Date()) + 10, 1).map((option) => (
                        <option key={option} value={option}>
                          {option}
                        </option>
                      ))}
                    </Select>
                  </Text>
                  <div onClick={nextYearButtonDisabled ? undefined : increaseYear}>
                    <Icon
                      className="cursor-pointer"
                      color={nextYearButtonDisabled ? TextColor.disabled : TextColor.primary}
                      name={IconName.ChevronEnd}
                    />
                  </div>
                </div>
              </div>
            )}
            onChange={(...args) => {
              props.onChange?.(...args);
              if (props.showTimeSelect) return;
              const [value] = args;
              if (value && Array.isArray(value)) {
                if (value[0] && value[1]) setShow(false);
              } else if (value) {
                setShow(false);
              }
            }}
            inline
          />
        </CalendarWrapper>
      }
    >
      <StyledDateSelect onClick={() => setShow(true)}>
        <Icon name={IconName.Calendar} size={'1.25rem'} />
        {props.selectsRange ? (
          <>
            {props.startDate || props.endDate ? (
              <Text className="overflow-hidden w-full text-left whitespace-pre overflow-ellipsis">
                {props.startDate ? format(props.startDate, 'MMM d yyyy') : 'Start Date'} -{' '}
                {props.endDate ? format(props.endDate, 'MMM d yyyy') : 'End Date'}
              </Text>
            ) : (
              <Text className="w-full text-left" color={TextColor.placeholder}>
                {props.placeholderText ?? 'Select Start Date - End Date'}
              </Text>
            )}
          </>
        ) : (
          <>
            {props.selected ? (
              <Text className="overflow-hidden w-full text-left whitespace-pre overflow-ellipsis">
                {props.showTimeSelect
                  ? format(props.selected, 'MMM d yyyy hh:mm a')
                  : format(props.selected, 'MMM d yyyy')}
              </Text>
            ) : (
              <Text className="w-full text-left" color={TextColor.placeholder}>
                {props.placeholderText ?? 'Select Date'}
              </Text>
            )}
          </>
        )}
        {isShowing && props.showTimeSelect
          ? (props.startDate || props.endDate || props.selected) && <Icon name={IconName.Check} size={'0.875rem'} />
          : (props.startDate || props.endDate || props.selected) && (
              <div
                onMouseDown={(event) => {
                  event.stopPropagation();
                  event.preventDefault();
                  props.onClear?.();
                }}
              >
                <Icon name={IconName.X} size={'0.875rem'} />
              </div>
            )}
      </StyledDateSelect>
    </Popover>
  );
};

export const DatePicker = React.forwardRef(DatePickerFn) as typeof DatePickerFn;

const StyledDateSelect = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  border-radius: ${({ theme }) => theme.system.border.radius.m};
  box-shadow: inset 0 0 0 1px ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
  padding-inline-start: ${({ theme }) => toSpacing(theme)(8)};
  padding-inline-end: ${({ theme }) => toSpacing(theme)(6)};
  gap: ${({ theme }) => toSpacing(theme)(6)};
  height: 3rem;
  transition: box-shadow 0.15s linear;
  background: ${({ theme }) => toLayerBackground(theme)('field')};
  &:hover {
    background: ${({ theme }) => toLayerBackground(theme)('fieldHover')};
  }
`;
