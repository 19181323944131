import type { CSSProperties } from 'react';

import { formatter } from '@aircarbon/utils-common';

import { formatACXDate } from 'utils/helpers';

import Table from '../components/Table';
import { Wrapper } from '../components/styled';
import type { AssetCategoryCode } from '../layouts/trading.hook';

type Props = {
  trades: any;
  style?: CSSProperties;
  assetCategory?: AssetCategoryCode;
};

const OtherTrades = ({ trades, style }: Props) => {
  const tradeList =
    trades?.total > 0 &&
    trades.items.map((item: any) => {
      const numDecimals = item?.__makerOrder__?.__pair__?.baseAsset?.numDecimals ?? 0;

      return {
        price: formatter.formatNumber(item.price),
        quantity: formatter.formatNumber(item.qty, numDecimals),
        type: item.__tradeType__.typeName,
        time: formatACXDate({ date: item.createdAtUtc, week: false, year: false }),
      };
    });

  return (
    <Wrapper style={style} className="flex flex-col w-full h-full">
      <div className="flex relative flex-row flex-auto h-full">
        <Table
          config={{
            withCustomScrollBar: true,
            columns: {
              price: {
                label: 'Price',
                font: 'code',
              },
              quantity: {
                label: 'Quantity',
                align: 'right',
                font: 'code',
              },
              type: {
                label: 'Type',
                align: 'left',
              },
              time: {
                label: 'Time',
                style: {
                  whiteSpace: 'pre',
                },
              },
            },
            rows: tradeList || [],
          }}
        />
      </div>
    </Wrapper>
  );
};

export default OtherTrades;
