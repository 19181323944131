import type { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import Loading from 'refreshed-component/molecules/Loading';
import styled from 'styled-components';

import {
  Card,
  LabelWithDescription,
  LabelWithDescriptionSize,
  Text,
  TextColor,
  TypographyVariant,
} from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

// Override the border radius of the Card component
const WrapperCard = styled(Card)`
  border-radius: 0 !important;
`;

function NewSettlementRequestPreview({
  totalQty,
  totalAmount,
  feeAmount,
  children,
  currencyUnit,
  tokenUnit,
  tokenNumDecimals,
}: {
  totalQty: number;
  totalAmount: number;
  feeAmount: number;
  currencyUnit: string;
  tokenUnit: string;
  tokenNumDecimals: number;
  children: ReactNode;
}) {
  const element = document.getElementById('sticky-bottom');
  if (!element) {
    return <Loading />;
  }

  return createPortal(
    <WrapperCard className="p-4 w-full rounded-none">
      <div className="flex justify-around">
        <div className="flex gap-4 felx-row">
          <LabelWithDescription
            description={`${formatter.formatNumber(totalQty || 0, tokenNumDecimals)} ${tokenUnit}`}
            label="Asset Quantity"
            size={LabelWithDescriptionSize.s}
          />
          <LabelWithDescription
            description={`${currencyUnit}${formatter.formatNumber(totalAmount)}`}
            label="Total Cash"
            size={LabelWithDescriptionSize.s}
            descriptionSuffix={
              <Text color={TextColor.secondary} variant={TypographyVariant.subtitle2}>
                (fee~{currencyUnit}
                {formatter.formatNumber(feeAmount)})
              </Text>
            }
          />
        </div>
        {children}
      </div>
    </WrapperCard>,
    element,
  );
}

export default NewSettlementRequestPreview;
