import type { SettleEntity } from 'generated';

import { ProgressItemStatus, countries } from '@aircarbon/ui';
import {
  AssetCategory,
  SettleStatus,
  assetCategoryNumDecimals,
  assetCategoryUom,
  formatter,
  logger,
} from '@aircarbon/utils-common';

import { formatDateWithParameter } from 'utils/helpers';

import { carbonList, metaMapping, recList } from '../utils/MetaData';
import { getOrderSide } from '../utils/SettleOrderSide';

export const prepareSettlementCardData = ({
  data,
  userId,
  product,
  mainCcyCode = '$',
}: {
  data?: SettleEntity[];
  userId: number;
  product: string;
  mainCcyCode: string;
}) => {
  if (!data) return [];
  return data.map((item) => {
    const uom = assetCategoryUom[Number(product) as AssetCategory];
    const numDecimals = assetCategoryNumDecimals[Number(product) as AssetCategory];

    const orderSide = getOrderSide({
      currentUserId: userId,
      buyerUserId: item.buyerUserId,
      sellerUserId: item.sellerUserId,
    });

    const prepareMetaFields = (
      meta: { id: number; blockOrderId: number; metaKeyCode: string; metaValue: string }[],
    ) => {
      // Prepare meta fields
      const metaFieldsMap = (Number(product) === AssetCategory.rec ? recList : carbonList).reduce(
        (acc, label) => {
          if (label === 'Contract') {
            acc[label] = { label, value: item?.xPair?.name ?? '-' };
          } else {
            acc[label] = { label, value: '-' };
          }
          return acc;
        },
        {} as { [key: string]: { label: string; value: string } },
      );

      // Set meta values
      const countriesByCode = countries.reduce(
        (curr, country) => ({
          ...curr,
          [country.countryCode]: country.name,
        }),
        {},
      );
      Object.values(meta).forEach((item) => {
        const label = metaMapping[item.metaKeyCode];
        if (label && metaFieldsMap[label]) {
          metaFieldsMap[label].value = label === 'Country' ? (countriesByCode[item.metaValue] ?? '-') : item.metaValue;
        }
      });

      const metaFields = Object.values(metaFieldsMap);
      if (item.createdByUser) {
        metaFields.push({
          label: 'Placed by',
          value: `${item?.createdByUser?.firstName} ${item?.createdByUser?.lastName}`,
        });
      }

      if (item.createdAt) {
        metaFields.push({
          label: 'Created on',
          value: formatDateWithParameter({
            date: item.createdAt,
            minute: false,
            hour: false,
            week: false,
            year: true,
          }),
        });
      }
      return metaFields;
    };

    const registryProjectId = getCriteriaMetaValueBy('PROJECT_ID', item?.criteria ?? [], '');

    return {
      id: item.id.toString(),
      side: orderSide,
      status: item.status,
      price: `${mainCcyCode}${formatter.formatNumber(item.price)}`,
      priceLabel: `Price (${uom})`,
      quantity: formatter.formatNumber(item.quantity, numDecimals),
      quantityLabel: `Qty (${uom})`,
      settlementDate: formatDateWithParameter({
        date: item.settlementDate,
        minute: false,
        hour: false,
        week: false,
        year: true,
      }),
      projectName: '-',
      registryProjectId,
      metaFields: prepareMetaFields(item?.criteria ?? []),
    };
  });
};

// This is mapping between filter on API/UI with Smart contract field
const metaFieldMapping = {
  PROJECT_ID: ['TXT_PROJECT_ID', 'PROJECT_ID'],
  PROJECT_NAME: ['PROJECT_NAME', 'TXT_PROJECT_NAME'],
  REGISTRY_ID: ['REGISTRY_ID', 'TXT_REGISTRY'],
  VINTAGE_YEAR: ['CALCULATED_VINTAGE_YEAR', 'TXT_VINTAGE_YEAR'],
  COUNTRY_CODE: ['LIST_COUNTRY', 'COUNTRY_CODE'],
  REC_TECHNOLOGY: ['REC_TECHNOLOGY', 'TECHNOLOGY'],
} as const;

type CriteriaKey = keyof typeof metaFieldMapping;

/**
 * Get value from Smart contract metadata.
 *
 * @param {string} field
 * @param {Record} metaMapping
 */
export function getProjectMetaBy(field: CriteriaKey, metaMapping: Record<string, string>) {
  const metaKeys = Object.keys(metaMapping);
  const keys = metaFieldMapping[field] as unknown as string[];
  const key = keys.find((key) => metaKeys.includes(key));
  if (!key) {
    logger.warn(`No key found for field ${field}`);
    return '-';
  }

  return metaMapping[key];
}

/**
 * Get criteria from value on api.
 *
 * @param {string} field
 * @param {{ metaKeyCode: string; metaValue: string }[]} criteria
 */
export function getCriteriaMetaValueBy(
  field: CriteriaKey,
  criteria: { metaKeyCode: string; metaValue: string }[] = [],
  defaultValue = '-',
) {
  const meta = criteria.find((c) => c.metaKeyCode === field);
  return meta?.metaValue ?? defaultValue;
}

export const prepareDetailsData = ({
  userId,
  settlement,
  product,
}: {
  userId: number;
  settlement: SettleEntity;
  product: string;
}) => {
  return {
    settlementDate: formatDateWithParameter({
      date: settlement.settlementDate,
      minute: false,
      hour: false,
      week: false,
      year: true,
    }),
    createdOn: formatDateWithParameter({
      date: settlement.createdAt,
      minute: false,
      hour: false,
      week: false,
      year: true,
    }),
    contract: settlement?.xPair?.name,
    productType: AssetCategory[Number(product) as AssetCategory],
    status: settlement.status,
    createdBy: `${settlement?.createdByUser?.firstName} ${settlement?.createdByUser?.lastName}`,
    vintage: getCriteriaMetaValueBy('VINTAGE_YEAR', settlement.criteria),
    projectId: getCriteriaMetaValueBy('PROJECT_ID', settlement.criteria, ''),
    countryCode: getCriteriaMetaValueBy('COUNTRY_CODE', settlement.criteria),
    technology: getCriteriaMetaValueBy('REC_TECHNOLOGY', settlement.criteria),
    registry: getCriteriaMetaValueBy('REGISTRY_ID', settlement.criteria),
    side: getOrderSide({
      currentUserId: userId,
      buyerUserId: settlement.buyerUserId,
      sellerUserId: settlement.sellerUserId,
    }),
  };
};

export const prepareProgressItems = ({
  logs,
  sellerUserId,
  buyerUserId,
  settlementStatus,
  settlementDate,
}: {
  logs?: { status: string; userId: number }[];
  sellerUserId: number;
  buyerUserId: number;
  settlementStatus: string;
  settlementDate: string;
}) => {
  let currentStep = 0;
  const steps = [
    [
      { title: 'Buyer Approves', status: ProgressItemStatus.Pending, statusText: 'Pending' },
      { title: 'Seller Approves', status: ProgressItemStatus.Pending, statusText: 'Pending' },
    ],
    [
      {
        title: 'Instruction Validation',
        description: 'The compliance team will check the instruction',
        status: ProgressItemStatus.Pending,
        statusText: 'Pending',
      },
    ],
    [
      { title: 'Buyer Reserve Fund', status: ProgressItemStatus.Pending, statusText: 'Pending' },
      { title: 'Seller Reserve Asset', status: ProgressItemStatus.Pending, statusText: 'Pending' },
    ],
    [
      {
        title: 'Completed',
        description: `Settlement date on ${formatDateWithParameter({
          date: settlementDate,
          minute: false,
          hour: false,
          week: false,
          year: true,
        })}`,
        status: '',
        statusText: '',
      },
    ],
  ];

  const statusMap: Record<string, Record<string, any>> = {
    APPROVED: {
      [buyerUserId]: steps[0][0], // Buyer Approves
      [sellerUserId]: steps[0][1], // Seller Approves
    },
    VALIDATED: {
      generic: steps[1][0], // Instruction Validation
    },
    RESERVED: {
      [buyerUserId]: steps[2][0], // Buyer Reserve Fund
      [sellerUserId]: steps[2][1], // Seller Reserve Asset
    },
  };

  if (settlementStatus === 'SETTLED') {
    steps.forEach((group) => {
      group.forEach((item) => {
        item.status = ProgressItemStatus.Completed;
        item.statusText = 'Done';
      });
    });
    currentStep = 4;
  } else {
    logs?.forEach((log) => {
      const { status, userId } = log;
      if (statusMap[status]) {
        if (status === 'VALIDATED') {
          const stepItem = statusMap[status]?.generic;
          stepItem.status = ProgressItemStatus.Completed;
          stepItem.statusText = 'Completed';
          currentStep = 2;
        } else if (statusMap[status]?.[userId]) {
          const stepItem = statusMap[status]?.[userId];
          stepItem.status = ProgressItemStatus.Completed;
          stepItem.statusText = 'Completed';
        }
      }
    });
  }

  const statuses = logs?.map((log) => log.status);
  if (currentStep === 0 && !statuses?.includes(SettleStatus.RESERVED)) {
    const approvedCount = statuses?.filter((status) => status === SettleStatus.APPROVED).length;
    currentStep = approvedCount === 2 ? 1 : 0;
  }
  if (statuses?.includes(SettleStatus.RESERVED) && settlementStatus !== 'SETTLED') {
    const reservedCount = statuses?.filter((status) => status === SettleStatus.RESERVED).length;
    currentStep = reservedCount === 2 ? 3 : 2;
  }

  return { currentStep, steps };
};

export const prepareActionsBarData = ({
  settlement,
  currentUserId,
  onPressControl,
  otcMarketSettings,
  isActionLoading,
}: {
  settlement?: SettleEntity;
  currentUserId: number;
  onPressControl?: {
    onPressAccept: () => void;
    onPressReject: () => void;
    onPressReserve: () => void;
  };
  otcMarketSettings: {
    otcEditEnabled: number;
  };
  isActionLoading: boolean;
}) => {
  if (
    !settlement ||
    ([SettleStatus.SETTLED, SettleStatus.REJECTED, SettleStatus.CANCELED, SettleStatus.EXPIRED] as string[]).includes(
      settlement?.status,
    )
  ) {
    return {
      isActionBarVisible: false,
    };
  }

  const isBuyer = settlement.buyerUserId === currentUserId;
  const mySideStatus = isBuyer ? settlement.buyerStatus : settlement.sellerStatus;
  const otherSideStatus = isBuyer ? settlement.sellerStatus : settlement.buyerStatus;

  // User can accept or reject the instruction
  if (mySideStatus === SettleStatus.SUBMITTED && otherSideStatus !== SettleStatus.REJECTED) {
    return {
      label: 'Do you accept the instruction?',
      description: 'Select "Accept" if you wish to continue or "Reject" to cancel the instruction.',
      buttonLabel: 'Accept',
      onPress: onPressControl?.onPressAccept,
      secondaryButtonLabel: 'Reject',
      secondaryOnPress: onPressControl?.onPressReject,
      isButtonDisabled: otcMarketSettings.otcEditEnabled === 0 || isActionLoading,
      isActionBarVisible: true,
    };
  }

  // Ops validate the instruction, user can reserve
  if (settlement.status === SettleStatus.VALIDATED && mySideStatus !== SettleStatus.RESERVED) {
    const isSeller = settlement.sellerUserId === currentUserId;
    return {
      label: isSeller ? 'Reserve the Asset' : 'Reserve the Fund',
      description: `Please reserve ${isSeller ? 'assets' : 'funds'} before the settlement date ${formatDateWithParameter(
        {
          date: settlement.settlementDate,
          minute: false,
          hour: false,
          week: false,
          year: true,
        },
      )} to avoid cancellation`,
      buttonLabel: isSeller ? 'Reserve Asset' : 'Reserve Fund',
      onPress: onPressControl?.onPressReserve,
      isButtonDisabled: otcMarketSettings.otcEditEnabled === 0 || isActionLoading,
      isActionBarVisible: true,
    };
  }

  // Both sides approved, But not validated
  if (otherSideStatus === SettleStatus.APPROVED && mySideStatus === SettleStatus.APPROVED) {
    return {
      label: 'Request Validation...',
      description:
        'Both parties accepted the request, the request is being validated by our compliance team, it takes up to 1 business day',
      isActionBarVisible: true,
    };
  }
  return {
    isActionBarVisible: false,
  };
};
