import { type PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { Const } from '@aircarbon/utils-common';

import { UI } from 'state/ui';

import { BottomTabsContext, bottomTabsContextInitialValue } from './utils/BottomTabsContext';

export const BottomTabsProvider: React.FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
  const [activeTabStoreState, setActiveTabStoreState] = useState({
    activeTabId: bottomTabsContextInitialValue.activeTabId,
    isOpened: bottomTabsContextInitialValue.isOpened,
  });
  const { getSetting } = UI.useContainer();
  const isMarketBoardAndAuctionsV2SettlementModeEnabled =
    getSetting(Const.FeatureToggle.MarketBoardAndAuctionsV2SettlementMode) === '1';

  const updateState = useCallback(
    (
      callback: (prevState: { isOpened: boolean; activeTabId: string }) => { isOpened: boolean; activeTabId: string },
    ) => {
      setActiveTabStoreState(callback);
    },
    [],
  );

  const memoizedContextValue = useMemo(() => {
    return {
      ...activeTabStoreState,
      availableTabs: isMarketBoardAndAuctionsV2SettlementModeEnabled
        ? bottomTabsContextInitialValue.availableTabs.filter((tab) => tab.id !== 'trade-history')
        : bottomTabsContextInitialValue.availableTabs,
      updateState,
    };
  }, [activeTabStoreState, isMarketBoardAndAuctionsV2SettlementModeEnabled, updateState]);
  return <BottomTabsContext.Provider value={memoizedContextValue}>{children}</BottomTabsContext.Provider>;
};
