import { type Dispatch, type SetStateAction, forwardRef, useMemo, useRef, useState } from 'react';
import { DndProvider, useDrag } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  BorderColor,
  Button,
  ButtonSize,
  ButtonVariant,
  Divider,
  Knob,
  Layer,
  Popover,
  TextColor,
  styled,
  toBorderColor,
  toLayerBackground,
  toTextColor,
  useTextColor,
} from '@aircarbon/ui';
import { hooks } from '@aircarbon/utils-common';

import { checkIfNonBilateral } from 'utils/checkIfTokenOrRec';

import TradingHook from './trading.hook';

const { useOnClickOutside } = hooks;

export type TabConfig = {
  key: string;
  label: string;
  droppable?: boolean;
};

export const ListOfTabsToken: TabConfig[] = [
  {
    key: 'order-book',
    label: 'Order Book',
  },
  {
    key: 'markets',
    label: 'Markets',
  },
  {
    key: 'candle-chart',
    label: 'Chart',
  },
  {
    key: 'market-trades',
    label: 'Market Trades',
  },
  {
    key: 'place-order-form',
    label: 'Place Order',
  },
  {
    key: 'order-history',
    label: 'Order History',
  },
  {
    key: 'open-orders',
    label: 'Open Orders',
  },
  {
    key: 'trade-history',
    label: 'Trade History',
  },
  {
    key: 'balance',
    label: 'Balance / P&L',
  },
  {
    key: 'news-feed',
    label: 'News',
  },
  {
    key: 'other-trades',
    label: 'Other Trades',
  },
];

export const ListOfTabsBiofuel: TabConfig[] = [
  {
    key: 'order-book',
    label: 'Order Matching',
  },
  {
    key: 'markets',
    label: 'Markets',
  },
  {
    key: 'candle-chart',
    label: 'Chart',
  },
  {
    key: 'market-trades',
    label: 'Confirmed Matches',
  },
  {
    key: 'place-order-form',
    label: 'Place Order',
  },
  {
    key: 'order-history',
    label: 'Order History',
  },
  {
    key: 'open-orders',
    label: 'Open Orders',
  },
  {
    key: 'trade-history',
    label: 'Match History',
  },
  {
    key: 'balance',
    label: 'Balance / P&L',
  },
  {
    key: 'news-feed',
    label: 'News',
  },
];

export const DropDownStyle = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6px;
  gap: 6px;
  font-size: 12px;
  font-weight: 500;
  box-sizing: border-box;

  > .label {
    color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    padding: 5px;
    background: ${({ theme }) => toLayerBackground(theme)('layer')};
    border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    border-radius: 6px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    > .text {
      flex: auto;
    }
    > .icon {
      flex-direction: row;
      display: flex;
    }
    &:hover {
      cursor: move;
    }
  }
`;

const Label = ({
  tabConfig,
  setDragging,
}: {
  tabConfig: TabConfig;
  setDragging: Dispatch<SetStateAction<boolean>>;
}) => {
  const { update } = useMemo(() => {
    let timer: NodeJS.Timeout;
    let lastUpdated = 0;
    function update(value: boolean) {
      const time = new Date().getTime();
      if (time - lastUpdated >= 100) {
        lastUpdated = time;
        setDragging(value);
      } else {
        clearTimeout(timer);
        timer = setTimeout(() => {
          clearTimeout(timer);
          update(value);
        }, 100);
      }
    }
    return {
      update,
    };
  }, []);

  const [{ opacity }, dragRef] = useDrag(
    () => ({
      type: 'Tab',
      item: { tabConfig },
      collect: (monitor) => {
        if (monitor.isDragging()) update(monitor.isDragging());
        return {
          opacity: monitor.isDragging() ? 0.5 : 1,
        };
      },
      end: () => {
        update(false);
      },
    }),
    [],
  );
  const { textColor } = useTextColor();

  return (
    <div ref={dragRef} style={{ opacity }} key={tabConfig.key} className="label">
      <div className="text">{tabConfig.label}</div>
      <div className="icon">
        <svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="1.5" cy="1.5" r="1.5" fill={textColor(TextColor.primary)} />
          <circle cx="1.5" cy="6.5" r="1.5" fill={textColor(TextColor.primary)} />
          <circle cx="1.5" cy="11.5" r="1.5" fill={textColor(TextColor.primary)} />
          <circle cx="7.5" cy="1.5" r="1.5" fill={textColor(TextColor.primary)} />
          <circle cx="7.5" cy="6.5" r="1.5" fill={textColor(TextColor.primary)} />
          <circle cx="7.5" cy="11.5" r="1.5" fill={textColor(TextColor.primary)} />
        </svg>
      </div>
    </div>
  );
};

const LayoutDropdown = forwardRef<
  HTMLDivElement,
  { onReset: () => void; setDragging: Dispatch<SetStateAction<boolean>> }
>((props, ref) => {
  const {
    states: { assetCategory },
  } = TradingHook();
  const isNonBilateral = checkIfNonBilateral(assetCategory);

  return (
    <DndProvider backend={HTML5Backend}>
      <DropDownStyle ref={ref as any}>
        {(isNonBilateral ? ListOfTabsToken : ListOfTabsBiofuel)
          .filter((item) => item.droppable !== false)
          .map((tabConfig) => (
            <Label setDragging={(value) => props.setDragging(value)} key={tabConfig.key} tabConfig={tabConfig} />
          ))}
        <Divider />
        <Button size={ButtonSize.xs} onPress={() => props.onReset()}>
          Reset Layout
        </Button>
      </DropDownStyle>
    </DndProvider>
  );
});

export const LayoutButton = ({ onReset }: { onReset: () => void }) => {
  const [isLayoutButtonOpen, setLayoutButtonOpen] = useState(false);
  const dropDownRef = useRef<HTMLDivElement>(null);
  const { textColor } = useTextColor();
  useOnClickOutside(dropDownRef, () => {
    setLayoutButtonOpen(false);
  });
  return (
    <>
      <Popover
        isVisible={isLayoutButtonOpen}
        value={
          <Layer initialLayer={1}>
            <LayoutDropdown
              setDragging={(value) => value && setLayoutButtonOpen(!value)}
              onReset={onReset}
              ref={dropDownRef}
            />
          </Layer>
        }
      >
        <Knob
          onPress={() => setLayoutButtonOpen(true)}
          icon={
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5 7H3C2.46957 7 1.96086 6.78929 1.58579 6.41421C1.21071 6.03914 1 5.53043 1 5V3C1 2.46957 1.21071 1.96086 1.58579 1.58579C1.96086 1.21071 2.46957 1 3 1H5C5.53043 1 6.03914 1.21071 6.41421 1.58579C6.78929 1.96086 7 2.46957 7 3V5C7 5.53043 6.78929 6.03914 6.41421 6.41421C6.03914 6.78929 5.53043 7 5 7Z"
                fill={textColor(TextColor.secondary)}
              />
              <path
                d="M15 7H13C12.4696 7 11.9609 6.78929 11.5858 6.41421C11.2107 6.03914 11 5.53043 11 5V3C11 2.46957 11.2107 1.96086 11.5858 1.58579C11.9609 1.21071 12.4696 1 13 1H15C15.5304 1 16.0391 1.21071 16.4142 1.58579C16.7893 1.96086 17 2.46957 17 3V5C17 5.53043 16.7893 6.03914 16.4142 6.41421C16.0391 6.78929 15.5304 7 15 7Z"
                fill={textColor(TextColor.secondary)}
              />
              <path
                d="M5 17H3C2.46957 17 1.96086 16.7893 1.58579 16.4142C1.21071 16.0391 1 15.5304 1 15V13C1 12.4696 1.21071 11.9609 1.58579 11.5858C1.96086 11.2107 2.46957 11 3 11H5C5.53043 11 6.03914 11.2107 6.41421 11.5858C6.78929 11.9609 7 12.4696 7 13V15C7 15.5304 6.78929 16.0391 6.41421 16.4142C6.03914 16.7893 5.53043 17 5 17Z"
                fill={textColor(TextColor.secondary)}
              />
              <path
                d="M14 11V17M11 14H17M3 7H5C5.53043 7 6.03914 6.78929 6.41421 6.41421C6.78929 6.03914 7 5.53043 7 5V3C7 2.46957 6.78929 1.96086 6.41421 1.58579C6.03914 1.21071 5.53043 1 5 1H3C2.46957 1 1.96086 1.21071 1.58579 1.58579C1.21071 1.96086 1 2.46957 1 3V5C1 5.53043 1.21071 6.03914 1.58579 6.41421C1.96086 6.78929 2.46957 7 3 7ZM13 7H15C15.5304 7 16.0391 6.78929 16.4142 6.41421C16.7893 6.03914 17 5.53043 17 5V3C17 2.46957 16.7893 1.96086 16.4142 1.58579C16.0391 1.21071 15.5304 1 15 1H13C12.4696 1 11.9609 1.21071 11.5858 1.58579C11.2107 1.96086 11 2.46957 11 3V5C11 5.53043 11.2107 6.03914 11.5858 6.41421C11.9609 6.78929 12.4696 7 13 7ZM3 17H5C5.53043 17 6.03914 16.7893 6.41421 16.4142C6.78929 16.0391 7 15.5304 7 15V13C7 12.4696 6.78929 11.9609 6.41421 11.5858C6.03914 11.2107 5.53043 11 5 11H3C2.46957 11 1.96086 11.2107 1.58579 11.5858C1.21071 11.9609 1 12.4696 1 13V15C1 15.5304 1.21071 16.0391 1.58579 16.4142C1.96086 16.7893 2.46957 17 3 17Z"
                stroke={textColor(TextColor.secondary)}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          }
          variant={ButtonVariant.ghost}
        />
      </Popover>
    </>
  );
};
