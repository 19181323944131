import { generatePath, useHistory } from 'react-router-dom';
import type { SideBarMenuProps } from 'refreshed-component/organisms/SideBar';

import { Divider, SidebarMenu, styled, toSpacing } from '@aircarbon/ui';

export const MobileMenu: React.FunctionComponent<{
  items: SideBarMenuProps[];
  onChangeRoute(): void;
}> = (props) => {
  const { items, onChangeRoute } = props;
  const history = useHistory();

  return (
    <StyledMobileMenu>
      {items.map((item, index) => {
        if (item.type === 'Separator') {
          return <Divider />;
        }

        const isListItemSelected =
          item.type === 'link'
            ? item.isSelected
            : item.type === 'list' && item.list?.map((item) => item.isSelected).find((isSelected) => isSelected);

        return (
          <>
            <SidebarMenu
              key={index}
              title={item.title}
              icon={item.icon}
              isParent={item.type === 'list'}
              isActive={isListItemSelected && item.type !== 'list'}
              isExpanded={isListItemSelected}
              onPress={() => {
                if (item.type === 'list') {
                  const page = item.list?.[0];
                  if (page?.path) {
                    history.push(generatePath(page.path, page.query));
                  }
                  item.onClickItem?.();
                } else {
                  if (item.path) {
                    history.push(generatePath(item.path, item.query));
                  }
                  item.onClickItem?.();
                }

                onChangeRoute();
              }}
            />
            {item.type === 'list' &&
              item.list &&
              isListItemSelected &&
              item.list.map((listItem, listIndex) => {
                return (
                  <SidebarMenu
                    key={`${index}-${listIndex}`}
                    title={listItem.title}
                    isActive={listItem.isSelected}
                    onPress={() => {
                      if (listItem.path) {
                        history.push(generatePath(listItem.path, listItem.query));
                      }
                      item.onClickItem?.();
                      onChangeRoute();
                    }}
                  />
                );
              })}
          </>
        );
      })}
    </StyledMobileMenu>
  );
};

const StyledMobileMenu = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => toSpacing(theme)(4)};
  gap: ${({ theme }) => toSpacing(theme)(4)};
  padding: 0 ${({ theme }) => toSpacing(theme)(8)};
`;
