import queryString from 'query-string';
import { toCMBOrderType } from 'refreshed-pages/market-board-v2/utils/toCMBOrderType';

import type { CMBOrderType } from '@aircarbon/ui';
import { type AssetCategory, type Dto, type UomCode, assetCategoryUom } from '@aircarbon/utils-common';

import { userAuthenticatedApi } from 'data-provider/fetcher';

interface FetchListingsProps {
  assetCategoryId: string;
  queryParams: object;
}

export interface FetchListingsResponse {
  data: Array<{
    id: string;
    type: CMBOrderType;
    quantity: number;
    quantityUnit: UomCode;
    sideId: number;
    isAuction: number;
    isPrefunded: number;
    price: number;
    priceCurrency: string;
    placedBy: number;
    placedByUser: { firstName: string; lastName: string };
    projectId: number;
    expiryUtc: string;
    userId: number;
    statusId: Dto.OtcOrderStatus;
    bestOffer?: number;
    bidVolume?: number;
    project?: {
      id: number;
      name: string;
      status: Dto.ProjectStatus;
      registryProjectId: string;
      __registry__?: {
        name: string;
      };
    };
    orderCriteria?: Array<{
      id: string;
      key: string;
      value: string;
    }>;
    user?: {
      firstName: string;
      lastName: string;
    };
    projects?: Dto.Project[];
    numNewRequests?: number;
    startAtUtc: string;
    quoteAsset?: {
      name: string;
      code: string;
      numDecimals: number;
      symbol: string;
    };
    baseAsset?: {
      name: string;
      symbol: string;
      numDecimals: number;
    };
    auctionRulesLink?: string;
    minQty?: number;
    maxQty?: number;
    qtyMultipleOf?: number;
  }>;
  currentPage: number;
  totalPages: number;
  totalCount: number;
}

export const fetchListings = async (props: FetchListingsProps): Promise<FetchListingsResponse> => {
  const userService = await userAuthenticatedApi();

  const res = userService
    .url(`/user/otc/order`)
    .query(
      queryString.stringify(
        {
          ...props.queryParams,
          assetCategoryId: props.assetCategoryId,
        },
        {
          arrayFormat: 'bracket',
        },
      ),
    )
    .get();

  const resJson: any = await res.json();

  return {
    data: resJson.data.map((order: any) => ({
      id: order.id,
      type: toCMBOrderType(order),
      quantity: order.qty,
      quantityUnit: assetCategoryUom[Number(props.assetCategoryId) as AssetCategory],
      price: order.price,
      priceCurrency: order.quoteAsset?.name || 'USD',
      baseAsset: order.baseAsset,
      auctionRulesLink: order.auctionRulesLink,
      placedBy: order.placedBy,
      placedByUser: order.placedByUser,
      userId: order.userId,
      project: order.project,
      sideId: order.sideId,
      bestOffer: order.bestOffer,
      bidVolume: order.bidVolume,
      isAuction: order.isAuction,
      statusId: order.statusId,
      isPrefunded: order.isPrefunded,
      orderCriteria: order.criteria.map((orderCriteriaItem: any) => ({
        id: orderCriteriaItem.id,
        key: orderCriteriaItem.key,
        value: orderCriteriaItem.values.join(','),
      })),
      user: order.user,
      projects: order.projects,
      expiryUtc: order.expiryUtc,
      startAtUtc: order.startAtUtc || '',
      numNewRequests: order.numNewRequests,
      quoteAsset: order.quoteAsset,
      qtyMultipleOf: order.qtyMultipleOf,
      maxQty: order.maxQty,
      minQty: order.minQty,
    })),
    totalPages: resJson.totalPages,
    currentPage: resJson.page,
    totalCount: resJson.totalCount,
  };
};
