import { ApolloProvider } from '@apollo/client';
import { lazy, useCallback } from 'react';
import { useQuery } from 'react-query';
import { Route } from 'react-router-dom';
import { AuthWrapper } from 'refreshed-component/molecules/AuthWrapper';
import { RootHolder } from 'refreshed-pages/RootHolder';
import { SignIn } from 'refreshed-pages/authentication/SignIn';

import { Layer, Text, TypographyVariant } from '@aircarbon/ui';
import { MfaType } from '@aircarbon/utils-common';

import Layout from 'components/Layout';
import SystemOffline from 'components/SystemOffline';
import Loading from 'components/styled/Loading';

import { Entity } from 'state/entity';
import { UI } from 'state/ui';
import { User } from 'state/user';

import useQueryParams from 'hooks/useQueryParams';

import { useApollo } from 'utils/apolloClient';
import { isDemoEnv } from 'utils/env';

import { Announcement } from './components/Styled';

const ExternalSellerSignUp = lazy(() => import('pages/ExternalSellerSignUp'));
const ExternalBuyerSignUp = lazy(() => import('pages/ExternalBuyerSignUp'));
const KYC = lazy(() => import('pages/account/components/KYC'));
const VerifyEmail = lazy(() => import('pages/account/components/VerifyEmail'));
const MFA = lazy(() => import('refreshed-pages/authentication/MFA'));
const AcceptTermsConditions = lazy(() => import('pages/account/components/AcceptTermsConditions'));

const AuthHandler = () => {
  return <SignIn />;
};

const Account = () => {
  const {
    user,
    status,
    TCFilename,
    selector: { getUserId, getKycStatus, getUserStatus },
    status: { isOffmarketSeller, isOffmarketBuyer, isOffmarketUser, isLoadingAcceptedTerms },
    isLoadingUser,
    fetchUser,
    refetchTcAccepted,
  } = User.useContainer();

  const { entity } = Entity.useContainer();

  const apolloClient = useApollo();
  const { uiStatus, getSetting } = UI.useContainer();
  const searchQuery = useQueryParams();

  const lgnOverride = searchQuery.get('lgnOverride');

  const { isTotpSetup, hasMfa, TCAccepted, isAccountActive } = status;

  const isMFAAndTermConditionClear = (isTotpSetup() || !hasMfa(MfaType.TOTP)) && TCAccepted();
  const isUserActive = isAccountActive() && (isTotpSetup() || !hasMfa(MfaType.TOTP)) && TCAccepted();

  const syncAccountWithCynopsis = useCallback(async () => {
    const sync = await fetch(`/api/kyc-sync?customer_reference_id=${user?.signInUserSession?.idToken?.payload?.sub}`, {
      method: 'POST',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${user?.signInUserSession?.accessToken?.jwtToken}`,
      },
    });
    if (sync.ok) {
      fetchUser();
    }
  }, [fetchUser, user?.signInUserSession?.idToken?.payload?.sub, user?.signInUserSession?.accessToken?.jwtToken]);

  // NOTE: Sync KYC information
  useQuery([user?.signInUserSession?.accessToken?.jwtToken, getKycStatus()], syncAccountWithCynopsis, {
    enabled: user?.signInUserSession?.accessToken?.jwtToken && isMFAAndTermConditionClear,
    // Only refetch when the status is NEW
    ...(getKycStatus() === 'NEW' ? { refetchInterval: 2000 } : {}),
  });

  // The user flow will be in the sequence
  // Login form -> MFA -> Verify email -> Accept term -> KYC -> Investor flow
  // However, it could turn on or off depend on configuration
  const getContent = () => {
    // show MFA on if has enable
    if (user && !isTotpSetup() && hasMfa(MfaType.TOTP)) {
      return (
        <AuthWrapper notLogged={true}>
          <MFA />
        </AuthWrapper>
      );
    }

    if (isDemoEnv && !status.isAccountActive() && !isOffmarketUser()) {
      return (
        <AuthWrapper>
          <VerifyEmail />
        </AuthWrapper>
      );
    }

    // NOTE: consider to show UI for disabled user if needed
    if (isLoadingAcceptedTerms || status.isAccountDisabled()) {
      return (
        <AuthWrapper>
          <Loading />
        </AuthWrapper>
      );
    }

    if (!TCAccepted() && !isOffmarketBuyer()) {
      return (
        <AcceptTermsConditions
          TCFilename={TCFilename ?? ''}
          type={isOffmarketSeller() ? 'embSeller' : 'client'}
          onSuccess={() => refetchTcAccepted()}
        />
      );
    }

    if (getUserStatus() === 'NEW' && isOffmarketUser())
      return (
        <Announcement>
          <div className="my-8 w-full text-center">
            <Text variant={TypographyVariant.subtitle1} className="mb-4">
              Your account is currently being verified and pending approval. We will send a confirmation email once
              verified.
            </Text>
          </div>
        </Announcement>
      );

    return (
      <ApolloProvider client={apolloClient}>
        <KYC />
      </ApolloProvider>
    );
  };

  // Disables dashboard and log in if DB flag disableDashboard is enabled. It also allows overriding this with a password and user whitelist.
  const loginOverridePwd = getSetting('web_settings_disableDashboardOverridePwd');
  const whitelistedUsers = getSetting('web_settings_disableDashboardOverrideUsers')?.split(',');
  const enableExternalUser = Number(getSetting('web_settings_enabled_externalUser') || 0) === 1;

  const isUserReady = !!user && !!getUserId();
  const isUserWhitelisted = isUserReady && !!whitelistedUsers?.find((id: string) => Number(id) === getUserId());

  if (
    uiStatus.isDashboardDisabled() &&
    ((!isUserReady && lgnOverride !== loginOverridePwd) || (isUserReady && !isUserWhitelisted))
  ) {
    const disableDashboardMsg = getSetting('web_settings_disableDashboardMsg');
    const disableDashboardTitle = getSetting('web_settings_disableDashboardTitle');
    return <SystemOffline title={disableDashboardTitle ?? ''} message={disableDashboardMsg ?? ''} />;
  }

  if (isUserReady) {
    if (isUserActive) {
      return (
        <ApolloProvider client={apolloClient}>
          <Layout title={entity?.displayName} enableContentHolder>
            <RootHolder />
          </Layout>
        </ApolloProvider>
      );
    }
    return (
      <Layout title={entity?.displayName}>
        <Route exact path="/account/activate">
          <VerifyEmail />
        </Route>
        <Route path={['/account']}>{getContent()}</Route>
      </Layout>
    );
  }

  if (!user && uiStatus.isReady() && !isLoadingUser)
    return (
      <Layer>
        <Layout title={entity?.displayName}>
          {enableExternalUser && <Route exact path={['/cmb/seller/sign-up']} component={ExternalSellerSignUp} />}
          {enableExternalUser && <Route exact path={['/auction/buyer/sign-up']} component={ExternalBuyerSignUp} />}
          <Route path="/account/*">
            <AuthHandler />
          </Route>
        </Layout>
      </Layer>
    );

  return <Loading />;
};

export default Account;
