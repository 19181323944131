import type { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';

export const toTableColumns = ({ filteringOptions }: { filteringOptions: OtcCriteria }) => {
  return filteringOptions.reduce((curr, option) => {
    if (option.value === 'COUNTRY_CODE' || option.value === 'VINTAGE_YEAR' || option.options?.length) {
      return {
        ...curr,
        [option.value]: {
          label: option.label,
        },
      };
    }

    return curr;
  }, {});
};
