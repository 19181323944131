import type { CleaveOptions, NumeralThousandsGroupStyleType } from 'cleave.js/options';
import Cleave from 'cleave.js/react';
import React, {
  type InputHTMLAttributes,
  type DetailedHTMLProps,
  useState,
  useRef,
  type RefObject,
  type ReactNode,
} from 'react';
import type { UseFormRegisterReturn } from 'react-hook-form';

import {
  BorderColor,
  TextColor,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
  toTextColor,
} from '@aircarbon/ui';

type InputBase = {
  prefix?: ReactNode;
  postfix?: ReactNode;
  validation?:
    | {
        type: 'cleave';
        option: CleaveOptions;
      }
    | {
        type: 'integer';
        numeralThousandsGroupStyle?: NumeralThousandsGroupStyleType | undefined;
        numeralPositiveOnly?: true;
      }
    | {
        type: 'float';
        numeralThousandsGroupStyle?: NumeralThousandsGroupStyleType | undefined;
        numeralDecimalScale?: number;
        numeralPositiveOnly?: true;
      };
};

type VariantGhost = {
  variant: 'ghost';
  size?: 'base';
};

type VariantIconAndButton = {
  variant?: 'icon' | 'button' | undefined;
  color?: 'primary' | 'secondary' | 'gray' | 'success' | 'warning' | 'error' | 'transparent';
  size?: 'xs' | 'sm' | 'base' | 'l';
};

export type InputConfig = InputBase & (VariantGhost | VariantIconAndButton);

export type InputBaseProps = {
  formRegister?: UseFormRegisterReturn;
  config?: InputConfig;
  ref?: React.RefObject<HTMLInputElement> | React.ForwardedRef<HTMLInputElement>;
  children?: never;
  onEnter?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
};

export type InputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & InputBaseProps;

const InputRoot = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  gap: ${({ theme }) => toSpacing(theme)(6)};
  line-height: 21px;
  border-radius: ${({ theme }) => theme.system.border.radius.m};
  font-weight: normal;
  font-size: 0.875rem;
  cursor: text;
  outline: rgba(0, 0, 0, 0) solid 0px !important;
  transition:
    background-color 200ms ease-in-out,
    border-color 200ms ease-in-out,
    outline-color 200ms ease-in-out;

  > .children {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1 1 0%;
    gap: ${({ theme }) => toSpacing(theme)(6)};
  }

  &.color-primary {
    color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    background: ${({ theme }) => theme.global.colors.primary[700]};
    svg path {
      fill: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    }

    &:hover {
      background: ${({ theme }) => theme.global.colors.primary[800]};
      svg path {
        fill: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
      }
    }

    &:focus,
    &.is-focused-true {
      background: ${({ theme }) => theme.global.colors.primary[700]};
      outline: ${({ theme }) => theme.global.colors.primary[800]} solid 3px !important;
    }
  }

  &.color-gray {
    color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    background: ${({ theme }) => toLayerBackground(theme)('field')};
    &.border-true {
      border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    }

    &:hover {
      background: ${({ theme }) => toLayerBackground(theme)('fieldHover')};
      &.border-true {
        border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
      }
    }

    &:focus,
    &.is-focused-true {
      &.border-true {
        border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.active)};
      }
    }
  }

  &.color-error {
    color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    background: ${({ theme }) => theme.global.colors.neutral[50]};
    &.border-true {
      border: 1px solid ${({ theme }) => theme.global.colors.danger[400]};
    }

    &:hover {
      background: ${({ theme }) => theme.global.colors.danger[100]};
      &.border-true {
        border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.error)};
      }
    }

    &:focus,
    &.is-focused-true {
      background: ${({ theme }) => theme.global.colors.danger[100]};
      &.border-true {
        border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.error)};
      }
    }
  }

  &.color-transparent {
    color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
    background: transparent;

    &:hover {
      background: ${({ theme }) => theme.global.colors.neutral[100]};
    }

    &:focus,
    &.is-focused-true {
      background: ${({ theme }) => theme.global.colors.neutral[200]};
    }
  }

  &.variant-button {
    &.size-xs {
      padding: 8px 12px;
      font-size: 0.75rem;
      font-weight: 500;
    }

    &.size-sm {
      padding: 8px 20px;
      font-size: 1rem;
      font-weight: 500;
    }

    &.size-base {
      padding: 12px 20px;
      font-size: 1rem;
      font-weight: 500;
    }

    &.size-l {
      padding: 14px 24px;
      font-size: 1rem;
      font-weight: 500;
    }
  }

  &.variant-icon {
    &.size-base {
      padding: ${({ theme }) => toSpacing(theme)(4)};
      height: 36px;
      width: 36px;
    }
  }

  > input {
    width: 100%;
    color: inherit;
    background-color: transparent;
    flex: 1 0 0%;
    line-height: 21px;
    outline: none;
    &:-internal-autofill-selected,
    &:-webkit-autofill:focus,
    &:-webkit-autofill {
      transition:
        background-color 600000s 0s,
        color 600000s 0s;
    }
    &:disabled {
      color: ${({ theme }) => toTextColor(theme)(TextColor.disabled)};
      background-color: ${({ theme }) => theme.global.colors.neutral[50]};
    }
  }
`;

export const Input: React.FC<InputProps> = React.forwardRef(
  ({ config, className, onEnter, formRegister, ...props }, forwardRef) => {
    const variant = config?.variant !== 'ghost' ? `variant-${config?.variant || 'button'}` : '';
    const color =
      config?.variant === 'icon' || config?.variant === 'button' || config?.variant === undefined
        ? `color-${'color' in config ? config.color : 'primary'}`
        : '';
    const mixProps = { ...props, ...formRegister };
    const inputInternalRef = useRef<HTMLInputElement>(null);
    const inputRef = (forwardRef || inputInternalRef) as RefObject<HTMLInputElement> | undefined;
    const [isFocused, setFocused] = useState(false);

    const getOption: () => CleaveOptions | undefined = () => {
      if (config?.validation?.type === 'integer') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type, ...rest } = config.validation;
        return {
          numeral: true,
          numeralDecimalScale: 0,
          numeralThousandsGroupStyle: 'none',
          numeralPositiveOnly: true,
          ...rest,
        };
      } else if (config?.validation?.type === 'float') {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { type, ...rest } = config.validation;
        return {
          numeral: true,
          numeralThousandsGroupStyle: 'none',
          numeralPositiveOnly: true,
          ...rest,
        };
      } else if (config?.validation?.type === 'cleave') {
        return config.validation.option;
      }
    };
    return (
      <InputRoot
        className={`${className || ''} is-focused-${isFocused ? 'true' : 'false'} border-true size-${
          config?.size || 'base'
        } ${color} ${variant}`}
        onMouseUp={(event) => {
          const target: HTMLElement = event.target as any;
          const currentTarget: HTMLElement = event.currentTarget as any;
          if (target && target.tagName !== 'input') {
            (currentTarget.children[0] as any).focus();
          }
        }}
      >
        {config?.prefix}
        {getOption() ? (
          <Cleave
            {...(mixProps as any)}
            htmlRef={(inputNode: HTMLInputElement) => {
              formRegister?.ref(inputNode);
              const ref = inputRef as any;
              if (typeof ref === 'function') {
                ref(inputNode);
              } else {
                ref.current = inputNode;
              }
            }}
            onFocus={(event) => {
              setFocused(true);
              return props.onFocus?.(event);
            }}
            onBlur={(event) => {
              setFocused(false);
              return props.onBlur?.(event);
            }}
            onKeyDownCapture={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                onEnter?.(event);
              }
              return props?.onKeyDownCapture?.(event);
            }}
            value={formRegister ? undefined : (props.value ?? '')}
            onChange={formRegister?.onChange || props.onChange}
            options={getOption()}
          />
        ) : (
          <input
            {...(mixProps as any)}
            ref={(inputNode) => {
              formRegister?.ref(inputNode);
              const ref = inputRef as any;
              if (typeof ref === 'function') {
                ref(inputNode);
              } else {
                ref.current = inputNode;
              }
            }}
            onFocus={(event) => {
              setFocused(true);
              return props.onFocus?.(event);
            }}
            onBlur={(event) => {
              setFocused(false);
              return props.onBlur?.(event);
            }}
            onKeyDownCapture={(event: React.KeyboardEvent<HTMLInputElement>) => {
              if (event.key === 'Enter') {
                onEnter?.(event);
              }
              return props?.onKeyDownCapture?.(event);
            }}
            value={formRegister ? undefined : (props.value ?? '')}
            onChange={formRegister?.onChange || props.onChange}
          />
        )}

        {config?.postfix}
      </InputRoot>
    );
  },
);
