import { useCallback, useRef } from 'react';

import {
  CardCMB,
  type CardCMBProps,
  Layer,
  Masonry,
  styled,
  toLayerBackground,
  toSpacing,
  useSpacing,
} from '@aircarbon/ui';

import { UI } from 'state/ui';

import { ListingCard } from '../ListingCard';

export const Grid: React.FunctionComponent<{
  isLoading: boolean;
  listings: Omit<
    CardCMBProps & {
      endsAt?: string;
    },
    'onPressCTA' | 'isCTADisabled'
  >[];
  onPressCardCTA(item: CardCMBProps): void;
  onLoadMore(startIndex: number, stopIndex: number): void;
}> = (props) => {
  const { listings, isLoading, onPressCardCTA, onLoadMore } = props;
  const { screenSize } = UI.useContainer();
  const { spacingInPixels } = useSpacing();
  const listingsContainerRef = useRef(null);

  const renderItemComponent = useCallback(
    ({ item }) => {
      return <ListingCard key={item.id} item={item} onPressCTA={onPressCardCTA} />;
    },
    [onPressCardCTA],
  );

  return (
    <ListingsWithShadow>
      <Layer>
        <ListingsContainer ref={listingsContainerRef}>
          {isLoading && (
            <Masonry
              items={[{ id: '1' }, { id: '2' }, { id: '3' }, { id: '4' }, { id: '5' }, { id: '6' }] as any}
              gap={spacingInPixels(8)}
              minColumnWidth={
                screenSize === 'small' || screenSize === 'medium' ? spacingInPixels(160) : spacingInPixels(200)
              }
              itemComponent={CardCMB.Loader}
              containerRef={listingsContainerRef}
              maxColumnCount={3}
              onLoadMore={onLoadMore}
            />
          )}
          {!isLoading && (
            <Masonry
              items={listings}
              gap={spacingInPixels(8)}
              minColumnWidth={
                screenSize === 'small' || screenSize === 'medium' ? spacingInPixels(160) : spacingInPixels(200)
              }
              itemComponent={renderItemComponent}
              containerRef={listingsContainerRef}
              maxColumnCount={3}
              onLoadMore={onLoadMore}
            />
          )}
        </ListingsContainer>
      </Layer>
    </ListingsWithShadow>
  );
};

const ListingsContainer = styled.div`
  padding: ${({ theme }) => toSpacing(theme)(8)};
  width: 100%;
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
`;

const ListingsWithShadow = styled.div`
  position: relative;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 0%,
      ${({ theme }) => {
        return toLayerBackground({ ...theme, layer: theme.layer })('layer');
      }}
        100%
    );
    height: 0.5rem;
    width: 100%;
  }
`;
