import { formatISO, isAfter, isBefore, isEqual, isValid } from 'date-fns';
import { forwardRef, useImperativeHandle, useState } from 'react';
import { DatePicker } from 'refreshed-component/atoms/DatePicker';
import { FieldsGroup } from 'refreshed-pages/market-board-v2/components/FieldsGroup';
import * as yup from 'yup';

import { Card, CardVariant, Text, TextColor, Toggle, ToggleLayout, TypographyVariant, useSpacing } from '@aircarbon/ui';

import { toRoundedUpTime } from './utils/toRoundedUpTime';

export interface AuctionFormFieldsRef {
  /**
   * Validates the form and returns true if forms has errors
   */
  validate(): boolean;
}

export interface AuctionFormFieldsValue {
  auctionPeriod: string;
  showBidVolume: boolean;
  showBestBid: boolean;
}

const validator = yup.object().shape({
  startsAt: yup

    .date()
    .transform((_, originalValue) => {
      const parsedDate = originalValue ? new Date(originalValue) : undefined;

      return parsedDate;
    })
    .required('Please provide auction starting date and time'),
  endsAt: yup
    .mixed()
    .transform((_, originalValue) => {
      const parsedDate = originalValue ? new Date(originalValue) : undefined;

      return parsedDate;
    })
    .test({
      name: 'validator-time',
      test: function (endTime: Date | null) {
        if (endTime) {
          const { from } = this as any;
          const formValue = from[0].value;
          const startTime: Date | null = formValue.startsAt;

          if (isValid(startTime) && startTime) {
            if (isEqual(endTime, startTime) || isAfter(startTime, endTime) || isBefore(endTime, startTime)) {
              return this.createError({
                message: 'Auction ending time cannot be before starting time',
                path: 'endsAt',
              });
            }
            return true;
          }
          return true;
        }
        return this.createError({
          message: 'Please provide auction ending time',
          path: 'endsAt',
        });
      },
    })
    .required('Please provide auction ending time'),
});

export const AuctionFormFields = forwardRef<
  AuctionFormFieldsRef,
  {
    value: AuctionFormFieldsValue;
    isReadOnly?: boolean;
    onChange(value: Partial<AuctionFormFieldsValue>): void;
  }
>((props, ref) => {
  const { value, isReadOnly, onChange } = props;
  const { spacing } = useSpacing();
  const minDate = new Date();
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [dirtyFields, setDirtyFields] = useState<Record<string, boolean>>({});

  const { auctionPeriod = '' } = value;

  useImperativeHandle(
    ref,
    () => ({
      validate() {
        setDirtyFields({});
        try {
          const [startsAt, endsAt] = auctionPeriod.split(',');
          validator.validateSync(
            {
              startsAt,
              endsAt,
            },
            {
              abortEarly: false,
            },
          );
        } catch (e) {
          setErrors(
            (e as yup.ValidationError).inner.reduce(
              (acc, curr) => ({ ...acc, [curr.path as string]: curr.message }),
              {},
            ),
          );
          return true;
        }

        setErrors({});
        return false;
      },
    }),
    [auctionPeriod],
  );

  const [startDate, endDate] = auctionPeriod.split(',');

  return (
    <>
      <FieldsGroup columnMinWidth="14rem">
        <div className="flex flex-col flex-1 gap-xs">
          <Text variant={TypographyVariant.subtitle2}>Starts at</Text>
          <DatePicker
            showTimeSelect
            disabled={isReadOnly}
            selected={startDate ? new Date(startDate) : null}
            minDate={minDate}
            filterTime={(time) => {
              const selectedDate = new Date(time);

              return minDate.getTime() < selectedDate.getTime();
            }}
            onChange={(date) => {
              if (!date) {
                onChange({
                  auctionPeriod: ['', endDate].join(','),
                });
                return;
              }

              setDirtyFields((prevFields) => ({
                ...prevFields,
                startsAt: true,
              }));

              if (endDate && new Date(endDate) < date) {
                onChange({
                  auctionPeriod: [formatISO(date), ''].filter((v) => !!v).join(','),
                });
                return;
              }

              onChange({
                auctionPeriod: [formatISO(toRoundedUpTime(date)), endDate || ''].filter((v) => !!v).join(','),
              });
            }}
          />
          {!!errors.startsAt && !dirtyFields.startsAt && (
            <Text variant={TypographyVariant.body2} color={TextColor.error}>
              {errors.startsAt}
            </Text>
          )}
        </div>
        <div className="flex flex-col flex-1 gap-xs">
          <Text variant={TypographyVariant.subtitle2}>Ends at</Text>
          <DatePicker
            selected={endDate ? new Date(endDate) : null}
            minDate={startDate ? new Date(startDate) : minDate}
            disabled={isReadOnly}
            filterTime={(time) => {
              const currentMinDate = startDate ? new Date(startDate) : minDate;
              const selectedDate = new Date(time);

              return currentMinDate.getTime() < selectedDate.getTime();
            }}
            showTimeSelect
            onChange={(date) => {
              if (!date) {
                onChange({
                  auctionPeriod: [startDate].join(','),
                });
                return;
              }
              setDirtyFields((prevFields) => ({
                ...prevFields,
                endsAt: true,
              }));
              if (startDate && new Date(startDate).getTime() > date.getTime()) {
                onChange({
                  auctionPeriod: [startDate, startDate].filter((v) => !!v).join(','),
                });
                return;
              }
              onChange({
                auctionPeriod: [startDate, formatISO(date)].filter((v) => !!v).join(','),
              });
            }}
          />
          {!!errors.endsAt && !dirtyFields.endsAt && (
            <Text variant={TypographyVariant.body2} color={TextColor.error}>
              {errors.endsAt}
            </Text>
          )}
        </div>
      </FieldsGroup>
      <FieldsGroup columnMinWidth="14rem">
        <Card variant={CardVariant.Bordered}>
          <Toggle
            isDisabled={isReadOnly}
            label="Show Bid Volume"
            isOn={value.showBidVolume}
            onChange={(isActive) =>
              onChange({
                showBidVolume: isActive,
              })
            }
            layout={ToggleLayout.LabelFirst}
            margin={spacing(8)}
          />
        </Card>
        <Card variant={CardVariant.Bordered}>
          <Toggle
            label="Show Best Bid"
            isDisabled={isReadOnly}
            isOn={value.showBestBid}
            layout={ToggleLayout.LabelFirst}
            margin={spacing(8)}
            onChange={(isActive) =>
              onChange({
                showBestBid: isActive,
              })
            }
          />
        </Card>
      </FieldsGroup>
    </>
  );
});
