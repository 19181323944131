import type { ReservedAsset } from './asset';
import type { Asset, Pair } from './pair';
import type { Project } from './project';
import type { Transaction } from './transaction';
import type { User } from './user';

export interface OrderSide {
  id: number;
  name: string;
  fixCode: string | null;
  enabled: number;
}

export interface Order {
  /**
   * The unique system ID of the order
   */
  id: string;
  /**
   * The unique system ID of the pair being traded
   */
  pairId: number;
  /**
   * The unique system ID of the side of the trade
   */
  sideId: number;
  /**
   * The unique system ID of the time in force type
   */
  timeInForceTypeId: number;
  /**
   * The unique system ID of the order type
   */
  typeId: number;
  /**
   * The price denominated in the quote currency of the pair referenced in paidId
   */
  price: number | null;
  /**
   * The quantity denominated in the base currency of the pair referenced in pairId
   */
  qty: number;
  /**
   * The time at which the order was created in the system
   */
  statusId: number;
  sourceId: number;
  createdAtUtc: Date | string;
  expiryUtc: Date | string | null;
  lastActionAtUtc: Date | string;
  fixClientOrderId: string | null;
  userId: number;
  user: User;
  processed: number;
  orderSide: OrderSide;
  pair: Pair;
  biofuelDetails?: OrderBiofuelDetails | null;
}

export interface TradeRequestCounterOffer {
  id: number;
  quantity: number;
  price: number;
  tradeRequest: TradeRequestDto;
  tradeRequestId: string;
  user: User;
  userId: number;
  placedByUser: User;
  placedBy: number;
  createdAtUtc: Date;
}

/**
 * The Trade Request interface.
 */
export interface TradeRequestDto {
  id: string;
  createdBy: number;
  brokerUserId: number | null;
  buyerUserId: number;
  sellerUserId: number;
  externalBuyerId?: number | null;
  externalSellerId?: number | null;
  baseAssetId: number;
  quoteAssetId: number;
  quantity: number;
  price: number;
  status: string;
  buyerTradeFee: number;
  sellerTradeFee: number;
  buyerArrangerFeeAmount: number;
  sellerArrangerFeeAmount: number;
  buyerConfirmationStatus: string;
  sellerConfirmationStatus: string;
  sellerAccount: string | null;
  buyerAccount: string | null;
  cancelReasonId: number | null;
  cancelTransactionId: number | null;
  baseAsset?: Asset;
  quoteAsset?: Asset;
  tradeRequestDetail: {
    carbonProjectId?: number | null;
    projectId?: number | null;
    carbonProjectVintageYear?: number | null;
    __project__: Project;
  };
  bfMakerOrder?: Order;
  bfTakerOrder?: Order;
  sellerUser?: User;
  buyerUser?: User;
  __sellerUser__?: User;
  __buyerUser__?: User;
  __createdByUser__?: User;
  createdByUser?: User;
  otcOrderId: number;
  counterOffers: TradeRequestCounterOffer[];
  __reservedAssets__?: ReservedAsset;
  reservedAssetQty?: number;
  reservedFiatAmount?: number;
  isPrefunded?: number;
  otcOrder?: {
    expiryUtc: Date;
    filledQty: number;
    id: number;
    isAuction: number;
    isPrefunded: number;
    qty: number;
    sideId: number;
    userId: number;
  };
  comment: string;
}

export interface Trade {
  id: string;
  price: number;
  createdAtUtc: Date;
  transactionId: number | null;
  makerTradeFee: number;
  takerTradeFee: number;
  makerOrder?: Order;
  makerOrderId: number | null;
  legacyHxNumber: string | null;
  takerOrder?: Order;
  takerOrderId: number | null;
  transaction: Transaction;
  status: string;
  sellerEntityId: number | null;
  buyerEntityId: number | null;
  tradeTypeId: number | null;
  sellerUserId: number;
  buyerUserId: number;
  sellerTradeFee: number;
  buyerTradeFee: number;
  tradeType: string;
  pairId: number | null;
  pair: Pair;
  replacingTradeId: number | null;
  sellerUser?: User;
  buyerUser?: User;
}

export interface OrderBiofuelDetails {
  id: string;
  orderId: string;
  fobId?: number | null;
  shippingId?: number | null;
  counterpartyUserId?: number | null;
  clientId?: number | null;
  fobType: string;
  shippingType: string;
  counterpartyUser: User;
  externalBrokerClient: ExternalBrokerClient;
}

export type ExternalBrokerClient = {
  id: number;
  brokerUserId: number;
  name: string;
  address?: string;
  country?: string;
  email: string;
  phone?: string;
  contactPersonName: string;
  status: string;
  createdAtUtc: Date | null;
  enabled: number;
};

export interface BlockTradeRequest {
  id: number;
  quantity: number;
  price: number;
  status: string;
  comment: string;
  sellerUserId: number;
  buyerUserId: number;
  createdBy: number;
  reviewedBy: number;
  approvedBy: number;
  executedBy: number;
  cancelledBy: number;
  createdAtUtc: Date | string;
  reviewedAtUtc: Date | string;
  approvedAtUtc: Date | string;
  executedAtUtc: Date | string;
  cancelledAtUtc: Date | string;
  requestToPartiesSentAtUtc: Date | string;
  confirmedByBuyerAtUtc: Date | string;
  confirmedBySellerAtUtc: Date | string;
  tokenAssetId: number;
  transactionId: number;
  currencyAssetId: number;
  fromAccount: string;
  toAccount: string;
  fromFee: number;
  toFee: number;
  stIds: string;
  approvedByUser: User;
  buyerUser: User | null;
  cancelledByUser: User;
  createdByUser: User;
  currencyAsset: Asset;
  executedByUser: User;
  fromAccountAddress: BlockTradeRequestAccount;
  reviewedByUser: User;
  sellerUser: User | null;
  toAccountAddress: BlockTradeRequestAccount;
  tokenAsset: Asset;
  transaction: Transaction;
}

export interface Transfer {
  id: number;
  fromAccount: string;
  toAccount: string;
  sellerUserId: number;
  buyerUserId: number;
  createdBy: number;
  reviewedBy: number;
  approvedBy: number;
  executedBy: number;
  cancelledBy: number;
  transferTypeId: number;
  createdAtUtc: Date | string;
  approvedAtUtc: Date | string;
  reviewedAtUtc: Date | string;
  executedAtUtc: Date | string;
  cancelledAtUtc: Date | string;
  assetId: number;
  transferValue: number;
  status: string;
  type: string;
  stIds: string;
  transactionId: number;
  comment: string;
  approvedByUser: User;
  buyerUser: User | null;
  cancelledByUser: User;
  createdByUser: User;
  executedByUser: User;
  fromAccountAddress: BlockTradeRequestAccount;
  reviewedByUser: User;
  sellerUser: User | null;
  toAccountAddress: BlockTradeRequestAccount;
  asset: Asset;
  transaction: Transaction;
  transferType: string;
}

export interface BlockTradeRequestAccount {
  name: string;
}

export interface FobType {
  id: number;
  name: string;
}

export interface ExternalMarketAsset {
  name: string;
  id: number;
  symbol: string;
  price: number;
}

export interface TradeAdjustment {
  xTradeId: number | null;

  comments: string | null;

  createdBy: number | null;

  approvedBy: number | null;

  createdAtUtc: Date | null;

  reverseReason: string | null;

  approvedAtUtc: Date | null;

  rejectedBy: number | null;
  rejectedAtUtc: Date | null;
  adjustmentType: string | null;

  adjustmentPrice: number | null;
  __xTradeAdjustmentTransactions__: {
    __transaction__: Transaction;
  }[];
}

export interface UserSpotTradeResponse {
  // TODO: Add types
  items: any[];
  total: number;
  totalPage: number;
}

export type TradeItem = {
  transaction: any;
  sellerUserId: any;
  sellerUser: { parentId: any; firstName: any; lastName: any };
  buyerUser: { firstName: any; lastName: any };
  tradeTypeId: number;
  sellerTradeFee: number;
  buyerTradeFee: number;
  makerTradeFee: number;
  takerTradeFee: number;
  statusId: number;
  transactionId: number;
  makerOrder?: {
    accountAddress: string;
    id: string;
    price: number;
    qty: number;
    tradeFee: number;
    userId: number;
    user: {
      email: string;
      firstName: string;
      id: number;
      lastName: string;
      parent: any;
    };
    placedByUser: any;
  };
  takerOrder?: {
    accountAddress: string;
    id: string;
    price: number;
    qty: number;
    tradeFee: number;
    userId: number;
    user: {
      email: string;
      firstName: string;
      id: number;
      lastName: string;
      parent: any;
    };
    placedByUser: any;
  };
  id: number;
  createdAtUtc: string;
  pair: {
    baseAssetId: number;
    quoteAssetId: number;
    name: string;
  };
  price: number;
  qty: number;
  status: string;
  tradeType: string;
  uom: string;
  vintages: number[];
};

export interface UserTradeResponse {
  trades: TradeItem[];
  total: number;
  totalPage: number;
}

export enum TradeType {
  AssetTraded = 'TRADE',
  PhysicalDelivery = 'DELIVERY',
  Retirement = 'RETIREMENT',
  Mint = 'MINT',
}
