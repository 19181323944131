import { format } from 'date-fns';
import type { OtcCriteria } from 'refreshed-pages/market-board-v2/utils/OtcCriteria';

import { CMBOrderType, TextColor, countries } from '@aircarbon/ui';
import { Dto, formatter } from '@aircarbon/utils-common';

import type { ReviewOrderModalData } from '../../ReviewOrderModal';
import type { AuctionFormValue } from '../components/AuctionForm';
import type { BidFormValue } from '../components/BidForm';
import type { OfferFormValue } from '../components/OfferForm';
import type { FormValue } from './FormValue';

const { formatPrice, formatNumber } = formatter;

interface ToReviewOrderModalDataProps<T extends CMBOrderType> {
  formValue: FormValue<T>;
  orderType: T;
  placedBy: string;
  onBehalfOf: string;
  fee: number;
  total: number;
  currency: string;
  quantityUnit: string;
  quantityDecimals: number;
  projectMeta: OtcCriteria;
  isOtcSettlementEnabled: boolean;
}

const orderTypeToSideMap: Record<
  CMBOrderType,
  {
    value: string;
    valueColor: TextColor;
  }
> = {
  [CMBOrderType.Auction]: {
    value: 'Auction',
    valueColor: TextColor.warning,
  },
  [CMBOrderType.Bid]: {
    value: 'Bid',
    valueColor: TextColor.success,
  },
  [CMBOrderType.Offer]: {
    value: 'Offer',
    valueColor: TextColor.error,
  },
};

interface SummaryItem {
  label: string;
  value: string;
  valueColor?: TextColor;
}

const toReviewOrderModalDataFromCriteria = (projectMeta: OtcCriteria) => {
  const projectMetaByKey = new Map<
    string,
    {
      value: string;
      label: string;
      options: Map<
        string,
        {
          label: string;
          value: string;
        }
      >;
    }
  >();

  projectMeta.forEach((metaItem) => {
    if (!metaItem.options?.length) {
      return;
    }

    const options = new Map();

    metaItem.options.forEach((option) => {
      options.set(option.value, option);
    });

    projectMetaByKey.set(metaItem.value, {
      label: metaItem.label,
      value: metaItem.value,
      options,
    });
  });

  return (criteria: BidFormValue['criteria'], summary: Array<SummaryItem>) => {
    const criteriaByKey = new Map<string, { key: string; value: string }>();

    criteria.forEach((item) => {
      criteriaByKey.set(item.key, item);
    });

    projectMeta.forEach((metaItem) => {
      const selectedCriteria = criteriaByKey.get(metaItem.value);
      const availableProjectMeta = projectMetaByKey.get(metaItem.value);
      if (!selectedCriteria) {
        return;
      }

      const summaryValue = selectedCriteria.value
        .split(',')
        .map((valueItem) =>
          selectedCriteria.key === Dto.ProjectMetaKeyCode.CountryCode
            ? (countries.find((c) => c.countryCode === valueItem)?.name ?? valueItem)
            : (availableProjectMeta?.options?.get(valueItem)?.label ?? valueItem),
        )
        .join(', ');

      summary.push({
        label: metaItem.label,
        value: summaryValue || '',
      });
    });

    return summary;
  };
};

const fillSummaryWithProjects = (projects: BidFormValue['projects'] = [], summary: Array<SummaryItem>) => {
  if (projects.length) {
    summary.push({
      label: projects.length > 1 ? 'Projects' : 'Project',
      value: projects
        .map((project) => [project.registryProjectId, project.title].filter((v) => !!v).join(' | '))
        .join(', '),
    });
  }

  return summary;
};

export const toReviewOrderModalData = <T extends CMBOrderType>(
  props: ToReviewOrderModalDataProps<T>,
): ReviewOrderModalData => {
  const {
    orderType,
    formValue,
    fee,
    total,
    placedBy,
    onBehalfOf,
    currency,
    quantityUnit,
    projectMeta,
    isOtcSettlementEnabled,
    quantityDecimals,
  } = props;

  let summary: Array<{
    label: string;
    value: string;
    valueColor?: TextColor;
  }> = [
    {
      label: 'Side',
      value: orderTypeToSideMap[orderType].value,
      valueColor: orderTypeToSideMap[orderType].valueColor,
    },
  ];

  const fillSummaryWithCriteria = toReviewOrderModalDataFromCriteria(projectMeta);

  if (orderType === CMBOrderType.Bid) {
    summary = fillSummaryWithProjects((formValue as BidFormValue).projects, summary);
    summary = fillSummaryWithCriteria((formValue as BidFormValue).criteria, summary);
  }

  if (orderType === CMBOrderType.Auction) {
    const auctionFormValue = formValue as AuctionFormValue;
    const [startDate, endDate] = auctionFormValue.auctionPeriod.split(',');

    summary = [
      ...summary,
      {
        label: 'Project',
        value:
          [auctionFormValue.project?.registryProjectId, auctionFormValue.project?.title]
            .filter((v) => !!v)
            .join(' | ') ?? '',
      },
      {
        label: 'Vintage',
        value: auctionFormValue.vintage,
      },
      {
        label: 'Auction Period',
        value: `${format(new Date(startDate), "dd/MM/yyyy 'at' p")} ~ ${format(new Date(endDate), "dd/MM/yyyy 'at' p")}`,
      },
      {
        label: 'Show Bid Volume',
        value: auctionFormValue.showBidVolume ? 'Yes' : 'No',
      },
      {
        label: 'Show Best Bid',
        value: auctionFormValue.showBestBid ? 'Yes' : 'No',
      },
    ];
  }

  if (orderType === CMBOrderType.Offer) {
    if ((formValue as OfferFormValue).project) {
      summary = fillSummaryWithProjects([(formValue as OfferFormValue).project], summary);
      summary = [
        ...summary,
        {
          label: 'Vintage',
          value: (formValue as OfferFormValue).vintage,
        },
      ];
    }

    summary = fillSummaryWithCriteria((formValue as OfferFormValue).criteria, summary);
  }

  summary = [
    ...summary,
    ...(orderType !== CMBOrderType.Auction && !isOtcSettlementEnabled
      ? [
          {
            label: 'Assets Reserved',
            value: formValue.isInstantTrade ? 'Yes (Assets will be reserved)' : 'No',
          },
        ]
      : []),
    ...(orderType === CMBOrderType.Offer &&
    formValue.baseAssetId &&
    (formValue as OfferFormValue).project?.vintageBalancePerAsset
      ? [
          {
            label: 'Contract',
            value:
              Object.keys((formValue as OfferFormValue).project?.vintageBalancePerAsset ?? {}).find(
                (v) =>
                  (formValue as OfferFormValue).project?.vintageBalancePerAsset?.[v].assetId ===
                  Number(formValue.baseAssetId),
              ) ?? '',
          },
        ]
      : []),
    ...(formValue.minimumQuantity
      ? [
          {
            label: orderType === CMBOrderType.Bid ? 'Minimum Offer Quantity' : 'Minimum Bid Quantity',
            value: formatNumber(Number(formValue.minimumQuantity), quantityDecimals),
          },
        ]
      : ([] as typeof summary)),
    ...(formValue.maximumQuantity
      ? [
          {
            label: orderType === CMBOrderType.Bid ? 'Maximum Offer Quantity' : 'Maximum Bid Quantity',
            value: formatNumber(Number(formValue.maximumQuantity), quantityDecimals),
          },
        ]
      : ([] as typeof summary)),
    ...(formValue.quantityMultiplesOf
      ? [
          {
            label: orderType === CMBOrderType.Bid ? 'Offer Qty in Multiples of' : 'Bid Qty in Multiples of',
            value: formatNumber(Number(formValue.quantityMultiplesOf), quantityDecimals),
          },
        ]
      : ([] as typeof summary)),
  ];

  return {
    summary,
    price: formatPrice(Number(formValue.price), currency),
    quantity: formatNumber(Number(formValue.quantity), quantityDecimals) + ' ' + quantityUnit,
    fee: formatPrice(Number(fee), currency),
    total: formatPrice(Number(total), currency),
    placedBy,
    onBehalfOf: onBehalfOf ?? placedBy,
  };
};
