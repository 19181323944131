import { type PropsWithChildren, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useQuery } from 'react-query';

import { type DepositRequestsFilter, ToastVariant, showToast } from '@aircarbon/ui';
import { Dto } from '@aircarbon/utils-common';

import useDebounce from 'hooks/useDebounce';

import {
  DepositRequestsContext,
  type DepositRequestsContextValueWithoutActions,
} from '../../utils/DepositRequestsContext';
import type { DepositRequestsPagination } from '../../utils/DepositRequestsPagination';
import { approveDepositRequest } from '../../utils/approveDepositRequest';
import { depositRequestsReducer } from '../../utils/depositRequestsReducer';
import { fetchDepositRequests } from '../../utils/fetchDepositRequests';
import { rejectDepositRequest } from '../../utils/rejectDepositRequest';

const defaultContextValue: DepositRequestsContextValueWithoutActions = {
  alerts: [],
  search: '',
  depositRequests: [],
  filters: [
    {
      label: 'Status',
      id: 'statuses',
      type: 'checkboxes',
      items: [
        {
          label: 'New',
          value: Dto.AssetDepositStatus.New,
        },
        {
          label: 'Approved',
          value: Dto.AssetDepositStatus.Approved,
        },
        {
          label: 'Rejected',
          value: Dto.AssetDepositStatus.Rejected,
        },
        {
          label: 'User Confirmed',
          value: Dto.AssetDepositStatus.ConfirmedByUser,
        },
        {
          label: 'User Rejected',
          value: Dto.AssetDepositStatus.RejectedByUser,
        },
        {
          label: 'Completed',
          value: Dto.AssetDepositStatus.Completed,
        },
        {
          label: 'Failed',
          value: Dto.AssetDepositStatus.Failed,
        },
      ],
      selectedItems: [],
    },
    {
      label: 'Date Range',
      id: 'dateRange',
      type: 'date-range',
      value: '',
    },
  ],
  isLoadingDepositRequests: true,
  pagination: {
    currentPage: 1,
    pagesCount: 1,
    itemsCount: '0',
    limit: 5,
    limitOptions: [5, 10, 20, 50, 100],
  },
  modalApproveDepositRequestProps: {
    isVisible: false,
    isConfirming: false,
    requestId: '',
    serialBlock: '',
  },
  modalRejectDepositRequestProps: {
    requestId: '123',
    rejectionReason: '',
    isVisible: false,
    isRejecting: false,
  },
};

export const DepositRequestsContextProvider: React.FunctionComponent<PropsWithChildren<object>> = ({ children }) => {
  const [state, dispatch] = useReducer(depositRequestsReducer, defaultContextValue);
  const debouncedSearch = useDebounce(state.search, 500);

  const { data, isLoading } = useQuery(
    ['deposit-requests', state.pagination.currentPage, state.pagination.limit, state.filters, debouncedSearch],
    () =>
      fetchDepositRequests({
        search: debouncedSearch,
        filters: state.filters.reduce(
          (acc, filter) => ({
            ...acc,
            [filter.id]: filter.type === 'checkboxes' ? filter.selectedItems : filter.value,
          }),
          {} as any,
        ),
        pagination: {
          page: state.pagination.currentPage,
          limit: state.pagination.limit,
        },
      }),
  );

  useEffect(() => {
    dispatch({
      type: 'fetching-requests',
      payload: isLoading,
    });
  }, [isLoading]);

  useEffect(() => {
    if (!data?.data) {
      return;
    }

    dispatch({
      type: 'set-deposit-requests',
      payload: data.data.data,
    });
    dispatch({
      type: 'change-pagination',
      payload: {
        pagesCount: data.data.totalPages,
        itemsCount: String(data.data.totalCount),
      },
    });
  }, [data, dispatch]);

  const changeSearch = useCallback(
    (search: string) => {
      dispatch({
        type: 'change-search',
        payload: search,
      });
    },
    [dispatch],
  );

  const changePagination = useCallback(
    (pagination: Partial<DepositRequestsPagination>) => {
      dispatch({
        type: 'change-pagination',
        payload: pagination,
      });
    },
    [dispatch],
  );

  const changeFilter = useCallback(
    (filter: DepositRequestsFilter) => {
      dispatch({
        type: 'change-filter',
        payload: filter,
      });
    },
    [dispatch],
  );

  const changeRejectionReason = useCallback(
    (rejectionReason: string) => {
      dispatch({
        type: 'change-rejection-reason',
        payload: rejectionReason,
      });
    },
    [dispatch],
  );

  const showApproveModal = useCallback(
    (requestId: string) => {
      dispatch({
        type: 'show-approve-modal',
        payload: requestId,
      });
    },
    [dispatch],
  );

  const hideApproveModal = useCallback(() => {
    dispatch({
      type: 'hide-approve-modal',
    });
  }, [dispatch]);

  const showRejectModal = useCallback(
    (requestId: string) => {
      dispatch({
        type: 'show-reject-modal',
        payload: requestId,
      });
    },
    [dispatch],
  );

  const hideRejectModal = useCallback(() => {
    dispatch({
      type: 'hide-reject-modal',
    });
  }, [dispatch]);

  const approveRequest = useCallback(async () => {
    dispatch({
      type: 'start-approving-request',
    });

    try {
      const request = state.depositRequests.find(
        (req) => req.id === Number(state.modalApproveDepositRequestProps.requestId),
      );

      if (!request) {
        throw new Error('Invalid request!');
      }

      await approveDepositRequest({ requestId: String(request.id) });
      showToast({
        variant: ToastVariant.Success,
        message: `Successfully approved deposit request #${request.id}!`,
      });
      dispatch({
        type: 'hide-approve-modal',
      });

      dispatch({
        type: 'update-deposit-request',
        payload: {
          id: request.id,
          status: Dto.AssetDepositStatus.ConfirmedByUser,
        },
      });
    } catch {
      showToast({
        variant: ToastVariant.Danger,
        message: `Failed to approve deposit request #${state.modalApproveDepositRequestProps.requestId}! Please try again later`,
      });
    } finally {
      dispatch({
        type: 'finish-approving-request',
      });
    }
  }, [state.modalApproveDepositRequestProps.requestId, state.depositRequests]);

  const rejectRequest = useCallback(async () => {
    dispatch({
      type: 'start-rejecting-request',
    });
    try {
      const request = state.depositRequests.find(
        (req) => req.id === Number(state.modalRejectDepositRequestProps.requestId),
      );

      if (!request) {
        throw new Error('Invalid request!');
      }

      await rejectDepositRequest({
        requestId: String(request.id),
        comment: state.modalRejectDepositRequestProps.rejectionReason,
      });

      showToast({
        variant: ToastVariant.Success,
        message: `Deposit request #${request.id} rejected!`,
      });
      dispatch({
        type: 'hide-reject-modal',
      });
      dispatch({
        type: 'update-deposit-request',
        payload: {
          id: request.id,
          status: Dto.AssetDepositStatus.Rejected,
        },
      });
    } catch {
      showToast({
        variant: ToastVariant.Danger,
        message: `Failed to reject deposit request #${state.modalRejectDepositRequestProps.requestId}! Please try again later`,
      });
    } finally {
      dispatch({
        type: 'finish-rejecting-request',
      });
    }
  }, [
    state.modalRejectDepositRequestProps.rejectionReason,
    state.modalRejectDepositRequestProps.requestId,
    state.depositRequests,
  ]);

  const removeAlert = useCallback((index: number) => {
    dispatch({ type: 'remove-alert', payload: index });
  }, []);

  const stateWithActions = useMemo(
    () => ({
      ...state,
      changeSearch,
      changePagination,
      changeFilter,
      changeRejectionReason,
      showApproveModal,
      hideApproveModal,
      showRejectModal,
      hideRejectModal,
      approveRequest,
      rejectRequest,
      removeAlert,
    }),
    [
      state,
      changeSearch,
      changePagination,
      changeFilter,
      changeRejectionReason,
      showApproveModal,
      showRejectModal,
      hideRejectModal,
      approveRequest,
      rejectRequest,
      removeAlert,
    ],
  );

  return <DepositRequestsContext.Provider value={stateWithActions}>{children}</DepositRequestsContext.Provider>;
};
