import { userAuthenticatedApi } from 'data-provider/fetcher';

export const submitProject = async (props: { id: number }) => {
  try {
    const userService = await userAuthenticatedApi();
    // NOTE: Need to check this is correct, GET or POST
    await userService.url(`/user/apx/projects/${props.id}/submit`).get();
  } catch {
    return false;
  }
  return true;
};
