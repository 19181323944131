import lodash from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDrop } from 'react-dnd';
import Responsive, { type Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';

import {
  BorderColor,
  ButtonSize,
  ButtonVariant,
  IconName,
  Knob,
  Layer,
  Tabs,
  TabsSize,
  TextColor,
  styled,
  toBorderColor,
  toLayerBackground,
  toSpacing,
  toTextColor,
  useBorderColor,
  useTheme,
} from '@aircarbon/ui';
import { hooks } from '@aircarbon/utils-common';

import { checkIfNonBilateral } from 'utils/checkIfTokenOrRec';
import { clearSelection, isTouchDevice } from 'utils/helpers';

import { TradingHookContainer } from '..';
import { TVChartContainer } from '../chart/TVChartContainer';
import SimpleBar from '../components/Simplebar';
import Balance from '../panels/Balance';
import NewsFeed from '../panels/NewsFeed';
import OrderBook from '../panels/OrderBook';
import OrderHistory from '../panels/OrderHistory';
import OtherTrades from '../panels/OtherTrades';
import PlaceOrderBiofuel from '../panels/PlaceOrderBiofuel';
import PlaceOrderCarbon from '../panels/PlaceOrderCarbon';
import Trades from '../panels/Trades';
import Watchlist from '../panels/Watchlist';
import { LayoutButton, type TabConfig } from './LayoutDropdown';

const { useLocalStorage } = hooks;

const TabRenderer = ({ tabConfig }: { tabConfig: TabConfig }) => {
  const {
    states: {
      showPairDropdown,
      orderBookView,
      setOrderBookView,
      orderBookLevel,
      setOrderBookLevel,
      setSelectedAccount,
      selectedAccount,
      assetCategory,
      projectId,
    },
    info: { watchlist, tradeSettings, orderBook, trades, otherTrades, isLoadingFirstTime, currentPair, currentCcy },
    utils: { changeUrl },
  } = TradingHookContainer.useContainer();

  const tabKey = `${tabConfig.key}.${selectedAccount.account}`;
  if (tabConfig.key === 'order-book') {
    return (
      <OrderBook
        key={tabConfig.key}
        pair={currentPair}
        isLoading={isLoadingFirstTime}
        orderBook={orderBook ?? []}
        showCumulative={true}
        view={orderBookView}
        onViewChanges={(view) => setOrderBookView(view)}
        level={orderBookLevel}
        onLevelChanges={(level) => setOrderBookLevel(level)}
        fromFlexLayout={true}
        assetCategory={assetCategory}
      />
    );
  } else if (tabConfig.key === 'markets') {
    return (
      <Watchlist
        key={tabConfig.key}
        withCustomScrollBar={true}
        watchlist={watchlist}
        onClick={(pairName) => {
          changeUrl({ pair: pairName });
          showPairDropdown(false);
        }}
        assetCategory={assetCategory}
      />
    );
  } else if (tabConfig.key === 'place-order-form') {
    return assetCategory === 'biofuel' ? (
      <PlaceOrderBiofuel
        key={tabKey}
        pairs={tradeSettings?.pairs ?? []}
        pair={currentPair?.name}
        timeInForces={tradeSettings?.timeInForces ?? []}
        ccyAsset={tradeSettings?.ccyAssets?.[currentCcy as any]}
        lastTradedPrice={orderBook?.lastTradedPrice}
        pairWatchlist={watchlist?.items?.find((item) => item.pairId === currentPair?.id)}
      />
    ) : (
      <PlaceOrderCarbon
        key={tabKey}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
        pairs={tradeSettings?.pairs ?? []}
        pair={currentPair?.name}
        timeInForces={tradeSettings?.timeInForces ?? []}
        ccyAsset={tradeSettings?.ccyAssets?.[currentCcy as any]}
        lastTradedPrice={orderBook?.lastTradedPrice}
        pairWatchlist={watchlist?.items?.find((item) => item.pairId === currentPair?.id)}
      />
    );
  } else if (tabConfig.key === 'candle-chart') {
    return (
      <>
        {currentPair && currentPair.symbol && currentPair.name && currentPair.id && (
          <TVChartContainer pair={currentPair} />
        )}
      </>
    );
  } else if (tabConfig.key === 'balance') {
    return <Balance showUserInfo={true} key={tabKey} selectedAccount={selectedAccount} projectId={projectId} />;
  } else if (tabConfig.key === 'order-history') {
    return (
      <OrderHistory
        key={tabKey}
        selectedAccount={selectedAccount}
        pair={currentPair}
        showPnL={false}
        layout={'largeOrWideDesktop'}
        viewOnly={'Order History'}
        assetCategory={assetCategory}
        projectId={projectId}
      />
    );
  } else if (tabConfig.key === 'open-orders') {
    return (
      <OrderHistory
        key={tabKey}
        selectedAccount={selectedAccount}
        pair={currentPair}
        showPnL={false}
        layout={'largeOrWideDesktop'}
        viewOnly={'Open Orders'}
        assetCategory={assetCategory}
        projectId={projectId}
      />
    );
  } else if (tabConfig.key === 'trade-history') {
    return (
      <OrderHistory
        key={tabKey}
        selectedAccount={selectedAccount}
        pair={currentPair}
        showPnL={false}
        layout={'largeOrWideDesktop'}
        viewOnly={checkIfNonBilateral(assetCategory) ? 'Trade History' : 'Match History'}
        assetCategory={assetCategory}
        projectId={projectId}
      />
    );
  } else if (tabConfig.key === 'market-trades') {
    return (
      <Trades
        key={tabConfig.key}
        trades={trades}
        baseAssetId={currentPair?.baseAsset?.id}
        assetCategory={assetCategory}
      />
    );
  } else if (tabConfig.key === 'other-trades') {
    return <OtherTrades key={tabConfig.key} trades={otherTrades} assetCategory={assetCategory} />;
  } else if (tabConfig.key === 'news-feed') {
    // TODO: implement project details widget
    // if (apxProject) return <TradingApxProject key={tabConfig.key} apxProject={apxProject} />;
    return <NewsFeed key={tabConfig.key} />;
  }
  return <></>;
};

const Holder = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  .react-grid-item {
    border-radius: 0px;
    z-index: 2;
  }
  .react-grid-item.react-grid-placeholder {
    border-radius: 0px;
    z-index: 1 !important;
  }
  > .splitter {
    position: absolute;
    background-color: #000;
    z-index: 2;
    opacity: 0;
    &:hover {
      opacity: 0.5;
    }
    &.horizontal {
      cursor: row-resize !important;
    }
    &.vertical {
      cursor: col-resize !important;
    }
  }
`;

const PlaceHolder = styled.div`
  background: red;
  opacity: 0.2;
  transition-duration: 100ms;
  z-index: 2;
  position: absolute;
  border-radius: 0px;
  > .react-resizable-handle {
    display: none;
  }
`;

const Frame = styled.div`
  color: ${({ theme }) => toTextColor(theme)(TextColor.primary)};
  box-sizing: border-box;
  overflow: visible;
  > .content {
    background-color: ${({ theme }) => toLayerBackground(theme)('layer')};
    border: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
    border-radius: ${({ theme }) => theme.system.border.radius.m};
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    > .draggableTitle {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 0 ${({ theme }) => toSpacing(theme)(6)};
      user-select: none;
      border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)};
      > .control {
        flex: 'auto';
        flex-direction: row;
      }
    }
    > .list {
      position: relative;
      width: 100%;
      height: auto;
      flex: 1 0 0%;
      > .body {
        flex: 1;
        padding: ${({ theme }) => toSpacing(theme)(4)};
        position: absolute;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        > .holder {
          position: relative;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  > .react-resizable-handle {
    background-image: none;
  }
  > .react-resizable-handle::after {
    width: 8px !important;
    height: 8px !important;
    border-right: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)} !important;
    border-bottom: 1px solid ${({ theme }) => toBorderColor(theme)(BorderColor.neutral)} !important;
    border-radius: 0 0 3px 0;
    bottom: 5px !important;
    right: 5px !important;
  }
`;

type PanelProps = { layout: Layout; tabs: TabConfig[]; isMinimized?: boolean };

export function Panel({ panelProps, layoutActions }: { panelProps: PanelProps; layoutActions: LayoutActions }) {
  const [, ref] = useDrop(
    () => ({
      accept: 'Tab',
      drop: (item: any) => {
        layoutActions.doneHover(item.tabConfig as TabConfig, item.parentPanelProps as PanelProps, panelProps);
      },
      hover: () => {
        layoutActions.doneHover();
      },
    }),
    [layoutActions, panelProps],
  );
  const [selected, setSelected] = useState(0);
  const selectedTab: TabConfig | undefined = panelProps.tabs[selected];
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDisplay(true);
    }, 250);
  }, []);

  return (
    <div ref={ref} className={`content ${selectedTab?.key}`}>
      <div className="draggableTitle">
        <PanelTabs
          layoutActions={layoutActions}
          parentPanelProps={panelProps}
          onUpdate={(index) => {
            setSelected(index);
            if (panelProps.isMinimized) layoutActions.tabMixMaxToggle(panelProps);
          }}
        />
      </div>
      <div className="list">
        {display &&
          !panelProps.isMinimized &&
          panelProps.tabs.map((item, index) => {
            const isSelected = index === selected;
            return (
              <div
                style={{
                  opacity: isSelected ? 1 : 0,
                  pointerEvents: isSelected ? 'auto' : 'none',
                }}
                className={`body ${selectedTab?.key}`}
              >
                <div className="holder">
                  <Layer>
                    <TabRenderer key={item.key} tabConfig={item} />
                  </Layer>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

function PanelTabs({
  parentPanelProps,
  layoutActions,
  onUpdate,
}: {
  parentPanelProps: PanelProps;
  layoutActions: LayoutActions;
  onUpdate: (index: number) => void;
}) {
  const [selected, setSelected] = useState(0);
  const { tabs } = parentPanelProps;
  useEffect(() => {
    if (selected > tabs.length - 1) {
      const newIndex = tabs.length === 0 ? 0 : tabs.length - 1;
      setSelected(newIndex);
      onUpdate(newIndex);
    }
  }, [tabs.length]);

  return (
    <Tabs
      activeItemId={String(selected)}
      items={tabs.map((tabItem, index) => ({
        id: String(index),
        label: tabItem.label,
        suffix: (
          <StyledCloseKnob
            icon={IconName.X}
            size={ButtonSize.xs}
            variant={ButtonVariant.ghost}
            onPress={() => layoutActions.removeTab(tabItem, parentPanelProps)}
          />
        ),
      }))}
      size={TabsSize.m}
      onPressTab={(selectedTab) => {
        setSelected(Number(selectedTab.id));
        onUpdate(Number(selectedTab.id));
      }}
    />
  );
}

const StyledCloseKnob = styled(Knob)`
  margin-inline-end: -${({ theme }) => toSpacing(theme)(6)};
`;

type LayoutActions = {
  resizeLayout: (layouts: Layout[]) => void;
  updateDropHover: (x: number, y: number) => void;
  doneDropHover: (x: number, y: number, tab: TabConfig, currentParent?: PanelProps) => void;
  doneHover: (tabConfig?: TabConfig, oldParent?: PanelProps, newParent?: PanelProps) => void;
  removeTab: (tabConfig: TabConfig, oldParent: PanelProps) => void;
  tabMixMaxToggle: (panel: PanelProps) => void;
};

export const TickerBar = ({ onReset }: { onReset: () => void }) => {
  const {
    views: { tickerArea, pairButton, projectButton },
  } = TradingHookContainer.useContainer();
  return (
    <div className="content px-small py-xs">
      <div className="flex flex-row justify-items-stretch w-full h-auto gap-small">
        {projectButton('largeOrWideDesktop')}
        <div className="h-auto">{pairButton()}</div>
        <div className="flex-1 h-auto">{tickerArea('largeOrWideDesktop')}</div>
        <div className="flex justify-center items-center h-auto">
          <LayoutButton onReset={onReset} />
        </div>
      </div>
    </div>
  );
};

const defaultLayoutToken: MasterLayout = {
  name: 'token',
  refreshCounter: 1,
  counter: 10,
  panels: {
    '2': { layout: { i: '2', x: 77, y: 0, w: 23, h: 51 }, tabs: [{ key: 'place-order-form', label: 'Place Order' }] },
    '3': { layout: { i: '3', x: 0, y: 6, w: 22, h: 21 }, tabs: [{ key: 'markets', label: 'Markets' }] },
    '4': { layout: { i: '4', x: 0, y: 51, w: 50, h: 30 }, tabs: [{ key: 'balance', label: 'Balance / P&L' }] },
    '5': {
      layout: { i: '5', x: 50, y: 51, w: 50, h: 30 },
      tabs: [
        { key: 'open-orders', label: 'Open Orders' },
        { key: 'order-history', label: 'Order History' },
        { key: 'trade-history', label: 'Trade History' },
      ],
    },
    '6': { layout: { i: '6', x: 22, y: 6, w: 29, h: 21 }, tabs: [{ key: 'order-book', label: 'Order Book' }] },
    '7': { layout: { i: '7', x: 22, y: 27, w: 55, h: 24 }, tabs: [{ key: 'candle-chart', label: 'Chart' }] },
    '8': {
      layout: { i: '8', x: 51, y: 6, w: 26, h: 21 },
      tabs: [
        { key: 'market-trades', label: 'Market Trades' },
        { key: 'other-trades', label: 'Other Trades' },
      ],
    },
    '9': { layout: { i: '9', x: 0, y: 27, w: 22, h: 24 }, tabs: [{ key: 'news-feed', label: 'News' }] },
  },
  pairsTickerBar: {
    i: 'pairs-ticker-bar',
    x: 0,
    y: 0,
    w: 77,
    h: 6,
    maxH: 6,
    minH: 6,
    isDraggable: false,
    isResizable: true,
    static: true,
  },
};
const defaultLayoutBiofuel: MasterLayout = {
  name: 'biofuel',
  refreshCounter: 1,
  counter: 10,
  panels: {
    '2': { layout: { i: '2', x: 79, y: 0, w: 21, h: 51 }, tabs: [{ key: 'place-order-form', label: 'Place Order' }] },
    '3': { layout: { i: '3', x: 0, y: 6, w: 22, h: 45 }, tabs: [{ key: 'markets', label: 'Markets' }] },
    '5': {
      layout: { i: '5', x: 0, y: 51, w: 100, h: 33 },
      tabs: [
        { key: 'open-orders', label: 'Open Orders' },
        { key: 'order-history', label: 'Order History' },
        { key: 'trade-history', label: 'Match History' },
      ],
    },
    '6': {
      layout: { i: '6', x: 22, y: 6, w: 57, h: 23 },
      tabs: [
        { key: 'order-book', label: 'Order Matching' },
        { key: 'market-trades', label: 'Confirmed Matches' },
      ],
    },
    '7': { layout: { i: '7', x: 22, y: 29, w: 57, h: 22 }, tabs: [{ key: 'candle-chart', label: 'Chart' }] },
  },
  pairsTickerBar: {
    i: 'pairs-ticker-bar',
    x: 0,
    y: 0,
    w: 79,
    h: 6,
    maxH: 6,
    minH: 6,
    isDraggable: false,
    isResizable: true,
    static: true,
  },
};

type MasterLayout = {
  name: string;
  refreshCounter: number;
  counter: number;
  panels: { [index: string]: PanelProps };
  hover?: Layout;
  pairsTickerBar: Layout;
  addedLayout?: string;
};

// parallel line hit test.
const parallelLineHitTest = (a1: number, a2: number, b1: number, b2: number) => {
  return (
    (a1 < b1 && a2 > b1) ||
    (a1 < b2 && a2 > b2) ||
    (a1 === b1 && a2 === b2) ||
    (b1 < a1 && b2 > a1) ||
    (b1 < a2 && b2 > a2)
  );
};

//remove repeating value from array.
function getUnique(array: number[]) {
  const uniqueArray = [];
  for (let index = 0; index < array.length; index++) {
    if (uniqueArray.indexOf(array[index]) === -1) {
      uniqueArray.push(array[index]);
    }
  }
  return uniqueArray;
}

function intersectRect(r1: Rect, r2: Rect) {
  if (r2.left >= r1.right || r2.right <= r1.left) return false;
  if (r2.top >= r1.bottom || r2.bottom <= r1.top) return false;
  return true;
}

const adjustAddedLayout = (masterLayout: MasterLayout & { addedLayout: string }) => {
  const react = [
    ...Object.values(masterLayout.panels)
      .filter((item) => item.layout.i !== masterLayout.addedLayout)
      .map((item) => item.layout),
    masterLayout.pairsTickerBar,
  ];

  const addedLayout = masterLayout.panels[masterLayout.addedLayout].layout;
  delete (masterLayout as any).addedLayout;
  const addedY1 = addedLayout.y;
  const addedY2 = addedLayout.y + addedLayout.h;
  const addedTop = addedLayout.y;

  let generateLeft = 0;
  let generateRight = 100;
  let generateBottom = Math.max(...react.map((layout) => layout.y + layout.h));

  for (let index = 0; index < react.length; index++) {
    const layout = react[index];
    const x1 = layout.x + layout.w;
    const x2 = layout.x;
    const y1 = layout.y + layout.h;
    const y2 = layout.y;
    const touchingY = parallelLineHitTest(y2, y1, addedY1, addedY2);
    if (touchingY && x1 <= addedLayout.x && x1 >= generateLeft) {
      generateLeft = x1;
    }
    if (touchingY && x2 >= addedLayout.x + addedLayout.w && x2 <= generateRight) {
      generateRight = x2;
    }
  }

  for (let index = 0; index < react.length; index++) {
    const layout = react[index];
    const x1 = layout.x + layout.w;
    const x2 = layout.x;
    const y2 = layout.y;
    const touchingX = parallelLineHitTest(x2, x1, generateLeft, generateRight);
    if (touchingX && y2 >= addedLayout.y + addedLayout.h && y2 <= generateBottom) {
      generateBottom = y2;
    }
  }

  addedLayout.x = generateLeft;
  addedLayout.y = addedTop;
  addedLayout.w = generateRight - generateLeft;
  addedLayout.h = generateBottom - addedTop < 16 ? 16 : generateBottom - addedTop;
};

type Rect = { left: number; right: number; top: number; bottom: number; empty?: boolean };

// parallel line with start to end
type ParallelLine = { key?: string; panel?: PanelProps; start: number; end: number; size: number };

type ParallelLineGroup = {
  point: number; // point can be x or y
  start: ParallelLine[];
  end: ParallelLine[];
};

type Splitter = {
  point: number;
  line: { start: number; end: number };
  start: ParallelLine[];
  end: ParallelLine[];
};

const getAndRemoveJointLineAsSplitter = (lineGroup: ParallelLineGroup) => {
  const startLine = lineGroup.start;
  const endLine = lineGroup.end;
  const splitter: Splitter = {
    point: lineGroup.point,
    line: { start: 0, end: 0 },
    start: [],
    end: [],
  };

  // find matching from start-lines and add it to splitter
  for (let index = 0; index < startLine.length; index++) {
    const currentStartLine = lineGroup.start[index];
    if (splitter.start.length === 0) {
      splitter.start.push(currentStartLine);
    } else {
      const prevStartLine = splitter.start[splitter.start.length - 1];
      if (currentStartLine && prevStartLine && currentStartLine.start === prevStartLine.end)
        splitter.start.push(currentStartLine);
    }
  }

  // find matching from end-lines and add it to splitter
  for (let index = 0; index < endLine.length; index++) {
    const currentEndLine = lineGroup.end[index];
    if (splitter.end.length === 0) {
      splitter.end.push(currentEndLine);
    } else {
      const prevEndLine = splitter.end[splitter.end.length - 1];
      if (currentEndLine && prevEndLine && currentEndLine.start === prevEndLine.end) splitter.end.push(currentEndLine);
    }
  }

  // mark the start and end of splitter line
  splitter.line.end = splitter.end[splitter.end.length - 1].end;
  splitter.line.start = splitter.start[0].start;

  //remove matching lines from lineGroup
  lineGroup.end = lineGroup.end.filter((item) => splitter.end.indexOf(item) === -1);
  lineGroup.start = lineGroup.start.filter((item) => splitter.start.indexOf(item) === -1);

  return splitter;
};

const findSplitters = (lineGroup: ParallelLineGroup) => {
  const splitters: Splitter[] = [];
  // find splitters until lineGroup is start lines or end lines is empty.
  while (lineGroup.end.length && lineGroup.start.length) {
    const splitter = getAndRemoveJointLineAsSplitter(lineGroup);
    if (splitter) splitters.push(splitter);
  }
  return splitters;
};

type Splitters = {
  verticalSplitters: Splitter[];
  horizontalSplitters: Splitter[];
};

const generateSplitters = (gridLayout: Layout[], masterLayout: MasterLayout) => {
  let xList = [];
  let yList = [];

  // find all x and y points and push the in to list (xList, yList)
  for (let index = 0; index < gridLayout.length; index++) {
    const layout = gridLayout[index];
    xList.push(layout.x);
    xList.push(layout.x + layout.w);
    yList.push(layout.y);
    yList.push(layout.y + layout.h);
  }

  // sort xList and yList points by 0 - 9 ascending order and remove repeating values.
  xList = getUnique(xList).sort((a, b) => a - b);
  yList = getUnique(yList).sort((a, b) => a - b);

  // build and mark filled rectangles as 2d array(imagine and excel sheet with various sizes of cells)
  const rects: ({ left: number; right: number; top: number; bottom: number; empty: boolean } | undefined)[][] = [];
  let prevRect: Rect | undefined;

  // build rectangles with priority of width then height.
  for (let yIndex = 0; yIndex < yList.length - 1; yIndex++) {
    const top = yList[yIndex];
    const bottom = yList[yIndex + 1];
    prevRect = undefined;

    for (let xIndex = 0; xIndex < xList.length - 1; xIndex++) {
      const left = xList[xIndex];
      const right = xList[xIndex + 1];
      const currentRect = { left, top, right, bottom, empty: true };
      rects[yIndex] = rects[yIndex] || [];

      for (let layoutIndex = 0; layoutIndex < gridLayout.length; layoutIndex++) {
        const layout = gridLayout[layoutIndex];
        const layoutRect = { left: layout.x, top: layout.y, right: layout.x + layout.w, bottom: layout.y + layout.h };
        if (intersectRect(layoutRect, currentRect)) currentRect.empty = false;
      }

      if (prevRect?.empty && currentRect.empty) {
        prevRect.right = currentRect.right;
      } else {
        rects[yIndex][xIndex] = currentRect;
        prevRect = currentRect;
      }
    }
  }

  // merge rectangles with same width and height and if they are touching each other
  for (let xIndex = 0; xIndex < xList.length - 1; xIndex++) {
    prevRect = undefined;
    for (let yIndex = 0; yIndex < yList.length - 1; yIndex++) {
      const currentRect = rects[yIndex][xIndex];
      if (prevRect?.empty && currentRect?.empty && prevRect.right === currentRect?.right) {
        prevRect.bottom = currentRect.bottom;
        rects[yIndex][xIndex] = undefined;
      } else {
        prevRect = currentRect;
      }
    }
  }

  prevRect = undefined;

  // find build empty layout from marked rectangles
  const emptyLayout: Layout[] = [];
  for (let xIndex = 0; xIndex < xList.length - 1; xIndex++) {
    for (let yIndex = 0; yIndex < yList.length - 1; yIndex++) {
      const currentRect = rects[yIndex][xIndex];
      if (currentRect?.empty)
        emptyLayout.push({
          i: 'empty',
          x: currentRect.left,
          w: currentRect.right - currentRect.left,
          y: currentRect.top,
          h: currentRect.bottom - currentRect.top,
        });
    }
  }

  // merge all layout togethers
  const withEmptyLayout = [...gridLayout, ...emptyLayout];

  const verticalLines: {
    [index: string]: ParallelLineGroup;
  } = {};

  const horizontalLines: {
    [index: string]: ParallelLineGroup;
  } = {};

  // build vertical and horizontal lines and stored in array
  for (let index = 0; index < withEmptyLayout.length; index++) {
    const layout = withEmptyLayout[index];

    const leftX = layout.x;
    const rightX = layout.x + layout.w;
    if (!verticalLines[leftX]) verticalLines[leftX] = { point: leftX, start: [], end: [] };
    if (!verticalLines[rightX]) verticalLines[rightX] = { point: rightX, start: [], end: [] };

    const verticalPanel =
      layout.i === 'pairs-ticker-bar'
        ? {
            layout: masterLayout.pairsTickerBar,
            tabs: [],
          }
        : masterLayout.panels[layout.i];

    const verticalLine = {
      key: layout.i,
      panel: verticalPanel ? { ...verticalPanel, layout: { ...verticalPanel.layout } } : undefined,
      start: layout.y,
      end: layout.y + layout.h,
      size: layout.w,
    };
    verticalLines[leftX].start.push({ ...verticalLine });
    verticalLines[rightX].end.push({ ...verticalLine });

    const topY = layout.y;
    const bottomY = layout.y + layout.h;
    if (!horizontalLines[topY]) horizontalLines[topY] = { point: topY, start: [], end: [] };
    if (!horizontalLines[bottomY]) horizontalLines[bottomY] = { point: bottomY, start: [], end: [] };

    const horizontalPanel =
      layout.i === 'pairs-ticker-bar'
        ? {
            layout: masterLayout.pairsTickerBar,
            tabs: [],
          }
        : masterLayout.panels[layout.i];
    const horizontalLine = {
      key: layout.i,
      panel: horizontalPanel ? { ...horizontalPanel, layout: { ...horizontalPanel.layout } } : undefined,
      start: layout.x,
      end: layout.x + layout.w,
      size: layout.h,
    };

    horizontalLines[topY].start.push({ ...horizontalLine });
    horizontalLines[bottomY].end.push({ ...horizontalLine });
  }

  // sort vertical lines
  const verticalLinesArray = Object.values(verticalLines);
  verticalLinesArray.forEach((item) => {
    item.start.sort((a, b) => a.start - b.start);
    item.end.sort((a, b) => a.start - b.start);
  });

  // sort horizontal lines
  const horizontalLinesArray = Object.values(horizontalLines);
  horizontalLinesArray.forEach((item) => {
    item.start.sort((a, b) => a.start - b.start);
    item.end.sort((a, b) => a.start - b.start);
  });

  // find vertical and horizontal splitters
  let verticalSplitters: Splitter[] = [];
  let horizontalSplitters: Splitter[] = [];

  for (let index = 0; index < verticalLinesArray.length; index++) {
    const verticalLineGroup = verticalLinesArray[index];
    verticalSplitters = [...verticalSplitters, ...findSplitters(verticalLineGroup)];
  }

  for (let index = 0; index < horizontalLinesArray.length; index++) {
    const horizontalLineGroup = horizontalLinesArray[index];
    horizontalSplitters = [...horizontalSplitters, ...findSplitters(horizontalLineGroup)];
  }

  return { verticalSplitters, horizontalSplitters };
};

const SplitterView = ({
  item,
  type,
  setSplitterLock,
  layoutActions,
  width,
}: {
  item: Splitter;
  type: 'horizontal' | 'vertical';
  setSplitterLock: (value: boolean) => void;
  layoutActions: LayoutActions;
  width: number;
}) => {
  const { borderColor } = useBorderColor();
  const theme = useTheme();
  const splitterRef = useRef<HTMLDivElement>(null);
  const rowSize = width / 100;
  const colSize = 8 + 3;
  const top = type === 'horizontal' ? Math.ceil(item.point * colSize) - 1 : Math.ceil(item.line.start * colSize) + 1;
  const left = type === 'horizontal' ? Math.ceil(item.line.start * rowSize) - 1 : Math.ceil(item.point * rowSize) - 3;

  const maxEndSize =
    Math.min(
      ...item.end
        .filter(
          (item) =>
            item.panel?.isMinimized || (type === 'vertical' ? item.panel?.layout.maxW : item.panel?.layout.maxH),
        )
        .map(
          (item) =>
            item.size -
            ((type === 'vertical'
              ? item.panel?.layout.maxW
              : item.panel?.isMinimized
                ? item.size
                : item.panel?.layout.maxH) || 0),
        ),
    ) ?? undefined;

  const maxStartSize =
    Math.min(
      ...item.start
        .filter(
          (item) =>
            item.panel?.isMinimized || (type === 'vertical' ? item.panel?.layout.maxW : item.panel?.layout.maxH),
        )
        .map(
          (item) =>
            item.size -
            ((type === 'vertical'
              ? item.panel?.layout.maxW
              : item.panel?.isMinimized
                ? item.size
                : item.panel?.layout.maxH) || 0),
        ),
    ) ?? undefined;

  const minStartSize = Math.min(
    maxEndSize,
    Math.max(
      Math.min(
        ...item.start.map(
          (item) =>
            (item.key === 'empty' ? item.size + 4 : item.size) -
            ((type === 'vertical' ? item.panel?.layout.minW : item.panel?.layout.minH) || 0),
        ),
      ) - 5,
      0,
    ),
  );

  const minEndSize = Math.min(
    maxStartSize,
    Math.max(
      Math.min(
        ...item.end.map(
          (item) =>
            (item.key === 'empty' ? item.size + 5 : item.size) -
            ((type === 'vertical' ? item.panel?.layout.minW : item.panel?.layout.minH) || 0),
        ),
      ) - 5,
      0,
    ),
  );

  const onSplitter = (event: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    const isTouchEvent = event.type === 'touchstart';
    const splitterElement = splitterRef.current;

    if (splitterElement) {
      clearSelection();
      document.body.classList.add('disable-select');
      document.body.classList.add('disable-tap-highlight');
      document.body.classList.add('children-pointer-events-none');
      setSplitterLock(true);
      splitterElement.style.opacity = '1';
      if (type === 'horizontal') {
        document.body.classList.add('row-resize');
      } else {
        document.body.classList.add('col-resize');
      }

      const startX: number = event.type === 'touchstart' ? (event as any).touches[0].clientX : (event as any).clientX;
      const startY: number = event.type === 'touchstart' ? (event as any).touches[0].clientY : (event as any).clientY;

      let diffX = 0;
      let diffY = 0;
      const maxStart = Math.ceil(minStartSize * (type === 'horizontal' ? colSize : rowSize));
      const maxEnd = Math.ceil(minEndSize * (type === 'horizontal' ? colSize : rowSize));
      let moveLock = true;

      const onStart = () => {};

      const onMove = () => {
        if (type === 'vertical') {
          const rowDiff = Math.ceil(diffX / rowSize);
          const leftDiff = left + Math.ceil(rowDiff * rowSize);
          const layouts = [
            ...item.end
              .filter((item) => item.panel?.layout)
              .map((item) => {
                const layout = item.panel?.layout as Layout;
                return {
                  i: layout.i,
                  x: layout.x,
                  y: layout.y,
                  w: layout.w + rowDiff,
                  h: layout.h,
                };
              }),
            ...item.start
              .filter((item) => item.panel?.layout)
              .map((item) => {
                const layout = item.panel?.layout as Layout;
                return {
                  i: layout.i,
                  x: layout.x + rowDiff,
                  y: layout.y,
                  w: layout.w - rowDiff,
                  h: layout.h,
                };
              }),
          ];
          layoutActions.resizeLayout(layouts);
          splitterElement.style.left = `${leftDiff}px`;
        } else {
          const colDiff = Math.ceil(diffY / colSize);
          const topDiff = top + Math.ceil(colDiff * colSize);
          const layouts = [
            ...item.end
              .filter((item) => item.panel?.layout)
              .map((item) => {
                const layout = item.panel?.layout as Layout;
                return {
                  i: layout.i,
                  x: layout.x,
                  y: layout.y,
                  w: layout.w,
                  h: layout.h + colDiff,
                };
              }),
            ...item.start
              .filter((item) => item.panel?.layout)
              .map((item) => {
                const layout = item.panel?.layout as Layout;
                return {
                  i: layout.i,
                  x: layout.x,
                  y: layout.y + colDiff,
                  w: layout.w,
                  h: layout.h - colDiff,
                };
              }),
          ];
          layoutActions.resizeLayout(layouts);
          splitterElement.style.top = `${topDiff}px`;
        }
      };

      const onWindowMove = (event: MouseEvent | TouchEvent) => {
        event.stopPropagation();
        event.preventDefault();
        const clientX: number = event.type === 'touchmove' ? (event as any).touches[0].clientX : (event as any).clientX;
        const clientY: number = event.type === 'touchmove' ? (event as any).touches[0].clientY : (event as any).clientY;

        if (
          moveLock &&
          (!(startX - 5 < clientX && startX + 5 > clientX) || !(startY - 5 < clientY && startY + 5 > clientY))
        ) {
          moveLock = false;
          onStart();
        }
        if (!moveLock) {
          diffX = clientX - startX;
          if (type === 'vertical') {
            if (diffX > 0 && diffX > maxStart) diffX = maxStart;
            if (diffX < 0 && -diffX > maxEnd) diffX = -maxEnd;
          }
          diffY = clientY - startY;
          if (type === 'horizontal') {
            if (diffY > 0 && diffY > maxStart) diffY = maxStart;
            if (diffY < 0 && -diffY > maxEnd) diffY = -maxEnd;
          }
          onMove();
        }
      };

      const onWindowUp = () => {
        splitterElement.style.opacity = '';
        document.body.classList.remove('disable-select');
        document.body.classList.remove('disable-tap-highlight');
        document.body.classList.remove('children-pointer-events-none');
        if (type === 'horizontal') {
          document.body.classList.remove('row-resize');
        } else {
          document.body.classList.remove('col-resize');
        }
        window.removeEventListener(isTouchEvent ? 'touchmove' : 'mousemove', onWindowMove);
        window.removeEventListener(isTouchEvent ? 'touchend' : 'mouseup', onWindowUp);
        clearSelection();
        setSplitterLock(false);
      };
      window.addEventListener(isTouchEvent ? 'touchmove' : 'mousemove', onWindowMove);
      window.addEventListener(isTouchEvent ? 'touchend' : 'mouseup', onWindowUp);
    }
  };

  if (type === 'horizontal') {
    const width = Math.ceil((item.line.end - item.line.start) * rowSize);
    return (
      <div
        ref={splitterRef as any}
        onMouseDown={onSplitter}
        onTouchStart={onSplitter}
        style={{
          height: '5px',
          width: `${width}px`,
          left,
          top,
          background: borderColor(BorderColor.active),
          borderRadius: theme.system.border.radius.m,
        }}
        className={`splitter ${type}`}
      />
    );
  } else {
    const height = Math.ceil((item.line.end - item.line.start) * colSize) + 1;
    return (
      <div
        ref={splitterRef as any}
        onMouseDown={onSplitter}
        onTouchStart={onSplitter}
        style={{
          width: '5px',
          height: `${height}px`,
          left,
          top,
          background: borderColor(BorderColor.active),
          borderRadius: theme.system.border.radius.m,
        }}
        className={`splitter ${type}`}
      />
    );
  }
};

export const DesktopGridLayout = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const {
    states: { assetCategory },
    views: { holder },
  } = TradingHookContainer.useContainer();
  const defaultLayout = assetCategory === 'biofuel' ? defaultLayoutBiofuel : defaultLayoutToken;
  const defaultLayoutKey = `trading-flexible-layout-1.0-${assetCategory}`;
  const [savedLayout, setSavedLayout] = useLocalStorage(defaultLayoutKey, JSON.parse(JSON.stringify(defaultLayout)));
  const [masterLayout, setMasterLayout] = useState<MasterLayout>(savedLayout);

  useEffect(() => {
    setMasterLayout(savedLayout);
  }, [defaultLayoutKey, savedLayout]);

  useEffect(() => {
    const update = lodash.debounce((width: number) => {
      setWidth(width);
    }, 100);
    const element = containerRef?.current;
    if (!element) return;
    const observer = new ResizeObserver(() => {
      update(element.clientWidth);
    });

    observer.observe(element);
    return () => {
      // Cleanup the observer by unobserving all elements
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [containerRef.current]);

  const {
    layoutActions,
    layoutActions: { updateDropHover, doneDropHover, doneHover },
  } = useMemo<{
    layoutActions: LayoutActions;
  }>(() => {
    let updateTimer: NodeJS.Timeout;
    let clearUpdateTimer: NodeJS.Timeout;
    let lastUpdatedTime = 0;
    function update(value: { x: number; y: number }) {
      clearTimeout(clearUpdateTimer);
      clearUpdateTimer = setTimeout(() => {
        clearTimeout(clearUpdateTimer);
        setMasterLayout({
          ...masterLayout,
          hover: undefined,
        });
      }, 1000);
      const time = new Date().getTime();
      if (time - lastUpdatedTime >= 100) {
        lastUpdatedTime = time;
        setMasterLayout({
          ...masterLayout,
          hover: {
            i: 'hover',
            x: value.x,
            y: value.y,
            w: 3,
            h: 4,
          },
        });
      } else {
        clearTimeout(updateTimer);
        updateTimer = setTimeout(() => {
          clearTimeout(updateTimer);
          update(value);
        }, 100);
      }
    }

    let updateLayoutTimer: NodeJS.Timeout;
    let lastUpdatedLayoutTime = 0;
    function updateLayout(layouts: Layout[]) {
      const time = new Date().getTime();
      if (time - lastUpdatedLayoutTime >= 100) {
        [masterLayout.pairsTickerBar, ...Object.values(masterLayout.panels).map((item) => item.layout)].forEach(
          (layout) => {
            const match = layouts.find((item) => item.i === layout.i);
            if (match) {
              layout.h = match.h;
              layout.w = match.w;
              layout.x = match.x;
              layout.y = match.y;
            }
          },
        );
        lastUpdatedLayoutTime = time;
        setMasterLayout({
          ...masterLayout,
        });
      } else {
        clearTimeout(updateLayoutTimer);
        updateLayoutTimer = setTimeout(() => {
          clearTimeout(updateLayoutTimer);
          updateLayout(layouts);
        }, 100);
      }
    }

    return {
      layoutActions: {
        resizeLayout: (layouts: Layout[]) => {
          updateLayout(layouts);
        },

        updateDropHover: (x: number, y: number) => {
          update({
            x: Math.ceil(x / (window.innerWidth / 100)),
            y: Math.ceil((y - 70) / (8 + 3)),
          });
        },
        doneDropHover: (x: number, y: number, tab: TabConfig, currentParent?: PanelProps) => {
          if (currentParent) {
            currentParent.tabs = currentParent.tabs.filter((item) => item.key !== tab.key);
            if (!currentParent.tabs.length) delete masterLayout.panels[currentParent.layout.i];
          }
          clearTimeout(updateTimer);
          clearTimeout(clearUpdateTimer);
          masterLayout.counter++;
          const newLayout = masterLayout.counter.toString();
          setMasterLayout({
            ...masterLayout,
            panels: {
              ...masterLayout.panels,
              [newLayout]: {
                layout: {
                  i: newLayout,
                  x: Math.ceil(x / (window.innerWidth / 100)),
                  y: Math.ceil((y - 70) / (8 + 3)),
                  w: 3,
                  h: 4,
                },
                tabs: [tab],
              },
            },
            hover: undefined,
            addedLayout: newLayout,
          });
        },
        doneHover: (tabConfig?: TabConfig, oldParent?: PanelProps, newParent?: PanelProps) => {
          if (tabConfig && oldParent !== newParent) {
            if (oldParent) {
              oldParent.tabs = oldParent.tabs.filter((item) => item.key !== tabConfig.key);
              if (!oldParent.tabs.length) delete masterLayout.panels[oldParent.layout.i];
            }
            if (newParent && !newParent.tabs.find((item) => item.key === tabConfig.key))
              newParent.tabs = [...newParent.tabs, tabConfig];
          }
          clearTimeout(updateTimer);
          clearTimeout(clearUpdateTimer);
          setMasterLayout({
            ...masterLayout,
            hover: undefined,
          });
        },
        removeTab: (tabConfig: TabConfig, parent: PanelProps) => {
          parent.tabs = parent.tabs.filter((item) => item.key !== tabConfig.key);
          if (!parent.tabs.length) delete masterLayout.panels[parent.layout.i];

          clearTimeout(clearUpdateTimer);
          setMasterLayout({
            ...masterLayout,
            hover: undefined,
          });
        },
        tabMixMaxToggle: (panel: PanelProps) => {
          panel.isMinimized = !panel.isMinimized;
          clearTimeout(clearUpdateTimer);
          setMasterLayout({
            ...masterLayout,
            hover: undefined,
          });
        },
      },
    };
  }, [masterLayout]);

  const { lastRenderLayout, updateLastRenderLayout } = useMemo(() => {
    let layouts: Layout[] = [];
    return {
      lastRenderLayout: () => {
        return layouts;
      },
      updateLastRenderLayout: (value: Layout[]) => {
        layouts = value;
      },
    };
  }, []);

  const { gridLayout } = useMemo(() => {
    const gridLayout: Layout[] = Object.values(masterLayout.panels).map(({ layout, isMinimized }) => {
      return {
        ...layout,
        h: isMinimized ? 4 : layout.h,
        isResizable: isMinimized ? false : layout.isResizable,
      };
    });
    gridLayout.unshift({
      ...masterLayout.pairsTickerBar,
    });
    if (masterLayout.hover) gridLayout.push(masterLayout.hover);
    if (masterLayout.name === defaultLayout.name && !masterLayout.addedLayout) setSavedLayout(masterLayout);
    return {
      gridLayout,
    };
  }, [masterLayout]);

  const [splitters, setSplitter] = useState<Splitters>();
  const [splitterLock, setSplitterLock] = useState<boolean>(false);

  const [, dropHolder] = useDrop(
    () => ({
      accept: 'Tab',
      drop: (item: any, monitor) => {
        if (monitor.isOver({ shallow: true })) {
          const x = monitor.getClientOffset()?.x || 0;
          const y = monitor.getClientOffset()?.y || 0;
          setTimeout(() => {
            doneDropHover(x, y, item.tabConfig as TabConfig, item.parentPanelProps as PanelProps | undefined);
          }, 250);
        } else {
          setTimeout(() => {
            doneHover();
          }, 250);
        }
      },
      collect: (monitor) => {
        if (monitor.isOver({ shallow: true })) {
          updateDropHover(monitor.getClientOffset()?.x || 0, monitor.getClientOffset()?.y || 0);
        }
      },
    }),
    [masterLayout.panels],
  );

  return holder(
    <div className="relative w-full h-full" ref={containerRef}>
      {containerRef.current && (
        <SimpleBar>
          <Holder ref={dropHolder}>
            <Layer>
              <Responsive
                key={`grid-layout-${masterLayout.refreshCounter}-${isTouchDevice()}-${assetCategory}`}
                className="layout"
                layout={gridLayout}
                cols={100}
                rowHeight={8}
                margin={[3, 3]}
                width={width}
                isDraggable={isTouchDevice() ? false : true}
                isResizable={true}
                draggableHandle={'.draggableTitle'}
                onLayoutChange={(layout) => {
                  updateLastRenderLayout(layout);
                  layout.forEach((item) => {
                    const panel = masterLayout.panels[item.i];
                    if (panel) {
                      if (!panel.isMinimized) panel.layout.h = item.h;
                      panel.layout.w = item.w;
                      panel.layout.x = item.x;
                      panel.layout.y = item.y;
                    } else if (masterLayout.pairsTickerBar.i === item.i) {
                      masterLayout.pairsTickerBar.h = item.h;
                      masterLayout.pairsTickerBar.w = item.w;
                      masterLayout.pairsTickerBar.x = item.x;
                      masterLayout.pairsTickerBar.y = item.y;
                    }
                  });
                  if (masterLayout.addedLayout) {
                    adjustAddedLayout(masterLayout as any);
                    setMasterLayout({
                      ...masterLayout,
                    });
                  }
                  if (!splitterLock) setSplitter(generateSplitters(layout, masterLayout));
                  if (masterLayout.name === defaultLayout.name) setSavedLayout(masterLayout);
                }}
              >
                <Frame key={'pairs-ticker-bar'}>
                  <TickerBar
                    onReset={() => {
                      setMasterLayout(
                        JSON.parse(
                          JSON.stringify({
                            ...defaultLayout,
                            refreshCounter: masterLayout.refreshCounter + 1,
                          }),
                        ),
                      );
                    }}
                  />
                </Frame>

                {Object.values(masterLayout.panels).map((item) => {
                  return (
                    <Frame key={item.layout.i}>
                      <Panel panelProps={item} layoutActions={layoutActions} />
                    </Frame>
                  );
                })}
                {gridLayout.find((item) => item.i === 'hover') && <PlaceHolder key="hover" />}
              </Responsive>
            </Layer>
            {splitters?.verticalSplitters.map((item, index) => (
              <SplitterView
                layoutActions={layoutActions}
                setSplitterLock={(value) => {
                  setSplitterLock(value);
                  if (!value) setSplitter(generateSplitters(lastRenderLayout(), masterLayout));
                }}
                key={`splitter-${item.point}-${index}`}
                item={item}
                type={'vertical'}
                width={width}
              />
            ))}
            {splitters?.horizontalSplitters.map((item, index) => (
              <SplitterView
                layoutActions={layoutActions}
                setSplitterLock={(value) => {
                  setSplitterLock(value);
                  if (!value) setSplitter(generateSplitters(lastRenderLayout(), masterLayout));
                }}
                key={`splitter-${item.point}-${index}`}
                item={item}
                type={'horizontal'}
                width={width}
              />
            ))}
          </Holder>
        </SimpleBar>
      )}
    </div>,
  );
};
