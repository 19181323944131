import type * as React from 'react';
import { useMemo, useState } from 'react';
import ContentLoader from 'react-content-loader';

import { Select, toLayerBackground, useTheme } from '@aircarbon/ui';

import { Account } from 'state/account';

export interface AccountDetails {
  userId: number;
  account: string;
  fullName: string;
}

interface Props {
  name: string;
  inputValue: string;
  label?: string;
  setFieldValue(field: string, value: { value: string; user_id: number; fullName: string }): void;
}

const SelectOboAccount: React.FC<Props> = ({ name, label, inputValue, setFieldValue }) => {
  const { memberOboAccounts, isLoadingLinkedUsers } = Account.useContainer();
  const [searchValue, setSearchValue] = useState('');
  const theme = useTheme();
  const options = useMemo(
    () =>
      memberOboAccounts?.map(({ account, fullName, user_id, user_name, email, account_type }) => ({
        account_type,
        user_name,
        user_id,
        email,
        value: account,
        fullName,
        title: fullName,
      })) ?? [],
    [memberOboAccounts],
  );

  const filteredOptions = useMemo(() => {
    if (!searchValue) {
      return options;
    }

    return options.filter((option) => (option.title as string).toLowerCase().includes(searchValue.toLowerCase()));
  }, [options, searchValue]);

  if (isLoadingLinkedUsers) {
    return (
      <ContentLoader
        speed={2}
        width={400}
        height={60}
        viewBox="0 0 400 60"
        backgroundColor={toLayerBackground(theme)('field')}
        foregroundColor={toLayerBackground(theme)('fieldAccent')}
      >
        <rect x="51" y="0" rx="3" ry="3" width="400" height="37" />
        <circle cx="20" cy="20" r="20" />
      </ContentLoader>
    );
  }

  const onSearch = (searchValue: string) => {
    setSearchValue(searchValue);
  };

  const onChangeOboAccount = ({ value }: { value: string }) => {
    const selectedItem = options.find((item) => item.value === value);

    if (!selectedItem) {
      return;
    }

    setFieldValue(name, {
      value: selectedItem.value as string,
      user_id: (selectedItem as unknown as { user_id: number }).user_id,
      fullName: (selectedItem as unknown as { fullName: string }).fullName,
    });
  };

  return (
    <Select
      label={label}
      isSearchable
      value={inputValue}
      onChange={onChangeOboAccount}
      items={filteredOptions}
      onSearch={onSearch}
    />
  );
};

export default SelectOboAccount;
