import _ from 'lodash';
import { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AssetCategory, hooks } from '@aircarbon/utils-common';

export const MarketplaceProductContext = createContext<{
  product: string;
  changeProduct(product: string): void;
  changeAvailableProducts(products: Array<AssetCategory>): void;
}>({
  product: String(AssetCategory.token),
  changeProduct: () => {},
  changeAvailableProducts: () => {},
});

export const MarketplaceProductProvider: React.FunctionComponent<React.PropsWithChildren<object>> = ({ children }) => {
  const { search } = useLocation();
  const [availableProducs, setAvailableProducs] = useState<Array<AssetCategory>>([]);
  const productFromSearch = new URLSearchParams(search).get('assetCategoryId');
  const previousProductFromSearch = hooks.usePrevious(productFromSearch);
  const [product, setProduct] = useState(productFromSearch || String(AssetCategory.token));

  // useLocation sometimes return null on first product render therefore need to update product from first url query
  useEffect(() => {
    if (previousProductFromSearch === null && !!productFromSearch) {
      setProduct(productFromSearch);
    }
  }, [previousProductFromSearch, productFromSearch]);

  useEffect(() => {
    if (!availableProducs.length || availableProducs.includes(Number(product))) {
      return;
    }

    setProduct(String(availableProducs[0]));
  }, [availableProducs, product]);

  const changeProduct = useCallback((product: string) => {
    setProduct(String(product));
  }, []);

  const changeAvailableProducts = useCallback(
    (products: Array<AssetCategory> = []) => {
      if (_.isEqual(products, availableProducs)) {
        return;
      }

      setAvailableProducs(products);
    },
    [availableProducs],
  );

  const providerValue = useMemo(
    () => ({
      product,
      changeProduct,
      changeAvailableProducts,
    }),
    [product, changeAvailableProducts, changeProduct],
  );

  return <MarketplaceProductContext.Provider value={providerValue}>{children}</MarketplaceProductContext.Provider>;
};
