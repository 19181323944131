import type * as React from 'react';

import {
  Icon,
  IconName,
  Text,
  type TextColor,
  type TextProps,
  ToastVariant,
  Tooltip,
  showToast,
  styled,
  toSpacing,
} from '@aircarbon/ui';
import { formatter, logger } from '@aircarbon/utils-common';

import { HitArea } from './HitArea';

function clipboardCopy(text: string) {
  let success = false;

  if (typeof window !== 'undefined') {
    // Use the Async Clipboard API when available. Requires a secure browsing
    // context (i.e. HTTPS)
    if (navigator.clipboard) {
      return navigator.clipboard.writeText(text).catch((err) => {
        throw err !== undefined ? err : new DOMException('The request is not allowed', 'NotAllowedError');
      });
    }

    // ...Otherwise, use document.execCommand() fallback

    // Put the text to copy into a <span>
    const span = document.createElement('span');
    span.textContent = text;

    // Preserve consecutive spaces and newlines
    span.style.whiteSpace = 'pre';

    // Add the <span> to the page
    document.body.appendChild(span);

    // Make a selection object representing the range of text selected by the user
    const selection = window.getSelection();
    const range = window.document.createRange();

    if (selection) {
      selection.removeAllRanges();
      range.selectNode(span);
      selection.addRange(range);

      // Copy text to the clipboard
      try {
        success = window.document.execCommand('copy');
      } catch (err) {
        logger.error(err);
      }

      // Cleanup
      selection.removeAllRanges();
      window.document.body.removeChild(span);
    }
  }

  return success
    ? Promise.resolve()
    : Promise.reject(new DOMException('The request is not allowed', 'NotAllowedError'));
}

export interface CopyInputProps extends TextProps {
  text: string;
  children?: never;
  length: number;
  svgColor?: TextColor;
  prefix?: React.ReactElement;
}

export const CopyInput: React.FC<CopyInputProps> = ({ text, prefix, ...props }) => {
  return (
    <StyledText {...props}>
      {prefix}
      {formatter.truncateMiddle(text || '', 16)}

      <Tooltip key={'copy'} value={'Click to Copy'}>
        <HitArea
          onClick={() => {
            clipboardCopy(text);
            showToast({
              message: 'Copied to clipboard!',
              variant: ToastVariant.Success,
            });
          }}
          width={20}
          height={20}
          className="cursor-pointer"
        >
          <Icon color={props.svgColor || props.color} name={IconName.Copy} size={'1.125rem'} />
        </HitArea>
      </Tooltip>
    </StyledText>
  );
};

const StyledText = styled(Text)`
  display: inline-flex;
  align-items: center;
  gap: ${({ theme }) => toSpacing(theme)(2)};
`;
