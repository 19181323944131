import { type BadgeProps, BadgeVariant } from '@aircarbon/ui';
import type { Dto } from '@aircarbon/utils-common';

import type { DepositRequestResource } from './DepositRequestResource';

const badgePropsByStatus: Record<Dto.AssetDepositStatus, Pick<BadgeProps, 'variant' | 'value'>> = {
  NEW: {
    value: 'New',
    variant: BadgeVariant.Info,
  },
  APPROVED: {
    value: 'Approved',
    variant: BadgeVariant.Success,
  },
  COMPLETED: {
    value: 'Completed',
    variant: BadgeVariant.Inverted,
  },
  CONFIRMED_BY_USER: {
    value: 'User Approved',
    variant: BadgeVariant.Success,
  },
  REJECTED_BY_USER: {
    value: 'User Rejected',
    variant: BadgeVariant.Danger,
  },
  FAILED: {
    value: 'Failed',
    variant: BadgeVariant.Danger,
  },
  REJECTED: {
    value: 'Rejected',
    variant: BadgeVariant.Danger,
  },
};

export const toStatusBadgeProps = (request: DepositRequestResource) =>
  badgePropsByStatus[request.status] ?? {
    value: 'Unknown',
    variant: BadgeVariant.Default,
  };
