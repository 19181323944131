import { useEffect } from 'react';

import { Alert, AlertColor, Card, CardVariant, Layer, Toggle, ToggleLayout, useSpacing } from '@aircarbon/ui';

import { Account } from 'state/account';
import { Entity } from 'state/entity';

export const ReserveExtraFunds: React.FunctionComponent<{
  baseAssetSymbol?: string;
  quantity: number;
  reservedQuantity: number;
  value: boolean;
  sellerUserId: number | null;
  onExtraReservationNeeded(shouldReserveMoreFunds: boolean): void;
  onChange(isReservingMoreFunds: boolean): void;
}> = ({ baseAssetSymbol, quantity, reservedQuantity, onExtraReservationNeeded, onChange, value, sellerUserId }) => {
  const { spacing } = useSpacing();

  const { consolidatedTokens, refetchAccountData, isLoading } = Account.useContainer();

  const { entity } = Entity.useContainer();
  const loggedInFooterItems = entity?.config?.footer?.loggedIn;

  const userGuideLink = loggedInFooterItems?.find((item) => item.id === 'userGuide');

  useEffect(() => {
    refetchAccountData();
  }, []);

  if (baseAssetSymbol && !consolidatedTokens[baseAssetSymbol]?.asset) {
    onExtraReservationNeeded(true);
    return (
      <Alert
        color={AlertColor.Danger}
        marginTop={spacing(4)}
        title="Unable to retrieve asset details"
        description="The asset might be disabled"
      />
    );
  }

  if (!baseAssetSymbol || quantity <= reservedQuantity) {
    onExtraReservationNeeded(isLoading || false);
    return null;
  }

  onExtraReservationNeeded(isLoading || true);

  const extraFundsToReserve = quantity - reservedQuantity;

  const userBalanceForBaseAsset = consolidatedTokens[baseAssetSymbol]?.users?.find(
    (user) => user.user?.user_id === sellerUserId,
  )?.qty;
  const baseAsset = consolidatedTokens[baseAssetSymbol].asset;

  const balanceFormatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: baseAsset.numDecimals,
    maximumFractionDigits: baseAsset.numDecimals,
  });

  if (!userBalanceForBaseAsset || userBalanceForBaseAsset < extraFundsToReserve) {
    onChange(false);
    return (
      <Alert
        color={AlertColor.Danger}
        marginTop={spacing(4)}
        title="You do not have enough assets to make this counter offer"
        description={
          userBalanceForBaseAsset
            ? `Please lower down offered quantity to match your available balance of ${balanceFormatter.format(userBalanceForBaseAsset)} ${baseAsset.uom.code} or deposit extra ${balanceFormatter.format(extraFundsToReserve)} ${baseAsset.uom.code} to make this counter offer`
            : undefined
        }
        primaryButtonText="Learn how to Deposit"
        onPressPrimaryButton={() => {
          window.open(userGuideLink?.url, '_blank');
        }}
      />
    );
  }

  return (
    <>
      <Alert
        color={AlertColor.Danger}
        marginTop={spacing(4)}
        title={`You need to reserve extra ${balanceFormatter.format(extraFundsToReserve)} ${baseAsset.uom.code} in order to make this counter offer`}
      />
      <Layer>
        <Card marginTop={spacing(4)} variant={CardVariant.Bordered}>
          <Toggle
            marginHorizontal={spacing(8)}
            marginVertical={spacing(6)}
            layout={ToggleLayout.LabelFirst}
            isOn={value}
            onChange={onChange}
            label={`Reserve extra ${balanceFormatter.format(extraFundsToReserve)} ${baseAsset.uom.code}`}
            description={`Available balance: ${isLoading ? 'Loading...' : `${balanceFormatter.format(userBalanceForBaseAsset)} ${baseAsset.uom.code}`}`}
          />
        </Card>
      </Layer>
    </>
  );
};
