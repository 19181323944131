import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { fetchProjectById } from '../../../utils/fetchProjectById';
import type { ProjectInformationProps } from '../../ProjectDetail/components/ProjectInformation';
import { toProjectInformation } from '../utils/toProjectInformation';

export interface Project extends ProjectInformationProps {
  apxId: string;
  name: string;
  description: string;
  milestones: Array<[string, string, string]>;
  markets: Array<{
    id: string;
    token: string;
    bid: string;
    ask: string;
    last: string;
  }>;
  creditingPeriod: string;
}

interface UseProjectDetailHook {
  project?: Project;
  isLoading: boolean;
}

export const useProjectDetail = (): UseProjectDetailHook => {
  const [project, setProject] = useState<Project>();
  const [isLoading, setIsLoading] = useState(true);

  const { projectId } = useParams<{ projectId: string }>();

  useEffect(() => {
    const initializeProjectInformation = async () => {
      const projectResponse = await fetchProjectById({
        projectId,
      });

      if (!projectResponse) {
        // todo: throw not found error
        return;
      }

      setProject(toProjectInformation(projectResponse));
      setIsLoading(false);
    };

    initializeProjectInformation();
  }, [projectId]);

  return {
    project,
    isLoading,
  };
};
