import { useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import { SummaryCard } from 'refreshed-component/molecules/SummaryCard';
import { SummaryHolder } from 'refreshed-component/molecules/SummaryHolder';
import { AuctionBidsQueryKey } from 'refreshed-pages/auction/Marketplace';

import { Icon, IconName, TextColor } from '@aircarbon/ui';
import { formatter } from '@aircarbon/utils-common';

import { Account } from 'state/account';
import { Entity } from 'state/entity';

import useAccountBalances from 'hooks/useAccountBalances';

import { fetchMyCMBAsks } from 'data-provider/market-board';
import { fetchMyAuctionBid } from 'data-provider/market-board/fetchMyAuctionBid';

export const MarketBoardSummery = () => {
  const {
    selector: { mainCcyCode, mainCcyNumDecimals },
  } = Entity.useContainer();

  const { accountUsers } = Account.useContainer();
  const { balances } = useAccountBalances(accountUsers.filter((user) => !!user.account).map((user) => user.user_id));

  const totalCash = useMemo(
    () =>
      balances?.reduce((previousValue, currentValue) => {
        return previousValue + currentValue.balance;
      }, 0),
    [balances],
  );

  const totalOpenCash = useMemo(
    () =>
      balances?.reduce((previousValue, currentValue) => {
        const openAmount = (currentValue?.balance ?? 0) - (currentValue?.availableAmount ?? 0);
        return previousValue + openAmount;
      }, 0),
    [balances],
  );

  const auctionBidsQueryKey = useContext(AuctionBidsQueryKey);

  const { data: bids } = useQuery([auctionBidsQueryKey], () =>
    fetchMyAuctionBid({
      page: 1,
      limit: 1,
      status: 'NEW',
    }),
  );

  const getAsksURL = `/api/user/carbon/my-cmb-ask?page=1&limit=100&isAuction=yes&status=all`;
  const { data: asks } = useQuery(getAsksURL, async () =>
    fetchMyCMBAsks({
      page: 1,
      limit: 1,
      status: 'all',
      isAuction: 'yes',
    }),
  );
  return (
    <SummaryHolder>
      <SummaryCard
        icon={<Icon name={IconName.Wallet} />}
        title="Available Balance"
        value={`${mainCcyCode}${formatter.formatNumber(totalCash, mainCcyNumDecimals)}`}
        secondaryValue={
          totalOpenCash > 0 ? `-${mainCcyCode}${formatter.formatNumber(totalOpenCash, mainCcyNumDecimals)}` : undefined
        }
        secondaryValueColor={TextColor.error}
        secondaryTooltip={`Reserved Cash Amount`}
      />
      <SummaryCard icon={<Icon name={IconName.Bids} />} title="My Bids" value={`${bids?.total ?? ''}`} />
      <SummaryCard icon={<Icon name={IconName.Database} />} title="My Listings" value={`${asks?.total ?? ''}`} />
    </SummaryHolder>
  );
};
