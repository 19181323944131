import type { Dto } from '@aircarbon/utils-common';

type ToAssetAllocationsProps = {
  basketConfigurationJSON: string;
  assets: Dto.Asset[];
  assetBalance: number | null;
};

export const toGERAssetsAllocations = (props: ToAssetAllocationsProps) => {
  const { basketConfigurationJSON, assets, assetBalance } = props;
  try {
    const decoded = JSON.parse(basketConfigurationJSON);
    const tokenIds = decoded.tokens?.map((token: { tokenTypeId: string }) => Number(token.tokenTypeId)) ?? [];
    const tokens: Array<{
      label: string;
      value: string;
    }> = [];
    assets.forEach((asset) => {
      const { scId, name } = asset;
      if (!tokenIds.includes(scId)) {
        return;
      }

      const percentage = decoded.tokens.find(
        (token: { tokenTypeId: string }) => Number(token.tokenTypeId) === Number(scId),
      )?.percentage;

      const tokenValue = assetBalance ? (percentage / 100) * assetBalance : 0;

      const formattedValue = isNaN(tokenValue) ? '-' : new Intl.NumberFormat('en-US').format(tokenValue);

      tokens.push({
        label: `${name} (${percentage}%)`,
        value: formattedValue,
      });
    });
    return tokens;
  } catch {
    return [];
  }
};
