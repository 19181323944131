import type { SettleEntity } from 'generated';

import { ModalSettlementReserveRECStep } from '@aircarbon/ui';
import { assetCategoryNumDecimals, formatter } from '@aircarbon/utils-common';

import { toRemainingQuantity } from './toRemainingQuantity';

const { formatNumber } = formatter;

export const toReserveRECModalData = (props: {
  settlementDetails: SettleEntity;
  projects: Array<{
    name: string;
    id: string;
    balance: number;
  }>;
  quantitiesByProject: Record<string, number>;
  uom?: string;
}) => {
  const {
    settlementDetails: { quantity, assetCategoryId },
    quantitiesByProject,
    projects,
    uom = 'MWh',
  } = props;

  const numDecimals = assetCategoryNumDecimals[assetCategoryId];
  const remainingQuantity = toRemainingQuantity({
    totalQuantity: quantity,
    quantitiesByProject,
  });

  const result = {
    currentStep: ModalSettlementReserveRECStep.AllocateFunds,
    totalQuantity: `${formatNumber(quantity, numDecimals)} ${uom}`,
    remainingQuantity: `${formatNumber(remainingQuantity, numDecimals)} ${uom}`,
    projects: projects.map((project) => {
      return {
        id: project.id,
        title: project.name,
        value: quantitiesByProject[project.id] ? String(quantitiesByProject[project.id]) : '',
        balance: `${formatNumber(project.balance, numDecimals)} ${uom}`,
        unit: uom,
        isDisabled: !quantitiesByProject[project.id] && remainingQuantity === 0,
      };
    }),
    fundAllocation: Object.keys(quantitiesByProject).map((projectId) => {
      return {
        label: projects.find((project) => project.id === projectId)?.name ?? '',
        value: `${formatNumber(quantitiesByProject[projectId], numDecimals)} ${uom}`,
      };
    }),
  };

  return result;
};
