import { createContext } from 'react';

import type {
  DepositRequestsFilter,
  ModalApproveDepositRequestProps,
  ModalRejectDepositRequestProps,
} from '@aircarbon/ui';

import type { DepositRequestAlert } from './DepositRequestAlert';
import type { DepositRequestResource } from './DepositRequestResource';
import type { DepositRequestsPagination } from './DepositRequestsPagination';

export interface DepositRequestContextHandlers {
  changeFilter(filter: DepositRequestsFilter): void;
  changeSearch(search: string): void;
  changePagination(pagination: Partial<DepositRequestsPagination>): void;
  changeRejectionReason(rejectionReason: string): void;
  showApproveModal(requestId: string): void;
  hideApproveModal(): void;
  showRejectModal(requestId: string): void;
  hideRejectModal(): void;
  approveRequest(): void;
  rejectRequest(): void;
  removeAlert(index: number): void;
}

export interface DepositRequestsContextValue extends DepositRequestContextHandlers {
  search: string;
  isLoadingDepositRequests: boolean;
  depositRequests: Array<DepositRequestResource>;
  filters: Array<DepositRequestsFilter>;
  pagination: DepositRequestsPagination;
  modalApproveDepositRequestProps: Omit<ModalApproveDepositRequestProps, 'onClose' | 'onConfirm'>;
  alerts: Array<DepositRequestAlert>;
  modalRejectDepositRequestProps: Omit<
    ModalRejectDepositRequestProps,
    'onChangeRejectionReason' | 'onClose' | 'onPressReject'
  >;
}

export type DepositRequestsContextValueWithoutActions = Omit<
  DepositRequestsContextValue,
  keyof DepositRequestContextHandlers
>;

export const DepositRequestsContext = createContext<DepositRequestsContextValue>(null as any);
