/* eslint-disable no-underscore-dangle */
import get from 'lodash.get';
import { type Hex, fromWei, toBN, toWei } from 'web3-utils';

/**
 * @deprecated use convert unit instead
 * @param amount
 * @param decimal
 */
export function convertNumber(amount: number | string, decimal: number) {
  return toBN(amount).divn(10 ** decimal);
}

/*
 * Web3 return _hex but Esther return hex on the value
 * TODO: Need to check if this is still needed when remove web3
 */
export type HexValue = { _hex: Hex } | { hex: Hex };

/**
 *
 * @param value
 * @param decimals
 */
export function convertUnit(value: HexValue | number | undefined, decimals = 0): number {
  let inputValue = 0;
  if (typeof value === 'number') {
    inputValue = value;
  } else {
    inputValue = Number(toBN(get(value, 'hex') || get(value, '_hex') || 0).toString());
  }

  return Number(inputValue / 10 ** decimals);
}

export function thousandFormatter(num: number, digits = 1): string {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find((value) => num >= value.value);
  return item ? (num / item.value).toFixed(digits).replace(rx, '$1') + item.symbol : '0';
}
/**
 *
 * @param value
 */
export function hex2int(value: HexValue): number {
  return convertUnit(value);
}

/**
 *
 * @param val
 */
export function centsToUsd(val: number): number {
  return Number(val / 100);
}

/**
 *
 * @param cents
 */
export function hex2usd(value: HexValue): number {
  return centsToUsd(Number(toBN(get(value, 'hex') || get(value, '_hex') || 0).toString()));
}

/**
 *
 * @param wei
 */
export function hex2eth(value: HexValue): number | string {
  return fromWei(toBN(get(value, 'hex') || get(value, '_hex') || 0), 'ether');
}

/**
 *
 * @param val
 */
export function usdToCents(val: number): number {
  return Number(val * 100);
}

/**
 *
 * @param val
 */
export function ethToWei(val: number) {
  return toWei(toBN(val * 10 ** 18), 'wei');
}

/**
 *
 * @param balance
 * @param decimals
 */
export function formatCurrency(
  balance:
    | number
    | {
        _hex: string | number;
      }
    | undefined,
  decimals = 0,
): string {
  if (typeof balance === 'number') {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: Math.min(2, decimals),
      maximumFractionDigits: Math.max(2, decimals),
    }).format(balance);
  }

  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: Math.min(2, decimals),
    maximumFractionDigits: Math.max(2, decimals),
  }).format(convertUnit(balance, decimals));
}

/**
 * @deprecated use convertUnit instead
 */
export function convertUnitValue(unit: 'cents' | 'Wei' | 'Satoshi', balance: HexValue): number {
  const convertMapping: Record<string, Function> = {
    Wei: hex2eth,
    cents: hex2usd,
    Satoshi: (value: HexValue) => hex2int(value) / 10 ** 10,
  };

  return convertMapping?.[unit]?.(balance);
}

/**
 *
 * @param number
 * @param decimals
 */
export function formatNumber(number: number, decimals = 2): string {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  }).format(number);
}

/**
 *
 * @param price
 * @param currencyCode
 */
export function formatPrice(price: number, currencyCode: string): string {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  }).format(price);
}

/**
 *
 * @param s
 * @param n
 */
export function truncateMiddle(s: string, n = 16): string {
  return s?.length > n && s?.length > 0
    ? `${s.substring(0, s.length / 2 - (s.length - n) / 2)}…${s.substring(s.length / 2 + (s.length - n) / 2)}`
    : s;
}

/**
 *
 * @param number
 * @param blockTime
 */
export function dateFromBlocks(number: number, blockTime = 15): string {
  return `${Math.floor((number * blockTime) / 60 / 60 / 24)} days`;
}

export default {
  hex2eth,
  hex2int,
  hex2usd,
  centsToUsd,
  convertUnitValue,
  convertNumber,
  usdToCents,
  ethToWei,
  formatCurrency,
  formatNumber,
  formatPrice,
  convertUnit,
  thousandFormatter,
  truncateMiddle,
  dateFromBlocks,
};
