import { useState } from 'react';

import {
  ButtonSize,
  ButtonVariant,
  Knob,
  Tabs,
  TabsSize,
  TabsVariant,
  Text,
  TextColor,
  Toggle,
  ToggleLayout,
  ToggleSize,
  TypographyVariant,
  styled,
  toLayerBackground,
  toTextColor,
} from '@aircarbon/ui';
import type { Pair } from '@aircarbon/utils-common/src/dto';

import { checkIfNonBilateral } from 'utils/checkIfTokenOrRec';

import type { AssetCategoryCode, Layouts } from '../layouts/trading.hook';
import OrderBookBiofuel from './OrderBookBiofuel';
import OrderBookL2 from './OrderBookL2';
import OrderBookL3 from './OrderBookL3';

type Theme = 'red' | 'green' | 'gray';

const Wrapper = styled.div`
  width: 100%;
  padding-top: 5px;
`;

export const CurrentPriceWrapper = styled.div<{ $theme?: Theme }>`
  display: flex;
  flex-direction: row;
  font-size: 11px;
  gap: 5px;
  background-color: ${(props) =>
    props.$theme === `gray` ? toLayerBackground(props.theme)('layerAccent') : toLayerBackground(props.theme)('layer')};
  padding: 5px 0;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  transition:
    border 300ms,
    color 300ms;
  ${(props) => {
    if (props.$theme === 'red') {
      return `color: ${toTextColor(props.theme)(TextColor.error)};`;
    } else if (props.$theme === 'green') {
      return `color: ${toTextColor(props.theme)(TextColor.success)};`;
    } else {
      return `color: ${toTextColor(props.theme)(TextColor.primary)};`;
    }
  }};
`;

export const CurrentPrice = styled(Text).attrs({
  variant: TypographyVariant.caption,
})``;

const StyledRow = styled(Text).attrs({
  variant: TypographyVariant.subtitle2,
})<{ isPublic?: boolean; isHeader?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 26px;
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 1px;
  ${(props) =>
    props.isHeader
      ? `background: ${toLayerBackground(props.theme)('layerAccent')};`
      : `
    background: ${toLayerBackground(props.theme)('layer')};
    ${
      !props.isPublic
        ? `&:hover {
      background-color: ${toLayerBackground(props.theme)('layerHover')};
      cursor: pointer;
    }`
        : ``
    }
  `}

  &.order-disabled {
    opacity: 0.5;
    background: transparent;
  }
`;

export const Row: React.FC<{
  className?: string;
  isPublic?: boolean;
  isHeader?: boolean;
  onClick?(): void;
}> = (props) => {
  return (
    <div className={props.className} onClick={props.onClick}>
      <StyledRow {...props} />
    </div>
  );
};

export const Bar = styled.div<{ $theme?: Theme; value: number; align?: 'right' | 'left' }>`
  width: ${(props) => props.value}%;
  height: 100%;
  position: absolute;
  background-color: ${(props) =>
    props.$theme === 'green'
      ? `${toLayerBackground(props.theme)('layerSuccess')}`
      : `${toLayerBackground(props.theme)('layerDanger')}`};
  z-index: 1;
  ${(props) => (props.align === 'left' ? 'left: 0;' : 'right: 0;')}
`;

const ViewsWrapper = styled.div<{ layout?: Layouts }>`
  ${(props) =>
    props.layout === 'mobile' || props.layout === 'tablet'
      ? ``
      : `position: absolute;
  top: -28px;
  left: 100px;
  right: 0;`}
  gap: 10px;
`;

export const Cell = styled(Text).attrs<{ $theme?: Theme | 'light' }>({
  variant: TypographyVariant.caption,
})`
  ${(props) => {
    if (props.$theme === 'red') {
      return `color: ${toTextColor(props.theme)(TextColor.error)};`;
    } else if (props.$theme === 'green') {
      return `color: ${toTextColor(props.theme)(TextColor.success)};`;
    } else if (props.$theme === 'light') {
      return `color: ${toTextColor(props.theme)(TextColor.secondary)};`;
    } else {
      return `color: ${toTextColor(props.theme)(TextColor.primary)};`;
    }
  }}
  &.align-right {
    display: flex;
    justify-content: end;
  }
  &.align-left {
    display: flex;
    justify-content: start;
  }
  &.dropdown {
    cursor: pointer;
  }
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: 2;
`;

export type OrderBookProps = {
  pair?: Pair;
  isPublic?: boolean;
  orderBook: any;
  isLoading?: boolean;
  style?: React.CSSProperties;
  view?: 'LeftAndRight' | 'TopAndBottom';
  level?: 'OB-L2' | 'OB-L3';
  link?: {
    label: string;
    url: string;
  };
  showCumulative?: boolean;
  onViewChanges?: (view: 'LeftAndRight' | 'TopAndBottom') => void;
  onLevelChanges?: (view: 'OB-L2' | 'OB-L3') => void;
  layout?: Layouts;
  theme?: 'gray';
  fromFlexLayout?: true;
  assetCategory?: AssetCategoryCode;
};

const OrderBook = ({ assetCategory = 'token', ...props }: OrderBookProps) => {
  const { style, onViewChanges, onLevelChanges, layout, view, level, isPublic } = props;
  const [showRestingOrders, setShowRestingOrders] = useState(false);

  const isNonBilateral = checkIfNonBilateral(assetCategory);

  return (
    <Wrapper style={style} className="flex absolute flex-col w-full h-full">
      <ViewsWrapper
        layout={layout}
        className="flex flex-row gap-3 justify-start mb-3"
        style={
          props.fromFlexLayout
            ? {
                position: 'relative',
                top: 'auto',
                left: 'auto',
              }
            : {}
        }
      >
        <div
          className="flex flex-row gap-1"
          style={{
            position: props.fromFlexLayout ? 'relative' : undefined,
            maxWidth: '100%',
            width: '100%',
          }}
        >
          {!isPublic && (
            <>
              {isNonBilateral && (
                <Tabs
                  items={[
                    {
                      id: 'OB-L3',
                      label: 'L3',
                    },
                    {
                      id: 'OB-L2',
                      label: 'L2',
                    },
                  ]}
                  size={TabsSize.m}
                  variant={TabsVariant.Contained}
                  activeItemId={level === 'OB-L3' ? 'OB-L3' : 'OB-L2'}
                  onPressTab={({ id }) => {
                    onLevelChanges?.(id as 'OB-L2' | 'OB-L3');
                  }}
                />
              )}

              {level === 'OB-L3' && isNonBilateral && (
                <div className="flex overflow-hidden flex-row flex-shrink items-center">
                  <Toggle
                    size={ToggleSize.s}
                    label="Show Resting Orders"
                    layout={ToggleLayout.LabelFirst}
                    onChange={() => setShowRestingOrders(!showRestingOrders)}
                    isOn={showRestingOrders}
                  />
                </div>
              )}
            </>
          )}
          <div className="flex flex-row gap-1 ml-auto">
            <Knob
              icon={
                <svg width="16" height="16" viewBox="0 0 20 20" fill="none">
                  <path d="M0 0H20V3H0V0Z" fill="#666" />
                  <path d="M11 5H20V20H11V5Z" fill="#F6465D" />
                  <path d="M0 5H9V20H0V5Z" fill="#0ECB81" />
                </svg>
              }
              size={ButtonSize.xs}
              variant={ButtonVariant.outlined}
              onPress={() => onViewChanges?.('LeftAndRight')}
              isActive={view === 'LeftAndRight'}
            />
            <Knob
              icon={
                <svg width="16" height="16" viewBox="0 0 20 20" fill="none">
                  <path d="M0 8.18182H20V10.9091H0V8.18182Z" fill="#666" />
                  <path d="M0 13H20V20H0V13Z" fill="#0ECB81" />
                  <path d="M0 0H20V6H0V0Z" fill="#F6465D" />
                </svg>
              }
              size={ButtonSize.xs}
              variant={ButtonVariant.outlined}
              onPress={() => onViewChanges?.('TopAndBottom')}
              isActive={view === 'TopAndBottom'}
            />
          </div>
        </div>
      </ViewsWrapper>
      <>
        {assetCategory === 'biofuel' ? (
          <OrderBookBiofuel isPublic={isPublic} {...props} />
        ) : (
          <>
            {level === 'OB-L3' && !isPublic ? (
              <OrderBookL3 isPublic={isPublic} showRestingOrders={showRestingOrders} {...props} />
            ) : (
              <OrderBookL2 isPublic={isPublic} {...props} />
            )}
          </>
        )}
      </>
    </Wrapper>
  );
};

export default OrderBook;
