export enum Spacing {
  _3xs = '--spacing-3xs',
  _2xs = '--spacing-2xs',
  _1_5 = '--spacing-1-5',
  xs = '--spacing-xs',
  small = '--spacing-small',
  medium = '--spacing-medium',
  base = '--spacing-base',
  large = '--spacing-large',
  xl = '--spacing-xl',
  _2xl = '--spacing-2xl',
  _3xl = '--spacing-3xl',
  _4xl = '--spacing-4xl',
  _5xl = '--spacing-5xl',
  _6xl = '--spacing-6xl',
}

export enum Radius {
  xl = '--border-radius-xl',
  large = '--border-radius-large',
  medium = '--border-radius-medium',
  small = '--border-radius-small',
}
