import { useState } from 'react';
import { useQuery } from 'react-query';
import { findGERAsset } from 'refreshed-pages/ger/utils/findGERAsset';
import { toAssetsAllocations } from 'refreshed-pages/ger/utils/toAssetsAllocations';
import { toValidationErrorsMap } from 'refreshed-pages/ger/utils/toValidationErrorsMap';

import { ToastVariant, showToast } from '@aircarbon/ui';

import { Contract } from 'state/contract';
import { User } from 'state/user';

import { toAssetBalance } from '../../GERBalance/utils/toAssetBalance';
import { unpackGER } from '../utils/unpackGER';

export const useGERUnpacker = (props: {
  onUnpackingFinished(): void;
}): {
  quantity: string;
  assets: Array<{
    assetType: string;
    balance: string;
    packQuantity: string;
    balanceAfter: string;
  }>;
  isUnpackingInProgress: boolean;
  isUnpackingDisabled: boolean;
  validationErrorsMap: Record<string, string>;
  resultingBalance: string;
  changeQuantity(newQuantity: string): void;
  unpack(): void;
  resetUserInput(): void;
} => {
  const [quantity, setQuantity] = useState('');
  const [isTouched, setIsTouched] = useState(false);
  const [isUnpackingInProgress, setIsUnpackingInProgress] = useState(false);

  const {
    selector: { getAuthToken },
  } = User.useContainer();

  const { tokenTypes } = Contract.useContainer();
  const { data: tokensQty = {} } = useQuery<Record<number, number>>(['ger-tokens-qty'], async () => {
    // TODO: Implement data-provider
    const authToken = await getAuthToken();
    return fetch(`/api/account/basket/token-available`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authToken}`,
      },
    }).then((resp) => resp.json());
  });
  const gerAsset = findGERAsset(tokenTypes);
  const assetBalance = toAssetBalance({
    asset: gerAsset,
    assetBalances: tokensQty,
  });

  const assetsAllocations = toAssetsAllocations({
    assetConfigurationJSON: gerAsset?.configuration,
    assets: tokenTypes,
    tokensQuantities: tokensQty,
  });

  const result = assetBalance
    ? unpackGER({
        quantity: Number(quantity || 0),
        currentBalance: assetBalance,
        assetsAllocations,
        scalingRatio: gerAsset?.uom?.scRatio ?? 1000,
      })
    : {
        assetBalances: [],
        resultingBalance: 0,
      };

  const validationErrorsMap = toValidationErrorsMap({
    quantity: Number(quantity || 0),
    assetsBalances: result.assetBalances,
    resultingBalance: result.resultingBalance,
  });

  const isUnpackingDisabled = false;

  const changeQuantity = (newQuantity: string) => {
    setQuantity(newQuantity);
  };

  const unpack = async () => {
    setIsTouched(true);

    if (validationErrorsMap) {
      return;
    }

    setIsUnpackingInProgress(true);
    try {
      // TODO: Implement data-mutation
      const authToken = await getAuthToken();
      const response = await fetch('/api/account/basket/unpacking', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          tokTypeId: gerAsset?.scId,
          qtyUnit: Number(quantity),
        }),
      });
      if (response.ok) {
        showToast({
          variant: ToastVariant.Info,
          message: 'Processing ...',
        });
        props.onUnpackingFinished();
      } else {
        const result = await response.json();
        // https://github.com/developit/unfetch#caveats
        throw new Error(result.message || response.statusText);
      }
      setIsUnpackingInProgress(false);
    } catch (err: any) {
      setIsUnpackingInProgress(false);

      showToast({
        variant: ToastVariant.Danger,
        message: err.message,
      });
    }
  };

  const resetUserInput = () => {
    setQuantity('');
    setIsTouched(false);
  };

  return {
    quantity,
    isUnpackingDisabled,
    isUnpackingInProgress,
    validationErrorsMap: isTouched ? validationErrorsMap || {} : {},
    assets: result.assetBalances.map((balance) => ({
      assetType: `${balance.name} (${balance.percentage}%)`,
      balance: new Intl.NumberFormat('en-US').format(balance.availableBalance),
      packQuantity: new Intl.NumberFormat('en-US').format(balance.balanceChange),
      balanceAfter: new Intl.NumberFormat('en-US').format(balance.resultingBalance),
    })),
    resultingBalance: new Intl.NumberFormat('en-US').format(result.resultingBalance),
    resetUserInput,
    changeQuantity,
    unpack,
  };
};
